import { nl, de, enGB } from 'date-fns/locale';
import format from 'date-fns/format';

export const getFormattedDate = (date: Date, dateFormat: string, locale?: string): string => {
	try {
		switch (locale) {
			case 'nl': {
				return format(date, dateFormat, {
					locale: nl
				});
			}
			case 'de': {
				return format(date, dateFormat, {
					locale: de
				});
			}
			case 'en': {
				return format(date, dateFormat, {
					locale: enGB
				});
			}
			default: {
				return format(date, dateFormat, {
					locale: nl
				});
			}
		}
	} catch (error) {
		return '';
	}
};

export const getDateFnsLocale = (locale?: string) => {
	switch (locale) {
		case 'nl': {
			return nl;
		}
		case 'de': {
			return de;
		}
		case 'en': {
			return enGB;
		}
		default: {
			return nl;
		}
	}
};
