import * as Types from './graphql-types';

import * as Operations from './graphql-types';
import * as Apollo from '@apollo/client';
import type React from 'react';
import type { NormalizedCacheObject } from '@apollo/client';

export async function getServerPageGetComments(
	options: Omit<Apollo.QueryOptions<Types.GetCommentsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetCommentsQuery>({ ...options, query: Operations.GetCommentsDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetCommentsComp = React.FC<{ data?: Types.GetCommentsQuery; error?: Apollo.ApolloError }>;
export const ssrGetComments = {
	getServerPage: getServerPageGetComments
};

export async function getServerPageGetCondoleanceSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetCondoleancePageSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetCondoleancePageSlugsQuery>({
		...options,
		query: Operations.GetCondoleancePageSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetCondoleanceSlugsComp = React.FC<{
	data?: Types.GetCondoleancePageSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetCondoleanceSlugs = {
	getServerPage: getServerPageGetCondoleanceSlugs
};
export async function getServerPageGetEfroOostInConvoWithArticleSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostInConvoWithArticleSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostInConvoWithArticleSlugsQuery>({
		...options,
		query: Operations.GetEfroOostInConvoWithArticleSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostInConvoWithArticleSlugsComp = React.FC<{
	data?: Types.GetEfroOostInConvoWithArticleSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetEfroOostInConvoWithArticleSlugs = {
	getServerPage: getServerPageGetEfroOostInConvoWithArticleSlugs
};
export async function getServerPageGetEfroOostInConvoWithArticles(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostInConvoWithArticlesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostInConvoWithArticlesQuery>({
		...options,
		query: Operations.GetEfroOostInConvoWithArticlesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostInConvoWithArticlesComp = React.FC<{
	data?: Types.GetEfroOostInConvoWithArticlesQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetEfroOostInConvoWithArticles = {
	getServerPage: getServerPageGetEfroOostInConvoWithArticles
};
export async function getServerPageGetEfroOostNewsArticleSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostNewsArticleSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostNewsArticleSlugsQuery>({
		...options,
		query: Operations.GetEfroOostNewsArticleSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostNewsArticleSlugsComp = React.FC<{
	data?: Types.GetEfroOostNewsArticleSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetEfroOostNewsArticleSlugs = {
	getServerPage: getServerPageGetEfroOostNewsArticleSlugs
};
export async function getServerPageGetEfroOostNewsArticles(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostNewsArticlesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostNewsArticlesQuery>({
		...options,
		query: Operations.GetEfroOostNewsArticlesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostNewsArticlesComp = React.FC<{
	data?: Types.GetEfroOostNewsArticlesQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetEfroOostNewsArticles = {
	getServerPage: getServerPageGetEfroOostNewsArticles
};
export async function getServerPageGetEfroOostSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostPageSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostPageSlugsQuery>({
		...options,
		query: Operations.GetEfroOostPageSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostSlugsComp = React.FC<{ data?: Types.GetEfroOostPageSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetEfroOostSlugs = {
	getServerPage: getServerPageGetEfroOostSlugs
};
export async function getServerPageGetEvents(
	options: Omit<Apollo.QueryOptions<Types.GetEventsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEventsQuery>({ ...options, query: Operations.GetEventsDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEventsComp = React.FC<{ data?: Types.GetEventsQuery; error?: Apollo.ApolloError }>;
export const ssrGetEvents = {
	getServerPage: getServerPageGetEvents
};
export async function getServerPageFindNotesByRelation(
	options: Omit<Apollo.QueryOptions<Types.FindNotesByRelationQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.FindNotesByRelationQuery>({
		...options,
		query: Operations.FindNotesByRelationDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageFindNotesByRelationComp = React.FC<{
	data?: Types.FindNotesByRelationQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrFindNotesByRelation = {
	getServerPage: getServerPageFindNotesByRelation
};
export async function getServerPageGetGeolinkSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetGeolinkPageSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetGeolinkPageSlugsQuery>({
		...options,
		query: Operations.GetGeolinkPageSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetGeolinkSlugsComp = React.FC<{ data?: Types.GetGeolinkPageSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetGeolinkSlugs = {
	getServerPage: getServerPageGetGeolinkSlugs
};
export async function getServerPageGlobalSearch(
	options: Omit<Apollo.QueryOptions<Types.GlobalSearchQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GlobalSearchQuery>({
		...options,
		query: Operations.GlobalSearchDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGlobalSearchComp = React.FC<{ data?: Types.GlobalSearchQuery; error?: Apollo.ApolloError }>;
export const ssrGlobalSearch = {
	getServerPage: getServerPageGlobalSearch
};
export async function getServerPageGlobalSearchDocuments(
	options: Omit<Apollo.QueryOptions<Types.GlobalSearchDocumentsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GlobalSearchDocumentsQuery>({
		...options,
		query: Operations.GlobalSearchDocumentsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGlobalSearchDocumentsComp = React.FC<{
	data?: Types.GlobalSearchDocumentsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGlobalSearchDocuments = {
	getServerPage: getServerPageGlobalSearchDocuments
};
export async function getServerPageGlobalSearchWooRequests(
	options: Omit<Apollo.QueryOptions<Types.GlobalSearchWooRequestsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GlobalSearchWooRequestsQuery>({
		...options,
		query: Operations.GlobalSearchWooRequestsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGlobalSearchWooRequestsComp = React.FC<{
	data?: Types.GlobalSearchWooRequestsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGlobalSearchWooRequests = {
	getServerPage: getServerPageGlobalSearchWooRequests
};
export async function getServerPageGlobalSearchWobRequests(
	options: Omit<Apollo.QueryOptions<Types.GlobalSearchWobRequestsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GlobalSearchWobRequestsQuery>({
		...options,
		query: Operations.GlobalSearchWobRequestsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGlobalSearchWobRequestsComp = React.FC<{
	data?: Types.GlobalSearchWobRequestsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGlobalSearchWobRequests = {
	getServerPage: getServerPageGlobalSearchWobRequests
};
export async function getServerPageGlobalSearchNotes(
	options: Omit<Apollo.QueryOptions<Types.GlobalSearchNotesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GlobalSearchNotesQuery>({
		...options,
		query: Operations.GlobalSearchNotesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGlobalSearchNotesComp = React.FC<{ data?: Types.GlobalSearchNotesQuery; error?: Apollo.ApolloError }>;
export const ssrGlobalSearchNotes = {
	getServerPage: getServerPageGlobalSearchNotes
};
export async function getServerPageGetHighlight(
	options: Omit<Apollo.QueryOptions<Types.GetHighlightQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetHighlightQuery>({
		...options,
		query: Operations.GetHighlightDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetHighlightComp = React.FC<{ data?: Types.GetHighlightQuery; error?: Apollo.ApolloError }>;
export const ssrGetHighlight = {
	getServerPage: getServerPageGetHighlight
};
export async function getServerPageGetHighlightWithTheme(
	options: Omit<Apollo.QueryOptions<Types.GetHighlightWithThemeQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetHighlightWithThemeQuery>({
		...options,
		query: Operations.GetHighlightWithThemeDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetHighlightWithThemeComp = React.FC<{
	data?: Types.GetHighlightWithThemeQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetHighlightWithTheme = {
	getServerPage: getServerPageGetHighlightWithTheme
};
export async function getServerPageGetInConvoWithArticleSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetInConvoWithArticleSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetInConvoWithArticleSlugsQuery>({
		...options,
		query: Operations.GetInConvoWithArticleSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInConvoWithArticleSlugsComp = React.FC<{
	data?: Types.GetInConvoWithArticleSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInConvoWithArticleSlugs = {
	getServerPage: getServerPageGetInConvoWithArticleSlugs
};
export async function getServerPageGetInConvoWithArticles(
	options: Omit<Apollo.QueryOptions<Types.GetInConvoWithArticlesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetInConvoWithArticlesQuery>({
		...options,
		query: Operations.GetInConvoWithArticlesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInConvoWithArticlesComp = React.FC<{
	data?: Types.GetInConvoWithArticlesQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInConvoWithArticles = {
	getServerPage: getServerPageGetInConvoWithArticles
};
export async function getServerPageGetLogin(
	options: Omit<Apollo.QueryOptions<Types.GetLoginQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetLoginQuery>({ ...options, query: Operations.GetLoginDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetLoginComp = React.FC<{ data?: Types.GetLoginQuery; error?: Apollo.ApolloError }>;
export const ssrGetLogin = {
	getServerPage: getServerPageGetLogin
};
export async function getServerPageMapsFiguresSearch(
	options: Omit<Apollo.QueryOptions<Types.MapsFiguresSearchQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.MapsFiguresSearchQuery>({
		...options,
		query: Operations.MapsFiguresSearchDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageMapsFiguresSearchComp = React.FC<{ data?: Types.MapsFiguresSearchQuery; error?: Apollo.ApolloError }>;
export const ssrMapsFiguresSearch = {
	getServerPage: getServerPageMapsFiguresSearch
};
export async function getServerPageGetNewsArticleSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetNewsArticleSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetNewsArticleSlugsQuery>({
		...options,
		query: Operations.GetNewsArticleSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetNewsArticleSlugsComp = React.FC<{
	data?: Types.GetNewsArticleSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetNewsArticleSlugs = {
	getServerPage: getServerPageGetNewsArticleSlugs
};
export async function getServerPageGetNewsArticles(
	options: Omit<Apollo.QueryOptions<Types.GetNewsArticlesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetNewsArticlesQuery>({
		...options,
		query: Operations.GetNewsArticlesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetNewsArticlesComp = React.FC<{ data?: Types.GetNewsArticlesQuery; error?: Apollo.ApolloError }>;
export const ssrGetNewsArticles = {
	getServerPage: getServerPageGetNewsArticles
};
export async function getServerPageGetNewsArticleContactPerson(
	options: Omit<Apollo.QueryOptions<Types.GetNewsArticleContactPersonQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetNewsArticleContactPersonQuery>({
		...options,
		query: Operations.GetNewsArticleContactPersonDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetNewsArticleContactPersonComp = React.FC<{
	data?: Types.GetNewsArticleContactPersonQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetNewsArticleContactPerson = {
	getServerPage: getServerPageGetNewsArticleContactPerson
};
export async function getServerPageGetManyNewsletters(
	options: Omit<Apollo.QueryOptions<Types.GetManyNewslettersQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetManyNewslettersQuery>({
		...options,
		query: Operations.GetManyNewslettersDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetManyNewslettersComp = React.FC<{ data?: Types.GetManyNewslettersQuery; error?: Apollo.ApolloError }>;
export const ssrGetManyNewsletters = {
	getServerPage: getServerPageGetManyNewsletters
};
export async function getServerPageGetNewsletterContact(
	options: Omit<Apollo.QueryOptions<Types.GetNewsletterContactQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetNewsletterContactQuery>({
		...options,
		query: Operations.GetNewsletterContactDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetNewsletterContactComp = React.FC<{
	data?: Types.GetNewsletterContactQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetNewsletterContact = {
	getServerPage: getServerPageGetNewsletterContact
};
export async function getServerPageGetNotificationBar(
	options: Omit<Apollo.QueryOptions<Types.GetNotificationBarQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetNotificationBarQuery>({
		...options,
		query: Operations.GetNotificationBarDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetNotificationBarComp = React.FC<{ data?: Types.GetNotificationBarQuery; error?: Apollo.ApolloError }>;
export const ssrGetNotificationBar = {
	getServerPage: getServerPageGetNotificationBar
};
export async function getServerPageGetOpOostInConvoWithArticleSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostInConvoWithArticleSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostInConvoWithArticleSlugsQuery>({
		...options,
		query: Operations.GetOpOostInConvoWithArticleSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostInConvoWithArticleSlugsComp = React.FC<{
	data?: Types.GetOpOostInConvoWithArticleSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpOostInConvoWithArticleSlugs = {
	getServerPage: getServerPageGetOpOostInConvoWithArticleSlugs
};
export async function getServerPageGetOpOostInConvoWithArticles(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostInConvoWithArticlesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostInConvoWithArticlesQuery>({
		...options,
		query: Operations.GetOpOostInConvoWithArticlesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostInConvoWithArticlesComp = React.FC<{
	data?: Types.GetOpOostInConvoWithArticlesQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpOostInConvoWithArticles = {
	getServerPage: getServerPageGetOpOostInConvoWithArticles
};
export async function getServerPageGetOpOostNewsArticleSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostNewsArticleSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostNewsArticleSlugsQuery>({
		...options,
		query: Operations.GetOpOostNewsArticleSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostNewsArticleSlugsComp = React.FC<{
	data?: Types.GetOpOostNewsArticleSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpOostNewsArticleSlugs = {
	getServerPage: getServerPageGetOpOostNewsArticleSlugs
};
export async function getServerPageGetOpOostNewsArticles(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostNewsArticlesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostNewsArticlesQuery>({
		...options,
		query: Operations.GetOpOostNewsArticlesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostNewsArticlesComp = React.FC<{
	data?: Types.GetOpOostNewsArticlesQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpOostNewsArticles = {
	getServerPage: getServerPageGetOpOostNewsArticles
};
export async function getServerPageGetOpOostSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostPageSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostPageSlugsQuery>({
		...options,
		query: Operations.GetOpOostPageSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostSlugsComp = React.FC<{ data?: Types.GetOpOostPageSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetOpOostSlugs = {
	getServerPage: getServerPageGetOpOostSlugs
};
export async function getServerPageGetSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetPageSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPageSlugsQuery>({
		...options,
		query: Operations.GetPageSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetSlugsComp = React.FC<{ data?: Types.GetPageSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetSlugs = {
	getServerPage: getServerPageGetSlugs
};
export async function getServerPageGetPermitSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetPermitSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPermitSlugsQuery>({
		...options,
		query: Operations.GetPermitSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPermitSlugsComp = React.FC<{ data?: Types.GetPermitSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetPermitSlugs = {
	getServerPage: getServerPageGetPermitSlugs
};
export async function getServerPageGetPermits(
	options: Omit<Apollo.QueryOptions<Types.GetPermitsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPermitsQuery>({ ...options, query: Operations.GetPermitsDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPermitsComp = React.FC<{ data?: Types.GetPermitsQuery; error?: Apollo.ApolloError }>;
export const ssrGetPermits = {
	getServerPage: getServerPageGetPermits
};
export async function getServerPageGetPressReleaseSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetPressReleaseSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPressReleaseSlugsQuery>({
		...options,
		query: Operations.GetPressReleaseSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPressReleaseSlugsComp = React.FC<{
	data?: Types.GetPressReleaseSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPressReleaseSlugs = {
	getServerPage: getServerPageGetPressReleaseSlugs
};
export async function getServerPageGetPressReleases(
	options: Omit<Apollo.QueryOptions<Types.GetPressReleasesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPressReleasesQuery>({
		...options,
		query: Operations.GetPressReleasesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPressReleasesComp = React.FC<{ data?: Types.GetPressReleasesQuery; error?: Apollo.ApolloError }>;
export const ssrGetPressReleases = {
	getServerPage: getServerPageGetPressReleases
};
export async function getServerPageGetPressReleaseContactPerson(
	options: Omit<Apollo.QueryOptions<Types.GetPressReleaseContactPersonQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPressReleaseContactPersonQuery>({
		...options,
		query: Operations.GetPressReleaseContactPersonDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPressReleaseContactPersonComp = React.FC<{
	data?: Types.GetPressReleaseContactPersonQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPressReleaseContactPerson = {
	getServerPage: getServerPageGetPressReleaseContactPerson
};
export async function getServerPageGetProjectSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetProjectSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetProjectSlugsQuery>({
		...options,
		query: Operations.GetProjectSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetProjectSlugsComp = React.FC<{ data?: Types.GetProjectSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetProjectSlugs = {
	getServerPage: getServerPageGetProjectSlugs
};
export async function getServerPageGetProjects(
	options: Omit<Apollo.QueryOptions<Types.GetProjectsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetProjectsQuery>({ ...options, query: Operations.GetProjectsDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetProjectsComp = React.FC<{ data?: Types.GetProjectsQuery; error?: Apollo.ApolloError }>;
export const ssrGetProjects = {
	getServerPage: getServerPageGetProjects
};
export async function getServerPageGetRegioExpresInConvoWithArticleSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresInConvoWithArticleSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresInConvoWithArticleSlugsQuery>({
		...options,
		query: Operations.GetRegioExpresInConvoWithArticleSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresInConvoWithArticleSlugsComp = React.FC<{
	data?: Types.GetRegioExpresInConvoWithArticleSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresInConvoWithArticleSlugs = {
	getServerPage: getServerPageGetRegioExpresInConvoWithArticleSlugs
};
export async function getServerPageGetRegioExpresInConvoWithArticles(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresInConvoWithArticlesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresInConvoWithArticlesQuery>({
		...options,
		query: Operations.GetRegioExpresInConvoWithArticlesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresInConvoWithArticlesComp = React.FC<{
	data?: Types.GetRegioExpresInConvoWithArticlesQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresInConvoWithArticles = {
	getServerPage: getServerPageGetRegioExpresInConvoWithArticles
};
export async function getServerPageGetRegioExpresNewsArticleSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresNewsArticleSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresNewsArticleSlugsQuery>({
		...options,
		query: Operations.GetRegioExpresNewsArticleSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresNewsArticleSlugsComp = React.FC<{
	data?: Types.GetRegioExpresNewsArticleSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresNewsArticleSlugs = {
	getServerPage: getServerPageGetRegioExpresNewsArticleSlugs
};
export async function getServerPageGetRegioExpresNewsArticles(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresNewsArticlesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresNewsArticlesQuery>({
		...options,
		query: Operations.GetRegioExpresNewsArticlesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresNewsArticlesComp = React.FC<{
	data?: Types.GetRegioExpresNewsArticlesQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresNewsArticles = {
	getServerPage: getServerPageGetRegioExpresNewsArticles
};
export async function getServerPageGetRegioexpresSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetRegioexpresPageSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioexpresPageSlugsQuery>({
		...options,
		query: Operations.GetRegioexpresPageSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioexpresSlugsComp = React.FC<{
	data?: Types.GetRegioexpresPageSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioexpresSlugs = {
	getServerPage: getServerPageGetRegioexpresSlugs
};
export async function getServerPageGetRegionSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetRegionSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegionSlugsQuery>({
		...options,
		query: Operations.GetRegionSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegionSlugsComp = React.FC<{ data?: Types.GetRegionSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetRegionSlugs = {
	getServerPage: getServerPageGetRegionSlugs
};
export async function getServerPageGetRegions(
	options: Omit<Apollo.QueryOptions<Types.GetRegionsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegionsQuery>({ ...options, query: Operations.GetRegionsDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegionsComp = React.FC<{ data?: Types.GetRegionsQuery; error?: Apollo.ApolloError }>;
export const ssrGetRegions = {
	getServerPage: getServerPageGetRegions
};
export async function getServerPageGetRoadMaintenancesSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetRoadMaintenancesSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRoadMaintenancesSlugsQuery>({
		...options,
		query: Operations.GetRoadMaintenancesSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRoadMaintenancesSlugsComp = React.FC<{
	data?: Types.GetRoadMaintenancesSlugsQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRoadMaintenancesSlugs = {
	getServerPage: getServerPageGetRoadMaintenancesSlugs
};
export async function getServerPageGetRoadMaintenances(
	options: Omit<Apollo.QueryOptions<Types.GetRoadMaintenancesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRoadMaintenancesQuery>({
		...options,
		query: Operations.GetRoadMaintenancesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRoadMaintenancesComp = React.FC<{
	data?: Types.GetRoadMaintenancesQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRoadMaintenances = {
	getServerPage: getServerPageGetRoadMaintenances
};
export async function getServerPageGetFullSitemap(
	options: Omit<Apollo.QueryOptions<Types.GetFullSitemapQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetFullSitemapQuery>({
		...options,
		query: Operations.GetFullSitemapDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetFullSitemapComp = React.FC<{ data?: Types.GetFullSitemapQuery; error?: Apollo.ApolloError }>;
export const ssrGetFullSitemap = {
	getServerPage: getServerPageGetFullSitemap
};
export async function getServerPageGetSubsidies(
	options: Omit<Apollo.QueryOptions<Types.GetSubsidiesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetSubsidiesQuery>({
		...options,
		query: Operations.GetSubsidiesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetSubsidiesComp = React.FC<{ data?: Types.GetSubsidiesQuery; error?: Apollo.ApolloError }>;
export const ssrGetSubsidies = {
	getServerPage: getServerPageGetSubsidies
};
export async function getServerPageGetSubsidyFilters(
	options: Omit<Apollo.QueryOptions<Types.GetSubsidyFiltersQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetSubsidyFiltersQuery>({
		...options,
		query: Operations.GetSubsidyFiltersDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetSubsidyFiltersComp = React.FC<{ data?: Types.GetSubsidyFiltersQuery; error?: Apollo.ApolloError }>;
export const ssrGetSubsidyFilters = {
	getServerPage: getServerPageGetSubsidyFilters
};
export async function getServerPageGetSubsidySlugs(
	options: Omit<Apollo.QueryOptions<Types.GetSubsidySlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetSubsidySlugsQuery>({
		...options,
		query: Operations.GetSubsidySlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetSubsidySlugsComp = React.FC<{ data?: Types.GetSubsidySlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetSubsidySlugs = {
	getServerPage: getServerPageGetSubsidySlugs
};
export async function getServerPageGetTags(
	options: Omit<Apollo.QueryOptions<Types.GetTagsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetTagsQuery>({ ...options, query: Operations.GetTagsDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetTagsComp = React.FC<{ data?: Types.GetTagsQuery; error?: Apollo.ApolloError }>;
export const ssrGetTags = {
	getServerPage: getServerPageGetTags
};
export async function getServerPageGetThemeSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetThemeSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetThemeSlugsQuery>({
		...options,
		query: Operations.GetThemeSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetThemeSlugsComp = React.FC<{ data?: Types.GetThemeSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetThemeSlugs = {
	getServerPage: getServerPageGetThemeSlugs
};
export async function getServerPageGetThemes(
	options: Omit<Apollo.QueryOptions<Types.GetThemesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetThemesQuery>({ ...options, query: Operations.GetThemesDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetThemesComp = React.FC<{ data?: Types.GetThemesQuery; error?: Apollo.ApolloError }>;
export const ssrGetThemes = {
	getServerPage: getServerPageGetThemes
};
export async function getServerPageGetWobRequests(
	options: Omit<Apollo.QueryOptions<Types.GetWobRequestsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWobRequestsQuery>({
		...options,
		query: Operations.GetWobRequestsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWobRequestsComp = React.FC<{ data?: Types.GetWobRequestsQuery; error?: Apollo.ApolloError }>;
export const ssrGetWobRequests = {
	getServerPage: getServerPageGetWobRequests
};
export async function getServerPageGetWooCategories(
	options: Omit<Apollo.QueryOptions<Types.GetWooCategoriesQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWooCategoriesQuery>({
		...options,
		query: Operations.GetWooCategoriesDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWooCategoriesComp = React.FC<{ data?: Types.GetWooCategoriesQuery; error?: Apollo.ApolloError }>;
export const ssrGetWooCategories = {
	getServerPage: getServerPageGetWooCategories
};
export async function getServerPageGetWooRequestSlugs(
	options: Omit<Apollo.QueryOptions<Types.GetWooRequestSlugsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWooRequestSlugsQuery>({
		...options,
		query: Operations.GetWooRequestSlugsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWooRequestSlugsComp = React.FC<{ data?: Types.GetWooRequestSlugsQuery; error?: Apollo.ApolloError }>;
export const ssrGetWooRequestSlugs = {
	getServerPage: getServerPageGetWooRequestSlugs
};
export async function getServerPageGetWooRequests(
	options: Omit<Apollo.QueryOptions<Types.GetWooRequestsQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWooRequestsQuery>({
		...options,
		query: Operations.GetWooRequestsDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWooRequestsComp = React.FC<{ data?: Types.GetWooRequestsQuery; error?: Apollo.ApolloError }>;
export const ssrGetWooRequests = {
	getServerPage: getServerPageGetWooRequests
};
export async function getServerPageGetAgenda(
	options: Omit<Apollo.QueryOptions<Types.GetAgendaPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetAgendaPageQuery>({
		...options,
		query: Operations.GetAgendaPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetAgendaComp = React.FC<{ data?: Types.GetAgendaPageQuery; error?: Apollo.ApolloError }>;
export const ssrGetAgenda = {
	getServerPage: getServerPageGetAgenda
};
export async function getServerPageGetCondoleanceHome(
	options: Omit<Apollo.QueryOptions<Types.GetCondoleanceHomepageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetCondoleanceHomepageQuery>({
		...options,
		query: Operations.GetCondoleanceHomepageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetCondoleanceHomeComp = React.FC<{
	data?: Types.GetCondoleanceHomepageQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetCondoleanceHome = {
	getServerPage: getServerPageGetCondoleanceHome
};
export async function getServerPageGetCondoleance(
	options: Omit<Apollo.QueryOptions<Types.GetCondoleancePageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetCondoleancePageQuery>({
		...options,
		query: Operations.GetCondoleancePageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetCondoleanceComp = React.FC<{ data?: Types.GetCondoleancePageQuery; error?: Apollo.ApolloError }>;
export const ssrGetCondoleance = {
	getServerPage: getServerPageGetCondoleance
};
export async function getServerPageGetContact(
	options: Omit<Apollo.QueryOptions<Types.GetContactQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetContactQuery>({ ...options, query: Operations.GetContactDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetContactComp = React.FC<{ data?: Types.GetContactQuery; error?: Apollo.ApolloError }>;
export const ssrGetContact = {
	getServerPage: getServerPageGetContact
};
export async function getServerPageGetCookie(
	options: Omit<Apollo.QueryOptions<Types.GetCookiePageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetCookiePageQuery>({
		...options,
		query: Operations.GetCookiePageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetCookieComp = React.FC<{ data?: Types.GetCookiePageQuery; error?: Apollo.ApolloError }>;
export const ssrGetCookie = {
	getServerPage: getServerPageGetCookie
};
export async function getServerPageGetDecisions(
	options: Omit<Apollo.QueryOptions<Types.GetDecisionsPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetDecisionsPageQuery>({
		...options,
		query: Operations.GetDecisionsPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetDecisionsComp = React.FC<{ data?: Types.GetDecisionsPageQuery; error?: Apollo.ApolloError }>;
export const ssrGetDecisions = {
	getServerPage: getServerPageGetDecisions
};
export async function getServerPageGetDownloads(
	options: Omit<Apollo.QueryOptions<Types.GetDownloadsPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetDownloadsPageQuery>({
		...options,
		query: Operations.GetDownloadsPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetDownloadsComp = React.FC<{ data?: Types.GetDownloadsPageQuery; error?: Apollo.ApolloError }>;
export const ssrGetDownloads = {
	getServerPage: getServerPageGetDownloads
};
export async function getServerPageGetEfroOostHome(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostHomepageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostHomepageQuery>({
		...options,
		query: Operations.GetEfroOostHomepageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostHomeComp = React.FC<{ data?: Types.GetEfroOostHomepageQuery; error?: Apollo.ApolloError }>;
export const ssrGetEfroOostHome = {
	getServerPage: getServerPageGetEfroOostHome
};
export async function getServerPageGetEfroOostInConvoWithDetail(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostInConvoWithDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostInConvoWithDetailQuery>({
		...options,
		query: Operations.GetEfroOostInConvoWithDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostInConvoWithDetailComp = React.FC<{
	data?: Types.GetEfroOostInConvoWithDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetEfroOostInConvoWithDetail = {
	getServerPage: getServerPageGetEfroOostInConvoWithDetail
};
export async function getServerPageGetEfroOostInConvoWithOverview(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostInConvoWithOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostInConvoWithOverviewQuery>({
		...options,
		query: Operations.GetEfroOostInConvoWithOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostInConvoWithOverviewComp = React.FC<{
	data?: Types.GetEfroOostInConvoWithOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetEfroOostInConvoWithOverview = {
	getServerPage: getServerPageGetEfroOostInConvoWithOverview
};
export async function getServerPageGetEfroOostNewsDetail(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostNewsDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostNewsDetailQuery>({
		...options,
		query: Operations.GetEfroOostNewsDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostNewsDetailComp = React.FC<{
	data?: Types.GetEfroOostNewsDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetEfroOostNewsDetail = {
	getServerPage: getServerPageGetEfroOostNewsDetail
};
export async function getServerPageGetEfroOostNewsOverview(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostNewsOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostNewsOverviewQuery>({
		...options,
		query: Operations.GetEfroOostNewsOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostNewsOverviewComp = React.FC<{
	data?: Types.GetEfroOostNewsOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetEfroOostNewsOverview = {
	getServerPage: getServerPageGetEfroOostNewsOverview
};
export async function getServerPageGetEfroOost(
	options: Omit<Apollo.QueryOptions<Types.GetEfroOostPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEfroOostPageQuery>({
		...options,
		query: Operations.GetEfroOostPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEfroOostComp = React.FC<{ data?: Types.GetEfroOostPageQuery; error?: Apollo.ApolloError }>;
export const ssrGetEfroOost = {
	getServerPage: getServerPageGetEfroOost
};
export async function getServerPageGetEventOverview(
	options: Omit<Apollo.QueryOptions<Types.GetEventOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetEventOverviewQuery>({
		...options,
		query: Operations.GetEventOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetEventOverviewComp = React.FC<{ data?: Types.GetEventOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetEventOverview = {
	getServerPage: getServerPageGetEventOverview
};
export async function getServerPageGetGeoLink(
	options: Omit<Apollo.QueryOptions<Types.GetGeoLinkPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetGeoLinkPageQuery>({
		...options,
		query: Operations.GetGeoLinkPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetGeoLinkComp = React.FC<{ data?: Types.GetGeoLinkPageQuery; error?: Apollo.ApolloError }>;
export const ssrGetGeoLink = {
	getServerPage: getServerPageGetGeoLink
};
export async function getServerPageGetGeolinkHome(
	options: Omit<Apollo.QueryOptions<Types.GetGeolinkHomepageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetGeolinkHomepageQuery>({
		...options,
		query: Operations.GetGeolinkHomepageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetGeolinkHomeComp = React.FC<{ data?: Types.GetGeolinkHomepageQuery; error?: Apollo.ApolloError }>;
export const ssrGetGeolinkHome = {
	getServerPage: getServerPageGetGeolinkHome
};
export async function getServerPageGetHome(
	options: Omit<Apollo.QueryOptions<Types.GetHomePageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetHomePageQuery>({ ...options, query: Operations.GetHomePageDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetHomeComp = React.FC<{ data?: Types.GetHomePageQuery; error?: Apollo.ApolloError }>;
export const ssrGetHome = {
	getServerPage: getServerPageGetHome
};
export async function getServerPageGetInConvoWithDetail(
	options: Omit<Apollo.QueryOptions<Types.GetInConvoWithDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetInConvoWithDetailQuery>({
		...options,
		query: Operations.GetInConvoWithDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInConvoWithDetailComp = React.FC<{
	data?: Types.GetInConvoWithDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInConvoWithDetail = {
	getServerPage: getServerPageGetInConvoWithDetail
};
export async function getServerPageGetInConvoWithOverview(
	options: Omit<Apollo.QueryOptions<Types.GetInConvoWithOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetInConvoWithOverviewQuery>({
		...options,
		query: Operations.GetInConvoWithOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInConvoWithOverviewComp = React.FC<{
	data?: Types.GetInConvoWithOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInConvoWithOverview = {
	getServerPage: getServerPageGetInConvoWithOverview
};
export async function getServerPageGetMapsFiguresOverview(
	options: Omit<Apollo.QueryOptions<Types.GetMapsFiguresOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetMapsFiguresOverviewQuery>({
		...options,
		query: Operations.GetMapsFiguresOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetMapsFiguresOverviewComp = React.FC<{
	data?: Types.GetMapsFiguresOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetMapsFiguresOverview = {
	getServerPage: getServerPageGetMapsFiguresOverview
};
export async function getServerPageGetNewsDetail(
	options: Omit<Apollo.QueryOptions<Types.GetNewsDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetNewsDetailQuery>({
		...options,
		query: Operations.GetNewsDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetNewsDetailComp = React.FC<{ data?: Types.GetNewsDetailQuery; error?: Apollo.ApolloError }>;
export const ssrGetNewsDetail = {
	getServerPage: getServerPageGetNewsDetail
};
export async function getServerPageGetNewsOverview(
	options: Omit<Apollo.QueryOptions<Types.GetNewsOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetNewsOverviewQuery>({
		...options,
		query: Operations.GetNewsOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetNewsOverviewComp = React.FC<{ data?: Types.GetNewsOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetNewsOverview = {
	getServerPage: getServerPageGetNewsOverview
};
export async function getServerPageGetArchiveNewsletterOverview(
	options: Omit<Apollo.QueryOptions<Types.GetArchiveNewsletterOverviewPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetArchiveNewsletterOverviewPageQuery>({
		...options,
		query: Operations.GetArchiveNewsletterOverviewPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetArchiveNewsletterOverviewComp = React.FC<{
	data?: Types.GetArchiveNewsletterOverviewPageQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetArchiveNewsletterOverview = {
	getServerPage: getServerPageGetArchiveNewsletterOverview
};
export async function getServerPageGetInternalNewsletter(
	options: Omit<Apollo.QueryOptions<Types.GetInternalNewsletterPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetInternalNewsletterPageQuery>({
		...options,
		query: Operations.GetInternalNewsletterPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInternalNewsletterComp = React.FC<{
	data?: Types.GetInternalNewsletterPageQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInternalNewsletter = {
	getServerPage: getServerPageGetInternalNewsletter
};
export async function getServerPageGetPublicNewsletterCondoleance(
	options: Omit<Apollo.QueryOptions<Types.GetPublicNewsletterPageCondoleanceQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPublicNewsletterPageCondoleanceQuery>({
		...options,
		query: Operations.GetPublicNewsletterPageCondoleanceDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPublicNewsletterCondoleanceComp = React.FC<{
	data?: Types.GetPublicNewsletterPageCondoleanceQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPublicNewsletterCondoleance = {
	getServerPage: getServerPageGetPublicNewsletterCondoleance
};
export async function getServerPageGetPublicNewsletterOfroOost(
	options: Omit<Apollo.QueryOptions<Types.GetPublicNewsletterPageOfroOostQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPublicNewsletterPageOfroOostQuery>({
		...options,
		query: Operations.GetPublicNewsletterPageOfroOostDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPublicNewsletterOfroOostComp = React.FC<{
	data?: Types.GetPublicNewsletterPageOfroOostQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPublicNewsletterOfroOost = {
	getServerPage: getServerPageGetPublicNewsletterOfroOost
};
export async function getServerPageGetPublicNewsletterGeolink(
	options: Omit<Apollo.QueryOptions<Types.GetPublicNewsletterPageGeolinkQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPublicNewsletterPageGeolinkQuery>({
		...options,
		query: Operations.GetPublicNewsletterPageGeolinkDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPublicNewsletterGeolinkComp = React.FC<{
	data?: Types.GetPublicNewsletterPageGeolinkQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPublicNewsletterGeolink = {
	getServerPage: getServerPageGetPublicNewsletterGeolink
};
export async function getServerPageGetPublicNewsletterOpOost(
	options: Omit<Apollo.QueryOptions<Types.GetPublicNewsletterPageOpOostQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPublicNewsletterPageOpOostQuery>({
		...options,
		query: Operations.GetPublicNewsletterPageOpOostDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPublicNewsletterOpOostComp = React.FC<{
	data?: Types.GetPublicNewsletterPageOpOostQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPublicNewsletterOpOost = {
	getServerPage: getServerPageGetPublicNewsletterOpOost
};
export async function getServerPageGetPublicNewsletterRegioExpres(
	options: Omit<Apollo.QueryOptions<Types.GetPublicNewsletterPageRegioExpresQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPublicNewsletterPageRegioExpresQuery>({
		...options,
		query: Operations.GetPublicNewsletterPageRegioExpresDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPublicNewsletterRegioExpresComp = React.FC<{
	data?: Types.GetPublicNewsletterPageRegioExpresQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPublicNewsletterRegioExpres = {
	getServerPage: getServerPageGetPublicNewsletterRegioExpres
};
export async function getServerPageGetPublicNewsletter(
	options: Omit<Apollo.QueryOptions<Types.GetPublicNewsletterPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPublicNewsletterPageQuery>({
		...options,
		query: Operations.GetPublicNewsletterPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPublicNewsletterComp = React.FC<{
	data?: Types.GetPublicNewsletterPageQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPublicNewsletter = {
	getServerPage: getServerPageGetPublicNewsletter
};
export async function getServerPageGetNotFound(
	options: Omit<Apollo.QueryOptions<Types.GetNotFoundQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetNotFoundQuery>({ ...options, query: Operations.GetNotFoundDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetNotFoundComp = React.FC<{ data?: Types.GetNotFoundQuery; error?: Apollo.ApolloError }>;
export const ssrGetNotFound = {
	getServerPage: getServerPageGetNotFound
};
export async function getServerPageGetOpOostHome(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostHomepageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostHomepageQuery>({
		...options,
		query: Operations.GetOpOostHomepageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostHomeComp = React.FC<{ data?: Types.GetOpOostHomepageQuery; error?: Apollo.ApolloError }>;
export const ssrGetOpOostHome = {
	getServerPage: getServerPageGetOpOostHome
};
export async function getServerPageGetOpOostInConvoWithDetail(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostInConvoWithDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostInConvoWithDetailQuery>({
		...options,
		query: Operations.GetOpOostInConvoWithDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostInConvoWithDetailComp = React.FC<{
	data?: Types.GetOpOostInConvoWithDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpOostInConvoWithDetail = {
	getServerPage: getServerPageGetOpOostInConvoWithDetail
};
export async function getServerPageGetOpOostInConvoWithOverview(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostInConvoWithOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostInConvoWithOverviewQuery>({
		...options,
		query: Operations.GetOpOostInConvoWithOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostInConvoWithOverviewComp = React.FC<{
	data?: Types.GetOpOostInConvoWithOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpOostInConvoWithOverview = {
	getServerPage: getServerPageGetOpOostInConvoWithOverview
};
export async function getServerPageGetOpOostNewsDetail(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostNewsDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostNewsDetailQuery>({
		...options,
		query: Operations.GetOpOostNewsDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostNewsDetailComp = React.FC<{
	data?: Types.GetOpOostNewsDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpOostNewsDetail = {
	getServerPage: getServerPageGetOpOostNewsDetail
};
export async function getServerPageGetOpOostNewsOverview(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostNewsOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostNewsOverviewQuery>({
		...options,
		query: Operations.GetOpOostNewsOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostNewsOverviewComp = React.FC<{
	data?: Types.GetOpOostNewsOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpOostNewsOverview = {
	getServerPage: getServerPageGetOpOostNewsOverview
};
export async function getServerPageGetOpOost(
	options: Omit<Apollo.QueryOptions<Types.GetOpOostPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpOostPageQuery>({
		...options,
		query: Operations.GetOpOostPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpOostComp = React.FC<{ data?: Types.GetOpOostPageQuery; error?: Apollo.ApolloError }>;
export const ssrGetOpOost = {
	getServerPage: getServerPageGetOpOost
};
export async function getServerPageGetOpenGelderlandHome(
	options: Omit<Apollo.QueryOptions<Types.GetOpenGelderlandHomepageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpenGelderlandHomepageQuery>({
		...options,
		query: Operations.GetOpenGelderlandHomepageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpenGelderlandHomeComp = React.FC<{
	data?: Types.GetOpenGelderlandHomepageQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpenGelderlandHome = {
	getServerPage: getServerPageGetOpenGelderlandHome
};
export async function getServerPageGetInformationCondoleance(
	options: Omit<Apollo.QueryOptions<Types.GetPageInformationCondoleanceQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPageInformationCondoleanceQuery>({
		...options,
		query: Operations.GetPageInformationCondoleanceDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInformationCondoleanceComp = React.FC<{
	data?: Types.GetPageInformationCondoleanceQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInformationCondoleance = {
	getServerPage: getServerPageGetInformationCondoleance
};
export async function getServerPageGetInformationEfroOost(
	options: Omit<Apollo.QueryOptions<Types.GetPageInformationEfroOostQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPageInformationEfroOostQuery>({
		...options,
		query: Operations.GetPageInformationEfroOostDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInformationEfroOostComp = React.FC<{
	data?: Types.GetPageInformationEfroOostQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInformationEfroOost = {
	getServerPage: getServerPageGetInformationEfroOost
};
export async function getServerPageGetInformationGelderland(
	options: Omit<Apollo.QueryOptions<Types.GetPageInformationGelderlandQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPageInformationGelderlandQuery>({
		...options,
		query: Operations.GetPageInformationGelderlandDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInformationGelderlandComp = React.FC<{
	data?: Types.GetPageInformationGelderlandQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInformationGelderland = {
	getServerPage: getServerPageGetInformationGelderland
};
export async function getServerPageGetInformationGeolink(
	options: Omit<Apollo.QueryOptions<Types.GetPageInformationGeolinkQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPageInformationGeolinkQuery>({
		...options,
		query: Operations.GetPageInformationGeolinkDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInformationGeolinkComp = React.FC<{
	data?: Types.GetPageInformationGeolinkQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInformationGeolink = {
	getServerPage: getServerPageGetInformationGeolink
};
export async function getServerPageGetInformationOpOost(
	options: Omit<Apollo.QueryOptions<Types.GetPageInformationOpOostQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPageInformationOpOostQuery>({
		...options,
		query: Operations.GetPageInformationOpOostDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInformationOpOostComp = React.FC<{
	data?: Types.GetPageInformationOpOostQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInformationOpOost = {
	getServerPage: getServerPageGetInformationOpOost
};
export async function getServerPageGetInformationRegioExpres(
	options: Omit<Apollo.QueryOptions<Types.GetPageInformationRegioExpresQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPageInformationRegioExpresQuery>({
		...options,
		query: Operations.GetPageInformationRegioExpresDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetInformationRegioExpresComp = React.FC<{
	data?: Types.GetPageInformationRegioExpresQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetInformationRegioExpres = {
	getServerPage: getServerPageGetInformationRegioExpres
};
export async function getServerPageGet(
	options: Omit<Apollo.QueryOptions<Types.GetPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPageQuery>({ ...options, query: Operations.GetPageDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetComp = React.FC<{ data?: Types.GetPageQuery; error?: Apollo.ApolloError }>;
export const ssrGet = {
	getServerPage: getServerPageGet
};
export async function getServerPageGetPassword(
	options: Omit<Apollo.QueryOptions<Types.GetPasswordPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPasswordPageQuery>({
		...options,
		query: Operations.GetPasswordPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPasswordComp = React.FC<{ data?: Types.GetPasswordPageQuery; error?: Apollo.ApolloError }>;
export const ssrGetPassword = {
	getServerPage: getServerPageGetPassword
};
export async function getServerPageGetPermitDetail(
	options: Omit<Apollo.QueryOptions<Types.GetPermitDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPermitDetailQuery>({
		...options,
		query: Operations.GetPermitDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPermitDetailComp = React.FC<{ data?: Types.GetPermitDetailQuery; error?: Apollo.ApolloError }>;
export const ssrGetPermitDetail = {
	getServerPage: getServerPageGetPermitDetail
};
export async function getServerPageGetPermitsOverview(
	options: Omit<Apollo.QueryOptions<Types.GetPermitsOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPermitsOverviewQuery>({
		...options,
		query: Operations.GetPermitsOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPermitsOverviewComp = React.FC<{ data?: Types.GetPermitsOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetPermitsOverview = {
	getServerPage: getServerPageGetPermitsOverview
};
export async function getServerPageGetPressReleaseDetail(
	options: Omit<Apollo.QueryOptions<Types.GetPressReleaseDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPressReleaseDetailQuery>({
		...options,
		query: Operations.GetPressReleaseDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPressReleaseDetailComp = React.FC<{
	data?: Types.GetPressReleaseDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPressReleaseDetail = {
	getServerPage: getServerPageGetPressReleaseDetail
};
export async function getServerPageGetPressReleaseOverview(
	options: Omit<Apollo.QueryOptions<Types.GetPressReleaseOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPressReleaseOverviewQuery>({
		...options,
		query: Operations.GetPressReleaseOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPressReleaseOverviewComp = React.FC<{
	data?: Types.GetPressReleaseOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetPressReleaseOverview = {
	getServerPage: getServerPageGetPressReleaseOverview
};
export async function getServerPageGetPrivacyPolicy(
	options: Omit<Apollo.QueryOptions<Types.GetPrivacyPolicyQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetPrivacyPolicyQuery>({
		...options,
		query: Operations.GetPrivacyPolicyDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetPrivacyPolicyComp = React.FC<{ data?: Types.GetPrivacyPolicyQuery; error?: Apollo.ApolloError }>;
export const ssrGetPrivacyPolicy = {
	getServerPage: getServerPageGetPrivacyPolicy
};
export async function getServerPageGetProjectDetail(
	options: Omit<Apollo.QueryOptions<Types.GetProjectDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetProjectDetailQuery>({
		...options,
		query: Operations.GetProjectDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetProjectDetailComp = React.FC<{ data?: Types.GetProjectDetailQuery; error?: Apollo.ApolloError }>;
export const ssrGetProjectDetail = {
	getServerPage: getServerPageGetProjectDetail
};
export async function getServerPageGetProjectOverview(
	options: Omit<Apollo.QueryOptions<Types.GetProjectOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetProjectOverviewQuery>({
		...options,
		query: Operations.GetProjectOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetProjectOverviewComp = React.FC<{ data?: Types.GetProjectOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetProjectOverview = {
	getServerPage: getServerPageGetProjectOverview
};
export async function getServerPageGetRegioExpresHome(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresHomepageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresHomepageQuery>({
		...options,
		query: Operations.GetRegioExpresHomepageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresHomeComp = React.FC<{
	data?: Types.GetRegioExpresHomepageQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresHome = {
	getServerPage: getServerPageGetRegioExpresHome
};
export async function getServerPageGetRegioExpresInConvoWithDetail(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresInConvoWithDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresInConvoWithDetailQuery>({
		...options,
		query: Operations.GetRegioExpresInConvoWithDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresInConvoWithDetailComp = React.FC<{
	data?: Types.GetRegioExpresInConvoWithDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresInConvoWithDetail = {
	getServerPage: getServerPageGetRegioExpresInConvoWithDetail
};
export async function getServerPageGetRegioExpresInConvoWithOverview(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresInConvoWithOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresInConvoWithOverviewQuery>({
		...options,
		query: Operations.GetRegioExpresInConvoWithOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresInConvoWithOverviewComp = React.FC<{
	data?: Types.GetRegioExpresInConvoWithOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresInConvoWithOverview = {
	getServerPage: getServerPageGetRegioExpresInConvoWithOverview
};
export async function getServerPageGetRegioExpresNewsDetail(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresNewsDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresNewsDetailQuery>({
		...options,
		query: Operations.GetRegioExpresNewsDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresNewsDetailComp = React.FC<{
	data?: Types.GetRegioExpresNewsDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresNewsDetail = {
	getServerPage: getServerPageGetRegioExpresNewsDetail
};
export async function getServerPageGetRegioExpresNewsOverview(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresNewsOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresNewsOverviewQuery>({
		...options,
		query: Operations.GetRegioExpresNewsOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresNewsOverviewComp = React.FC<{
	data?: Types.GetRegioExpresNewsOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRegioExpresNewsOverview = {
	getServerPage: getServerPageGetRegioExpresNewsOverview
};
export async function getServerPageGetRegioExpres(
	options: Omit<Apollo.QueryOptions<Types.GetRegioExpresPageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegioExpresPageQuery>({
		...options,
		query: Operations.GetRegioExpresPageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegioExpresComp = React.FC<{ data?: Types.GetRegioExpresPageQuery; error?: Apollo.ApolloError }>;
export const ssrGetRegioExpres = {
	getServerPage: getServerPageGetRegioExpres
};
export async function getServerPageGetRegionDetail(
	options: Omit<Apollo.QueryOptions<Types.GetRegionDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegionDetailQuery>({
		...options,
		query: Operations.GetRegionDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegionDetailComp = React.FC<{ data?: Types.GetRegionDetailQuery; error?: Apollo.ApolloError }>;
export const ssrGetRegionDetail = {
	getServerPage: getServerPageGetRegionDetail
};
export async function getServerPageGetRegionOverview(
	options: Omit<Apollo.QueryOptions<Types.GetRegionOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRegionOverviewQuery>({
		...options,
		query: Operations.GetRegionOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRegionOverviewComp = React.FC<{ data?: Types.GetRegionOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetRegionOverview = {
	getServerPage: getServerPageGetRegionOverview
};
export async function getServerPageGetRoadMaintenanceDetail(
	options: Omit<Apollo.QueryOptions<Types.GetRoadMaintenanceDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRoadMaintenanceDetailQuery>({
		...options,
		query: Operations.GetRoadMaintenanceDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRoadMaintenanceDetailComp = React.FC<{
	data?: Types.GetRoadMaintenanceDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRoadMaintenanceDetail = {
	getServerPage: getServerPageGetRoadMaintenanceDetail
};
export async function getServerPageGetRoadMaintenanceOverview(
	options: Omit<Apollo.QueryOptions<Types.GetRoadMaintenanceOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetRoadMaintenanceOverviewQuery>({
		...options,
		query: Operations.GetRoadMaintenanceOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetRoadMaintenanceOverviewComp = React.FC<{
	data?: Types.GetRoadMaintenanceOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetRoadMaintenanceOverview = {
	getServerPage: getServerPageGetRoadMaintenanceOverview
};
export async function getServerPageGetSearchOverview(
	options: Omit<Apollo.QueryOptions<Types.GetSearchOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetSearchOverviewQuery>({
		...options,
		query: Operations.GetSearchOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetSearchOverviewComp = React.FC<{ data?: Types.GetSearchOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetSearchOverview = {
	getServerPage: getServerPageGetSearchOverview
};
export async function getServerPageGetLocalSitemapCondoleance(
	options: Omit<Apollo.QueryOptions<Types.GetLocalSitemapCondoleanceQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetLocalSitemapCondoleanceQuery>({
		...options,
		query: Operations.GetLocalSitemapCondoleanceDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetLocalSitemapCondoleanceComp = React.FC<{
	data?: Types.GetLocalSitemapCondoleanceQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetLocalSitemapCondoleance = {
	getServerPage: getServerPageGetLocalSitemapCondoleance
};
export async function getServerPageGetLocalSitemapEfroOost(
	options: Omit<Apollo.QueryOptions<Types.GetLocalSitemapEfroOostQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetLocalSitemapEfroOostQuery>({
		...options,
		query: Operations.GetLocalSitemapEfroOostDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetLocalSitemapEfroOostComp = React.FC<{
	data?: Types.GetLocalSitemapEfroOostQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetLocalSitemapEfroOost = {
	getServerPage: getServerPageGetLocalSitemapEfroOost
};
export async function getServerPageGetLocalSitemapGeolink(
	options: Omit<Apollo.QueryOptions<Types.GetLocalSitemapGeolinkQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetLocalSitemapGeolinkQuery>({
		...options,
		query: Operations.GetLocalSitemapGeolinkDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetLocalSitemapGeolinkComp = React.FC<{
	data?: Types.GetLocalSitemapGeolinkQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetLocalSitemapGeolink = {
	getServerPage: getServerPageGetLocalSitemapGeolink
};
export async function getServerPageGetLocalSitemapOpOost(
	options: Omit<Apollo.QueryOptions<Types.GetLocalSitemapOpOostQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetLocalSitemapOpOostQuery>({
		...options,
		query: Operations.GetLocalSitemapOpOostDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetLocalSitemapOpOostComp = React.FC<{
	data?: Types.GetLocalSitemapOpOostQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetLocalSitemapOpOost = {
	getServerPage: getServerPageGetLocalSitemapOpOost
};
export async function getServerPageGetLocalSitemapRegioExpres(
	options: Omit<Apollo.QueryOptions<Types.GetLocalSitemapRegioExpresQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetLocalSitemapRegioExpresQuery>({
		...options,
		query: Operations.GetLocalSitemapRegioExpresDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetLocalSitemapRegioExpresComp = React.FC<{
	data?: Types.GetLocalSitemapRegioExpresQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetLocalSitemapRegioExpres = {
	getServerPage: getServerPageGetLocalSitemapRegioExpres
};
export async function getServerPageGetLocalSitemap(
	options: Omit<Apollo.QueryOptions<Types.GetLocalSitemapQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetLocalSitemapQuery>({
		...options,
		query: Operations.GetLocalSitemapDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetLocalSitemapComp = React.FC<{ data?: Types.GetLocalSitemapQuery; error?: Apollo.ApolloError }>;
export const ssrGetLocalSitemap = {
	getServerPage: getServerPageGetLocalSitemap
};
export async function getServerPageGetSubsidyDetail(
	options: Omit<Apollo.QueryOptions<Types.GetSubsidyDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetSubsidyDetailQuery>({
		...options,
		query: Operations.GetSubsidyDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetSubsidyDetailComp = React.FC<{ data?: Types.GetSubsidyDetailQuery; error?: Apollo.ApolloError }>;
export const ssrGetSubsidyDetail = {
	getServerPage: getServerPageGetSubsidyDetail
};
export async function getServerPageGetSubsidyOverview(
	options: Omit<Apollo.QueryOptions<Types.GetSubsidyOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetSubsidyOverviewQuery>({
		...options,
		query: Operations.GetSubsidyOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetSubsidyOverviewComp = React.FC<{ data?: Types.GetSubsidyOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetSubsidyOverview = {
	getServerPage: getServerPageGetSubsidyOverview
};
export async function getServerPageGetThemeDetail(
	options: Omit<Apollo.QueryOptions<Types.GetThemeDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetThemeDetailQuery>({
		...options,
		query: Operations.GetThemeDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetThemeDetailComp = React.FC<{ data?: Types.GetThemeDetailQuery; error?: Apollo.ApolloError }>;
export const ssrGetThemeDetail = {
	getServerPage: getServerPageGetThemeDetail
};
export async function getServerPageGetThemeOverview(
	options: Omit<Apollo.QueryOptions<Types.GetThemeOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetThemeOverviewQuery>({
		...options,
		query: Operations.GetThemeOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetThemeOverviewComp = React.FC<{ data?: Types.GetThemeOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetThemeOverview = {
	getServerPage: getServerPageGetThemeOverview
};
export async function getServerPageGetVacancyDetail(
	options: Omit<Apollo.QueryOptions<Types.GetVacancyDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetVacancyDetailQuery>({
		...options,
		query: Operations.GetVacancyDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetVacancyDetailComp = React.FC<{ data?: Types.GetVacancyDetailQuery; error?: Apollo.ApolloError }>;
export const ssrGetVacancyDetail = {
	getServerPage: getServerPageGetVacancyDetail
};
export async function getServerPageGetVacancyInternalDetail(
	options: Omit<Apollo.QueryOptions<Types.GetVacancyInternalDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetVacancyInternalDetailQuery>({
		...options,
		query: Operations.GetVacancyInternalDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetVacancyInternalDetailComp = React.FC<{
	data?: Types.GetVacancyInternalDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetVacancyInternalDetail = {
	getServerPage: getServerPageGetVacancyInternalDetail
};
export async function getServerPageGetVacancyInternalOverview(
	options: Omit<Apollo.QueryOptions<Types.GetVacancyInternalOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetVacancyInternalOverviewQuery>({
		...options,
		query: Operations.GetVacancyInternalOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetVacancyInternalOverviewComp = React.FC<{
	data?: Types.GetVacancyInternalOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetVacancyInternalOverview = {
	getServerPage: getServerPageGetVacancyInternalOverview
};
export async function getServerPageGetVacancyOverview(
	options: Omit<Apollo.QueryOptions<Types.GetVacancyOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetVacancyOverviewQuery>({
		...options,
		query: Operations.GetVacancyOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetVacancyOverviewComp = React.FC<{ data?: Types.GetVacancyOverviewQuery; error?: Apollo.ApolloError }>;
export const ssrGetVacancyOverview = {
	getServerPage: getServerPageGetVacancyOverview
};
export async function getServerPageGetWobRequestOverview(
	options: Omit<Apollo.QueryOptions<Types.GetWobRequestOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWobRequestOverviewQuery>({
		...options,
		query: Operations.GetWobRequestOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWobRequestOverviewComp = React.FC<{
	data?: Types.GetWobRequestOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetWobRequestOverview = {
	getServerPage: getServerPageGetWobRequestOverview
};
export async function getServerPageGetWooDocumentCount(
	options: Omit<Apollo.QueryOptions<Types.GetWooDocumentCountQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWooDocumentCountQuery>({
		...options,
		query: Operations.GetWooDocumentCountDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWooDocumentCountComp = React.FC<{
	data?: Types.GetWooDocumentCountQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetWooDocumentCount = {
	getServerPage: getServerPageGetWooDocumentCount
};
export async function getServerPageGetOpenWooRequestDetail(
	options: Omit<Apollo.QueryOptions<Types.GetOpenWooRequestDetailQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpenWooRequestDetailQuery>({
		...options,
		query: Operations.GetOpenWooRequestDetailDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpenWooRequestDetailComp = React.FC<{
	data?: Types.GetOpenWooRequestDetailQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpenWooRequestDetail = {
	getServerPage: getServerPageGetOpenWooRequestDetail
};
export async function getServerPageGetOpenWooRequestOverview(
	options: Omit<Apollo.QueryOptions<Types.GetOpenWooRequestOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetOpenWooRequestOverviewQuery>({
		...options,
		query: Operations.GetOpenWooRequestOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetOpenWooRequestOverviewComp = React.FC<{
	data?: Types.GetOpenWooRequestOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetOpenWooRequestOverview = {
	getServerPage: getServerPageGetOpenWooRequestOverview
};
export async function getServerPageGetWooRequestDetail(
	options: Omit<Apollo.QueryOptions<Types.GetWooRequestDetailpageQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWooRequestDetailpageQuery>({
		...options,
		query: Operations.GetWooRequestDetailpageDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWooRequestDetailComp = React.FC<{
	data?: Types.GetWooRequestDetailpageQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetWooRequestDetail = {
	getServerPage: getServerPageGetWooRequestDetail
};
export async function getServerPageGetWooRequestDetailXml(
	options: Omit<Apollo.QueryOptions<Types.GetWooRequestDetailXmlQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWooRequestDetailXmlQuery>({
		...options,
		query: Operations.GetWooRequestDetailXmlDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWooRequestDetailXmlComp = React.FC<{
	data?: Types.GetWooRequestDetailXmlQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetWooRequestDetailXml = {
	getServerPage: getServerPageGetWooRequestDetailXml
};
export async function getServerPageGetWooRequestOverview(
	options: Omit<Apollo.QueryOptions<Types.GetWooRequestOverviewQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWooRequestOverviewQuery>({
		...options,
		query: Operations.GetWooRequestOverviewDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWooRequestOverviewComp = React.FC<{
	data?: Types.GetWooRequestOverviewQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetWooRequestOverview = {
	getServerPage: getServerPageGetWooRequestOverview
};
export async function getServerPageGetWooRequestOverviewXml(
	options: Omit<Apollo.QueryOptions<Types.GetWooRequestOverviewXmlQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWooRequestOverviewXmlQuery>({
		...options,
		query: Operations.GetWooRequestOverviewXmlDocument
	});

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWooRequestOverviewXmlComp = React.FC<{
	data?: Types.GetWooRequestOverviewXmlQuery;
	error?: Apollo.ApolloError;
}>;
export const ssrGetWooRequestOverviewXml = {
	getServerPage: getServerPageGetWooRequestOverviewXml
};
export async function getServerPageGetWorkAt(
	options: Omit<Apollo.QueryOptions<Types.GetWorkAtQueryVariables>, 'query'>,
	apolloClient: Apollo.ApolloClient<NormalizedCacheObject>
) {
	const data = await apolloClient.query<Types.GetWorkAtQuery>({ ...options, query: Operations.GetWorkAtDocument });

	const apolloState = apolloClient.cache.extract();

	return {
		props: {
			apolloState: apolloState,
			data: data?.data,
			error: data?.error ?? data?.errors ?? null
		}
	};
}
export type PageGetWorkAtComp = React.FC<{ data?: Types.GetWorkAtQuery; error?: Apollo.ApolloError }>;
export const ssrGetWorkAt = {
	getServerPage: getServerPageGetWorkAt
};

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		AgendaPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'Error'
		],
		CondoleanceHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		CondoleancePageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		ContactModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesLinkBox',
			'ComponentModulesPartners',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		DecisionsPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'Error'
		],
		DownloadsPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'Error'
		],
		EerbeekloenenHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		EerbeekloenenInConvoArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		EerbeekloenenInConvoOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EerbeekloenenInConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EerbeekloenenNewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EerbeekloenenNewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EerbeekloenenNewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		EerbeekloenenPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		EfroOostHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		EfroOostInConvoWithArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		EfroOostInConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EfroOostInConvoWithOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EfroOostNewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EfroOostNewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EfroOostNewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		EfroOostPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		EventOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesEventOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		GenericMorph: [
			'AgendaPage',
			'AuditLogAuditLog',
			'ComponentComponentsButton',
			'ComponentComponentsIconLink',
			'ComponentComponentsIconLinkGroup',
			'ComponentComponentsLink',
			'ComponentComponentsLinkGroup',
			'ComponentComponentsSubject',
			'ComponentInternalAboutMaintenance',
			'ComponentInternalAccordeonItem',
			'ComponentInternalAccordeonSection',
			'ComponentInternalBodyTextColumn',
			'ComponentInternalDefaultModuleOptions',
			'ComponentInternalDefaultModuleOptionsExtended',
			'ComponentInternalEmptyState',
			'ComponentInternalFirstRowItems',
			'ComponentInternalFooter',
			'ComponentInternalHighlightEvents',
			'ComponentInternalHighlightMapsAndStats',
			'ComponentInternalHighlightNews',
			'ComponentInternalHighlightPermits',
			'ComponentInternalHighlightSubsidies',
			'ComponentInternalHighlightTopics',
			'ComponentInternalHighlightWoo',
			'ComponentInternalNavbar',
			'ComponentInternalNewsletter',
			'ComponentInternalPhoto',
			'ComponentInternalSecondColumnItems',
			'ComponentInternalSeo',
			'ComponentInternalSidebar',
			'ComponentInternalStepByStepCard',
			'ComponentInternalTest',
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCollections',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesEventOverview',
			'ComponentModulesExpandedHeader',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesHomepageHeader',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesIntroHeader',
			'ComponentModulesIntroHeaderImage',
			'ComponentModulesLinkBox',
			'ComponentModulesMapsFiguresOverview',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesOpenWooRequestOverview',
			'ComponentModulesPartners',
			'ComponentModulesPermitsOverview',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesPressReleaseOverview',
			'ComponentModulesProjectOverview',
			'ComponentModulesRegions',
			'ComponentModulesRoadMaintenanceOverview',
			'ComponentModulesScheduling',
			'ComponentModulesSearchHeader',
			'ComponentModulesSearchOverview',
			'ComponentModulesSeo',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesSubsidyOverview',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'ComponentModulesThemes',
			'ComponentModulesVacanciesOverview',
			'ComponentModulesWobRequestOverview',
			'ComponentModulesWooRequestOverview',
			'ComponentSdgSdgLink',
			'ComponentSdgSdgProductVertaling',
			'CondoleanceHomepage',
			'CondoleanceInformation',
			'CondoleancePage',
			'Contact',
			'ConversationsConversationitem',
			'ConversationsConversations',
			'CookiePage',
			'DecisionsPage',
			'DownloadsPage',
			'EerbeekloenenHomepage',
			'EerbeekloenenInConvoArticle',
			'EerbeekloenenInConvoOverview',
			'EerbeekloenenInConvoWithDetail',
			'EerbeekloenenInformation',
			'EerbeekloenenNewsDetail',
			'EerbeekloenenNewsOverview',
			'EerbeekloenenNewsarticle',
			'EerbeekloenenPage',
			'EfroOostHomepage',
			'EfroOostInConvoWithArticle',
			'EfroOostInConvoWithDetail',
			'EfroOostInConvoWithOverview',
			'EfroOostInformation',
			'EfroOostNewsDetail',
			'EfroOostNewsOverview',
			'EfroOostNewsarticle',
			'EfroOostPage',
			'Event',
			'EventOverview',
			'GeolinkHomepage',
			'GeolinkInformation',
			'GeolinkPage',
			'HomePage',
			'I18NLocale',
			'InConvoWithArticle',
			'InConvoWithDetail',
			'InConvoWithOverview',
			'InternalLinkInternalLink',
			'InternalLinkInternalLinkWysiwyg',
			'Login',
			'MapsFigure',
			'MapsFiguresOverview',
			'NewsDetail',
			'NewsOverview',
			'Newsarticle',
			'Newsletter',
			'NewsletterArchiveOverview',
			'NewsletterInternal',
			'NewsletterPublic',
			'NotFoundPage',
			'NoteNote',
			'NoteNoterelation',
			'OpOostHomepage',
			'OpOostInConvoWithArticle',
			'OpOostInConvoWithDetail',
			'OpOostInConvoWithOverview',
			'OpOostInformation',
			'OpOostNewsDetail',
			'OpOostNewsOverview',
			'OpOostNewsarticle',
			'OpOostPage',
			'OpenGelderlandHomepage',
			'OpenGelderlandInformation',
			'OpenGelderlandWooOverview',
			'Page',
			'PageInformation',
			'PasswordPage',
			'Permit',
			'PermitDetail',
			'PermitLaw',
			'PermitProductType',
			'PermitsOverview',
			'PressRelease',
			'PressReleaseDetail',
			'PressReleaseOverview',
			'PrivacyPolicy',
			'Project',
			'ProjectDetail',
			'ProjectOverview',
			'RecordLockingOpenEntity',
			'RecycleBinRecycleBinItem',
			'RegioExpresHomepage',
			'RegioExpresInConvoOverview',
			'RegioExpresInConvoWithArticle',
			'RegioExpresInConvoWithDetail',
			'RegioExpresInformation',
			'RegioExpresNewsDetail',
			'RegioExpresNewsOverview',
			'RegioExpresNewsarticle',
			'RegioExpresPage',
			'Region',
			'RegionDetail',
			'RegionOverview',
			'RoadMaintenance',
			'RoadMaintenanceDetail',
			'RoadMaintenanceOverview',
			'SchedulerScheduler',
			'SdgLocatie',
			'SdgSdg',
			'SdgUplLijst',
			'SearchOverview',
			'Sitemap',
			'Subsidy',
			'SubsidyDetail',
			'SubsidyKind',
			'SubsidyOverview',
			'SubsidyScale',
			'Tag',
			'TargetGroup',
			'Theme',
			'ThemeDetail',
			'ThemeOverview',
			'Upl',
			'UploadFile',
			'UploadFolder',
			'UsersPermissionsPermission',
			'UsersPermissionsRole',
			'UsersPermissionsUser',
			'VacancyDetail',
			'VacancyInternalDetail',
			'VacancyInternalOverview',
			'VacancyOverview',
			'WobRequest',
			'WobRequestOverview',
			'WooCategory',
			'WooDocumentDetail',
			'WooRequest',
			'WooRequestOverview',
			'WorkAt'
		],
		GeolinkHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		GeolinkPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		HomePageModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesSubjects',
			'ComponentModulesTestimonial',
			'ComponentModulesThemes',
			'Error'
		],
		InConvoWithArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		InConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		InConvoWithOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		LoginModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesVacanciesOverview',
			'Error'
		],
		MapsFiguresOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		NewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesSubjects',
			'Error'
		],
		NewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		NewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		NewsletterArchiveOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesPermitsOverview',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'Error'
		],
		OpOostHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		OpOostInConvoWithArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		OpOostInConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		OpOostInConvoWithOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		OpOostNewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		OpOostNewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		OpOostNewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		OpOostPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		OpenGelderlandHomepageModulesDynamicZone: ['ComponentModulesCta', 'Error'],
		OpenGelderlandWooOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		PageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'ComponentModulesThemes',
			'Error'
		],
		PasswordPageModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesSubjects',
			'ComponentModulesTestimonial',
			'ComponentModulesThemes',
			'Error'
		],
		PermitDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'Error'
		],
		PermitModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		PermitsOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesPermitsOverview',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'Error'
		],
		PressReleaseDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesSubjects',
			'Error'
		],
		PressReleaseModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		PressReleaseOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesPressReleaseOverview',
			'Error'
		],
		ProjectDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		ProjectModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		ProjectOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesProjectOverview',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegioExpresHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegioExpresInConvoOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		RegioExpresInConvoWithArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegioExpresInConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		RegioExpresNewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		RegioExpresNewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		RegioExpresNewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegioExpresPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegionDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesThemes',
			'Error'
		],
		RegionModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesThemes',
			'Error'
		],
		RegionOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		RoadMaintenanceDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'Error'
		],
		RoadMaintenanceModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'Error'
		],
		RoadMaintenanceOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		SearchOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		SitemapModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'Error'
		],
		SubsidyDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		SubsidyModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		SubsidyOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesSubjects',
			'ComponentModulesSubsidyOverview',
			'ComponentModulesThemes',
			'Error'
		],
		ThemeDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesThemes',
			'Error'
		],
		ThemeModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesThemes',
			'Error'
		],
		ThemeOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		VacancyDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesSubjects',
			'Error'
		],
		VacancyInternalDetailModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesSubjects',
			'Error'
		],
		VacancyInternalOverviewModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesVacanciesOverview',
			'Error'
		],
		VacancyOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesVacanciesOverview',
			'Error'
		],
		WobRequestOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		WooDocumentDetailModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesLinkBox',
			'ComponentModulesShare',
			'Error'
		],
		WooRequestOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		WorkAtModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesCta',
			'ComponentModulesLinkBox',
			'ComponentModulesPartners',
			'Error'
		]
	}
};
export default result;
