import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	AgendaPageModulesDynamicZoneInput: any;
	CondoleanceHomepageModulesDynamicZoneInput: any;
	CondoleancePageModulesDynamicZoneInput: any;
	ContactModulesDynamicZoneInput: any;
	Date: any;
	DateTime: any;
	DecisionsPageModulesDynamicZoneInput: any;
	DownloadsPageModulesDynamicZoneInput: any;
	EerbeekloenenHomepageModulesDynamicZoneInput: any;
	EerbeekloenenInConvoArticleModulesDynamicZoneInput: any;
	EerbeekloenenInConvoOverviewModulesDynamicZoneInput: any;
	EerbeekloenenInConvoWithDetailModulesDynamicZoneInput: any;
	EerbeekloenenNewsDetailModulesDynamicZoneInput: any;
	EerbeekloenenNewsOverviewModulesDynamicZoneInput: any;
	EerbeekloenenNewsarticleModulesDynamicZoneInput: any;
	EerbeekloenenPageModulesDynamicZoneInput: any;
	EfroOostHomepageModulesDynamicZoneInput: any;
	EfroOostInConvoWithArticleModulesDynamicZoneInput: any;
	EfroOostInConvoWithDetailModulesDynamicZoneInput: any;
	EfroOostInConvoWithOverviewModulesDynamicZoneInput: any;
	EfroOostNewsDetailModulesDynamicZoneInput: any;
	EfroOostNewsOverviewModulesDynamicZoneInput: any;
	EfroOostNewsarticleModulesDynamicZoneInput: any;
	EfroOostPageModulesDynamicZoneInput: any;
	EventOverviewModulesDynamicZoneInput: any;
	GeolinkHomepageModulesDynamicZoneInput: any;
	GeolinkPageModulesDynamicZoneInput: any;
	HomePageModulesDynamicZoneInput: any;
	I18NLocaleCode: any;
	InConvoWithArticleModulesDynamicZoneInput: any;
	InConvoWithDetailModulesDynamicZoneInput: any;
	InConvoWithOverviewModulesDynamicZoneInput: any;
	JSON: any;
	LoginModulesDynamicZoneInput: any;
	Long: any;
	MapsFiguresOverviewModulesDynamicZoneInput: any;
	NewsDetailModulesDynamicZoneInput: any;
	NewsOverviewModulesDynamicZoneInput: any;
	NewsarticleModulesDynamicZoneInput: any;
	NewsletterArchiveOverviewModulesDynamicZoneInput: any;
	OpOostHomepageModulesDynamicZoneInput: any;
	OpOostInConvoWithArticleModulesDynamicZoneInput: any;
	OpOostInConvoWithDetailModulesDynamicZoneInput: any;
	OpOostInConvoWithOverviewModulesDynamicZoneInput: any;
	OpOostNewsDetailModulesDynamicZoneInput: any;
	OpOostNewsOverviewModulesDynamicZoneInput: any;
	OpOostNewsarticleModulesDynamicZoneInput: any;
	OpOostPageModulesDynamicZoneInput: any;
	OpenGelderlandHomepageModulesDynamicZoneInput: any;
	OpenGelderlandWooOverviewModulesDynamicZoneInput: any;
	PageModulesDynamicZoneInput: any;
	PasswordPageModulesDynamicZoneInput: any;
	PermitDetailModulesDynamicZoneInput: any;
	PermitModulesDynamicZoneInput: any;
	PermitsOverviewModulesDynamicZoneInput: any;
	PressReleaseDetailModulesDynamicZoneInput: any;
	PressReleaseModulesDynamicZoneInput: any;
	PressReleaseOverviewModulesDynamicZoneInput: any;
	ProjectDetailModulesDynamicZoneInput: any;
	ProjectModulesDynamicZoneInput: any;
	ProjectOverviewModulesDynamicZoneInput: any;
	RegioExpresHomepageModulesDynamicZoneInput: any;
	RegioExpresInConvoOverviewModulesDynamicZoneInput: any;
	RegioExpresInConvoWithArticleModulesDynamicZoneInput: any;
	RegioExpresInConvoWithDetailModulesDynamicZoneInput: any;
	RegioExpresNewsDetailModulesDynamicZoneInput: any;
	RegioExpresNewsOverviewModulesDynamicZoneInput: any;
	RegioExpresNewsarticleModulesDynamicZoneInput: any;
	RegioExpresPageModulesDynamicZoneInput: any;
	RegionDetailModulesDynamicZoneInput: any;
	RegionModulesDynamicZoneInput: any;
	RegionOverviewModulesDynamicZoneInput: any;
	RoadMaintenanceDetailModulesDynamicZoneInput: any;
	RoadMaintenanceModulesDynamicZoneInput: any;
	RoadMaintenanceOverviewModulesDynamicZoneInput: any;
	SearchOverviewModulesDynamicZoneInput: any;
	SitemapModulesDynamicZoneInput: any;
	SubsidyDetailModulesDynamicZoneInput: any;
	SubsidyModulesDynamicZoneInput: any;
	SubsidyOverviewModulesDynamicZoneInput: any;
	ThemeDetailModulesDynamicZoneInput: any;
	ThemeModulesDynamicZoneInput: any;
	ThemeOverviewModulesDynamicZoneInput: any;
	Upload: any;
	VacancyDetailModulesDynamicZoneInput: any;
	VacancyInternalDetailModulesDynamicZoneInput: any;
	VacancyInternalOverviewModulesDynamicZoneInput: any;
	VacancyOverviewModulesDynamicZoneInput: any;
	WobRequestOverviewModulesDynamicZoneInput: any;
	WooDocumentDetailModulesDynamicZoneInput: any;
	WooRequestOverviewModulesDynamicZoneInput: any;
	WorkAtModulesDynamicZoneInput: any;
};

export type AgendaPage = {
	__typename?: 'AgendaPage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<AgendaPageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<AgendaPageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AgendaPageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type AgendaPageEntity = {
	__typename?: 'AgendaPageEntity';
	attributes?: Maybe<AgendaPage>;
	id?: Maybe<Scalars['ID']>;
};

export type AgendaPageEntityResponse = {
	__typename?: 'AgendaPageEntityResponse';
	data?: Maybe<AgendaPageEntity>;
};

export type AgendaPageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['AgendaPageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type AgendaPageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| Error;

export type AgendaPageRelationResponseCollection = {
	__typename?: 'AgendaPageRelationResponseCollection';
	data: Array<AgendaPageEntity>;
};

export type AuditLogAuditLog = {
	__typename?: 'AuditLogAuditLog';
	action: Scalars['String'];
	createdAt?: Maybe<Scalars['DateTime']>;
	date: Scalars['DateTime'];
	details?: Maybe<Scalars['JSON']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	user?: Maybe<Scalars['String']>;
};

export type AuditLogAuditLogEntity = {
	__typename?: 'AuditLogAuditLogEntity';
	attributes?: Maybe<AuditLogAuditLog>;
	id?: Maybe<Scalars['ID']>;
};

export type AuditLogAuditLogEntityResponse = {
	__typename?: 'AuditLogAuditLogEntityResponse';
	data?: Maybe<AuditLogAuditLogEntity>;
};

export type AuditLogAuditLogEntityResponseCollection = {
	__typename?: 'AuditLogAuditLogEntityResponseCollection';
	data: Array<AuditLogAuditLogEntity>;
	meta: ResponseCollectionMeta;
};

export type AuditLogAuditLogFiltersInput = {
	action?: InputMaybe<StringFilterInput>;
	and?: InputMaybe<Array<InputMaybe<AuditLogAuditLogFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	date?: InputMaybe<DateTimeFilterInput>;
	details?: InputMaybe<JsonFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<AuditLogAuditLogFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<AuditLogAuditLogFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	user?: InputMaybe<StringFilterInput>;
};

export type AuditLogAuditLogInput = {
	action?: InputMaybe<Scalars['String']>;
	date?: InputMaybe<Scalars['DateTime']>;
	details?: InputMaybe<Scalars['JSON']>;
	password?: InputMaybe<Scalars['String']>;
	user?: InputMaybe<Scalars['String']>;
};

export type BooleanFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
	contains?: InputMaybe<Scalars['Boolean']>;
	containsi?: InputMaybe<Scalars['Boolean']>;
	endsWith?: InputMaybe<Scalars['Boolean']>;
	eq?: InputMaybe<Scalars['Boolean']>;
	eqi?: InputMaybe<Scalars['Boolean']>;
	gt?: InputMaybe<Scalars['Boolean']>;
	gte?: InputMaybe<Scalars['Boolean']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
	lt?: InputMaybe<Scalars['Boolean']>;
	lte?: InputMaybe<Scalars['Boolean']>;
	ne?: InputMaybe<Scalars['Boolean']>;
	nei?: InputMaybe<Scalars['Boolean']>;
	not?: InputMaybe<BooleanFilterInput>;
	notContains?: InputMaybe<Scalars['Boolean']>;
	notContainsi?: InputMaybe<Scalars['Boolean']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
	startsWith?: InputMaybe<Scalars['Boolean']>;
};

export type CampaignNewsletterContact = {
	__typename?: 'CampaignNewsletterContact';
	contact?: Maybe<NewsletterContactData>;
	id: Scalars['String'];
};

export type CampaignNewsletterField = {
	__typename?: 'CampaignNewsletterField';
	campaignId?: Maybe<Scalars['String']>;
	newsletter?: Maybe<Scalars['String']>;
	value?: Maybe<Scalars['String']>;
};

export type ComponentComponentsButton = {
	__typename?: 'ComponentComponentsButton';
	href: Scalars['JSON'];
	iconLeft?: Maybe<UploadFileEntityResponse>;
	iconRight?: Maybe<UploadFileEntityResponse>;
	id: Scalars['ID'];
	title?: Maybe<Scalars['String']>;
	variant: Enum_Componentcomponentsbutton_Variant;
};

export type ComponentComponentsButtonFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentComponentsButtonFiltersInput>>>;
	href?: InputMaybe<JsonFilterInput>;
	not?: InputMaybe<ComponentComponentsButtonFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentComponentsButtonFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
	variant?: InputMaybe<StringFilterInput>;
};

export type ComponentComponentsButtonInput = {
	href?: InputMaybe<Scalars['JSON']>;
	iconLeft?: InputMaybe<Scalars['ID']>;
	iconRight?: InputMaybe<Scalars['ID']>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
	variant?: InputMaybe<Enum_Componentcomponentsbutton_Variant>;
};

export type ComponentComponentsIconLink = {
	__typename?: 'ComponentComponentsIconLink';
	href: Scalars['JSON'];
	icon: UploadFileEntityResponse;
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type ComponentComponentsIconLinkFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentComponentsIconLinkFiltersInput>>>;
	href?: InputMaybe<JsonFilterInput>;
	not?: InputMaybe<ComponentComponentsIconLinkFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentComponentsIconLinkFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
};

export type ComponentComponentsIconLinkGroup = {
	__typename?: 'ComponentComponentsIconLinkGroup';
	id: Scalars['ID'];
	links: Array<Maybe<ComponentComponentsIconLink>>;
	title: Scalars['String'];
};

export type ComponentComponentsIconLinkGroupLinksArgs = {
	filters?: InputMaybe<ComponentComponentsIconLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentComponentsIconLinkGroupInput = {
	id?: InputMaybe<Scalars['ID']>;
	links?: InputMaybe<Array<InputMaybe<ComponentComponentsIconLinkInput>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentComponentsIconLinkInput = {
	href?: InputMaybe<Scalars['JSON']>;
	icon?: InputMaybe<Scalars['ID']>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentComponentsLink = {
	__typename?: 'ComponentComponentsLink';
	description?: Maybe<Scalars['String']>;
	href: Scalars['JSON'];
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type ComponentComponentsLinkFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkFiltersInput>>>;
	description?: InputMaybe<StringFilterInput>;
	href?: InputMaybe<JsonFilterInput>;
	not?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
};

export type ComponentComponentsLinkGroup = {
	__typename?: 'ComponentComponentsLinkGroup';
	href?: Maybe<Scalars['JSON']>;
	id: Scalars['ID'];
	links: Array<Maybe<ComponentComponentsLink>>;
	title: Scalars['String'];
};

export type ComponentComponentsLinkGroupLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentComponentsLinkGroupFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkGroupFiltersInput>>>;
	href?: InputMaybe<JsonFilterInput>;
	links?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	not?: InputMaybe<ComponentComponentsLinkGroupFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkGroupFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
};

export type ComponentComponentsLinkGroupInput = {
	href?: InputMaybe<Scalars['JSON']>;
	id?: InputMaybe<Scalars['ID']>;
	links?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkInput>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentComponentsLinkInput = {
	description?: InputMaybe<Scalars['String']>;
	href?: InputMaybe<Scalars['JSON']>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentComponentsSubject = {
	__typename?: 'ComponentComponentsSubject';
	description?: Maybe<Scalars['String']>;
	href?: Maybe<Scalars['JSON']>;
	id: Scalars['ID'];
	title?: Maybe<Scalars['String']>;
};

export type ComponentInternalAboutMaintenance = {
	__typename?: 'ComponentInternalAboutMaintenance';
	contractor?: Maybe<Scalars['String']>;
	contractorWebsite?: Maybe<Scalars['String']>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	detour?: Maybe<Scalars['String']>;
	endDate?: Maybe<Scalars['DateTime']>;
	id: Scalars['ID'];
	startDate?: Maybe<Scalars['DateTime']>;
};

export type ComponentInternalAboutMaintenanceFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalAboutMaintenanceFiltersInput>>>;
	contractor?: InputMaybe<StringFilterInput>;
	contractorWebsite?: InputMaybe<StringFilterInput>;
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsFiltersInput>;
	detour?: InputMaybe<StringFilterInput>;
	endDate?: InputMaybe<DateTimeFilterInput>;
	not?: InputMaybe<ComponentInternalAboutMaintenanceFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalAboutMaintenanceFiltersInput>>>;
	startDate?: InputMaybe<DateTimeFilterInput>;
};

export type ComponentInternalAboutMaintenanceInput = {
	contractor?: InputMaybe<Scalars['String']>;
	contractorWebsite?: InputMaybe<Scalars['String']>;
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsInput>;
	detour?: InputMaybe<Scalars['String']>;
	endDate?: InputMaybe<Scalars['DateTime']>;
	id?: InputMaybe<Scalars['ID']>;
	startDate?: InputMaybe<Scalars['DateTime']>;
};

export type ComponentInternalAccordeonItem = {
	__typename?: 'ComponentInternalAccordeonItem';
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	itemTitle: Scalars['String'];
};

export type ComponentInternalAccordeonItemFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalAccordeonItemFiltersInput>>>;
	description?: InputMaybe<StringFilterInput>;
	itemTitle?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentInternalAccordeonItemFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalAccordeonItemFiltersInput>>>;
};

export type ComponentInternalAccordeonSection = {
	__typename?: 'ComponentInternalAccordeonSection';
	accordeonSectionName?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	items: Array<Maybe<ComponentInternalAccordeonItem>>;
};

export type ComponentInternalAccordeonSectionItemsArgs = {
	filters?: InputMaybe<ComponentInternalAccordeonItemFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalAccordeonSectionFiltersInput = {
	accordeonSectionName?: InputMaybe<StringFilterInput>;
	and?: InputMaybe<Array<InputMaybe<ComponentInternalAccordeonSectionFiltersInput>>>;
	items?: InputMaybe<ComponentInternalAccordeonItemFiltersInput>;
	not?: InputMaybe<ComponentInternalAccordeonSectionFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalAccordeonSectionFiltersInput>>>;
};

export type ComponentInternalBodyTextColumn = {
	__typename?: 'ComponentInternalBodyTextColumn';
	bodyText?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
};

export type ComponentInternalDefaultModuleOptions = {
	__typename?: 'ComponentInternalDefaultModuleOptions';
	backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
	id: Scalars['ID'];
	padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
};

export type ComponentInternalDefaultModuleOptionsExtended = {
	__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
	backgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
	id: Scalars['ID'];
	padding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
};

export type ComponentInternalDefaultModuleOptionsExtendedFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalDefaultModuleOptionsExtendedFiltersInput>>>;
	backgroundColor?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentInternalDefaultModuleOptionsExtendedFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalDefaultModuleOptionsExtendedFiltersInput>>>;
	padding?: InputMaybe<StringFilterInput>;
};

export type ComponentInternalDefaultModuleOptionsExtendedInput = {
	backgroundColor?: InputMaybe<Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor>;
	id?: InputMaybe<Scalars['ID']>;
	padding?: InputMaybe<Enum_Componentinternaldefaultmoduleoptionsextended_Padding>;
};

export type ComponentInternalDefaultModuleOptionsFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalDefaultModuleOptionsFiltersInput>>>;
	backgroundColor?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentInternalDefaultModuleOptionsFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalDefaultModuleOptionsFiltersInput>>>;
	padding?: InputMaybe<StringFilterInput>;
};

export type ComponentInternalDefaultModuleOptionsInput = {
	backgroundColor?: InputMaybe<Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor>;
	id?: InputMaybe<Scalars['ID']>;
	padding?: InputMaybe<Enum_Componentinternaldefaultmoduleoptions_Padding>;
};

export type ComponentInternalEmptyState = {
	__typename?: 'ComponentInternalEmptyState';
	buttonHref?: Maybe<Scalars['String']>;
	buttonTitle?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	message?: Maybe<Scalars['String']>;
};

export type ComponentInternalFirstRowItems = {
	__typename?: 'ComponentInternalFirstRowItems';
	bodyText?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
};

export type ComponentInternalFirstRowItemsFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalFirstRowItemsFiltersInput>>>;
	bodyText?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentInternalFirstRowItemsFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalFirstRowItemsFiltersInput>>>;
};

export type ComponentInternalFooter = {
	__typename?: 'ComponentInternalFooter';
	accessibilityLink?: Maybe<Scalars['String']>;
	accessibilityLogo?: Maybe<UploadFileEntityResponse>;
	bottomLinks?: Maybe<Array<Maybe<ComponentComponentsLink>>>;
	contactLinkGroup?: Maybe<ComponentComponentsLinkGroup>;
	id: Scalars['ID'];
	logo?: Maybe<UploadFileEntityResponse>;
	socialLinkGroup?: Maybe<ComponentComponentsIconLinkGroup>;
	themeLinkGroup?: Maybe<ComponentComponentsLinkGroup>;
};

export type ComponentInternalFooterBottomLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalFooterInput = {
	accessibilityLink?: InputMaybe<Scalars['String']>;
	accessibilityLogo?: InputMaybe<Scalars['ID']>;
	bottomLinks?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkInput>>>;
	contactLinkGroup?: InputMaybe<ComponentComponentsLinkGroupInput>;
	id?: InputMaybe<Scalars['ID']>;
	logo?: InputMaybe<Scalars['ID']>;
	socialLinkGroup?: InputMaybe<ComponentComponentsIconLinkGroupInput>;
	themeLinkGroup?: InputMaybe<ComponentComponentsLinkGroupInput>;
};

export type ComponentInternalHighlightEvents = {
	__typename?: 'ComponentInternalHighlightEvents';
	buttonHref?: Maybe<Scalars['JSON']>;
	buttonTitle?: Maybe<Scalars['String']>;
	empty?: Maybe<ComponentInternalEmptyState>;
	events?: Maybe<NewsarticleRelationResponseCollection>;
	id: Scalars['ID'];
	title?: Maybe<Scalars['String']>;
};

export type ComponentInternalHighlightEventsEventsArgs = {
	filters?: InputMaybe<NewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalHighlightMapsAndStats = {
	__typename?: 'ComponentInternalHighlightMapsAndStats';
	buttonHref?: Maybe<Scalars['JSON']>;
	buttonTitle?: Maybe<Scalars['String']>;
	empty?: Maybe<ComponentInternalEmptyState>;
	id: Scalars['ID'];
	mapsAndStats?: Maybe<MapsFigureRelationResponseCollection>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentInternalHighlightMapsAndStatsMapsAndStatsArgs = {
	filters?: InputMaybe<MapsFigureFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalHighlightNews = {
	__typename?: 'ComponentInternalHighlightNews';
	buttonHref?: Maybe<Scalars['JSON']>;
	buttonTitle?: Maybe<Scalars['String']>;
	empty?: Maybe<ComponentInternalEmptyState>;
	id: Scalars['ID'];
	newsArticles?: Maybe<NewsarticleRelationResponseCollection>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentInternalHighlightNewsNewsArticlesArgs = {
	filters?: InputMaybe<NewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalHighlightPermits = {
	__typename?: 'ComponentInternalHighlightPermits';
	buttonHref?: Maybe<Scalars['JSON']>;
	buttonTitle?: Maybe<Scalars['String']>;
	empty?: Maybe<ComponentInternalEmptyState>;
	id: Scalars['ID'];
	permits?: Maybe<PermitRelationResponseCollection>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentInternalHighlightPermitsPermitsArgs = {
	filters?: InputMaybe<PermitFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalHighlightSubsidies = {
	__typename?: 'ComponentInternalHighlightSubsidies';
	buttonHref?: Maybe<Scalars['JSON']>;
	buttonTitle?: Maybe<Scalars['String']>;
	empty?: Maybe<ComponentInternalEmptyState>;
	id: Scalars['ID'];
	subsidies?: Maybe<SubsidyRelationResponseCollection>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentInternalHighlightSubsidiesSubsidiesArgs = {
	filters?: InputMaybe<SubsidyFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalHighlightTopics = {
	__typename?: 'ComponentInternalHighlightTopics';
	buttonHref?: Maybe<Scalars['JSON']>;
	buttonTitle?: Maybe<Scalars['String']>;
	empty?: Maybe<ComponentInternalEmptyState>;
	id: Scalars['ID'];
	title?: Maybe<Scalars['String']>;
	topics?: Maybe<NewsarticleRelationResponseCollection>;
};

export type ComponentInternalHighlightTopicsTopicsArgs = {
	filters?: InputMaybe<NewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalHighlightWoo = {
	__typename?: 'ComponentInternalHighlightWoo';
	buttonHref?: Maybe<Scalars['JSON']>;
	buttonTitle?: Maybe<Scalars['String']>;
	empty?: Maybe<ComponentInternalEmptyState>;
	id: Scalars['ID'];
	requests?: Maybe<WooRequestRelationResponseCollection>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentInternalHighlightWooRequestsArgs = {
	filters?: InputMaybe<WooRequestFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalNavbar = {
	__typename?: 'ComponentInternalNavbar';
	ThemeGroupTitle?: Maybe<Scalars['String']>;
	bottomLinks?: Maybe<Array<Maybe<ComponentComponentsLink>>>;
	id: Scalars['ID'];
	logo?: Maybe<UploadFileEntityResponse>;
	mostPopularGroupLinks?: Maybe<Array<Maybe<ComponentComponentsLinkGroup>>>;
	mostPopularTitle?: Maybe<Scalars['String']>;
	notificationIsExternal?: Maybe<Scalars['Boolean']>;
	notificationLink?: Maybe<Scalars['JSON']>;
	notificationMessage?: Maybe<Scalars['String']>;
	notificationPublishDate?: Maybe<Scalars['DateTime']>;
	notificationUnpublishDate?: Maybe<Scalars['DateTime']>;
	rightNavLink?: Maybe<Scalars['JSON']>;
	searchPlaceholderText?: Maybe<Scalars['String']>;
	themeGroupLinks?: Maybe<Array<Maybe<ComponentComponentsLinkGroup>>>;
};

export type ComponentInternalNavbarBottomLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalNavbarMostPopularGroupLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkGroupFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalNavbarThemeGroupLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkGroupFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalNavbarInput = {
	ThemeGroupTitle?: InputMaybe<Scalars['String']>;
	bottomLinks?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkInput>>>;
	id?: InputMaybe<Scalars['ID']>;
	logo?: InputMaybe<Scalars['ID']>;
	mostPopularGroupLinks?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkGroupInput>>>;
	mostPopularTitle?: InputMaybe<Scalars['String']>;
	notificationIsExternal?: InputMaybe<Scalars['Boolean']>;
	notificationLink?: InputMaybe<Scalars['JSON']>;
	notificationMessage?: InputMaybe<Scalars['String']>;
	notificationPublishDate?: InputMaybe<Scalars['DateTime']>;
	notificationUnpublishDate?: InputMaybe<Scalars['DateTime']>;
	rightNavLink?: InputMaybe<Scalars['JSON']>;
	searchPlaceholderText?: InputMaybe<Scalars['String']>;
	themeGroupLinks?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkGroupInput>>>;
};

export type ComponentInternalNewsletter = {
	__typename?: 'ComponentInternalNewsletter';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	fieldLabel?: Maybe<Scalars['String']>;
	fieldPlaceholder?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	submitButton?: Maybe<ComponentComponentsButton>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentInternalNewsletterInput = {
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsInput>;
	fieldLabel?: InputMaybe<Scalars['String']>;
	fieldPlaceholder?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	submitButton?: InputMaybe<ComponentComponentsButtonInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentInternalPhoto = {
	__typename?: 'ComponentInternalPhoto';
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	photo: UploadFileEntityResponse;
	title: Scalars['String'];
};

export type ComponentInternalPhotoFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalPhotoFiltersInput>>>;
	description?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentInternalPhotoFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalPhotoFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
};

export type ComponentInternalSecondColumnItems = {
	__typename?: 'ComponentInternalSecondColumnItems';
	bodyText?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
};

export type ComponentInternalSecondColumnItemsFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalSecondColumnItemsFiltersInput>>>;
	bodyText?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentInternalSecondColumnItemsFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalSecondColumnItemsFiltersInput>>>;
};

export type ComponentInternalSeo = {
	__typename?: 'ComponentInternalSeo';
	description: Scalars['String'];
	id: Scalars['ID'];
	noFollow: Scalars['Boolean'];
	noIndex: Scalars['Boolean'];
	ogDescription?: Maybe<Scalars['String']>;
	ogImage?: Maybe<UploadFileEntityResponse>;
	ogTitle?: Maybe<Scalars['String']>;
	ogType?: Maybe<Scalars['String']>;
	ogUrl?: Maybe<Scalars['String']>;
	title: Scalars['String'];
};

export type ComponentInternalSeoInput = {
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	noFollow?: InputMaybe<Scalars['Boolean']>;
	noIndex?: InputMaybe<Scalars['Boolean']>;
	ogDescription?: InputMaybe<Scalars['String']>;
	ogImage?: InputMaybe<Scalars['ID']>;
	ogTitle?: InputMaybe<Scalars['String']>;
	ogType?: InputMaybe<Scalars['String']>;
	ogUrl?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentInternalSidebar = {
	__typename?: 'ComponentInternalSidebar';
	files?: Maybe<UploadFileRelationResponseCollection>;
	id: Scalars['ID'];
	links?: Maybe<Array<Maybe<ComponentComponentsLink>>>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentInternalSidebarFilesArgs = {
	filters?: InputMaybe<UploadFileFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalSidebarLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentInternalSidebarFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalSidebarFiltersInput>>>;
	links?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	not?: InputMaybe<ComponentInternalSidebarFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalSidebarFiltersInput>>>;
	title?: InputMaybe<JsonFilterInput>;
};

export type ComponentInternalSidebarInput = {
	files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	id?: InputMaybe<Scalars['ID']>;
	links?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkInput>>>;
	title?: InputMaybe<Scalars['JSON']>;
};

export type ComponentInternalStepByStepCard = {
	__typename?: 'ComponentInternalStepByStepCard';
	cardDescription?: Maybe<Scalars['String']>;
	cardTitle: Scalars['String'];
	id: Scalars['ID'];
};

export type ComponentInternalStepByStepCardFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentInternalStepByStepCardFiltersInput>>>;
	cardDescription?: InputMaybe<StringFilterInput>;
	cardTitle?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentInternalStepByStepCardFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentInternalStepByStepCardFiltersInput>>>;
};

export type ComponentInternalTest = {
	__typename?: 'ComponentInternalTest';
	Title?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	nieuws_artikels?: Maybe<NewsarticleRelationResponseCollection>;
	thema?: Maybe<ThemeEntityResponse>;
};

export type ComponentInternalTestNieuws_ArtikelsArgs = {
	filters?: InputMaybe<NewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesAccordeon = {
	__typename?: 'ComponentModulesAccordeon';
	accordeonTitle?: Maybe<Scalars['JSON']>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	sections: Array<Maybe<ComponentInternalAccordeonSection>>;
	sidebar?: Maybe<ComponentInternalSidebar>;
};

export type ComponentModulesAccordeonSectionsArgs = {
	filters?: InputMaybe<ComponentInternalAccordeonSectionFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesBbvmsMediaplayer = {
	__typename?: 'ComponentModulesBbvmsMediaplayer';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
	title?: Maybe<Scalars['JSON']>;
	transcript?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
	videoId: Scalars['String'];
};

export type ComponentModulesBodyText = {
	__typename?: 'ComponentModulesBodyText';
	bodyText?: Maybe<Scalars['String']>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	sidebar?: Maybe<ComponentInternalSidebar>;
};

export type ComponentModulesBodyTextFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentModulesBodyTextFiltersInput>>>;
	bodyText?: InputMaybe<StringFilterInput>;
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsFiltersInput>;
	not?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentModulesBodyTextFiltersInput>>>;
	sidebar?: InputMaybe<ComponentInternalSidebarFiltersInput>;
};

export type ComponentModulesBodyTextInput = {
	bodyText?: InputMaybe<Scalars['String']>;
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsInput>;
	id?: InputMaybe<Scalars['ID']>;
	sidebar?: InputMaybe<ComponentInternalSidebarInput>;
};

export type ComponentModulesBodyTextTwoColumn = {
	__typename?: 'ComponentModulesBodyTextTwoColumn';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	leftColumn: ComponentInternalBodyTextColumn;
	rightColumn: ComponentInternalBodyTextColumn;
};

export type ComponentModulesCollections = {
	__typename?: 'ComponentModulesCollections';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	links: Array<Maybe<ComponentComponentsLink>>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentModulesCollectionsLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesCollectionsInput = {
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsInput>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	links?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkInput>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesCta = {
	__typename?: 'ComponentModulesCta';
	buttons?: Maybe<Array<Maybe<ComponentComponentsButton>>>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptionsExtended;
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	imageOrVideoFile?: Maybe<UploadFileEntityResponse>;
	imagePosition?: Maybe<Enum_Componentmodulescta_Imageposition>;
	title?: Maybe<Scalars['JSON']>;
	videoLink?: Maybe<Scalars['String']>;
};

export type ComponentModulesCtaButtonsArgs = {
	filters?: InputMaybe<ComponentComponentsButtonFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesCtaInput = {
	buttons?: InputMaybe<Array<InputMaybe<ComponentComponentsButtonInput>>>;
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsExtendedInput>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	imageOrVideoFile?: InputMaybe<Scalars['ID']>;
	imagePosition?: InputMaybe<Enum_Componentmodulescta_Imageposition>;
	title?: InputMaybe<Scalars['JSON']>;
	videoLink?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesDownloads = {
	__typename?: 'ComponentModulesDownloads';
	defaultModuleOptions: ComponentInternalDefaultModuleOptionsExtended;
	downloadLinks?: Maybe<Array<Maybe<ComponentComponentsLink>>>;
	downloads?: Maybe<UploadFileRelationResponseCollection>;
	id: Scalars['ID'];
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesDownloadsDownloadLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesDownloadsDownloadsArgs = {
	filters?: InputMaybe<UploadFileFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesDownloadsFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentModulesDownloadsFiltersInput>>>;
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsExtendedFiltersInput>;
	downloadLinks?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	not?: InputMaybe<ComponentModulesDownloadsFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentModulesDownloadsFiltersInput>>>;
	title?: InputMaybe<JsonFilterInput>;
};

export type ComponentModulesDownloadsInput = {
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsExtendedInput>;
	downloadLinks?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkInput>>>;
	downloads?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['JSON']>;
};

export type ComponentModulesEventOverview = {
	__typename?: 'ComponentModulesEventOverview';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	filterLabel?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
};

export type ComponentModulesExpandedHeader = {
	__typename?: 'ComponentModulesExpandedHeader';
	description?: Maybe<Scalars['String']>;
	headerButton?: Maybe<ComponentComponentsButton>;
	headerDescription?: Maybe<Scalars['String']>;
	headerImage?: Maybe<UploadFileEntityResponse>;
	headerTitle?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	title?: Maybe<Scalars['String']>;
};

export type ComponentModulesExpandedHeaderFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentModulesExpandedHeaderFiltersInput>>>;
	description?: InputMaybe<StringFilterInput>;
	headerButton?: InputMaybe<ComponentComponentsButtonFiltersInput>;
	headerDescription?: InputMaybe<StringFilterInput>;
	headerTitle?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentModulesExpandedHeaderFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentModulesExpandedHeaderFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
};

export type ComponentModulesExpandedHeaderInput = {
	description?: InputMaybe<Scalars['String']>;
	headerButton?: InputMaybe<ComponentComponentsButtonInput>;
	headerDescription?: InputMaybe<Scalars['String']>;
	headerImage?: InputMaybe<Scalars['ID']>;
	headerTitle?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesFeaturedNews = {
	__typename?: 'ComponentModulesFeaturedNews';
	cta?: Maybe<ComponentComponentsButton>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	newsArticles?: Maybe<NewsarticleRelationResponseCollection>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesFeaturedNewsNewsArticlesArgs = {
	filters?: InputMaybe<NewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesFeaturedNewsEerbeekloenen = {
	__typename?: 'ComponentModulesFeaturedNewsEerbeekloenen';
	cta?: Maybe<ComponentComponentsButton>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	newsArticles?: Maybe<EerbeekloenenNewsarticleRelationResponseCollection>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesFeaturedNewsEerbeekloenenNewsArticlesArgs = {
	filters?: InputMaybe<EerbeekloenenNewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesFeaturedNewsEfroOost = {
	__typename?: 'ComponentModulesFeaturedNewsEfroOost';
	cta?: Maybe<ComponentComponentsButton>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	newsArticles?: Maybe<EfroOostNewsarticleRelationResponseCollection>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesFeaturedNewsEfroOostNewsArticlesArgs = {
	filters?: InputMaybe<EfroOostNewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesFeaturedNewsOpOost = {
	__typename?: 'ComponentModulesFeaturedNewsOpOost';
	cta?: Maybe<ComponentComponentsButton>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	news?: Maybe<OpOostNewsarticleRelationResponseCollection>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesFeaturedNewsOpOostNewsArgs = {
	filters?: InputMaybe<OpOostNewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesFeaturedNewsRegioExpres = {
	__typename?: 'ComponentModulesFeaturedNewsRegioExpres';
	cta?: Maybe<ComponentComponentsButton>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	newsArticles?: Maybe<RegioExpresNewsarticleRelationResponseCollection>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesFeaturedNewsRegioExpresNewsArticlesArgs = {
	filters?: InputMaybe<RegioExpresNewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesFeaturedPages = {
	__typename?: 'ComponentModulesFeaturedPages';
	cta?: Maybe<ComponentComponentsButton>;
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	pages?: Maybe<PageRelationResponseCollection>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesFeaturedPagesPagesArgs = {
	filters?: InputMaybe<PageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesHighlight = {
	__typename?: 'ComponentModulesHighlight';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	eventsTab?: Maybe<ComponentInternalHighlightEvents>;
	id: Scalars['ID'];
	mapsAndStatsTab?: Maybe<ComponentInternalHighlightMapsAndStats>;
	newsTab?: Maybe<ComponentInternalHighlightNews>;
	permitsTab?: Maybe<ComponentInternalHighlightPermits>;
	subsidiesTab?: Maybe<ComponentInternalHighlightSubsidies>;
	theme?: Maybe<ThemeEntityResponse>;
	title?: Maybe<Scalars['JSON']>;
	topicsTab?: Maybe<ComponentInternalHighlightTopics>;
	wooTab?: Maybe<ComponentInternalHighlightWoo>;
};

export type ComponentModulesHomepageHeader = {
	__typename?: 'ComponentModulesHomepageHeader';
	backgroundImage?: Maybe<UploadFileEntityResponse>;
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	links?: Maybe<Array<Maybe<ComponentComponentsLink>>>;
	popularHeader?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentModulesHomepageHeaderLinksArgs = {
	filters?: InputMaybe<ComponentComponentsLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesHomepageHeaderInput = {
	backgroundImage?: InputMaybe<Scalars['ID']>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	links?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkInput>>>;
	popularHeader?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesInConvoWithOverview = {
	__typename?: 'ComponentModulesInConvoWithOverview';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	filterLabel?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
};

export type ComponentModulesIntroHeader = {
	__typename?: 'ComponentModulesIntroHeader';
	backgroundColor?: Maybe<Enum_Componentmodulesintroheader_Backgroundcolor>;
	excerpt: Scalars['String'];
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type ComponentModulesIntroHeaderFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentModulesIntroHeaderFiltersInput>>>;
	backgroundColor?: InputMaybe<StringFilterInput>;
	excerpt?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentModulesIntroHeaderFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentModulesIntroHeaderFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
};

export type ComponentModulesIntroHeaderImage = {
	__typename?: 'ComponentModulesIntroHeaderImage';
	backgroundColor?: Maybe<Enum_Componentmodulesintroheaderimage_Backgroundcolor>;
	cover?: Maybe<UploadFileEntityResponse>;
	excerpt?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type ComponentModulesIntroHeaderImageFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>>>;
	backgroundColor?: InputMaybe<StringFilterInput>;
	excerpt?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
};

export type ComponentModulesIntroHeaderImageInput = {
	backgroundColor?: InputMaybe<Enum_Componentmodulesintroheaderimage_Backgroundcolor>;
	cover?: InputMaybe<Scalars['ID']>;
	excerpt?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesIntroHeaderInput = {
	backgroundColor?: InputMaybe<Enum_Componentmodulesintroheader_Backgroundcolor>;
	excerpt?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesLinkBox = {
	__typename?: 'ComponentModulesLinkBox';
	defaultModuleOptions: ComponentInternalDefaultModuleOptionsExtended;
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	links?: Maybe<Array<Maybe<ComponentComponentsButton>>>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesLinkBoxLinksArgs = {
	filters?: InputMaybe<ComponentComponentsButtonFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesLinkBoxInput = {
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsExtendedInput>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	links?: InputMaybe<Array<InputMaybe<ComponentComponentsButtonInput>>>;
	title?: InputMaybe<Scalars['JSON']>;
};

export type ComponentModulesMapsFiguresOverview = {
	__typename?: 'ComponentModulesMapsFiguresOverview';
	filterHeaderText: Scalars['String'];
	headerTitle: Scalars['String'];
	id: Scalars['ID'];
	kindFilterText: Scalars['String'];
	overviewTitleText: Scalars['String'];
	searchPlaceholderText: Scalars['String'];
	showAllThemesText: Scalars['String'];
	showResultsText: Scalars['String'];
	themeFilterText: Scalars['String'];
};

export type ComponentModulesMapsFiguresOverviewInput = {
	filterHeaderText?: InputMaybe<Scalars['String']>;
	headerTitle?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	kindFilterText?: InputMaybe<Scalars['String']>;
	overviewTitleText?: InputMaybe<Scalars['String']>;
	searchPlaceholderText?: InputMaybe<Scalars['String']>;
	showAllThemesText?: InputMaybe<Scalars['String']>;
	showResultsText?: InputMaybe<Scalars['String']>;
	themeFilterText?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesMedia = {
	__typename?: 'ComponentModulesMedia';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	media?: Maybe<UploadFileEntityResponse>;
	mediaLink?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['JSON']>;
	transcript?: Maybe<Scalars['String']>;
};

export type ComponentModulesNewsOverview = {
	__typename?: 'ComponentModulesNewsOverview';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	filterLabel?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
};

export type ComponentModulesOpenWooRequestOverview = {
	__typename?: 'ComponentModulesOpenWooRequestOverview';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	filterHeaderText: Scalars['String'];
	filterLink?: Maybe<ComponentComponentsLink>;
	headerTitle: Scalars['String'];
	id: Scalars['ID'];
};

export type ComponentModulesOpenWooRequestOverviewInput = {
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsInput>;
	filterHeaderText?: InputMaybe<Scalars['String']>;
	filterLink?: InputMaybe<ComponentComponentsLinkInput>;
	headerTitle?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
};

export type ComponentModulesPartners = {
	__typename?: 'ComponentModulesPartners';
	defaultModuleOptions: ComponentInternalDefaultModuleOptionsExtended;
	id: Scalars['ID'];
	logos: Array<Maybe<ComponentComponentsIconLink>>;
	title: Scalars['JSON'];
};

export type ComponentModulesPartnersLogosArgs = {
	filters?: InputMaybe<ComponentComponentsIconLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesPermitsOverview = {
	__typename?: 'ComponentModulesPermitsOverview';
	filterHeaderText: Scalars['String'];
	headerTitle: Scalars['String'];
	id: Scalars['ID'];
	lawFilterText: Scalars['String'];
	overviewTitleText: Scalars['String'];
	productTypeFilterText: Scalars['String'];
	searchPlaceholderText: Scalars['String'];
	showAllLawsText: Scalars['String'];
	showAllProductTypesText: Scalars['String'];
	showAllThemesText: Scalars['String'];
	showResultsText: Scalars['String'];
	themeFilterText: Scalars['String'];
};

export type ComponentModulesPhotoAlbum = {
	__typename?: 'ComponentModulesPhotoAlbum';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	description?: Maybe<Scalars['String']>;
	horizontal?: Maybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	photos: Array<Maybe<ComponentInternalPhoto>>;
	title: Scalars['JSON'];
};

export type ComponentModulesPhotoAlbumPhotosArgs = {
	filters?: InputMaybe<ComponentInternalPhotoFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesPressReleaseOverview = {
	__typename?: 'ComponentModulesPressReleaseOverview';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
};

export type ComponentModulesProjectOverview = {
	__typename?: 'ComponentModulesProjectOverview';
	defaultModuleOptions?: Maybe<ComponentInternalDefaultModuleOptions>;
	filterLabel?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
};

export type ComponentModulesRegions = {
	__typename?: 'ComponentModulesRegions';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	regions?: Maybe<RegionRelationResponseCollection>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesRegionsRegionsArgs = {
	filters?: InputMaybe<RegionFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesRoadMaintenanceOverview = {
	__typename?: 'ComponentModulesRoadMaintenanceOverview';
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type ComponentModulesRoadMaintenanceOverviewInput = {
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesScheduling = {
	__typename?: 'ComponentModulesScheduling';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	firstColumnRows: Array<Maybe<ComponentInternalFirstRowItems>>;
	firstColumnTitle: Scalars['String'];
	id: Scalars['ID'];
	secondColumnRows?: Maybe<Array<Maybe<ComponentInternalSecondColumnItems>>>;
	secondColumnTitle?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesSchedulingFirstColumnRowsArgs = {
	filters?: InputMaybe<ComponentInternalFirstRowItemsFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesSchedulingSecondColumnRowsArgs = {
	filters?: InputMaybe<ComponentInternalSecondColumnItemsFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesSearchHeader = {
	__typename?: 'ComponentModulesSearchHeader';
	id: Scalars['ID'];
	searchPlaceholder?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentModulesSearchHeaderInput = {
	id?: InputMaybe<Scalars['ID']>;
	searchPlaceholder?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesSearchOverview = {
	__typename?: 'ComponentModulesSearchOverview';
	id: Scalars['ID'];
	resultLabel?: Maybe<Scalars['String']>;
};

export type ComponentModulesSearchOverviewInput = {
	id?: InputMaybe<Scalars['ID']>;
	resultLabel?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesSeo = {
	__typename?: 'ComponentModulesSeo';
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	noFollow?: Maybe<Scalars['Boolean']>;
	noIndex?: Maybe<Scalars['Boolean']>;
	ogDescription?: Maybe<Scalars['String']>;
	ogImage?: Maybe<UploadFileEntityResponse>;
	ogTitle?: Maybe<Scalars['String']>;
	ogType?: Maybe<Scalars['String']>;
	ogUrl?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentModulesSeoFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentModulesSeoFiltersInput>>>;
	description?: InputMaybe<StringFilterInput>;
	noFollow?: InputMaybe<BooleanFilterInput>;
	noIndex?: InputMaybe<BooleanFilterInput>;
	not?: InputMaybe<ComponentModulesSeoFiltersInput>;
	ogDescription?: InputMaybe<StringFilterInput>;
	ogTitle?: InputMaybe<StringFilterInput>;
	ogType?: InputMaybe<StringFilterInput>;
	ogUrl?: InputMaybe<StringFilterInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentModulesSeoFiltersInput>>>;
	title?: InputMaybe<StringFilterInput>;
};

export type ComponentModulesSeoInput = {
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	noFollow?: InputMaybe<Scalars['Boolean']>;
	noIndex?: InputMaybe<Scalars['Boolean']>;
	ogDescription?: InputMaybe<Scalars['String']>;
	ogImage?: InputMaybe<Scalars['ID']>;
	ogTitle?: InputMaybe<Scalars['String']>;
	ogType?: InputMaybe<Scalars['String']>;
	ogUrl?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesShare = {
	__typename?: 'ComponentModulesShare';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesShareFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentModulesShareFiltersInput>>>;
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsFiltersInput>;
	not?: InputMaybe<ComponentModulesShareFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentModulesShareFiltersInput>>>;
	title?: InputMaybe<JsonFilterInput>;
};

export type ComponentModulesShareInput = {
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsInput>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['JSON']>;
};

export type ComponentModulesStepByStep = {
	__typename?: 'ComponentModulesStepByStep';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	stepByStepCard: Array<Maybe<ComponentInternalStepByStepCard>>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesStepByStepStepByStepCardArgs = {
	filters?: InputMaybe<ComponentInternalStepByStepCardFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesSubjects = {
	__typename?: 'ComponentModulesSubjects';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	projects?: Maybe<ProjectRelationResponseCollection>;
	subjects?: Maybe<PageRelationResponseCollection>;
	themes?: Maybe<ThemeRelationResponseCollection>;
	title?: Maybe<Scalars['String']>;
};

export type ComponentModulesSubjectsProjectsArgs = {
	filters?: InputMaybe<ProjectFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesSubjectsSubjectsArgs = {
	filters?: InputMaybe<PageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesSubjectsThemesArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesSubsidyOverview = {
	__typename?: 'ComponentModulesSubsidyOverview';
	filterHeaderText: Scalars['String'];
	headerTitle: Scalars['String'];
	id: Scalars['ID'];
	kindFilterText: Scalars['String'];
	overviewTitleText: Scalars['String'];
	scaleFilterText: Scalars['String'];
	searchPlaceholderText: Scalars['String'];
	showLessThemesText: Scalars['String'];
	showMoreThemesText: Scalars['String'];
	showResultsText: Scalars['String'];
	targetGroupFilterText: Scalars['String'];
	themeFilterText: Scalars['String'];
};

export type ComponentModulesTable = {
	__typename?: 'ComponentModulesTable';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	table?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesTestimonial = {
	__typename?: 'ComponentModulesTestimonial';
	defaultModuleOptions: ComponentInternalDefaultModuleOptionsExtended;
	id: Scalars['ID'];
	logo?: Maybe<UploadFileEntityResponse>;
	name: Scalars['String'];
	organization?: Maybe<Scalars['String']>;
	photo?: Maybe<UploadFileEntityResponse>;
	quote?: Maybe<Scalars['String']>;
	title: Scalars['JSON'];
};

export type ComponentModulesThemes = {
	__typename?: 'ComponentModulesThemes';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	themes?: Maybe<ThemeRelationResponseCollection>;
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesThemesThemesArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesVacanciesOverview = {
	__typename?: 'ComponentModulesVacanciesOverview';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	title?: Maybe<Scalars['JSON']>;
};

export type ComponentModulesWobRequestOverview = {
	__typename?: 'ComponentModulesWobRequestOverview';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type ComponentModulesWobRequestOverviewInput = {
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsInput>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentModulesWooRequestOverview = {
	__typename?: 'ComponentModulesWooRequestOverview';
	defaultModuleOptions: ComponentInternalDefaultModuleOptions;
	filterLabel?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type ComponentModulesWooRequestOverviewInput = {
	defaultModuleOptions?: InputMaybe<ComponentInternalDefaultModuleOptionsInput>;
	filterLabel?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ComponentSdgSdgLink = {
	__typename?: 'ComponentSdgSdgLink';
	id: Scalars['ID'];
	link: Scalars['JSON'];
};

export type ComponentSdgSdgLinkFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentSdgSdgLinkFiltersInput>>>;
	link?: InputMaybe<JsonFilterInput>;
	not?: InputMaybe<ComponentSdgSdgLinkFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentSdgSdgLinkFiltersInput>>>;
};

export type ComponentSdgSdgLinkInput = {
	id?: InputMaybe<Scalars['ID']>;
	link?: InputMaybe<Scalars['JSON']>;
};

export type ComponentSdgSdgProductVertaling = {
	__typename?: 'ComponentSdgSdgProductVertaling';
	bewijs?: Maybe<Scalars['String']>;
	bezwaarEnBeroep?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	kostenEnBetaalmethoden?: Maybe<Scalars['String']>;
	links?: Maybe<Array<Maybe<ComponentSdgSdgLink>>>;
	procedureBeschrijving?: Maybe<Scalars['String']>;
	procedureLink?: Maybe<ComponentSdgSdgLink>;
	productAanwezigToelichting?: Maybe<Scalars['String']>;
	productValtOnderToelichting?: Maybe<Scalars['String']>;
	taal?: Maybe<Enum_Componentsdgsdgproductvertaling_Taal>;
	tekst?: Maybe<Scalars['String']>;
	titel?: Maybe<Scalars['String']>;
	uitersteTermijn?: Maybe<Scalars['String']>;
	vereisten?: Maybe<Scalars['String']>;
	wtdBijGeenReactie?: Maybe<Scalars['String']>;
};

export type ComponentSdgSdgProductVertalingLinksArgs = {
	filters?: InputMaybe<ComponentSdgSdgLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentSdgSdgProductVertalingFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ComponentSdgSdgProductVertalingFiltersInput>>>;
	bewijs?: InputMaybe<StringFilterInput>;
	bezwaarEnBeroep?: InputMaybe<StringFilterInput>;
	kostenEnBetaalmethoden?: InputMaybe<StringFilterInput>;
	links?: InputMaybe<ComponentSdgSdgLinkFiltersInput>;
	not?: InputMaybe<ComponentSdgSdgProductVertalingFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ComponentSdgSdgProductVertalingFiltersInput>>>;
	procedureBeschrijving?: InputMaybe<StringFilterInput>;
	procedureLink?: InputMaybe<ComponentSdgSdgLinkFiltersInput>;
	productAanwezigToelichting?: InputMaybe<StringFilterInput>;
	productValtOnderToelichting?: InputMaybe<StringFilterInput>;
	taal?: InputMaybe<StringFilterInput>;
	tekst?: InputMaybe<StringFilterInput>;
	titel?: InputMaybe<StringFilterInput>;
	uitersteTermijn?: InputMaybe<StringFilterInput>;
	vereisten?: InputMaybe<StringFilterInput>;
	wtdBijGeenReactie?: InputMaybe<StringFilterInput>;
};

export type ComponentSdgSdgProductVertalingInput = {
	bewijs?: InputMaybe<Scalars['String']>;
	bezwaarEnBeroep?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	kostenEnBetaalmethoden?: InputMaybe<Scalars['String']>;
	links?: InputMaybe<Array<InputMaybe<ComponentSdgSdgLinkInput>>>;
	procedureBeschrijving?: InputMaybe<Scalars['String']>;
	procedureLink?: InputMaybe<ComponentSdgSdgLinkInput>;
	productAanwezigToelichting?: InputMaybe<Scalars['String']>;
	productValtOnderToelichting?: InputMaybe<Scalars['String']>;
	taal?: InputMaybe<Enum_Componentsdgsdgproductvertaling_Taal>;
	tekst?: InputMaybe<Scalars['String']>;
	titel?: InputMaybe<Scalars['String']>;
	uitersteTermijn?: InputMaybe<Scalars['String']>;
	vereisten?: InputMaybe<Scalars['String']>;
	wtdBijGeenReactie?: InputMaybe<Scalars['String']>;
};

export type CondoleanceHomepage = {
	__typename?: 'CondoleanceHomepage';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesHomepageHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<CondoleanceHomepageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<CondoleanceHomepageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CondoleanceHomepageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type CondoleanceHomepageEntity = {
	__typename?: 'CondoleanceHomepageEntity';
	attributes?: Maybe<CondoleanceHomepage>;
	id?: Maybe<Scalars['ID']>;
};

export type CondoleanceHomepageEntityResponse = {
	__typename?: 'CondoleanceHomepageEntityResponse';
	data?: Maybe<CondoleanceHomepageEntity>;
};

export type CondoleanceHomepageInput = {
	header?: InputMaybe<ComponentModulesHomepageHeaderInput>;
	modules?: InputMaybe<Array<Scalars['CondoleanceHomepageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type CondoleanceHomepageModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type CondoleanceHomepageRelationResponseCollection = {
	__typename?: 'CondoleanceHomepageRelationResponseCollection';
	data: Array<CondoleanceHomepageEntity>;
};

export type CondoleanceInformation = {
	__typename?: 'CondoleanceInformation';
	createdAt?: Maybe<Scalars['DateTime']>;
	footer?: Maybe<ComponentInternalFooter>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<CondoleanceInformationRelationResponseCollection>;
	navbar?: Maybe<ComponentInternalNavbar>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo?: Maybe<ComponentInternalSeo>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CondoleanceInformationLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type CondoleanceInformationEntity = {
	__typename?: 'CondoleanceInformationEntity';
	attributes?: Maybe<CondoleanceInformation>;
	id?: Maybe<Scalars['ID']>;
};

export type CondoleanceInformationEntityResponse = {
	__typename?: 'CondoleanceInformationEntityResponse';
	data?: Maybe<CondoleanceInformationEntity>;
};

export type CondoleanceInformationInput = {
	footer?: InputMaybe<ComponentInternalFooterInput>;
	navbar?: InputMaybe<ComponentInternalNavbarInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentInternalSeoInput>;
};

export type CondoleanceInformationRelationResponseCollection = {
	__typename?: 'CondoleanceInformationRelationResponseCollection';
	data: Array<CondoleanceInformationEntity>;
};

export type CondoleancePage = {
	__typename?: 'CondoleancePage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<CondoleancePageRelationResponseCollection>;
	modules: Array<Maybe<CondoleancePageModulesDynamicZone>>;
	parent?: Maybe<CondoleancePageEntityResponse>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	shortDescription?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CondoleancePageLocalizationsArgs = {
	filters?: InputMaybe<CondoleancePageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CondoleancePageTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CondoleancePageEntity = {
	__typename?: 'CondoleancePageEntity';
	attributes?: Maybe<CondoleancePage>;
	id?: Maybe<Scalars['ID']>;
};

export type CondoleancePageEntityResponse = {
	__typename?: 'CondoleancePageEntityResponse';
	data?: Maybe<CondoleancePageEntity>;
};

export type CondoleancePageEntityResponseCollection = {
	__typename?: 'CondoleancePageEntityResponseCollection';
	data: Array<CondoleancePageEntity>;
	meta: ResponseCollectionMeta;
};

export type CondoleancePageFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<CondoleancePageFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<CondoleancePageFiltersInput>;
	not?: InputMaybe<CondoleancePageFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<CondoleancePageFiltersInput>>>;
	parent?: InputMaybe<CondoleancePageFiltersInput>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	shortDescription?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CondoleancePageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['CondoleancePageModulesDynamicZoneInput']>>;
	parent?: InputMaybe<Scalars['ID']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	shortDescription?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type CondoleancePageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type CondoleancePageRelationResponseCollection = {
	__typename?: 'CondoleancePageRelationResponseCollection';
	data: Array<CondoleancePageEntity>;
};

export type Contact = {
	__typename?: 'Contact';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<ContactRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<ContactModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContactLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type ContactEntity = {
	__typename?: 'ContactEntity';
	attributes?: Maybe<Contact>;
	id?: Maybe<Scalars['ID']>;
};

export type ContactEntityResponse = {
	__typename?: 'ContactEntityResponse';
	data?: Maybe<ContactEntity>;
};

export type ContactInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['ContactModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type ContactModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesLinkBox
	| ComponentModulesPartners
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type ContactRelationResponseCollection = {
	__typename?: 'ContactRelationResponseCollection';
	data: Array<ContactEntity>;
};

export type ConversationsConversationitem = {
	__typename?: 'ConversationsConversationitem';
	author?: Maybe<Scalars['String']>;
	contentTypeId?: Maybe<Scalars['Int']>;
	contentTypeUid?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	message?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConversationsConversationitemEntity = {
	__typename?: 'ConversationsConversationitemEntity';
	attributes?: Maybe<ConversationsConversationitem>;
	id?: Maybe<Scalars['ID']>;
};

export type ConversationsConversationitemEntityResponse = {
	__typename?: 'ConversationsConversationitemEntityResponse';
	data?: Maybe<ConversationsConversationitemEntity>;
};

export type ConversationsConversationitemEntityResponseCollection = {
	__typename?: 'ConversationsConversationitemEntityResponseCollection';
	data: Array<ConversationsConversationitemEntity>;
	meta: ResponseCollectionMeta;
};

export type ConversationsConversationitemFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ConversationsConversationitemFiltersInput>>>;
	author?: InputMaybe<StringFilterInput>;
	contentTypeId?: InputMaybe<IntFilterInput>;
	contentTypeUid?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	message?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<ConversationsConversationitemFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ConversationsConversationitemFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ConversationsConversationitemInput = {
	author?: InputMaybe<Scalars['String']>;
	contentTypeId?: InputMaybe<Scalars['Int']>;
	contentTypeUid?: InputMaybe<Scalars['String']>;
	message?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ConversationsConversations = {
	__typename?: 'ConversationsConversations';
	contentTypeId?: Maybe<Scalars['Int']>;
	contentTypeUid?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	enabled?: Maybe<Scalars['Boolean']>;
	isSingleType?: Maybe<Scalars['Boolean']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConversationsConversationsEntity = {
	__typename?: 'ConversationsConversationsEntity';
	attributes?: Maybe<ConversationsConversations>;
	id?: Maybe<Scalars['ID']>;
};

export type ConversationsConversationsEntityResponse = {
	__typename?: 'ConversationsConversationsEntityResponse';
	data?: Maybe<ConversationsConversationsEntity>;
};

export type ConversationsConversationsEntityResponseCollection = {
	__typename?: 'ConversationsConversationsEntityResponseCollection';
	data: Array<ConversationsConversationsEntity>;
	meta: ResponseCollectionMeta;
};

export type ConversationsConversationsFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ConversationsConversationsFiltersInput>>>;
	contentTypeId?: InputMaybe<IntFilterInput>;
	contentTypeUid?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	enabled?: InputMaybe<BooleanFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	isSingleType?: InputMaybe<BooleanFilterInput>;
	not?: InputMaybe<ConversationsConversationsFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ConversationsConversationsFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ConversationsConversationsInput = {
	contentTypeId?: InputMaybe<Scalars['Int']>;
	contentTypeUid?: InputMaybe<Scalars['String']>;
	enabled?: InputMaybe<Scalars['Boolean']>;
	isSingleType?: InputMaybe<Scalars['Boolean']>;
	password?: InputMaybe<Scalars['String']>;
};

export type CookiePage = {
	__typename?: 'CookiePage';
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<CookiePageRelationResponseCollection>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CookiePageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type CookiePageEntity = {
	__typename?: 'CookiePageEntity';
	attributes?: Maybe<CookiePage>;
	id?: Maybe<Scalars['ID']>;
};

export type CookiePageEntityResponse = {
	__typename?: 'CookiePageEntityResponse';
	data?: Maybe<CookiePageEntity>;
};

export type CookiePageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type CookiePageRelationResponseCollection = {
	__typename?: 'CookiePageRelationResponseCollection';
	data: Array<CookiePageEntity>;
};

export type DateFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
	contains?: InputMaybe<Scalars['Date']>;
	containsi?: InputMaybe<Scalars['Date']>;
	endsWith?: InputMaybe<Scalars['Date']>;
	eq?: InputMaybe<Scalars['Date']>;
	eqi?: InputMaybe<Scalars['Date']>;
	gt?: InputMaybe<Scalars['Date']>;
	gte?: InputMaybe<Scalars['Date']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
	lt?: InputMaybe<Scalars['Date']>;
	lte?: InputMaybe<Scalars['Date']>;
	ne?: InputMaybe<Scalars['Date']>;
	nei?: InputMaybe<Scalars['Date']>;
	not?: InputMaybe<DateFilterInput>;
	notContains?: InputMaybe<Scalars['Date']>;
	notContainsi?: InputMaybe<Scalars['Date']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
	startsWith?: InputMaybe<Scalars['Date']>;
};

export type DateTimeFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	contains?: InputMaybe<Scalars['DateTime']>;
	containsi?: InputMaybe<Scalars['DateTime']>;
	endsWith?: InputMaybe<Scalars['DateTime']>;
	eq?: InputMaybe<Scalars['DateTime']>;
	eqi?: InputMaybe<Scalars['DateTime']>;
	gt?: InputMaybe<Scalars['DateTime']>;
	gte?: InputMaybe<Scalars['DateTime']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	lt?: InputMaybe<Scalars['DateTime']>;
	lte?: InputMaybe<Scalars['DateTime']>;
	ne?: InputMaybe<Scalars['DateTime']>;
	nei?: InputMaybe<Scalars['DateTime']>;
	not?: InputMaybe<DateTimeFilterInput>;
	notContains?: InputMaybe<Scalars['DateTime']>;
	notContainsi?: InputMaybe<Scalars['DateTime']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
	startsWith?: InputMaybe<Scalars['DateTime']>;
};

export type DecisionsPage = {
	__typename?: 'DecisionsPage';
	archiveLink: ComponentComponentsLink;
	basePath?: Maybe<Scalars['String']>;
	bodyText?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<DecisionsPageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<DecisionsPageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	seo: ComponentModulesSeo;
	sidebarArchiveTitle: Scalars['String'];
	sidebarCurrentYearTitle: Scalars['String'];
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DecisionsPageEntity = {
	__typename?: 'DecisionsPageEntity';
	attributes?: Maybe<DecisionsPage>;
	id?: Maybe<Scalars['ID']>;
};

export type DecisionsPageEntityResponse = {
	__typename?: 'DecisionsPageEntityResponse';
	data?: Maybe<DecisionsPageEntity>;
};

export type DecisionsPageInput = {
	archiveLink?: InputMaybe<ComponentComponentsLinkInput>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['DecisionsPageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	sidebarArchiveTitle?: InputMaybe<Scalars['String']>;
	sidebarCurrentYearTitle?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type DecisionsPageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| Error;

export type DecisionsPageRelationResponseCollection = {
	__typename?: 'DecisionsPageRelationResponseCollection';
	data: Array<DecisionsPageEntity>;
};

export type DownloadsPage = {
	__typename?: 'DownloadsPage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	downloads: Array<Maybe<ComponentModulesDownloads>>;
	introHeader: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<DownloadsPageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<DownloadsPageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DownloadsPageDownloadsArgs = {
	filters?: InputMaybe<ComponentModulesDownloadsFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DownloadsPageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type DownloadsPageEntity = {
	__typename?: 'DownloadsPageEntity';
	attributes?: Maybe<DownloadsPage>;
	id?: Maybe<Scalars['ID']>;
};

export type DownloadsPageEntityResponse = {
	__typename?: 'DownloadsPageEntityResponse';
	data?: Maybe<DownloadsPageEntity>;
};

export type DownloadsPageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	downloads?: InputMaybe<Array<InputMaybe<ComponentModulesDownloadsInput>>>;
	introHeader?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['DownloadsPageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type DownloadsPageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| Error;

export type DownloadsPageRelationResponseCollection = {
	__typename?: 'DownloadsPageRelationResponseCollection';
	data: Array<DownloadsPageEntity>;
};

export type Enum_Componentcomponentsbutton_Variant = 'primary' | 'secondary' | 'tertiary';

export type Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor = 'darkblue' | 'grey' | 'white';

export type Enum_Componentinternaldefaultmoduleoptionsextended_Padding = 'large' | 'medium' | 'none' | 'small';

export type Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor = 'grey' | 'white';

export type Enum_Componentinternaldefaultmoduleoptions_Padding = 'large' | 'medium' | 'none' | 'small';

export type Enum_Componentmodulesbbvmsmediaplayer_Playout = 'Website' | 'Website_klein';

export type Enum_Componentmodulescta_Imageposition = 'left' | 'right';

export type Enum_Componentmodulesintroheaderimage_Backgroundcolor = 'grey' | 'white';

export type Enum_Componentmodulesintroheader_Backgroundcolor = 'grey' | 'white';

export type Enum_Componentsdgsdgproductvertaling_Taal = 'en' | 'nl';

export type Enum_Mapsfigure_Type = 'figure' | 'map';

export type Enum_Permit_Audience = 'beide' | 'ondernemer' | 'particulier';

export type Enum_Permit_Law =
	| 'aviation'
	| 'environmental_dumping_outside_facilities'
	| 'environmental_groundwater_quiet_areas'
	| 'excavations'
	| 'ground_protection'
	| 'hygiene_bathing_and_swimming'
	| 'nature_conservation'
	| 'water';

export type Enum_Permit_Producttype = 'annual_statement' | 'exemption' | 'permits' | 'proclamation';

export type Enum_Roadmaintenance_Status = 'Active' | 'Inactive' | 'Planned';

export type Enum_Schedulerscheduler_Type = 'archive' | 'publish';

export type Enum_Subsidy_Audience = 'beide' | 'ondernemer' | 'particulier';

export type Enum_Subsidy_Status = 'almost_opened' | 'closed' | 'open';

export type Enum_Subsidy_Subsidyceiling = 'almost_met' | 'met';

export type Enum_Woorequest_Publisher = 'provincie_Gelderland';

export type EerbeekloenenHomepage = {
	__typename?: 'EerbeekloenenHomepage';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesHomepageHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenHomepageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EerbeekloenenHomepageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenHomepageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EerbeekloenenHomepageEntity = {
	__typename?: 'EerbeekloenenHomepageEntity';
	attributes?: Maybe<EerbeekloenenHomepage>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenHomepageEntityResponse = {
	__typename?: 'EerbeekloenenHomepageEntityResponse';
	data?: Maybe<EerbeekloenenHomepageEntity>;
};

export type EerbeekloenenHomepageInput = {
	header?: InputMaybe<ComponentModulesHomepageHeaderInput>;
	modules?: InputMaybe<Array<Scalars['EerbeekloenenHomepageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type EerbeekloenenHomepageModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsEerbeekloenen
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type EerbeekloenenHomepageRelationResponseCollection = {
	__typename?: 'EerbeekloenenHomepageRelationResponseCollection';
	data: Array<EerbeekloenenHomepageEntity>;
};

export type EerbeekloenenInConvoArticle = {
	__typename?: 'EerbeekloenenInConvoArticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenInConvoArticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EerbeekloenenInConvoArticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenInConvoArticleLocalizationsArgs = {
	filters?: InputMaybe<EerbeekloenenInConvoArticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EerbeekloenenInConvoArticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EerbeekloenenInConvoArticleEntity = {
	__typename?: 'EerbeekloenenInConvoArticleEntity';
	attributes?: Maybe<EerbeekloenenInConvoArticle>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenInConvoArticleEntityResponse = {
	__typename?: 'EerbeekloenenInConvoArticleEntityResponse';
	data?: Maybe<EerbeekloenenInConvoArticleEntity>;
};

export type EerbeekloenenInConvoArticleEntityResponseCollection = {
	__typename?: 'EerbeekloenenInConvoArticleEntityResponseCollection';
	data: Array<EerbeekloenenInConvoArticleEntity>;
	meta: ResponseCollectionMeta;
};

export type EerbeekloenenInConvoArticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<EerbeekloenenInConvoArticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<EerbeekloenenInConvoArticleFiltersInput>;
	not?: InputMaybe<EerbeekloenenInConvoArticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<EerbeekloenenInConvoArticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EerbeekloenenInConvoArticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['EerbeekloenenInConvoArticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type EerbeekloenenInConvoArticleModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type EerbeekloenenInConvoArticleRelationResponseCollection = {
	__typename?: 'EerbeekloenenInConvoArticleRelationResponseCollection';
	data: Array<EerbeekloenenInConvoArticleEntity>;
};

export type EerbeekloenenInConvoOverview = {
	__typename?: 'EerbeekloenenInConvoOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenInConvoOverviewRelationResponseCollection>;
	modules: Array<Maybe<EerbeekloenenInConvoOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenInConvoOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EerbeekloenenInConvoOverviewEntity = {
	__typename?: 'EerbeekloenenInConvoOverviewEntity';
	attributes?: Maybe<EerbeekloenenInConvoOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenInConvoOverviewEntityResponse = {
	__typename?: 'EerbeekloenenInConvoOverviewEntityResponse';
	data?: Maybe<EerbeekloenenInConvoOverviewEntity>;
};

export type EerbeekloenenInConvoOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['EerbeekloenenInConvoOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type EerbeekloenenInConvoOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesInConvoWithOverview
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type EerbeekloenenInConvoOverviewRelationResponseCollection = {
	__typename?: 'EerbeekloenenInConvoOverviewRelationResponseCollection';
	data: Array<EerbeekloenenInConvoOverviewEntity>;
};

export type EerbeekloenenInConvoWithDetail = {
	__typename?: 'EerbeekloenenInConvoWithDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenInConvoWithDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EerbeekloenenInConvoWithDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenInConvoWithDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EerbeekloenenInConvoWithDetailEntity = {
	__typename?: 'EerbeekloenenInConvoWithDetailEntity';
	attributes?: Maybe<EerbeekloenenInConvoWithDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenInConvoWithDetailEntityResponse = {
	__typename?: 'EerbeekloenenInConvoWithDetailEntityResponse';
	data?: Maybe<EerbeekloenenInConvoWithDetailEntity>;
};

export type EerbeekloenenInConvoWithDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['EerbeekloenenInConvoWithDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EerbeekloenenInConvoWithDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type EerbeekloenenInConvoWithDetailRelationResponseCollection = {
	__typename?: 'EerbeekloenenInConvoWithDetailRelationResponseCollection';
	data: Array<EerbeekloenenInConvoWithDetailEntity>;
};

export type EerbeekloenenInformation = {
	__typename?: 'EerbeekloenenInformation';
	createdAt?: Maybe<Scalars['DateTime']>;
	footer?: Maybe<ComponentInternalFooter>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenInformationRelationResponseCollection>;
	navbar?: Maybe<ComponentInternalNavbar>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo?: Maybe<ComponentInternalSeo>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenInformationLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EerbeekloenenInformationEntity = {
	__typename?: 'EerbeekloenenInformationEntity';
	attributes?: Maybe<EerbeekloenenInformation>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenInformationEntityResponse = {
	__typename?: 'EerbeekloenenInformationEntityResponse';
	data?: Maybe<EerbeekloenenInformationEntity>;
};

export type EerbeekloenenInformationInput = {
	footer?: InputMaybe<ComponentInternalFooterInput>;
	navbar?: InputMaybe<ComponentInternalNavbarInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentInternalSeoInput>;
};

export type EerbeekloenenInformationRelationResponseCollection = {
	__typename?: 'EerbeekloenenInformationRelationResponseCollection';
	data: Array<EerbeekloenenInformationEntity>;
};

export type EerbeekloenenNewsDetail = {
	__typename?: 'EerbeekloenenNewsDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenNewsDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EerbeekloenenNewsDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenNewsDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EerbeekloenenNewsDetailEntity = {
	__typename?: 'EerbeekloenenNewsDetailEntity';
	attributes?: Maybe<EerbeekloenenNewsDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenNewsDetailEntityResponse = {
	__typename?: 'EerbeekloenenNewsDetailEntityResponse';
	data?: Maybe<EerbeekloenenNewsDetailEntity>;
};

export type EerbeekloenenNewsDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['EerbeekloenenNewsDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EerbeekloenenNewsDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsEerbeekloenen
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type EerbeekloenenNewsDetailRelationResponseCollection = {
	__typename?: 'EerbeekloenenNewsDetailRelationResponseCollection';
	data: Array<EerbeekloenenNewsDetailEntity>;
};

export type EerbeekloenenNewsOverview = {
	__typename?: 'EerbeekloenenNewsOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenNewsOverviewRelationResponseCollection>;
	modules: Array<Maybe<EerbeekloenenNewsOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenNewsOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EerbeekloenenNewsOverviewEntity = {
	__typename?: 'EerbeekloenenNewsOverviewEntity';
	attributes?: Maybe<EerbeekloenenNewsOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenNewsOverviewEntityResponse = {
	__typename?: 'EerbeekloenenNewsOverviewEntityResponse';
	data?: Maybe<EerbeekloenenNewsOverviewEntity>;
};

export type EerbeekloenenNewsOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['EerbeekloenenNewsOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type EerbeekloenenNewsOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesNewsOverview
	| ComponentModulesPhotoAlbum
	| Error;

export type EerbeekloenenNewsOverviewRelationResponseCollection = {
	__typename?: 'EerbeekloenenNewsOverviewRelationResponseCollection';
	data: Array<EerbeekloenenNewsOverviewEntity>;
};

export type EerbeekloenenNewsarticle = {
	__typename?: 'EerbeekloenenNewsarticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenNewsarticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EerbeekloenenNewsarticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenNewsarticleLocalizationsArgs = {
	filters?: InputMaybe<EerbeekloenenNewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EerbeekloenenNewsarticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EerbeekloenenNewsarticleEntity = {
	__typename?: 'EerbeekloenenNewsarticleEntity';
	attributes?: Maybe<EerbeekloenenNewsarticle>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenNewsarticleEntityResponse = {
	__typename?: 'EerbeekloenenNewsarticleEntityResponse';
	data?: Maybe<EerbeekloenenNewsarticleEntity>;
};

export type EerbeekloenenNewsarticleEntityResponseCollection = {
	__typename?: 'EerbeekloenenNewsarticleEntityResponseCollection';
	data: Array<EerbeekloenenNewsarticleEntity>;
	meta: ResponseCollectionMeta;
};

export type EerbeekloenenNewsarticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<EerbeekloenenNewsarticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<EerbeekloenenNewsarticleFiltersInput>;
	not?: InputMaybe<EerbeekloenenNewsarticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<EerbeekloenenNewsarticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EerbeekloenenNewsarticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['EerbeekloenenNewsarticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type EerbeekloenenNewsarticleModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsEerbeekloenen
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesShare
	| ComponentModulesTestimonial
	| Error;

export type EerbeekloenenNewsarticleRelationResponseCollection = {
	__typename?: 'EerbeekloenenNewsarticleRelationResponseCollection';
	data: Array<EerbeekloenenNewsarticleEntity>;
};

export type EerbeekloenenPage = {
	__typename?: 'EerbeekloenenPage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EerbeekloenenPageRelationResponseCollection>;
	modules: Array<Maybe<EerbeekloenenPageModulesDynamicZone>>;
	parent?: Maybe<EerbeekloenenPageEntityResponse>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	shortDescription?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EerbeekloenenPageLocalizationsArgs = {
	filters?: InputMaybe<EerbeekloenenPageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EerbeekloenenPageTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EerbeekloenenPageEntity = {
	__typename?: 'EerbeekloenenPageEntity';
	attributes?: Maybe<EerbeekloenenPage>;
	id?: Maybe<Scalars['ID']>;
};

export type EerbeekloenenPageEntityResponse = {
	__typename?: 'EerbeekloenenPageEntityResponse';
	data?: Maybe<EerbeekloenenPageEntity>;
};

export type EerbeekloenenPageEntityResponseCollection = {
	__typename?: 'EerbeekloenenPageEntityResponseCollection';
	data: Array<EerbeekloenenPageEntity>;
	meta: ResponseCollectionMeta;
};

export type EerbeekloenenPageFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<EerbeekloenenPageFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<EerbeekloenenPageFiltersInput>;
	not?: InputMaybe<EerbeekloenenPageFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<EerbeekloenenPageFiltersInput>>>;
	parent?: InputMaybe<EerbeekloenenPageFiltersInput>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	shortDescription?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EerbeekloenenPageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['EerbeekloenenPageModulesDynamicZoneInput']>>;
	parent?: InputMaybe<Scalars['ID']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	shortDescription?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type EerbeekloenenPageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsEerbeekloenen
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesShare
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type EerbeekloenenPageRelationResponseCollection = {
	__typename?: 'EerbeekloenenPageRelationResponseCollection';
	data: Array<EerbeekloenenPageEntity>;
};

export type EfroOostHomepage = {
	__typename?: 'EfroOostHomepage';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesHomepageHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostHomepageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EfroOostHomepageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostHomepageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EfroOostHomepageEntity = {
	__typename?: 'EfroOostHomepageEntity';
	attributes?: Maybe<EfroOostHomepage>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostHomepageEntityResponse = {
	__typename?: 'EfroOostHomepageEntityResponse';
	data?: Maybe<EfroOostHomepageEntity>;
};

export type EfroOostHomepageInput = {
	header?: InputMaybe<ComponentModulesHomepageHeaderInput>;
	modules?: InputMaybe<Array<Scalars['EfroOostHomepageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type EfroOostHomepageModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsEfroOost
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type EfroOostHomepageRelationResponseCollection = {
	__typename?: 'EfroOostHomepageRelationResponseCollection';
	data: Array<EfroOostHomepageEntity>;
};

export type EfroOostInConvoWithArticle = {
	__typename?: 'EfroOostInConvoWithArticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostInConvoWithArticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EfroOostInConvoWithArticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostInConvoWithArticleLocalizationsArgs = {
	filters?: InputMaybe<EfroOostInConvoWithArticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EfroOostInConvoWithArticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EfroOostInConvoWithArticleEntity = {
	__typename?: 'EfroOostInConvoWithArticleEntity';
	attributes?: Maybe<EfroOostInConvoWithArticle>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostInConvoWithArticleEntityResponse = {
	__typename?: 'EfroOostInConvoWithArticleEntityResponse';
	data?: Maybe<EfroOostInConvoWithArticleEntity>;
};

export type EfroOostInConvoWithArticleEntityResponseCollection = {
	__typename?: 'EfroOostInConvoWithArticleEntityResponseCollection';
	data: Array<EfroOostInConvoWithArticleEntity>;
	meta: ResponseCollectionMeta;
};

export type EfroOostInConvoWithArticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<EfroOostInConvoWithArticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<EfroOostInConvoWithArticleFiltersInput>;
	not?: InputMaybe<EfroOostInConvoWithArticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<EfroOostInConvoWithArticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EfroOostInConvoWithArticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['EfroOostInConvoWithArticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type EfroOostInConvoWithArticleModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type EfroOostInConvoWithArticleRelationResponseCollection = {
	__typename?: 'EfroOostInConvoWithArticleRelationResponseCollection';
	data: Array<EfroOostInConvoWithArticleEntity>;
};

export type EfroOostInConvoWithDetail = {
	__typename?: 'EfroOostInConvoWithDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostInConvoWithDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EfroOostInConvoWithDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostInConvoWithDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EfroOostInConvoWithDetailEntity = {
	__typename?: 'EfroOostInConvoWithDetailEntity';
	attributes?: Maybe<EfroOostInConvoWithDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostInConvoWithDetailEntityResponse = {
	__typename?: 'EfroOostInConvoWithDetailEntityResponse';
	data?: Maybe<EfroOostInConvoWithDetailEntity>;
};

export type EfroOostInConvoWithDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['EfroOostInConvoWithDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EfroOostInConvoWithDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type EfroOostInConvoWithDetailRelationResponseCollection = {
	__typename?: 'EfroOostInConvoWithDetailRelationResponseCollection';
	data: Array<EfroOostInConvoWithDetailEntity>;
};

export type EfroOostInConvoWithOverview = {
	__typename?: 'EfroOostInConvoWithOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostInConvoWithOverviewRelationResponseCollection>;
	modules: Array<Maybe<EfroOostInConvoWithOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostInConvoWithOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EfroOostInConvoWithOverviewEntity = {
	__typename?: 'EfroOostInConvoWithOverviewEntity';
	attributes?: Maybe<EfroOostInConvoWithOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostInConvoWithOverviewEntityResponse = {
	__typename?: 'EfroOostInConvoWithOverviewEntityResponse';
	data?: Maybe<EfroOostInConvoWithOverviewEntity>;
};

export type EfroOostInConvoWithOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['EfroOostInConvoWithOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type EfroOostInConvoWithOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesInConvoWithOverview
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type EfroOostInConvoWithOverviewRelationResponseCollection = {
	__typename?: 'EfroOostInConvoWithOverviewRelationResponseCollection';
	data: Array<EfroOostInConvoWithOverviewEntity>;
};

export type EfroOostInformation = {
	__typename?: 'EfroOostInformation';
	createdAt?: Maybe<Scalars['DateTime']>;
	footer?: Maybe<ComponentInternalFooter>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostInformationRelationResponseCollection>;
	navbar?: Maybe<ComponentInternalNavbar>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo?: Maybe<ComponentInternalSeo>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostInformationLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EfroOostInformationEntity = {
	__typename?: 'EfroOostInformationEntity';
	attributes?: Maybe<EfroOostInformation>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostInformationEntityResponse = {
	__typename?: 'EfroOostInformationEntityResponse';
	data?: Maybe<EfroOostInformationEntity>;
};

export type EfroOostInformationInput = {
	footer?: InputMaybe<ComponentInternalFooterInput>;
	navbar?: InputMaybe<ComponentInternalNavbarInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentInternalSeoInput>;
};

export type EfroOostInformationRelationResponseCollection = {
	__typename?: 'EfroOostInformationRelationResponseCollection';
	data: Array<EfroOostInformationEntity>;
};

export type EfroOostNewsDetail = {
	__typename?: 'EfroOostNewsDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostNewsDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EfroOostNewsDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostNewsDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EfroOostNewsDetailEntity = {
	__typename?: 'EfroOostNewsDetailEntity';
	attributes?: Maybe<EfroOostNewsDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostNewsDetailEntityResponse = {
	__typename?: 'EfroOostNewsDetailEntityResponse';
	data?: Maybe<EfroOostNewsDetailEntity>;
};

export type EfroOostNewsDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['EfroOostNewsDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EfroOostNewsDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsEfroOost
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type EfroOostNewsDetailRelationResponseCollection = {
	__typename?: 'EfroOostNewsDetailRelationResponseCollection';
	data: Array<EfroOostNewsDetailEntity>;
};

export type EfroOostNewsOverview = {
	__typename?: 'EfroOostNewsOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostNewsOverviewRelationResponseCollection>;
	modules: Array<Maybe<EfroOostNewsOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostNewsOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EfroOostNewsOverviewEntity = {
	__typename?: 'EfroOostNewsOverviewEntity';
	attributes?: Maybe<EfroOostNewsOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostNewsOverviewEntityResponse = {
	__typename?: 'EfroOostNewsOverviewEntityResponse';
	data?: Maybe<EfroOostNewsOverviewEntity>;
};

export type EfroOostNewsOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['EfroOostNewsOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type EfroOostNewsOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesNewsOverview
	| ComponentModulesPhotoAlbum
	| Error;

export type EfroOostNewsOverviewRelationResponseCollection = {
	__typename?: 'EfroOostNewsOverviewRelationResponseCollection';
	data: Array<EfroOostNewsOverviewEntity>;
};

export type EfroOostNewsarticle = {
	__typename?: 'EfroOostNewsarticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostNewsarticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EfroOostNewsarticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostNewsarticleLocalizationsArgs = {
	filters?: InputMaybe<EfroOostNewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EfroOostNewsarticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EfroOostNewsarticleEntity = {
	__typename?: 'EfroOostNewsarticleEntity';
	attributes?: Maybe<EfroOostNewsarticle>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostNewsarticleEntityResponse = {
	__typename?: 'EfroOostNewsarticleEntityResponse';
	data?: Maybe<EfroOostNewsarticleEntity>;
};

export type EfroOostNewsarticleEntityResponseCollection = {
	__typename?: 'EfroOostNewsarticleEntityResponseCollection';
	data: Array<EfroOostNewsarticleEntity>;
	meta: ResponseCollectionMeta;
};

export type EfroOostNewsarticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<EfroOostNewsarticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<EfroOostNewsarticleFiltersInput>;
	not?: InputMaybe<EfroOostNewsarticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<EfroOostNewsarticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EfroOostNewsarticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['EfroOostNewsarticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type EfroOostNewsarticleModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsEfroOost
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesShare
	| ComponentModulesTestimonial
	| Error;

export type EfroOostNewsarticleRelationResponseCollection = {
	__typename?: 'EfroOostNewsarticleRelationResponseCollection';
	data: Array<EfroOostNewsarticleEntity>;
};

export type EfroOostPage = {
	__typename?: 'EfroOostPage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EfroOostPageRelationResponseCollection>;
	modules: Array<Maybe<EfroOostPageModulesDynamicZone>>;
	parent?: Maybe<EfroOostPageEntityResponse>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	qqqq?: Maybe<Scalars['String']>;
	seo: ComponentModulesSeo;
	shortDescription?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EfroOostPageLocalizationsArgs = {
	filters?: InputMaybe<EfroOostPageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EfroOostPageTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EfroOostPageEntity = {
	__typename?: 'EfroOostPageEntity';
	attributes?: Maybe<EfroOostPage>;
	id?: Maybe<Scalars['ID']>;
};

export type EfroOostPageEntityResponse = {
	__typename?: 'EfroOostPageEntityResponse';
	data?: Maybe<EfroOostPageEntity>;
};

export type EfroOostPageEntityResponseCollection = {
	__typename?: 'EfroOostPageEntityResponseCollection';
	data: Array<EfroOostPageEntity>;
	meta: ResponseCollectionMeta;
};

export type EfroOostPageFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<EfroOostPageFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<EfroOostPageFiltersInput>;
	not?: InputMaybe<EfroOostPageFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<EfroOostPageFiltersInput>>>;
	parent?: InputMaybe<EfroOostPageFiltersInput>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	qqqq?: InputMaybe<StringFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	shortDescription?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EfroOostPageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['EfroOostPageModulesDynamicZoneInput']>>;
	parent?: InputMaybe<Scalars['ID']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	qqqq?: InputMaybe<Scalars['String']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	shortDescription?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type EfroOostPageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsEfroOost
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesShare
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type EfroOostPageRelationResponseCollection = {
	__typename?: 'EfroOostPageRelationResponseCollection';
	data: Array<EfroOostPageEntity>;
};

export type Error = {
	__typename?: 'Error';
	code: Scalars['String'];
	message?: Maybe<Scalars['String']>;
};

export type Event = {
	__typename?: 'Event';
	basePath: Scalars['String'];
	createdAt?: Maybe<Scalars['DateTime']>;
	from: Scalars['DateTime'];
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EventRelationResponseCollection>;
	location: Scalars['String'];
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	share?: Maybe<ComponentModulesShare>;
	theme?: Maybe<ThemeRelationResponseCollection>;
	title: Scalars['String'];
	until: Scalars['DateTime'];
	updatedAt?: Maybe<Scalars['DateTime']>;
	url: Scalars['String'];
};

export type EventLocalizationsArgs = {
	filters?: InputMaybe<EventFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EventThemeArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EventEntity = {
	__typename?: 'EventEntity';
	attributes?: Maybe<Event>;
	id?: Maybe<Scalars['ID']>;
};

export type EventEntityResponse = {
	__typename?: 'EventEntityResponse';
	data?: Maybe<EventEntity>;
};

export type EventEntityResponseCollection = {
	__typename?: 'EventEntityResponseCollection';
	data: Array<EventEntity>;
	meta: ResponseCollectionMeta;
};

export type EventFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	from?: InputMaybe<DateTimeFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<EventFiltersInput>;
	location?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<EventFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	share?: InputMaybe<ComponentModulesShareFiltersInput>;
	theme?: InputMaybe<ThemeFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	until?: InputMaybe<DateTimeFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	url?: InputMaybe<StringFilterInput>;
};

export type EventInput = {
	basePath?: InputMaybe<Scalars['String']>;
	from?: InputMaybe<Scalars['DateTime']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	location?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	share?: InputMaybe<ComponentModulesShareInput>;
	theme?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
	until?: InputMaybe<Scalars['DateTime']>;
	url?: InputMaybe<Scalars['String']>;
};

export type EventOverview = {
	__typename?: 'EventOverview';
	basePath: Scalars['String'];
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<EventOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<EventOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type EventOverviewEntity = {
	__typename?: 'EventOverviewEntity';
	attributes?: Maybe<EventOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type EventOverviewEntityResponse = {
	__typename?: 'EventOverviewEntityResponse';
	data?: Maybe<EventOverviewEntity>;
};

export type EventOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['EventOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type EventOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesEventOverview
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type EventOverviewRelationResponseCollection = {
	__typename?: 'EventOverviewRelationResponseCollection';
	data: Array<EventOverviewEntity>;
};

export type EventRelationResponseCollection = {
	__typename?: 'EventRelationResponseCollection';
	data: Array<EventEntity>;
};

export type FileInfoInput = {
	alternativeText?: InputMaybe<Scalars['String']>;
	caption?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
};

export type FloatFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	contains?: InputMaybe<Scalars['Float']>;
	containsi?: InputMaybe<Scalars['Float']>;
	endsWith?: InputMaybe<Scalars['Float']>;
	eq?: InputMaybe<Scalars['Float']>;
	eqi?: InputMaybe<Scalars['Float']>;
	gt?: InputMaybe<Scalars['Float']>;
	gte?: InputMaybe<Scalars['Float']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	lt?: InputMaybe<Scalars['Float']>;
	lte?: InputMaybe<Scalars['Float']>;
	ne?: InputMaybe<Scalars['Float']>;
	nei?: InputMaybe<Scalars['Float']>;
	not?: InputMaybe<FloatFilterInput>;
	notContains?: InputMaybe<Scalars['Float']>;
	notContainsi?: InputMaybe<Scalars['Float']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
	startsWith?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph =
	| AgendaPage
	| AuditLogAuditLog
	| ComponentComponentsButton
	| ComponentComponentsIconLink
	| ComponentComponentsIconLinkGroup
	| ComponentComponentsLink
	| ComponentComponentsLinkGroup
	| ComponentComponentsSubject
	| ComponentInternalAboutMaintenance
	| ComponentInternalAccordeonItem
	| ComponentInternalAccordeonSection
	| ComponentInternalBodyTextColumn
	| ComponentInternalDefaultModuleOptions
	| ComponentInternalDefaultModuleOptionsExtended
	| ComponentInternalEmptyState
	| ComponentInternalFirstRowItems
	| ComponentInternalFooter
	| ComponentInternalHighlightEvents
	| ComponentInternalHighlightMapsAndStats
	| ComponentInternalHighlightNews
	| ComponentInternalHighlightPermits
	| ComponentInternalHighlightSubsidies
	| ComponentInternalHighlightTopics
	| ComponentInternalHighlightWoo
	| ComponentInternalNavbar
	| ComponentInternalNewsletter
	| ComponentInternalPhoto
	| ComponentInternalSecondColumnItems
	| ComponentInternalSeo
	| ComponentInternalSidebar
	| ComponentInternalStepByStepCard
	| ComponentInternalTest
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCollections
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesEventOverview
	| ComponentModulesExpandedHeader
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedNewsEerbeekloenen
	| ComponentModulesFeaturedNewsEfroOost
	| ComponentModulesFeaturedNewsOpOost
	| ComponentModulesFeaturedNewsRegioExpres
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesHomepageHeader
	| ComponentModulesInConvoWithOverview
	| ComponentModulesIntroHeader
	| ComponentModulesIntroHeaderImage
	| ComponentModulesLinkBox
	| ComponentModulesMapsFiguresOverview
	| ComponentModulesMedia
	| ComponentModulesNewsOverview
	| ComponentModulesOpenWooRequestOverview
	| ComponentModulesPartners
	| ComponentModulesPermitsOverview
	| ComponentModulesPhotoAlbum
	| ComponentModulesPressReleaseOverview
	| ComponentModulesProjectOverview
	| ComponentModulesRegions
	| ComponentModulesRoadMaintenanceOverview
	| ComponentModulesScheduling
	| ComponentModulesSearchHeader
	| ComponentModulesSearchOverview
	| ComponentModulesSeo
	| ComponentModulesShare
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesSubsidyOverview
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| ComponentModulesThemes
	| ComponentModulesVacanciesOverview
	| ComponentModulesWobRequestOverview
	| ComponentModulesWooRequestOverview
	| ComponentSdgSdgLink
	| ComponentSdgSdgProductVertaling
	| CondoleanceHomepage
	| CondoleanceInformation
	| CondoleancePage
	| Contact
	| ConversationsConversationitem
	| ConversationsConversations
	| CookiePage
	| DecisionsPage
	| DownloadsPage
	| EerbeekloenenHomepage
	| EerbeekloenenInConvoArticle
	| EerbeekloenenInConvoOverview
	| EerbeekloenenInConvoWithDetail
	| EerbeekloenenInformation
	| EerbeekloenenNewsDetail
	| EerbeekloenenNewsOverview
	| EerbeekloenenNewsarticle
	| EerbeekloenenPage
	| EfroOostHomepage
	| EfroOostInConvoWithArticle
	| EfroOostInConvoWithDetail
	| EfroOostInConvoWithOverview
	| EfroOostInformation
	| EfroOostNewsDetail
	| EfroOostNewsOverview
	| EfroOostNewsarticle
	| EfroOostPage
	| Event
	| EventOverview
	| GeolinkHomepage
	| GeolinkInformation
	| GeolinkPage
	| HomePage
	| I18NLocale
	| InConvoWithArticle
	| InConvoWithDetail
	| InConvoWithOverview
	| InternalLinkInternalLink
	| InternalLinkInternalLinkWysiwyg
	| Login
	| MapsFigure
	| MapsFiguresOverview
	| NewsDetail
	| NewsOverview
	| Newsarticle
	| Newsletter
	| NewsletterArchiveOverview
	| NewsletterInternal
	| NewsletterPublic
	| NotFoundPage
	| NoteNote
	| NoteNoterelation
	| OpOostHomepage
	| OpOostInConvoWithArticle
	| OpOostInConvoWithDetail
	| OpOostInConvoWithOverview
	| OpOostInformation
	| OpOostNewsDetail
	| OpOostNewsOverview
	| OpOostNewsarticle
	| OpOostPage
	| OpenGelderlandHomepage
	| OpenGelderlandInformation
	| OpenGelderlandWooOverview
	| Page
	| PageInformation
	| PasswordPage
	| Permit
	| PermitDetail
	| PermitLaw
	| PermitProductType
	| PermitsOverview
	| PressRelease
	| PressReleaseDetail
	| PressReleaseOverview
	| PrivacyPolicy
	| Project
	| ProjectDetail
	| ProjectOverview
	| RecordLockingOpenEntity
	| RecycleBinRecycleBinItem
	| RegioExpresHomepage
	| RegioExpresInConvoOverview
	| RegioExpresInConvoWithArticle
	| RegioExpresInConvoWithDetail
	| RegioExpresInformation
	| RegioExpresNewsDetail
	| RegioExpresNewsOverview
	| RegioExpresNewsarticle
	| RegioExpresPage
	| Region
	| RegionDetail
	| RegionOverview
	| RoadMaintenance
	| RoadMaintenanceDetail
	| RoadMaintenanceOverview
	| SchedulerScheduler
	| SdgLocatie
	| SdgSdg
	| SdgUplLijst
	| SearchOverview
	| Sitemap
	| Subsidy
	| SubsidyDetail
	| SubsidyKind
	| SubsidyOverview
	| SubsidyScale
	| Tag
	| TargetGroup
	| Theme
	| ThemeDetail
	| ThemeOverview
	| Upl
	| UploadFile
	| UploadFolder
	| UsersPermissionsPermission
	| UsersPermissionsRole
	| UsersPermissionsUser
	| VacancyDetail
	| VacancyInternalDetail
	| VacancyInternalOverview
	| VacancyOverview
	| WobRequest
	| WobRequestOverview
	| WooCategory
	| WooDocumentDetail
	| WooRequest
	| WooRequestOverview
	| WorkAt;

export type GeolinkHomepage = {
	__typename?: 'GeolinkHomepage';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesHomepageHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<GeolinkHomepageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<GeolinkHomepageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GeolinkHomepageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type GeolinkHomepageEntity = {
	__typename?: 'GeolinkHomepageEntity';
	attributes?: Maybe<GeolinkHomepage>;
	id?: Maybe<Scalars['ID']>;
};

export type GeolinkHomepageEntityResponse = {
	__typename?: 'GeolinkHomepageEntityResponse';
	data?: Maybe<GeolinkHomepageEntity>;
};

export type GeolinkHomepageInput = {
	header?: InputMaybe<ComponentModulesHomepageHeaderInput>;
	modules?: InputMaybe<Array<Scalars['GeolinkHomepageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type GeolinkHomepageModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type GeolinkHomepageRelationResponseCollection = {
	__typename?: 'GeolinkHomepageRelationResponseCollection';
	data: Array<GeolinkHomepageEntity>;
};

export type GeolinkInformation = {
	__typename?: 'GeolinkInformation';
	createdAt?: Maybe<Scalars['DateTime']>;
	footer?: Maybe<ComponentInternalFooter>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<GeolinkInformationRelationResponseCollection>;
	navbar?: Maybe<ComponentInternalNavbar>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo?: Maybe<ComponentInternalSeo>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GeolinkInformationLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type GeolinkInformationEntity = {
	__typename?: 'GeolinkInformationEntity';
	attributes?: Maybe<GeolinkInformation>;
	id?: Maybe<Scalars['ID']>;
};

export type GeolinkInformationEntityResponse = {
	__typename?: 'GeolinkInformationEntityResponse';
	data?: Maybe<GeolinkInformationEntity>;
};

export type GeolinkInformationInput = {
	footer?: InputMaybe<ComponentInternalFooterInput>;
	navbar?: InputMaybe<ComponentInternalNavbarInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentInternalSeoInput>;
};

export type GeolinkInformationRelationResponseCollection = {
	__typename?: 'GeolinkInformationRelationResponseCollection';
	data: Array<GeolinkInformationEntity>;
};

export type GeolinkPage = {
	__typename?: 'GeolinkPage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<GeolinkPageRelationResponseCollection>;
	modules: Array<Maybe<GeolinkPageModulesDynamicZone>>;
	parent?: Maybe<GeolinkPageEntityResponse>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	shortDescription?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GeolinkPageLocalizationsArgs = {
	filters?: InputMaybe<GeolinkPageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeolinkPageTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GeolinkPageEntity = {
	__typename?: 'GeolinkPageEntity';
	attributes?: Maybe<GeolinkPage>;
	id?: Maybe<Scalars['ID']>;
};

export type GeolinkPageEntityResponse = {
	__typename?: 'GeolinkPageEntityResponse';
	data?: Maybe<GeolinkPageEntity>;
};

export type GeolinkPageEntityResponseCollection = {
	__typename?: 'GeolinkPageEntityResponseCollection';
	data: Array<GeolinkPageEntity>;
	meta: ResponseCollectionMeta;
};

export type GeolinkPageFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<GeolinkPageFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<GeolinkPageFiltersInput>;
	not?: InputMaybe<GeolinkPageFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<GeolinkPageFiltersInput>>>;
	parent?: InputMaybe<GeolinkPageFiltersInput>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	shortDescription?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GeolinkPageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['GeolinkPageModulesDynamicZoneInput']>>;
	parent?: InputMaybe<Scalars['ID']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	shortDescription?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type GeolinkPageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type GeolinkPageRelationResponseCollection = {
	__typename?: 'GeolinkPageRelationResponseCollection';
	data: Array<GeolinkPageEntity>;
};

export type HomePage = {
	__typename?: 'HomePage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesHomepageHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<HomePageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<HomePageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HomePageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type HomePageEntity = {
	__typename?: 'HomePageEntity';
	attributes?: Maybe<HomePage>;
	id?: Maybe<Scalars['ID']>;
};

export type HomePageEntityResponse = {
	__typename?: 'HomePageEntityResponse';
	data?: Maybe<HomePageEntity>;
};

export type HomePageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesHomepageHeaderInput>;
	modules?: InputMaybe<Array<Scalars['HomePageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type HomePageModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesSubjects
	| ComponentModulesTestimonial
	| ComponentModulesThemes
	| Error;

export type HomePageRelationResponseCollection = {
	__typename?: 'HomePageRelationResponseCollection';
	data: Array<HomePageEntity>;
};

export type I18NLocale = {
	__typename?: 'I18NLocale';
	code?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	name?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
	__typename?: 'I18NLocaleEntity';
	attributes?: Maybe<I18NLocale>;
	id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
	__typename?: 'I18NLocaleEntityResponse';
	data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
	__typename?: 'I18NLocaleEntityResponseCollection';
	data: Array<I18NLocaleEntity>;
	meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
	code?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<I18NLocaleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	contains?: InputMaybe<Scalars['ID']>;
	containsi?: InputMaybe<Scalars['ID']>;
	endsWith?: InputMaybe<Scalars['ID']>;
	eq?: InputMaybe<Scalars['ID']>;
	eqi?: InputMaybe<Scalars['ID']>;
	gt?: InputMaybe<Scalars['ID']>;
	gte?: InputMaybe<Scalars['ID']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	lt?: InputMaybe<Scalars['ID']>;
	lte?: InputMaybe<Scalars['ID']>;
	ne?: InputMaybe<Scalars['ID']>;
	nei?: InputMaybe<Scalars['ID']>;
	not?: InputMaybe<IdFilterInput>;
	notContains?: InputMaybe<Scalars['ID']>;
	notContainsi?: InputMaybe<Scalars['ID']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	startsWith?: InputMaybe<Scalars['ID']>;
};

export type InConvoWithArticle = {
	__typename?: 'InConvoWithArticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<InConvoWithArticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<InConvoWithArticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InConvoWithArticleLocalizationsArgs = {
	filters?: InputMaybe<InConvoWithArticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InConvoWithArticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InConvoWithArticleEntity = {
	__typename?: 'InConvoWithArticleEntity';
	attributes?: Maybe<InConvoWithArticle>;
	id?: Maybe<Scalars['ID']>;
};

export type InConvoWithArticleEntityResponse = {
	__typename?: 'InConvoWithArticleEntityResponse';
	data?: Maybe<InConvoWithArticleEntity>;
};

export type InConvoWithArticleEntityResponseCollection = {
	__typename?: 'InConvoWithArticleEntityResponseCollection';
	data: Array<InConvoWithArticleEntity>;
	meta: ResponseCollectionMeta;
};

export type InConvoWithArticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<InConvoWithArticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<InConvoWithArticleFiltersInput>;
	not?: InputMaybe<InConvoWithArticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<InConvoWithArticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type InConvoWithArticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['InConvoWithArticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type InConvoWithArticleModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesShare
	| ComponentModulesTestimonial
	| Error;

export type InConvoWithArticleRelationResponseCollection = {
	__typename?: 'InConvoWithArticleRelationResponseCollection';
	data: Array<InConvoWithArticleEntity>;
};

export type InConvoWithDetail = {
	__typename?: 'InConvoWithDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<InConvoWithDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<InConvoWithDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InConvoWithDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type InConvoWithDetailEntity = {
	__typename?: 'InConvoWithDetailEntity';
	attributes?: Maybe<InConvoWithDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type InConvoWithDetailEntityResponse = {
	__typename?: 'InConvoWithDetailEntityResponse';
	data?: Maybe<InConvoWithDetailEntity>;
};

export type InConvoWithDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['InConvoWithDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InConvoWithDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type InConvoWithDetailRelationResponseCollection = {
	__typename?: 'InConvoWithDetailRelationResponseCollection';
	data: Array<InConvoWithDetailEntity>;
};

export type InConvoWithOverview = {
	__typename?: 'InConvoWithOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<InConvoWithOverviewRelationResponseCollection>;
	modules: Array<Maybe<InConvoWithOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InConvoWithOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type InConvoWithOverviewEntity = {
	__typename?: 'InConvoWithOverviewEntity';
	attributes?: Maybe<InConvoWithOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type InConvoWithOverviewEntityResponse = {
	__typename?: 'InConvoWithOverviewEntityResponse';
	data?: Maybe<InConvoWithOverviewEntity>;
};

export type InConvoWithOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['InConvoWithOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type InConvoWithOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesInConvoWithOverview
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type InConvoWithOverviewRelationResponseCollection = {
	__typename?: 'InConvoWithOverviewRelationResponseCollection';
	data: Array<InConvoWithOverviewEntity>;
};

export type IntFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	contains?: InputMaybe<Scalars['Int']>;
	containsi?: InputMaybe<Scalars['Int']>;
	endsWith?: InputMaybe<Scalars['Int']>;
	eq?: InputMaybe<Scalars['Int']>;
	eqi?: InputMaybe<Scalars['Int']>;
	gt?: InputMaybe<Scalars['Int']>;
	gte?: InputMaybe<Scalars['Int']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	lt?: InputMaybe<Scalars['Int']>;
	lte?: InputMaybe<Scalars['Int']>;
	ne?: InputMaybe<Scalars['Int']>;
	nei?: InputMaybe<Scalars['Int']>;
	not?: InputMaybe<IntFilterInput>;
	notContains?: InputMaybe<Scalars['Int']>;
	notContainsi?: InputMaybe<Scalars['Int']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
	startsWith?: InputMaybe<Scalars['Int']>;
};

export type InternalLinkInternalLink = {
	__typename?: 'InternalLinkInternalLink';
	createdAt?: Maybe<Scalars['DateTime']>;
	password?: Maybe<Scalars['String']>;
	sourceContentTypeId?: Maybe<Scalars['String']>;
	sourceContentTypeUid?: Maybe<Scalars['String']>;
	sourceFieldName?: Maybe<Scalars['String']>;
	targetContentTypeId?: Maybe<Scalars['String']>;
	targetContentTypeUid?: Maybe<Scalars['String']>;
	text?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	url?: Maybe<Scalars['String']>;
};

export type InternalLinkInternalLinkEntity = {
	__typename?: 'InternalLinkInternalLinkEntity';
	attributes?: Maybe<InternalLinkInternalLink>;
	id?: Maybe<Scalars['ID']>;
};

export type InternalLinkInternalLinkEntityResponse = {
	__typename?: 'InternalLinkInternalLinkEntityResponse';
	data?: Maybe<InternalLinkInternalLinkEntity>;
};

export type InternalLinkInternalLinkEntityResponseCollection = {
	__typename?: 'InternalLinkInternalLinkEntityResponseCollection';
	data: Array<InternalLinkInternalLinkEntity>;
	meta: ResponseCollectionMeta;
};

export type InternalLinkInternalLinkFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<InternalLinkInternalLinkFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<InternalLinkInternalLinkFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<InternalLinkInternalLinkFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	sourceContentTypeId?: InputMaybe<StringFilterInput>;
	sourceContentTypeUid?: InputMaybe<StringFilterInput>;
	sourceFieldName?: InputMaybe<StringFilterInput>;
	targetContentTypeId?: InputMaybe<StringFilterInput>;
	targetContentTypeUid?: InputMaybe<StringFilterInput>;
	text?: InputMaybe<StringFilterInput>;
	type?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	url?: InputMaybe<StringFilterInput>;
};

export type InternalLinkInternalLinkInput = {
	password?: InputMaybe<Scalars['String']>;
	sourceContentTypeId?: InputMaybe<Scalars['String']>;
	sourceContentTypeUid?: InputMaybe<Scalars['String']>;
	sourceFieldName?: InputMaybe<Scalars['String']>;
	targetContentTypeId?: InputMaybe<Scalars['String']>;
	targetContentTypeUid?: InputMaybe<Scalars['String']>;
	text?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<Scalars['String']>;
	url?: InputMaybe<Scalars['String']>;
};

export type InternalLinkInternalLinkWysiwyg = {
	__typename?: 'InternalLinkInternalLinkWysiwyg';
	createdAt?: Maybe<Scalars['DateTime']>;
	password?: Maybe<Scalars['String']>;
	sourceContentTypeId?: Maybe<Scalars['String']>;
	sourceContentTypeUid?: Maybe<Scalars['String']>;
	sourceFieldName?: Maybe<Scalars['String']>;
	targetContentTypeId?: Maybe<Scalars['String']>;
	targetContentTypeUid?: Maybe<Scalars['String']>;
	text?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	url?: Maybe<Scalars['String']>;
	uuid?: Maybe<Scalars['String']>;
};

export type InternalLinkInternalLinkWysiwygEntity = {
	__typename?: 'InternalLinkInternalLinkWysiwygEntity';
	attributes?: Maybe<InternalLinkInternalLinkWysiwyg>;
	id?: Maybe<Scalars['ID']>;
};

export type InternalLinkInternalLinkWysiwygEntityResponse = {
	__typename?: 'InternalLinkInternalLinkWysiwygEntityResponse';
	data?: Maybe<InternalLinkInternalLinkWysiwygEntity>;
};

export type InternalLinkInternalLinkWysiwygEntityResponseCollection = {
	__typename?: 'InternalLinkInternalLinkWysiwygEntityResponseCollection';
	data: Array<InternalLinkInternalLinkWysiwygEntity>;
	meta: ResponseCollectionMeta;
};

export type InternalLinkInternalLinkWysiwygFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<InternalLinkInternalLinkWysiwygFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<InternalLinkInternalLinkWysiwygFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<InternalLinkInternalLinkWysiwygFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	sourceContentTypeId?: InputMaybe<StringFilterInput>;
	sourceContentTypeUid?: InputMaybe<StringFilterInput>;
	sourceFieldName?: InputMaybe<StringFilterInput>;
	targetContentTypeId?: InputMaybe<StringFilterInput>;
	targetContentTypeUid?: InputMaybe<StringFilterInput>;
	text?: InputMaybe<StringFilterInput>;
	type?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	url?: InputMaybe<StringFilterInput>;
	uuid?: InputMaybe<StringFilterInput>;
};

export type InternalLinkInternalLinkWysiwygInput = {
	password?: InputMaybe<Scalars['String']>;
	sourceContentTypeId?: InputMaybe<Scalars['String']>;
	sourceContentTypeUid?: InputMaybe<Scalars['String']>;
	sourceFieldName?: InputMaybe<Scalars['String']>;
	targetContentTypeId?: InputMaybe<Scalars['String']>;
	targetContentTypeUid?: InputMaybe<Scalars['String']>;
	text?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<Scalars['String']>;
	url?: InputMaybe<Scalars['String']>;
	uuid?: InputMaybe<Scalars['String']>;
};

export type InternalNewsletterWithSubscriptions = {
	__typename?: 'InternalNewsletterWithSubscriptions';
	page?: Maybe<NewsletterInternal>;
	subscriptions?: Maybe<Array<Maybe<NewsletterData>>>;
};

export type JsonFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
	contains?: InputMaybe<Scalars['JSON']>;
	containsi?: InputMaybe<Scalars['JSON']>;
	endsWith?: InputMaybe<Scalars['JSON']>;
	eq?: InputMaybe<Scalars['JSON']>;
	eqi?: InputMaybe<Scalars['JSON']>;
	gt?: InputMaybe<Scalars['JSON']>;
	gte?: InputMaybe<Scalars['JSON']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
	lt?: InputMaybe<Scalars['JSON']>;
	lte?: InputMaybe<Scalars['JSON']>;
	ne?: InputMaybe<Scalars['JSON']>;
	nei?: InputMaybe<Scalars['JSON']>;
	not?: InputMaybe<JsonFilterInput>;
	notContains?: InputMaybe<Scalars['JSON']>;
	notContainsi?: InputMaybe<Scalars['JSON']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
	startsWith?: InputMaybe<Scalars['JSON']>;
};

export type Login = {
	__typename?: 'Login';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<LoginRelationResponseCollection>;
	modules: Array<Maybe<LoginModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoginEntity = {
	__typename?: 'LoginEntity';
	attributes?: Maybe<Login>;
	id?: Maybe<Scalars['ID']>;
};

export type LoginEntityResponse = {
	__typename?: 'LoginEntityResponse';
	data?: Maybe<LoginEntity>;
};

export type LoginInput = {
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['LoginModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
};

export type LoginModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesVacanciesOverview
	| Error;

export type LoginRelationResponseCollection = {
	__typename?: 'LoginRelationResponseCollection';
	data: Array<LoginEntity>;
};

export type LongFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
	contains?: InputMaybe<Scalars['Long']>;
	containsi?: InputMaybe<Scalars['Long']>;
	endsWith?: InputMaybe<Scalars['Long']>;
	eq?: InputMaybe<Scalars['Long']>;
	eqi?: InputMaybe<Scalars['Long']>;
	gt?: InputMaybe<Scalars['Long']>;
	gte?: InputMaybe<Scalars['Long']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
	lt?: InputMaybe<Scalars['Long']>;
	lte?: InputMaybe<Scalars['Long']>;
	ne?: InputMaybe<Scalars['Long']>;
	nei?: InputMaybe<Scalars['Long']>;
	not?: InputMaybe<LongFilterInput>;
	notContains?: InputMaybe<Scalars['Long']>;
	notContainsi?: InputMaybe<Scalars['Long']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
	startsWith?: InputMaybe<Scalars['Long']>;
};

export type MapsFigure = {
	__typename?: 'MapsFigure';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	keywords?: Maybe<Scalars['String']>;
	link: Scalars['JSON'];
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<MapsFigureRelationResponseCollection>;
	media?: Maybe<UploadFileEntityResponse>;
	name: Scalars['String'];
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	tags?: Maybe<TagRelationResponseCollection>;
	themes?: Maybe<ThemeRelationResponseCollection>;
	type: Enum_Mapsfigure_Type;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MapsFigureLocalizationsArgs = {
	filters?: InputMaybe<MapsFigureFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MapsFigureTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MapsFigureThemesArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MapsFigureEntity = {
	__typename?: 'MapsFigureEntity';
	attributes?: Maybe<MapsFigure>;
	id?: Maybe<Scalars['ID']>;
};

export type MapsFigureEntityResponse = {
	__typename?: 'MapsFigureEntityResponse';
	data?: Maybe<MapsFigureEntity>;
};

export type MapsFigureEntityResponseCollection = {
	__typename?: 'MapsFigureEntityResponseCollection';
	data: Array<MapsFigureEntity>;
	meta: ResponseCollectionMeta;
};

export type MapsFigureFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<MapsFigureFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	link?: InputMaybe<JsonFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<MapsFigureFiltersInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<MapsFigureFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<MapsFigureFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	themes?: InputMaybe<ThemeFiltersInput>;
	type?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MapsFigureInput = {
	basePath?: InputMaybe<Scalars['String']>;
	keywords?: InputMaybe<Scalars['String']>;
	link?: InputMaybe<Scalars['JSON']>;
	media?: InputMaybe<Scalars['ID']>;
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	themes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	type?: InputMaybe<Enum_Mapsfigure_Type>;
};

export type MapsFigureRelationResponseCollection = {
	__typename?: 'MapsFigureRelationResponseCollection';
	data: Array<MapsFigureEntity>;
};

export type MapsFiguresOverview = {
	__typename?: 'MapsFiguresOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<MapsFiguresOverviewRelationResponseCollection>;
	mapsFiguresOverview: ComponentModulesMapsFiguresOverview;
	modules?: Maybe<Array<Maybe<MapsFiguresOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MapsFiguresOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type MapsFiguresOverviewEntity = {
	__typename?: 'MapsFiguresOverviewEntity';
	attributes?: Maybe<MapsFiguresOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type MapsFiguresOverviewEntityResponse = {
	__typename?: 'MapsFiguresOverviewEntityResponse';
	data?: Maybe<MapsFiguresOverviewEntity>;
};

export type MapsFiguresOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	mapsFiguresOverview?: InputMaybe<ComponentModulesMapsFiguresOverviewInput>;
	modules?: InputMaybe<Array<Scalars['MapsFiguresOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type MapsFiguresOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesThemes
	| Error;

export type MapsFiguresOverviewRelationResponseCollection = {
	__typename?: 'MapsFiguresOverviewRelationResponseCollection';
	data: Array<MapsFiguresOverviewEntity>;
};

export type Mutation = {
	__typename?: 'Mutation';
	addComment?: Maybe<ConversationsConversationitemEntityResponse>;
	addUserToNewsletters?: Maybe<Array<Maybe<NewsletterSubscriptions>>>;
	/** Change user password. Confirm with the current password. */
	changePassword?: Maybe<UsersPermissionsLoginPayload>;
	createAgendaPageLocalization?: Maybe<AgendaPageEntityResponse>;
	createAuditLogAuditLog?: Maybe<AuditLogAuditLogEntityResponse>;
	createCondoleanceHomepageLocalization?: Maybe<CondoleanceHomepageEntityResponse>;
	createCondoleanceInformationLocalization?: Maybe<CondoleanceInformationEntityResponse>;
	createCondoleancePage?: Maybe<CondoleancePageEntityResponse>;
	createCondoleancePageLocalization?: Maybe<CondoleancePageEntityResponse>;
	createContactLocalization?: Maybe<ContactEntityResponse>;
	createConversationsConversationitem?: Maybe<ConversationsConversationitemEntityResponse>;
	createConversationsConversations?: Maybe<ConversationsConversationsEntityResponse>;
	createCookiePageLocalization?: Maybe<CookiePageEntityResponse>;
	createDecisionsPageLocalization?: Maybe<DecisionsPageEntityResponse>;
	createDownloadsPageLocalization?: Maybe<DownloadsPageEntityResponse>;
	createEerbeekloenenHomepageLocalization?: Maybe<EerbeekloenenHomepageEntityResponse>;
	createEerbeekloenenInConvoArticle?: Maybe<EerbeekloenenInConvoArticleEntityResponse>;
	createEerbeekloenenInConvoArticleLocalization?: Maybe<EerbeekloenenInConvoArticleEntityResponse>;
	createEerbeekloenenInConvoOverviewLocalization?: Maybe<EerbeekloenenInConvoOverviewEntityResponse>;
	createEerbeekloenenInConvoWithDetailLocalization?: Maybe<EerbeekloenenInConvoWithDetailEntityResponse>;
	createEerbeekloenenInformationLocalization?: Maybe<EerbeekloenenInformationEntityResponse>;
	createEerbeekloenenNewsDetailLocalization?: Maybe<EerbeekloenenNewsDetailEntityResponse>;
	createEerbeekloenenNewsOverviewLocalization?: Maybe<EerbeekloenenNewsOverviewEntityResponse>;
	createEerbeekloenenNewsarticle?: Maybe<EerbeekloenenNewsarticleEntityResponse>;
	createEerbeekloenenNewsarticleLocalization?: Maybe<EerbeekloenenNewsarticleEntityResponse>;
	createEerbeekloenenPage?: Maybe<EerbeekloenenPageEntityResponse>;
	createEerbeekloenenPageLocalization?: Maybe<EerbeekloenenPageEntityResponse>;
	createEfroOostHomepageLocalization?: Maybe<EfroOostHomepageEntityResponse>;
	createEfroOostInConvoWithArticle?: Maybe<EfroOostInConvoWithArticleEntityResponse>;
	createEfroOostInConvoWithArticleLocalization?: Maybe<EfroOostInConvoWithArticleEntityResponse>;
	createEfroOostInConvoWithDetailLocalization?: Maybe<EfroOostInConvoWithDetailEntityResponse>;
	createEfroOostInConvoWithOverviewLocalization?: Maybe<EfroOostInConvoWithOverviewEntityResponse>;
	createEfroOostInformationLocalization?: Maybe<EfroOostInformationEntityResponse>;
	createEfroOostNewsDetailLocalization?: Maybe<EfroOostNewsDetailEntityResponse>;
	createEfroOostNewsOverviewLocalization?: Maybe<EfroOostNewsOverviewEntityResponse>;
	createEfroOostNewsarticle?: Maybe<EfroOostNewsarticleEntityResponse>;
	createEfroOostNewsarticleLocalization?: Maybe<EfroOostNewsarticleEntityResponse>;
	createEfroOostPage?: Maybe<EfroOostPageEntityResponse>;
	createEfroOostPageLocalization?: Maybe<EfroOostPageEntityResponse>;
	createEvent?: Maybe<EventEntityResponse>;
	createEventLocalization?: Maybe<EventEntityResponse>;
	createEventOverviewLocalization?: Maybe<EventOverviewEntityResponse>;
	createGeolinkHomepageLocalization?: Maybe<GeolinkHomepageEntityResponse>;
	createGeolinkInformationLocalization?: Maybe<GeolinkInformationEntityResponse>;
	createGeolinkPage?: Maybe<GeolinkPageEntityResponse>;
	createGeolinkPageLocalization?: Maybe<GeolinkPageEntityResponse>;
	createHomePageLocalization?: Maybe<HomePageEntityResponse>;
	createInConvoWithArticle?: Maybe<InConvoWithArticleEntityResponse>;
	createInConvoWithArticleLocalization?: Maybe<InConvoWithArticleEntityResponse>;
	createInConvoWithDetailLocalization?: Maybe<InConvoWithDetailEntityResponse>;
	createInConvoWithOverviewLocalization?: Maybe<InConvoWithOverviewEntityResponse>;
	createInternalLinkInternalLink?: Maybe<InternalLinkInternalLinkEntityResponse>;
	createInternalLinkInternalLinkWysiwyg?: Maybe<InternalLinkInternalLinkWysiwygEntityResponse>;
	createLoginLocalization?: Maybe<LoginEntityResponse>;
	createMapsFigure?: Maybe<MapsFigureEntityResponse>;
	createMapsFigureLocalization?: Maybe<MapsFigureEntityResponse>;
	createMapsFiguresOverviewLocalization?: Maybe<MapsFiguresOverviewEntityResponse>;
	createNewsDetailLocalization?: Maybe<NewsDetailEntityResponse>;
	createNewsOverviewLocalization?: Maybe<NewsOverviewEntityResponse>;
	createNewsarticle?: Maybe<NewsarticleEntityResponse>;
	createNewsarticleLocalization?: Maybe<NewsarticleEntityResponse>;
	createNewsletterArchiveOverviewLocalization?: Maybe<NewsletterArchiveOverviewEntityResponse>;
	createNewsletterInternalLocalization?: Maybe<NewsletterInternalEntityResponse>;
	createNewsletterPublicLocalization?: Maybe<NewsletterPublicEntityResponse>;
	createNotFoundPageLocalization?: Maybe<NotFoundPageEntityResponse>;
	createNoteNote?: Maybe<NoteNoteEntityResponse>;
	createNoteNoterelation?: Maybe<NoteNoterelationEntityResponse>;
	createOpOostHomepageLocalization?: Maybe<OpOostHomepageEntityResponse>;
	createOpOostInConvoWithArticle?: Maybe<OpOostInConvoWithArticleEntityResponse>;
	createOpOostInConvoWithArticleLocalization?: Maybe<OpOostInConvoWithArticleEntityResponse>;
	createOpOostInConvoWithDetailLocalization?: Maybe<OpOostInConvoWithDetailEntityResponse>;
	createOpOostInConvoWithOverviewLocalization?: Maybe<OpOostInConvoWithOverviewEntityResponse>;
	createOpOostInformationLocalization?: Maybe<OpOostInformationEntityResponse>;
	createOpOostNewsDetailLocalization?: Maybe<OpOostNewsDetailEntityResponse>;
	createOpOostNewsOverviewLocalization?: Maybe<OpOostNewsOverviewEntityResponse>;
	createOpOostNewsarticle?: Maybe<OpOostNewsarticleEntityResponse>;
	createOpOostNewsarticleLocalization?: Maybe<OpOostNewsarticleEntityResponse>;
	createOpOostPage?: Maybe<OpOostPageEntityResponse>;
	createOpOostPageLocalization?: Maybe<OpOostPageEntityResponse>;
	createOpenGelderlandHomepageLocalization?: Maybe<OpenGelderlandHomepageEntityResponse>;
	createOpenGelderlandInformationLocalization?: Maybe<OpenGelderlandInformationEntityResponse>;
	createOpenGelderlandWooOverviewLocalization?: Maybe<OpenGelderlandWooOverviewEntityResponse>;
	createPage?: Maybe<PageEntityResponse>;
	createPageInformationLocalization?: Maybe<PageInformationEntityResponse>;
	createPageLocalization?: Maybe<PageEntityResponse>;
	createPasswordPageLocalization?: Maybe<PasswordPageEntityResponse>;
	createPermit?: Maybe<PermitEntityResponse>;
	createPermitDetailLocalization?: Maybe<PermitDetailEntityResponse>;
	createPermitLaw?: Maybe<PermitLawEntityResponse>;
	createPermitLawLocalization?: Maybe<PermitLawEntityResponse>;
	createPermitLocalization?: Maybe<PermitEntityResponse>;
	createPermitProductType?: Maybe<PermitProductTypeEntityResponse>;
	createPermitProductTypeLocalization?: Maybe<PermitProductTypeEntityResponse>;
	createPermitsOverviewLocalization?: Maybe<PermitsOverviewEntityResponse>;
	createPressRelease?: Maybe<PressReleaseEntityResponse>;
	createPressReleaseDetailLocalization?: Maybe<PressReleaseDetailEntityResponse>;
	createPressReleaseLocalization?: Maybe<PressReleaseEntityResponse>;
	createPressReleaseOverviewLocalization?: Maybe<PressReleaseOverviewEntityResponse>;
	createPrivacyPolicyLocalization?: Maybe<PrivacyPolicyEntityResponse>;
	createProject?: Maybe<ProjectEntityResponse>;
	createProjectDetailLocalization?: Maybe<ProjectDetailEntityResponse>;
	createProjectLocalization?: Maybe<ProjectEntityResponse>;
	createProjectOverviewLocalization?: Maybe<ProjectOverviewEntityResponse>;
	createRecordLockingOpenEntity?: Maybe<RecordLockingOpenEntityEntityResponse>;
	createRecycleBinRecycleBinItem?: Maybe<RecycleBinRecycleBinItemEntityResponse>;
	createRegioExpresHomepageLocalization?: Maybe<RegioExpresHomepageEntityResponse>;
	createRegioExpresInConvoOverviewLocalization?: Maybe<RegioExpresInConvoOverviewEntityResponse>;
	createRegioExpresInConvoWithArticle?: Maybe<RegioExpresInConvoWithArticleEntityResponse>;
	createRegioExpresInConvoWithArticleLocalization?: Maybe<RegioExpresInConvoWithArticleEntityResponse>;
	createRegioExpresInConvoWithDetailLocalization?: Maybe<RegioExpresInConvoWithDetailEntityResponse>;
	createRegioExpresInformationLocalization?: Maybe<RegioExpresInformationEntityResponse>;
	createRegioExpresNewsDetailLocalization?: Maybe<RegioExpresNewsDetailEntityResponse>;
	createRegioExpresNewsOverviewLocalization?: Maybe<RegioExpresNewsOverviewEntityResponse>;
	createRegioExpresNewsarticle?: Maybe<RegioExpresNewsarticleEntityResponse>;
	createRegioExpresNewsarticleLocalization?: Maybe<RegioExpresNewsarticleEntityResponse>;
	createRegioExpresPage?: Maybe<RegioExpresPageEntityResponse>;
	createRegioExpresPageLocalization?: Maybe<RegioExpresPageEntityResponse>;
	createRegion?: Maybe<RegionEntityResponse>;
	createRegionDetailLocalization?: Maybe<RegionDetailEntityResponse>;
	createRegionLocalization?: Maybe<RegionEntityResponse>;
	createRegionOverviewLocalization?: Maybe<RegionOverviewEntityResponse>;
	createRoadMaintenance?: Maybe<RoadMaintenanceEntityResponse>;
	createRoadMaintenanceDetailLocalization?: Maybe<RoadMaintenanceDetailEntityResponse>;
	createRoadMaintenanceLocalization?: Maybe<RoadMaintenanceEntityResponse>;
	createRoadMaintenanceOverviewLocalization?: Maybe<RoadMaintenanceOverviewEntityResponse>;
	createSchedulerScheduler?: Maybe<SchedulerSchedulerEntityResponse>;
	createSdgLocatie?: Maybe<SdgLocatieEntityResponse>;
	createSdgSdg?: Maybe<SdgSdgEntityResponse>;
	createSdgUplLijst?: Maybe<SdgUplLijstEntityResponse>;
	createSearchOverviewLocalization?: Maybe<SearchOverviewEntityResponse>;
	createSitemapLocalization?: Maybe<SitemapEntityResponse>;
	createSubsidy?: Maybe<SubsidyEntityResponse>;
	createSubsidyDetailLocalization?: Maybe<SubsidyDetailEntityResponse>;
	createSubsidyKind?: Maybe<SubsidyKindEntityResponse>;
	createSubsidyKindLocalization?: Maybe<SubsidyKindEntityResponse>;
	createSubsidyLocalization?: Maybe<SubsidyEntityResponse>;
	createSubsidyOverviewLocalization?: Maybe<SubsidyOverviewEntityResponse>;
	createSubsidyScale?: Maybe<SubsidyScaleEntityResponse>;
	createSubsidyScaleLocalization?: Maybe<SubsidyScaleEntityResponse>;
	createTag?: Maybe<TagEntityResponse>;
	createTagLocalization?: Maybe<TagEntityResponse>;
	createTargetGroup?: Maybe<TargetGroupEntityResponse>;
	createTargetGroupLocalization?: Maybe<TargetGroupEntityResponse>;
	createTheme?: Maybe<ThemeEntityResponse>;
	createThemeDetailLocalization?: Maybe<ThemeDetailEntityResponse>;
	createThemeLocalization?: Maybe<ThemeEntityResponse>;
	createThemeOverviewLocalization?: Maybe<ThemeOverviewEntityResponse>;
	createUpl?: Maybe<UplEntityResponse>;
	createUploadFile?: Maybe<UploadFileEntityResponse>;
	createUploadFolder?: Maybe<UploadFolderEntityResponse>;
	/** Create a new role */
	createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
	/** Create a new user */
	createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
	createVacancyDetailLocalization?: Maybe<VacancyDetailEntityResponse>;
	createVacancyInternalDetailLocalization?: Maybe<VacancyInternalDetailEntityResponse>;
	createVacancyInternalOverviewLocalization?: Maybe<VacancyInternalOverviewEntityResponse>;
	createVacancyOverviewLocalization?: Maybe<VacancyOverviewEntityResponse>;
	createWobRequest?: Maybe<WobRequestEntityResponse>;
	createWobRequestLocalization?: Maybe<WobRequestEntityResponse>;
	createWobRequestOverviewLocalization?: Maybe<WobRequestOverviewEntityResponse>;
	createWooCategory?: Maybe<WooCategoryEntityResponse>;
	createWooDocumentDetailLocalization?: Maybe<WooDocumentDetailEntityResponse>;
	createWooRequest?: Maybe<WooRequestEntityResponse>;
	createWooRequestLocalization?: Maybe<WooRequestEntityResponse>;
	createWooRequestOverviewLocalization?: Maybe<WooRequestOverviewEntityResponse>;
	createWorkAtLocalization?: Maybe<WorkAtEntityResponse>;
	deleteAgendaPage?: Maybe<AgendaPageEntityResponse>;
	deleteAuditLogAuditLog?: Maybe<AuditLogAuditLogEntityResponse>;
	deleteCondoleanceHomepage?: Maybe<CondoleanceHomepageEntityResponse>;
	deleteCondoleanceInformation?: Maybe<CondoleanceInformationEntityResponse>;
	deleteCondoleancePage?: Maybe<CondoleancePageEntityResponse>;
	deleteContact?: Maybe<ContactEntityResponse>;
	deleteConversationsConversationitem?: Maybe<ConversationsConversationitemEntityResponse>;
	deleteConversationsConversations?: Maybe<ConversationsConversationsEntityResponse>;
	deleteCookiePage?: Maybe<CookiePageEntityResponse>;
	deleteDecisionsPage?: Maybe<DecisionsPageEntityResponse>;
	deleteDownloadsPage?: Maybe<DownloadsPageEntityResponse>;
	deleteEerbeekloenenHomepage?: Maybe<EerbeekloenenHomepageEntityResponse>;
	deleteEerbeekloenenInConvoArticle?: Maybe<EerbeekloenenInConvoArticleEntityResponse>;
	deleteEerbeekloenenInConvoOverview?: Maybe<EerbeekloenenInConvoOverviewEntityResponse>;
	deleteEerbeekloenenInConvoWithDetail?: Maybe<EerbeekloenenInConvoWithDetailEntityResponse>;
	deleteEerbeekloenenInformation?: Maybe<EerbeekloenenInformationEntityResponse>;
	deleteEerbeekloenenNewsDetail?: Maybe<EerbeekloenenNewsDetailEntityResponse>;
	deleteEerbeekloenenNewsOverview?: Maybe<EerbeekloenenNewsOverviewEntityResponse>;
	deleteEerbeekloenenNewsarticle?: Maybe<EerbeekloenenNewsarticleEntityResponse>;
	deleteEerbeekloenenPage?: Maybe<EerbeekloenenPageEntityResponse>;
	deleteEfroOostHomepage?: Maybe<EfroOostHomepageEntityResponse>;
	deleteEfroOostInConvoWithArticle?: Maybe<EfroOostInConvoWithArticleEntityResponse>;
	deleteEfroOostInConvoWithDetail?: Maybe<EfroOostInConvoWithDetailEntityResponse>;
	deleteEfroOostInConvoWithOverview?: Maybe<EfroOostInConvoWithOverviewEntityResponse>;
	deleteEfroOostInformation?: Maybe<EfroOostInformationEntityResponse>;
	deleteEfroOostNewsDetail?: Maybe<EfroOostNewsDetailEntityResponse>;
	deleteEfroOostNewsOverview?: Maybe<EfroOostNewsOverviewEntityResponse>;
	deleteEfroOostNewsarticle?: Maybe<EfroOostNewsarticleEntityResponse>;
	deleteEfroOostPage?: Maybe<EfroOostPageEntityResponse>;
	deleteEvent?: Maybe<EventEntityResponse>;
	deleteEventOverview?: Maybe<EventOverviewEntityResponse>;
	deleteGeolinkHomepage?: Maybe<GeolinkHomepageEntityResponse>;
	deleteGeolinkInformation?: Maybe<GeolinkInformationEntityResponse>;
	deleteGeolinkPage?: Maybe<GeolinkPageEntityResponse>;
	deleteHomePage?: Maybe<HomePageEntityResponse>;
	deleteInConvoWithArticle?: Maybe<InConvoWithArticleEntityResponse>;
	deleteInConvoWithDetail?: Maybe<InConvoWithDetailEntityResponse>;
	deleteInConvoWithOverview?: Maybe<InConvoWithOverviewEntityResponse>;
	deleteInternalLinkInternalLink?: Maybe<InternalLinkInternalLinkEntityResponse>;
	deleteInternalLinkInternalLinkWysiwyg?: Maybe<InternalLinkInternalLinkWysiwygEntityResponse>;
	deleteLogin?: Maybe<LoginEntityResponse>;
	deleteMapsFigure?: Maybe<MapsFigureEntityResponse>;
	deleteMapsFiguresOverview?: Maybe<MapsFiguresOverviewEntityResponse>;
	deleteNewsDetail?: Maybe<NewsDetailEntityResponse>;
	deleteNewsOverview?: Maybe<NewsOverviewEntityResponse>;
	deleteNewsarticle?: Maybe<NewsarticleEntityResponse>;
	deleteNewsletter?: Maybe<NewsletterEntityResponse>;
	deleteNewsletterArchiveOverview?: Maybe<NewsletterArchiveOverviewEntityResponse>;
	deleteNewsletterInternal?: Maybe<NewsletterInternalEntityResponse>;
	deleteNewsletterPublic?: Maybe<NewsletterPublicEntityResponse>;
	deleteNotFoundPage?: Maybe<NotFoundPageEntityResponse>;
	deleteNoteNote?: Maybe<NoteNoteEntityResponse>;
	deleteNoteNoterelation?: Maybe<NoteNoterelationEntityResponse>;
	deleteOpOostHomepage?: Maybe<OpOostHomepageEntityResponse>;
	deleteOpOostInConvoWithArticle?: Maybe<OpOostInConvoWithArticleEntityResponse>;
	deleteOpOostInConvoWithDetail?: Maybe<OpOostInConvoWithDetailEntityResponse>;
	deleteOpOostInConvoWithOverview?: Maybe<OpOostInConvoWithOverviewEntityResponse>;
	deleteOpOostInformation?: Maybe<OpOostInformationEntityResponse>;
	deleteOpOostNewsDetail?: Maybe<OpOostNewsDetailEntityResponse>;
	deleteOpOostNewsOverview?: Maybe<OpOostNewsOverviewEntityResponse>;
	deleteOpOostNewsarticle?: Maybe<OpOostNewsarticleEntityResponse>;
	deleteOpOostPage?: Maybe<OpOostPageEntityResponse>;
	deleteOpenGelderlandHomepage?: Maybe<OpenGelderlandHomepageEntityResponse>;
	deleteOpenGelderlandInformation?: Maybe<OpenGelderlandInformationEntityResponse>;
	deleteOpenGelderlandWooOverview?: Maybe<OpenGelderlandWooOverviewEntityResponse>;
	deletePage?: Maybe<PageEntityResponse>;
	deletePageInformation?: Maybe<PageInformationEntityResponse>;
	deletePasswordPage?: Maybe<PasswordPageEntityResponse>;
	deletePermit?: Maybe<PermitEntityResponse>;
	deletePermitDetail?: Maybe<PermitDetailEntityResponse>;
	deletePermitLaw?: Maybe<PermitLawEntityResponse>;
	deletePermitProductType?: Maybe<PermitProductTypeEntityResponse>;
	deletePermitsOverview?: Maybe<PermitsOverviewEntityResponse>;
	deletePressRelease?: Maybe<PressReleaseEntityResponse>;
	deletePressReleaseDetail?: Maybe<PressReleaseDetailEntityResponse>;
	deletePressReleaseOverview?: Maybe<PressReleaseOverviewEntityResponse>;
	deletePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
	deleteProject?: Maybe<ProjectEntityResponse>;
	deleteProjectDetail?: Maybe<ProjectDetailEntityResponse>;
	deleteProjectOverview?: Maybe<ProjectOverviewEntityResponse>;
	deleteRecordLockingOpenEntity?: Maybe<RecordLockingOpenEntityEntityResponse>;
	deleteRecycleBinRecycleBinItem?: Maybe<RecycleBinRecycleBinItemEntityResponse>;
	deleteRegioExpresHomepage?: Maybe<RegioExpresHomepageEntityResponse>;
	deleteRegioExpresInConvoOverview?: Maybe<RegioExpresInConvoOverviewEntityResponse>;
	deleteRegioExpresInConvoWithArticle?: Maybe<RegioExpresInConvoWithArticleEntityResponse>;
	deleteRegioExpresInConvoWithDetail?: Maybe<RegioExpresInConvoWithDetailEntityResponse>;
	deleteRegioExpresInformation?: Maybe<RegioExpresInformationEntityResponse>;
	deleteRegioExpresNewsDetail?: Maybe<RegioExpresNewsDetailEntityResponse>;
	deleteRegioExpresNewsOverview?: Maybe<RegioExpresNewsOverviewEntityResponse>;
	deleteRegioExpresNewsarticle?: Maybe<RegioExpresNewsarticleEntityResponse>;
	deleteRegioExpresPage?: Maybe<RegioExpresPageEntityResponse>;
	deleteRegion?: Maybe<RegionEntityResponse>;
	deleteRegionDetail?: Maybe<RegionDetailEntityResponse>;
	deleteRegionOverview?: Maybe<RegionOverviewEntityResponse>;
	deleteRoadMaintenance?: Maybe<RoadMaintenanceEntityResponse>;
	deleteRoadMaintenanceDetail?: Maybe<RoadMaintenanceDetailEntityResponse>;
	deleteRoadMaintenanceOverview?: Maybe<RoadMaintenanceOverviewEntityResponse>;
	deleteSchedulerScheduler?: Maybe<SchedulerSchedulerEntityResponse>;
	deleteSdgLocatie?: Maybe<SdgLocatieEntityResponse>;
	deleteSdgSdg?: Maybe<SdgSdgEntityResponse>;
	deleteSdgUplLijst?: Maybe<SdgUplLijstEntityResponse>;
	deleteSearchOverview?: Maybe<SearchOverviewEntityResponse>;
	deleteSitemap?: Maybe<SitemapEntityResponse>;
	deleteSubsidy?: Maybe<SubsidyEntityResponse>;
	deleteSubsidyDetail?: Maybe<SubsidyDetailEntityResponse>;
	deleteSubsidyKind?: Maybe<SubsidyKindEntityResponse>;
	deleteSubsidyOverview?: Maybe<SubsidyOverviewEntityResponse>;
	deleteSubsidyScale?: Maybe<SubsidyScaleEntityResponse>;
	deleteTag?: Maybe<TagEntityResponse>;
	deleteTargetGroup?: Maybe<TargetGroupEntityResponse>;
	deleteTheme?: Maybe<ThemeEntityResponse>;
	deleteThemeDetail?: Maybe<ThemeDetailEntityResponse>;
	deleteThemeOverview?: Maybe<ThemeOverviewEntityResponse>;
	deleteUpl?: Maybe<UplEntityResponse>;
	deleteUploadFile?: Maybe<UploadFileEntityResponse>;
	deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
	/** Delete an existing role */
	deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
	/** Delete an existing user */
	deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
	deleteVacancyDetail?: Maybe<VacancyDetailEntityResponse>;
	deleteVacancyInternalDetail?: Maybe<VacancyInternalDetailEntityResponse>;
	deleteVacancyInternalOverview?: Maybe<VacancyInternalOverviewEntityResponse>;
	deleteVacancyOverview?: Maybe<VacancyOverviewEntityResponse>;
	deleteWobRequest?: Maybe<WobRequestEntityResponse>;
	deleteWobRequestOverview?: Maybe<WobRequestOverviewEntityResponse>;
	deleteWooCategory?: Maybe<WooCategoryEntityResponse>;
	deleteWooDocumentDetail?: Maybe<WooDocumentDetailEntityResponse>;
	deleteWooRequest?: Maybe<WooRequestEntityResponse>;
	deleteWooRequestOverview?: Maybe<WooRequestOverviewEntityResponse>;
	deleteWorkAt?: Maybe<WorkAtEntityResponse>;
	/** Confirm an email users email address */
	emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
	/** Request a reset password token */
	forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
	login: UsersPermissionsLoginPayload;
	multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
	/** Register a user */
	register: UsersPermissionsLoginPayload;
	removeFile?: Maybe<UploadFileEntityResponse>;
	/** Reset user password. Confirm with a code (resetToken from forgotPassword) */
	resetPassword?: Maybe<UsersPermissionsLoginPayload>;
	updateAgendaPage?: Maybe<AgendaPageEntityResponse>;
	updateAuditLogAuditLog?: Maybe<AuditLogAuditLogEntityResponse>;
	updateCondoleanceHomepage?: Maybe<CondoleanceHomepageEntityResponse>;
	updateCondoleanceInformation?: Maybe<CondoleanceInformationEntityResponse>;
	updateCondoleancePage?: Maybe<CondoleancePageEntityResponse>;
	updateContact?: Maybe<ContactEntityResponse>;
	updateConversationsConversationitem?: Maybe<ConversationsConversationitemEntityResponse>;
	updateConversationsConversations?: Maybe<ConversationsConversationsEntityResponse>;
	updateCookiePage?: Maybe<CookiePageEntityResponse>;
	updateDecisionsPage?: Maybe<DecisionsPageEntityResponse>;
	updateDownloadsPage?: Maybe<DownloadsPageEntityResponse>;
	updateEerbeekloenenHomepage?: Maybe<EerbeekloenenHomepageEntityResponse>;
	updateEerbeekloenenInConvoArticle?: Maybe<EerbeekloenenInConvoArticleEntityResponse>;
	updateEerbeekloenenInConvoOverview?: Maybe<EerbeekloenenInConvoOverviewEntityResponse>;
	updateEerbeekloenenInConvoWithDetail?: Maybe<EerbeekloenenInConvoWithDetailEntityResponse>;
	updateEerbeekloenenInformation?: Maybe<EerbeekloenenInformationEntityResponse>;
	updateEerbeekloenenNewsDetail?: Maybe<EerbeekloenenNewsDetailEntityResponse>;
	updateEerbeekloenenNewsOverview?: Maybe<EerbeekloenenNewsOverviewEntityResponse>;
	updateEerbeekloenenNewsarticle?: Maybe<EerbeekloenenNewsarticleEntityResponse>;
	updateEerbeekloenenPage?: Maybe<EerbeekloenenPageEntityResponse>;
	updateEfroOostHomepage?: Maybe<EfroOostHomepageEntityResponse>;
	updateEfroOostInConvoWithArticle?: Maybe<EfroOostInConvoWithArticleEntityResponse>;
	updateEfroOostInConvoWithDetail?: Maybe<EfroOostInConvoWithDetailEntityResponse>;
	updateEfroOostInConvoWithOverview?: Maybe<EfroOostInConvoWithOverviewEntityResponse>;
	updateEfroOostInformation?: Maybe<EfroOostInformationEntityResponse>;
	updateEfroOostNewsDetail?: Maybe<EfroOostNewsDetailEntityResponse>;
	updateEfroOostNewsOverview?: Maybe<EfroOostNewsOverviewEntityResponse>;
	updateEfroOostNewsarticle?: Maybe<EfroOostNewsarticleEntityResponse>;
	updateEfroOostPage?: Maybe<EfroOostPageEntityResponse>;
	updateEvent?: Maybe<EventEntityResponse>;
	updateEventOverview?: Maybe<EventOverviewEntityResponse>;
	updateFileInfo: UploadFileEntityResponse;
	updateGeolinkHomepage?: Maybe<GeolinkHomepageEntityResponse>;
	updateGeolinkInformation?: Maybe<GeolinkInformationEntityResponse>;
	updateGeolinkPage?: Maybe<GeolinkPageEntityResponse>;
	updateHomePage?: Maybe<HomePageEntityResponse>;
	updateInConvoWithArticle?: Maybe<InConvoWithArticleEntityResponse>;
	updateInConvoWithDetail?: Maybe<InConvoWithDetailEntityResponse>;
	updateInConvoWithOverview?: Maybe<InConvoWithOverviewEntityResponse>;
	updateInternalLinkInternalLink?: Maybe<InternalLinkInternalLinkEntityResponse>;
	updateInternalLinkInternalLinkWysiwyg?: Maybe<InternalLinkInternalLinkWysiwygEntityResponse>;
	updateLogin?: Maybe<LoginEntityResponse>;
	updateMapsFigure?: Maybe<MapsFigureEntityResponse>;
	updateMapsFiguresOverview?: Maybe<MapsFiguresOverviewEntityResponse>;
	updateNewsDetail?: Maybe<NewsDetailEntityResponse>;
	updateNewsOverview?: Maybe<NewsOverviewEntityResponse>;
	updateNewsarticle?: Maybe<NewsarticleEntityResponse>;
	updateNewsletter?: Maybe<NewsletterEntityResponse>;
	updateNewsletterArchiveOverview?: Maybe<NewsletterArchiveOverviewEntityResponse>;
	updateNewsletterInternal?: Maybe<NewsletterInternalEntityResponse>;
	updateNewsletterPublic?: Maybe<NewsletterPublicEntityResponse>;
	updateNotFoundPage?: Maybe<NotFoundPageEntityResponse>;
	updateNoteNote?: Maybe<NoteNoteEntityResponse>;
	updateNoteNoterelation?: Maybe<NoteNoterelationEntityResponse>;
	updateOpOostHomepage?: Maybe<OpOostHomepageEntityResponse>;
	updateOpOostInConvoWithArticle?: Maybe<OpOostInConvoWithArticleEntityResponse>;
	updateOpOostInConvoWithDetail?: Maybe<OpOostInConvoWithDetailEntityResponse>;
	updateOpOostInConvoWithOverview?: Maybe<OpOostInConvoWithOverviewEntityResponse>;
	updateOpOostInformation?: Maybe<OpOostInformationEntityResponse>;
	updateOpOostNewsDetail?: Maybe<OpOostNewsDetailEntityResponse>;
	updateOpOostNewsOverview?: Maybe<OpOostNewsOverviewEntityResponse>;
	updateOpOostNewsarticle?: Maybe<OpOostNewsarticleEntityResponse>;
	updateOpOostPage?: Maybe<OpOostPageEntityResponse>;
	updateOpenGelderlandHomepage?: Maybe<OpenGelderlandHomepageEntityResponse>;
	updateOpenGelderlandInformation?: Maybe<OpenGelderlandInformationEntityResponse>;
	updateOpenGelderlandWooOverview?: Maybe<OpenGelderlandWooOverviewEntityResponse>;
	updatePage?: Maybe<PageEntityResponse>;
	updatePageInformation?: Maybe<PageInformationEntityResponse>;
	updatePasswordPage?: Maybe<PasswordPageEntityResponse>;
	updatePermit?: Maybe<PermitEntityResponse>;
	updatePermitDetail?: Maybe<PermitDetailEntityResponse>;
	updatePermitLaw?: Maybe<PermitLawEntityResponse>;
	updatePermitProductType?: Maybe<PermitProductTypeEntityResponse>;
	updatePermitsOverview?: Maybe<PermitsOverviewEntityResponse>;
	updatePressRelease?: Maybe<PressReleaseEntityResponse>;
	updatePressReleaseDetail?: Maybe<PressReleaseDetailEntityResponse>;
	updatePressReleaseOverview?: Maybe<PressReleaseOverviewEntityResponse>;
	updatePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
	updateProject?: Maybe<ProjectEntityResponse>;
	updateProjectDetail?: Maybe<ProjectDetailEntityResponse>;
	updateProjectOverview?: Maybe<ProjectOverviewEntityResponse>;
	updateRecordLockingOpenEntity?: Maybe<RecordLockingOpenEntityEntityResponse>;
	updateRecycleBinRecycleBinItem?: Maybe<RecycleBinRecycleBinItemEntityResponse>;
	updateRegioExpresHomepage?: Maybe<RegioExpresHomepageEntityResponse>;
	updateRegioExpresInConvoOverview?: Maybe<RegioExpresInConvoOverviewEntityResponse>;
	updateRegioExpresInConvoWithArticle?: Maybe<RegioExpresInConvoWithArticleEntityResponse>;
	updateRegioExpresInConvoWithDetail?: Maybe<RegioExpresInConvoWithDetailEntityResponse>;
	updateRegioExpresInformation?: Maybe<RegioExpresInformationEntityResponse>;
	updateRegioExpresNewsDetail?: Maybe<RegioExpresNewsDetailEntityResponse>;
	updateRegioExpresNewsOverview?: Maybe<RegioExpresNewsOverviewEntityResponse>;
	updateRegioExpresNewsarticle?: Maybe<RegioExpresNewsarticleEntityResponse>;
	updateRegioExpresPage?: Maybe<RegioExpresPageEntityResponse>;
	updateRegion?: Maybe<RegionEntityResponse>;
	updateRegionDetail?: Maybe<RegionDetailEntityResponse>;
	updateRegionOverview?: Maybe<RegionOverviewEntityResponse>;
	updateRoadMaintenance?: Maybe<RoadMaintenanceEntityResponse>;
	updateRoadMaintenanceDetail?: Maybe<RoadMaintenanceDetailEntityResponse>;
	updateRoadMaintenanceOverview?: Maybe<RoadMaintenanceOverviewEntityResponse>;
	updateSchedulerScheduler?: Maybe<SchedulerSchedulerEntityResponse>;
	updateSdgLocatie?: Maybe<SdgLocatieEntityResponse>;
	updateSdgSdg?: Maybe<SdgSdgEntityResponse>;
	updateSdgUplLijst?: Maybe<SdgUplLijstEntityResponse>;
	updateSearchOverview?: Maybe<SearchOverviewEntityResponse>;
	updateSitemap?: Maybe<SitemapEntityResponse>;
	updateSubsidy?: Maybe<SubsidyEntityResponse>;
	updateSubsidyDetail?: Maybe<SubsidyDetailEntityResponse>;
	updateSubsidyKind?: Maybe<SubsidyKindEntityResponse>;
	updateSubsidyOverview?: Maybe<SubsidyOverviewEntityResponse>;
	updateSubsidyScale?: Maybe<SubsidyScaleEntityResponse>;
	updateTag?: Maybe<TagEntityResponse>;
	updateTargetGroup?: Maybe<TargetGroupEntityResponse>;
	updateTheme?: Maybe<ThemeEntityResponse>;
	updateThemeDetail?: Maybe<ThemeDetailEntityResponse>;
	updateThemeOverview?: Maybe<ThemeOverviewEntityResponse>;
	updateUpl?: Maybe<UplEntityResponse>;
	updateUploadFile?: Maybe<UploadFileEntityResponse>;
	updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
	updateUserNewsletterSubscription?: Maybe<Array<Maybe<NewsletterSubscriptions>>>;
	/** Update an existing role */
	updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
	/** Update an existing user */
	updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
	updateVacancyDetail?: Maybe<VacancyDetailEntityResponse>;
	updateVacancyInternalDetail?: Maybe<VacancyInternalDetailEntityResponse>;
	updateVacancyInternalOverview?: Maybe<VacancyInternalOverviewEntityResponse>;
	updateVacancyOverview?: Maybe<VacancyOverviewEntityResponse>;
	updateWobRequest?: Maybe<WobRequestEntityResponse>;
	updateWobRequestOverview?: Maybe<WobRequestOverviewEntityResponse>;
	updateWooCategory?: Maybe<WooCategoryEntityResponse>;
	updateWooDocumentDetail?: Maybe<WooDocumentDetailEntityResponse>;
	updateWooRequest?: Maybe<WooRequestEntityResponse>;
	updateWooRequestOverview?: Maybe<WooRequestOverviewEntityResponse>;
	updateWorkAt?: Maybe<WorkAtEntityResponse>;
	upload: UploadFileEntityResponse;
};

export type MutationAddCommentArgs = {
	author?: InputMaybe<Scalars['String']>;
	contentTypeId?: InputMaybe<Scalars['Int']>;
	contentTypeUid?: InputMaybe<Scalars['String']>;
	message?: InputMaybe<Scalars['String']>;
};

export type MutationAddUserToNewslettersArgs = {
	campaignId: Scalars['String'];
	email: Scalars['String'];
	newsletters: Array<InputMaybe<NewslettersListInput>>;
};

export type MutationChangePasswordArgs = {
	currentPassword: Scalars['String'];
	password: Scalars['String'];
	passwordConfirmation: Scalars['String'];
};

export type MutationCreateAgendaPageLocalizationArgs = {
	data?: InputMaybe<AgendaPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateAuditLogAuditLogArgs = {
	data: AuditLogAuditLogInput;
};

export type MutationCreateCondoleanceHomepageLocalizationArgs = {
	data?: InputMaybe<CondoleanceHomepageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateCondoleanceInformationLocalizationArgs = {
	data?: InputMaybe<CondoleanceInformationInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateCondoleancePageArgs = {
	data: CondoleancePageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateCondoleancePageLocalizationArgs = {
	data?: InputMaybe<CondoleancePageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateContactLocalizationArgs = {
	data?: InputMaybe<ContactInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateConversationsConversationitemArgs = {
	data: ConversationsConversationitemInput;
};

export type MutationCreateConversationsConversationsArgs = {
	data: ConversationsConversationsInput;
};

export type MutationCreateCookiePageLocalizationArgs = {
	data?: InputMaybe<CookiePageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateDecisionsPageLocalizationArgs = {
	data?: InputMaybe<DecisionsPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateDownloadsPageLocalizationArgs = {
	data?: InputMaybe<DownloadsPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenHomepageLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenHomepageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenInConvoArticleArgs = {
	data: EerbeekloenenInConvoArticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenInConvoArticleLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenInConvoArticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenInConvoOverviewLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenInConvoOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenInConvoWithDetailLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenInConvoWithDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenInformationLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenInformationInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenNewsDetailLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenNewsDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenNewsOverviewLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenNewsOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenNewsarticleArgs = {
	data: EerbeekloenenNewsarticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenNewsarticleLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenNewsarticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenPageArgs = {
	data: EerbeekloenenPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEerbeekloenenPageLocalizationArgs = {
	data?: InputMaybe<EerbeekloenenPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostHomepageLocalizationArgs = {
	data?: InputMaybe<EfroOostHomepageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostInConvoWithArticleArgs = {
	data: EfroOostInConvoWithArticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostInConvoWithArticleLocalizationArgs = {
	data?: InputMaybe<EfroOostInConvoWithArticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostInConvoWithDetailLocalizationArgs = {
	data?: InputMaybe<EfroOostInConvoWithDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostInConvoWithOverviewLocalizationArgs = {
	data?: InputMaybe<EfroOostInConvoWithOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostInformationLocalizationArgs = {
	data?: InputMaybe<EfroOostInformationInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostNewsDetailLocalizationArgs = {
	data?: InputMaybe<EfroOostNewsDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostNewsOverviewLocalizationArgs = {
	data?: InputMaybe<EfroOostNewsOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostNewsarticleArgs = {
	data: EfroOostNewsarticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostNewsarticleLocalizationArgs = {
	data?: InputMaybe<EfroOostNewsarticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostPageArgs = {
	data: EfroOostPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEfroOostPageLocalizationArgs = {
	data?: InputMaybe<EfroOostPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEventArgs = {
	data: EventInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEventLocalizationArgs = {
	data?: InputMaybe<EventInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateEventOverviewLocalizationArgs = {
	data?: InputMaybe<EventOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateGeolinkHomepageLocalizationArgs = {
	data?: InputMaybe<GeolinkHomepageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateGeolinkInformationLocalizationArgs = {
	data?: InputMaybe<GeolinkInformationInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateGeolinkPageArgs = {
	data: GeolinkPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateGeolinkPageLocalizationArgs = {
	data?: InputMaybe<GeolinkPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateHomePageLocalizationArgs = {
	data?: InputMaybe<HomePageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateInConvoWithArticleArgs = {
	data: InConvoWithArticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateInConvoWithArticleLocalizationArgs = {
	data?: InputMaybe<InConvoWithArticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateInConvoWithDetailLocalizationArgs = {
	data?: InputMaybe<InConvoWithDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateInConvoWithOverviewLocalizationArgs = {
	data?: InputMaybe<InConvoWithOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateInternalLinkInternalLinkArgs = {
	data: InternalLinkInternalLinkInput;
};

export type MutationCreateInternalLinkInternalLinkWysiwygArgs = {
	data: InternalLinkInternalLinkWysiwygInput;
};

export type MutationCreateLoginLocalizationArgs = {
	data?: InputMaybe<LoginInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateMapsFigureArgs = {
	data: MapsFigureInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateMapsFigureLocalizationArgs = {
	data?: InputMaybe<MapsFigureInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateMapsFiguresOverviewLocalizationArgs = {
	data?: InputMaybe<MapsFiguresOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNewsDetailLocalizationArgs = {
	data?: InputMaybe<NewsDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNewsOverviewLocalizationArgs = {
	data?: InputMaybe<NewsOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNewsarticleArgs = {
	data: NewsarticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNewsarticleLocalizationArgs = {
	data?: InputMaybe<NewsarticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNewsletterArchiveOverviewLocalizationArgs = {
	data?: InputMaybe<NewsletterArchiveOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNewsletterInternalLocalizationArgs = {
	data?: InputMaybe<NewsletterInternalInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNewsletterPublicLocalizationArgs = {
	data?: InputMaybe<NewsletterPublicInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNotFoundPageLocalizationArgs = {
	data?: InputMaybe<NotFoundPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateNoteNoteArgs = {
	data: NoteNoteInput;
};

export type MutationCreateNoteNoterelationArgs = {
	data: NoteNoterelationInput;
};

export type MutationCreateOpOostHomepageLocalizationArgs = {
	data?: InputMaybe<OpOostHomepageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostInConvoWithArticleArgs = {
	data: OpOostInConvoWithArticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostInConvoWithArticleLocalizationArgs = {
	data?: InputMaybe<OpOostInConvoWithArticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostInConvoWithDetailLocalizationArgs = {
	data?: InputMaybe<OpOostInConvoWithDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostInConvoWithOverviewLocalizationArgs = {
	data?: InputMaybe<OpOostInConvoWithOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostInformationLocalizationArgs = {
	data?: InputMaybe<OpOostInformationInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostNewsDetailLocalizationArgs = {
	data?: InputMaybe<OpOostNewsDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostNewsOverviewLocalizationArgs = {
	data?: InputMaybe<OpOostNewsOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostNewsarticleArgs = {
	data: OpOostNewsarticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostNewsarticleLocalizationArgs = {
	data?: InputMaybe<OpOostNewsarticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostPageArgs = {
	data: OpOostPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpOostPageLocalizationArgs = {
	data?: InputMaybe<OpOostPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpenGelderlandHomepageLocalizationArgs = {
	data?: InputMaybe<OpenGelderlandHomepageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpenGelderlandInformationLocalizationArgs = {
	data?: InputMaybe<OpenGelderlandInformationInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateOpenGelderlandWooOverviewLocalizationArgs = {
	data?: InputMaybe<OpenGelderlandWooOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePageArgs = {
	data: PageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePageInformationLocalizationArgs = {
	data?: InputMaybe<PageInformationInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePageLocalizationArgs = {
	data?: InputMaybe<PageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePasswordPageLocalizationArgs = {
	data?: InputMaybe<PasswordPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePermitArgs = {
	data: PermitInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePermitDetailLocalizationArgs = {
	data?: InputMaybe<PermitDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePermitLawArgs = {
	data: PermitLawInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePermitLawLocalizationArgs = {
	data?: InputMaybe<PermitLawInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePermitLocalizationArgs = {
	data?: InputMaybe<PermitInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePermitProductTypeArgs = {
	data: PermitProductTypeInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePermitProductTypeLocalizationArgs = {
	data?: InputMaybe<PermitProductTypeInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePermitsOverviewLocalizationArgs = {
	data?: InputMaybe<PermitsOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePressReleaseArgs = {
	data: PressReleaseInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePressReleaseDetailLocalizationArgs = {
	data?: InputMaybe<PressReleaseDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePressReleaseLocalizationArgs = {
	data?: InputMaybe<PressReleaseInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePressReleaseOverviewLocalizationArgs = {
	data?: InputMaybe<PressReleaseOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreatePrivacyPolicyLocalizationArgs = {
	data?: InputMaybe<PrivacyPolicyInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateProjectArgs = {
	data: ProjectInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateProjectDetailLocalizationArgs = {
	data?: InputMaybe<ProjectDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateProjectLocalizationArgs = {
	data?: InputMaybe<ProjectInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateProjectOverviewLocalizationArgs = {
	data?: InputMaybe<ProjectOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRecordLockingOpenEntityArgs = {
	data: RecordLockingOpenEntityInput;
};

export type MutationCreateRecycleBinRecycleBinItemArgs = {
	data: RecycleBinRecycleBinItemInput;
};

export type MutationCreateRegioExpresHomepageLocalizationArgs = {
	data?: InputMaybe<RegioExpresHomepageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresInConvoOverviewLocalizationArgs = {
	data?: InputMaybe<RegioExpresInConvoOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresInConvoWithArticleArgs = {
	data: RegioExpresInConvoWithArticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresInConvoWithArticleLocalizationArgs = {
	data?: InputMaybe<RegioExpresInConvoWithArticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresInConvoWithDetailLocalizationArgs = {
	data?: InputMaybe<RegioExpresInConvoWithDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresInformationLocalizationArgs = {
	data?: InputMaybe<RegioExpresInformationInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresNewsDetailLocalizationArgs = {
	data?: InputMaybe<RegioExpresNewsDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresNewsOverviewLocalizationArgs = {
	data?: InputMaybe<RegioExpresNewsOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresNewsarticleArgs = {
	data: RegioExpresNewsarticleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresNewsarticleLocalizationArgs = {
	data?: InputMaybe<RegioExpresNewsarticleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresPageArgs = {
	data: RegioExpresPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegioExpresPageLocalizationArgs = {
	data?: InputMaybe<RegioExpresPageInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegionArgs = {
	data: RegionInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegionDetailLocalizationArgs = {
	data?: InputMaybe<RegionDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegionLocalizationArgs = {
	data?: InputMaybe<RegionInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRegionOverviewLocalizationArgs = {
	data?: InputMaybe<RegionOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRoadMaintenanceArgs = {
	data: RoadMaintenanceInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRoadMaintenanceDetailLocalizationArgs = {
	data?: InputMaybe<RoadMaintenanceDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRoadMaintenanceLocalizationArgs = {
	data?: InputMaybe<RoadMaintenanceInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateRoadMaintenanceOverviewLocalizationArgs = {
	data?: InputMaybe<RoadMaintenanceOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSchedulerSchedulerArgs = {
	data: SchedulerSchedulerInput;
};

export type MutationCreateSdgLocatieArgs = {
	data: SdgLocatieInput;
};

export type MutationCreateSdgSdgArgs = {
	data: SdgSdgInput;
};

export type MutationCreateSdgUplLijstArgs = {
	data: SdgUplLijstInput;
};

export type MutationCreateSearchOverviewLocalizationArgs = {
	data?: InputMaybe<SearchOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSitemapLocalizationArgs = {
	data?: InputMaybe<SitemapInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSubsidyArgs = {
	data: SubsidyInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSubsidyDetailLocalizationArgs = {
	data?: InputMaybe<SubsidyDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSubsidyKindArgs = {
	data: SubsidyKindInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSubsidyKindLocalizationArgs = {
	data?: InputMaybe<SubsidyKindInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSubsidyLocalizationArgs = {
	data?: InputMaybe<SubsidyInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSubsidyOverviewLocalizationArgs = {
	data?: InputMaybe<SubsidyOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSubsidyScaleArgs = {
	data: SubsidyScaleInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateSubsidyScaleLocalizationArgs = {
	data?: InputMaybe<SubsidyScaleInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateTagArgs = {
	data: TagInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateTagLocalizationArgs = {
	data?: InputMaybe<TagInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateTargetGroupArgs = {
	data: TargetGroupInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateTargetGroupLocalizationArgs = {
	data?: InputMaybe<TargetGroupInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateThemeArgs = {
	data: ThemeInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateThemeDetailLocalizationArgs = {
	data?: InputMaybe<ThemeDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateThemeLocalizationArgs = {
	data?: InputMaybe<ThemeInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateThemeOverviewLocalizationArgs = {
	data?: InputMaybe<ThemeOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateUplArgs = {
	data: UplInput;
};

export type MutationCreateUploadFileArgs = {
	data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
	data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
	data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
	data: UsersPermissionsUserInput;
};

export type MutationCreateVacancyDetailLocalizationArgs = {
	data?: InputMaybe<VacancyDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateVacancyInternalDetailLocalizationArgs = {
	data?: InputMaybe<VacancyInternalDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateVacancyInternalOverviewLocalizationArgs = {
	data?: InputMaybe<VacancyInternalOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateVacancyOverviewLocalizationArgs = {
	data?: InputMaybe<VacancyOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateWobRequestArgs = {
	data: WobRequestInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateWobRequestLocalizationArgs = {
	data?: InputMaybe<WobRequestInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateWobRequestOverviewLocalizationArgs = {
	data?: InputMaybe<WobRequestOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateWooCategoryArgs = {
	data: WooCategoryInput;
};

export type MutationCreateWooDocumentDetailLocalizationArgs = {
	data?: InputMaybe<WooDocumentDetailInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateWooRequestArgs = {
	data: WooRequestInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateWooRequestLocalizationArgs = {
	data?: InputMaybe<WooRequestInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateWooRequestOverviewLocalizationArgs = {
	data?: InputMaybe<WooRequestOverviewInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationCreateWorkAtLocalizationArgs = {
	data?: InputMaybe<WorkAtInput>;
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteAgendaPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteAuditLogAuditLogArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteCondoleanceHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteCondoleanceInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteCondoleancePageArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteContactArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteConversationsConversationitemArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteConversationsConversationsArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteCookiePageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteDecisionsPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteDownloadsPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenInConvoArticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenInConvoOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenNewsarticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEerbeekloenenPageArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostInConvoWithArticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostInConvoWithOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostNewsarticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEfroOostPageArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEventArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteEventOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteGeolinkHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteGeolinkInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteGeolinkPageArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteHomePageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteInConvoWithArticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteInConvoWithOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteInternalLinkInternalLinkArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteInternalLinkInternalLinkWysiwygArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteLoginArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteMapsFigureArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteMapsFiguresOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteNewsarticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteNewsletterArchiveOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteNewsletterInternalArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteNewsletterPublicArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteNotFoundPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteNoteNoteArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteNoteNoterelationArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteOpOostHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpOostInConvoWithArticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpOostInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpOostInConvoWithOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpOostInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpOostNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpOostNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpOostNewsarticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpOostPageArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpenGelderlandHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpenGelderlandInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteOpenGelderlandWooOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePageArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePageInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePasswordPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePermitArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePermitDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePermitLawArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePermitProductTypeArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePermitsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePressReleaseArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePressReleaseDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePressReleaseOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeletePrivacyPolicyArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteProjectArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteProjectDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteProjectOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRecordLockingOpenEntityArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteRecycleBinRecycleBinItemArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteRegioExpresHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegioExpresInConvoOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegioExpresInConvoWithArticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegioExpresInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegioExpresInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegioExpresNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegioExpresNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegioExpresNewsarticleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegioExpresPageArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegionArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegionDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRegionOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRoadMaintenanceArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRoadMaintenanceDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteRoadMaintenanceOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteSchedulerSchedulerArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteSdgLocatieArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteSdgSdgArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteSdgUplLijstArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteSearchOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteSitemapArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteSubsidyArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteSubsidyDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteSubsidyKindArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteSubsidyOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteSubsidyScaleArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteTagArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteTargetGroupArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteThemeArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteThemeDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteThemeOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteUplArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteUploadFileArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteUploadFolderArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteVacancyDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteVacancyInternalDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteVacancyInternalOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteVacancyOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteWobRequestArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteWobRequestOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteWooCategoryArgs = {
	id: Scalars['ID'];
};

export type MutationDeleteWooDocumentDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteWooRequestArgs = {
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteWooRequestOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationDeleteWorkAtArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationEmailConfirmationArgs = {
	confirmation: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
	email: Scalars['String'];
};

export type MutationLoginArgs = {
	input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
	field?: InputMaybe<Scalars['String']>;
	files: Array<InputMaybe<Scalars['Upload']>>;
	ref?: InputMaybe<Scalars['String']>;
	refId?: InputMaybe<Scalars['ID']>;
};

export type MutationRegisterArgs = {
	input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
	id: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
	code: Scalars['String'];
	password: Scalars['String'];
	passwordConfirmation: Scalars['String'];
};

export type MutationUpdateAgendaPageArgs = {
	data: AgendaPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateAuditLogAuditLogArgs = {
	data: AuditLogAuditLogInput;
	id: Scalars['ID'];
};

export type MutationUpdateCondoleanceHomepageArgs = {
	data: CondoleanceHomepageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateCondoleanceInformationArgs = {
	data: CondoleanceInformationInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateCondoleancePageArgs = {
	data: CondoleancePageInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateContactArgs = {
	data: ContactInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateConversationsConversationitemArgs = {
	data: ConversationsConversationitemInput;
	id: Scalars['ID'];
};

export type MutationUpdateConversationsConversationsArgs = {
	data: ConversationsConversationsInput;
	id: Scalars['ID'];
};

export type MutationUpdateCookiePageArgs = {
	data: CookiePageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateDecisionsPageArgs = {
	data: DecisionsPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateDownloadsPageArgs = {
	data: DownloadsPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenHomepageArgs = {
	data: EerbeekloenenHomepageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenInConvoArticleArgs = {
	data: EerbeekloenenInConvoArticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenInConvoOverviewArgs = {
	data: EerbeekloenenInConvoOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenInConvoWithDetailArgs = {
	data: EerbeekloenenInConvoWithDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenInformationArgs = {
	data: EerbeekloenenInformationInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenNewsDetailArgs = {
	data: EerbeekloenenNewsDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenNewsOverviewArgs = {
	data: EerbeekloenenNewsOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenNewsarticleArgs = {
	data: EerbeekloenenNewsarticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEerbeekloenenPageArgs = {
	data: EerbeekloenenPageInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostHomepageArgs = {
	data: EfroOostHomepageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostInConvoWithArticleArgs = {
	data: EfroOostInConvoWithArticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostInConvoWithDetailArgs = {
	data: EfroOostInConvoWithDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostInConvoWithOverviewArgs = {
	data: EfroOostInConvoWithOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostInformationArgs = {
	data: EfroOostInformationInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostNewsDetailArgs = {
	data: EfroOostNewsDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostNewsOverviewArgs = {
	data: EfroOostNewsOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostNewsarticleArgs = {
	data: EfroOostNewsarticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEfroOostPageArgs = {
	data: EfroOostPageInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEventArgs = {
	data: EventInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateEventOverviewArgs = {
	data: EventOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateFileInfoArgs = {
	id: Scalars['ID'];
	info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateGeolinkHomepageArgs = {
	data: GeolinkHomepageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateGeolinkInformationArgs = {
	data: GeolinkInformationInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateGeolinkPageArgs = {
	data: GeolinkPageInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateHomePageArgs = {
	data: HomePageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateInConvoWithArticleArgs = {
	data: InConvoWithArticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateInConvoWithDetailArgs = {
	data: InConvoWithDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateInConvoWithOverviewArgs = {
	data: InConvoWithOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateInternalLinkInternalLinkArgs = {
	data: InternalLinkInternalLinkInput;
	id: Scalars['ID'];
};

export type MutationUpdateInternalLinkInternalLinkWysiwygArgs = {
	data: InternalLinkInternalLinkWysiwygInput;
	id: Scalars['ID'];
};

export type MutationUpdateLoginArgs = {
	data: LoginInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateMapsFigureArgs = {
	data: MapsFigureInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateMapsFiguresOverviewArgs = {
	data: MapsFiguresOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateNewsDetailArgs = {
	data: NewsDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateNewsOverviewArgs = {
	data: NewsOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateNewsarticleArgs = {
	data: NewsarticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateNewsletterArgs = {
	data: NewsletterInput;
};

export type MutationUpdateNewsletterArchiveOverviewArgs = {
	data: NewsletterArchiveOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateNewsletterInternalArgs = {
	data: NewsletterInternalInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateNewsletterPublicArgs = {
	data: NewsletterPublicInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateNotFoundPageArgs = {
	data: NotFoundPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateNoteNoteArgs = {
	data: NoteNoteInput;
	id: Scalars['ID'];
};

export type MutationUpdateNoteNoterelationArgs = {
	data: NoteNoterelationInput;
	id: Scalars['ID'];
};

export type MutationUpdateOpOostHomepageArgs = {
	data: OpOostHomepageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpOostInConvoWithArticleArgs = {
	data: OpOostInConvoWithArticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpOostInConvoWithDetailArgs = {
	data: OpOostInConvoWithDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpOostInConvoWithOverviewArgs = {
	data: OpOostInConvoWithOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpOostInformationArgs = {
	data: OpOostInformationInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpOostNewsDetailArgs = {
	data: OpOostNewsDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpOostNewsOverviewArgs = {
	data: OpOostNewsOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpOostNewsarticleArgs = {
	data: OpOostNewsarticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpOostPageArgs = {
	data: OpOostPageInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpenGelderlandHomepageArgs = {
	data: OpenGelderlandHomepageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpenGelderlandInformationArgs = {
	data: OpenGelderlandInformationInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateOpenGelderlandWooOverviewArgs = {
	data: OpenGelderlandWooOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePageArgs = {
	data: PageInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePageInformationArgs = {
	data: PageInformationInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePasswordPageArgs = {
	data: PasswordPageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePermitArgs = {
	data: PermitInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePermitDetailArgs = {
	data: PermitDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePermitLawArgs = {
	data: PermitLawInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePermitProductTypeArgs = {
	data: PermitProductTypeInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePermitsOverviewArgs = {
	data: PermitsOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePressReleaseArgs = {
	data: PressReleaseInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePressReleaseDetailArgs = {
	data: PressReleaseDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePressReleaseOverviewArgs = {
	data: PressReleaseOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdatePrivacyPolicyArgs = {
	data: PrivacyPolicyInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateProjectArgs = {
	data: ProjectInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateProjectDetailArgs = {
	data: ProjectDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateProjectOverviewArgs = {
	data: ProjectOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRecordLockingOpenEntityArgs = {
	data: RecordLockingOpenEntityInput;
	id: Scalars['ID'];
};

export type MutationUpdateRecycleBinRecycleBinItemArgs = {
	data: RecycleBinRecycleBinItemInput;
	id: Scalars['ID'];
};

export type MutationUpdateRegioExpresHomepageArgs = {
	data: RegioExpresHomepageInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegioExpresInConvoOverviewArgs = {
	data: RegioExpresInConvoOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegioExpresInConvoWithArticleArgs = {
	data: RegioExpresInConvoWithArticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegioExpresInConvoWithDetailArgs = {
	data: RegioExpresInConvoWithDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegioExpresInformationArgs = {
	data: RegioExpresInformationInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegioExpresNewsDetailArgs = {
	data: RegioExpresNewsDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegioExpresNewsOverviewArgs = {
	data: RegioExpresNewsOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegioExpresNewsarticleArgs = {
	data: RegioExpresNewsarticleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegioExpresPageArgs = {
	data: RegioExpresPageInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegionArgs = {
	data: RegionInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegionDetailArgs = {
	data: RegionDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRegionOverviewArgs = {
	data: RegionOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRoadMaintenanceArgs = {
	data: RoadMaintenanceInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRoadMaintenanceDetailArgs = {
	data: RoadMaintenanceDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateRoadMaintenanceOverviewArgs = {
	data: RoadMaintenanceOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateSchedulerSchedulerArgs = {
	data: SchedulerSchedulerInput;
	id: Scalars['ID'];
};

export type MutationUpdateSdgLocatieArgs = {
	data: SdgLocatieInput;
	id: Scalars['ID'];
};

export type MutationUpdateSdgSdgArgs = {
	data: SdgSdgInput;
	id: Scalars['ID'];
};

export type MutationUpdateSdgUplLijstArgs = {
	data: SdgUplLijstInput;
	id: Scalars['ID'];
};

export type MutationUpdateSearchOverviewArgs = {
	data: SearchOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateSitemapArgs = {
	data: SitemapInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateSubsidyArgs = {
	data: SubsidyInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateSubsidyDetailArgs = {
	data: SubsidyDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateSubsidyKindArgs = {
	data: SubsidyKindInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateSubsidyOverviewArgs = {
	data: SubsidyOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateSubsidyScaleArgs = {
	data: SubsidyScaleInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateTagArgs = {
	data: TagInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateTargetGroupArgs = {
	data: TargetGroupInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateThemeArgs = {
	data: ThemeInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateThemeDetailArgs = {
	data: ThemeDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateThemeOverviewArgs = {
	data: ThemeOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateUplArgs = {
	data: UplInput;
	id: Scalars['ID'];
};

export type MutationUpdateUploadFileArgs = {
	data: UploadFileInput;
	id: Scalars['ID'];
};

export type MutationUpdateUploadFolderArgs = {
	data: UploadFolderInput;
	id: Scalars['ID'];
};

export type MutationUpdateUserNewsletterSubscriptionArgs = {
	campaignId: Scalars['String'];
	email: Scalars['String'];
	newsletters: Array<InputMaybe<NewslettersListInput>>;
	userId: Scalars['String'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
	data: UsersPermissionsRoleInput;
	id: Scalars['ID'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
	data: UsersPermissionsUserInput;
	id: Scalars['ID'];
};

export type MutationUpdateVacancyDetailArgs = {
	data: VacancyDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateVacancyInternalDetailArgs = {
	data: VacancyInternalDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateVacancyInternalOverviewArgs = {
	data: VacancyInternalOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateVacancyOverviewArgs = {
	data: VacancyOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateWobRequestArgs = {
	data: WobRequestInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateWobRequestOverviewArgs = {
	data: WobRequestOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateWooCategoryArgs = {
	data: WooCategoryInput;
	id: Scalars['ID'];
};

export type MutationUpdateWooDocumentDetailArgs = {
	data: WooDocumentDetailInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateWooRequestArgs = {
	data: WooRequestInput;
	id: Scalars['ID'];
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateWooRequestOverviewArgs = {
	data: WooRequestOverviewInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUpdateWorkAtArgs = {
	data: WorkAtInput;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type MutationUploadArgs = {
	field?: InputMaybe<Scalars['String']>;
	file: Scalars['Upload'];
	info?: InputMaybe<FileInfoInput>;
	ref?: InputMaybe<Scalars['String']>;
	refId?: InputMaybe<Scalars['ID']>;
};

export type NewsDetail = {
	__typename?: 'NewsDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<NewsDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<NewsDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type NewsDetailEntity = {
	__typename?: 'NewsDetailEntity';
	attributes?: Maybe<NewsDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type NewsDetailEntityResponse = {
	__typename?: 'NewsDetailEntityResponse';
	data?: Maybe<NewsDetailEntity>;
};

export type NewsDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['NewsDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesShare
	| ComponentModulesSubjects
	| Error;

export type NewsDetailRelationResponseCollection = {
	__typename?: 'NewsDetailRelationResponseCollection';
	data: Array<NewsDetailEntity>;
};

export type NewsOverview = {
	__typename?: 'NewsOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<NewsOverviewRelationResponseCollection>;
	modules: Array<Maybe<NewsOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type NewsOverviewEntity = {
	__typename?: 'NewsOverviewEntity';
	attributes?: Maybe<NewsOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type NewsOverviewEntityResponse = {
	__typename?: 'NewsOverviewEntityResponse';
	data?: Maybe<NewsOverviewEntity>;
};

export type NewsOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['NewsOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type NewsOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesNewsOverview
	| ComponentModulesPhotoAlbum
	| Error;

export type NewsOverviewRelationResponseCollection = {
	__typename?: 'NewsOverviewRelationResponseCollection';
	data: Array<NewsOverviewEntity>;
};

export type Newsarticle = {
	__typename?: 'Newsarticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	contactPerson?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<NewsarticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<NewsarticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	project?: Maybe<ProjectRelationResponseCollection>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	theme?: Maybe<ThemeRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsarticleLocalizationsArgs = {
	filters?: InputMaybe<NewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NewsarticleProjectArgs = {
	filters?: InputMaybe<ProjectFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NewsarticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NewsarticleThemeArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NewsarticleData = {
	__typename?: 'NewsarticleData';
	contactPerson: Scalars['String'];
};

export type NewsarticleEntity = {
	__typename?: 'NewsarticleEntity';
	attributes?: Maybe<Newsarticle>;
	id?: Maybe<Scalars['ID']>;
};

export type NewsarticleEntityResponse = {
	__typename?: 'NewsarticleEntityResponse';
	data?: Maybe<NewsarticleEntity>;
};

export type NewsarticleEntityResponseCollection = {
	__typename?: 'NewsarticleEntityResponseCollection';
	data: Array<NewsarticleEntity>;
	meta: ResponseCollectionMeta;
};

export type NewsarticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<NewsarticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	contactPerson?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<NewsarticleFiltersInput>;
	not?: InputMaybe<NewsarticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<NewsarticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	project?: InputMaybe<ProjectFiltersInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	theme?: InputMaybe<ThemeFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type NewsarticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	contactPerson?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['NewsarticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	project?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	theme?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type NewsarticleModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesShare
	| ComponentModulesTestimonial
	| Error;

export type NewsarticleRelationResponseCollection = {
	__typename?: 'NewsarticleRelationResponseCollection';
	data: Array<NewsarticleEntity>;
};

export type Newsletter = {
	__typename?: 'Newsletter';
	createdAt?: Maybe<Scalars['DateTime']>;
	expireTime?: Maybe<Scalars['DateTime']>;
	name: Scalars['String'];
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	token: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsletterArchiveOverview = {
	__typename?: 'NewsletterArchiveOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<NewsletterArchiveOverviewRelationResponseCollection>;
	modules: Array<Maybe<NewsletterArchiveOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsletterArchiveOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type NewsletterArchiveOverviewEntity = {
	__typename?: 'NewsletterArchiveOverviewEntity';
	attributes?: Maybe<NewsletterArchiveOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type NewsletterArchiveOverviewEntityResponse = {
	__typename?: 'NewsletterArchiveOverviewEntityResponse';
	data?: Maybe<NewsletterArchiveOverviewEntity>;
};

export type NewsletterArchiveOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['NewsletterArchiveOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type NewsletterArchiveOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesPermitsOverview
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| Error;

export type NewsletterArchiveOverviewRelationResponseCollection = {
	__typename?: 'NewsletterArchiveOverviewRelationResponseCollection';
	data: Array<NewsletterArchiveOverviewEntity>;
};

export type NewsletterCampaign = {
	__typename?: 'NewsletterCampaign';
	id: Scalars['String'];
	name?: Maybe<Scalars['String']>;
};

export type NewsletterContact = {
	__typename?: 'NewsletterContact';
	active?: Maybe<Scalars['Boolean']>;
	campaigns?: Maybe<Array<Maybe<CampaignNewsletterContact>>>;
	newsletters?: Maybe<Array<Maybe<CampaignNewsletterField>>>;
};

export type NewsletterContactData = {
	__typename?: 'NewsletterContactData';
	create_date: Scalars['String'];
	custom?: Maybe<Array<Maybe<NewsletterField>>>;
	email: Scalars['String'];
	id: Scalars['String'];
	lang?: Maybe<Scalars['String']>;
	mobile_nr?: Maybe<Scalars['String']>;
};

export type NewsletterData = {
	__typename?: 'NewsletterData';
	campaign?: Maybe<NewsletterCampaign>;
	newsletters?: Maybe<Array<Maybe<NewslettersList>>>;
};

export type NewsletterEntity = {
	__typename?: 'NewsletterEntity';
	attributes?: Maybe<Newsletter>;
	id?: Maybe<Scalars['ID']>;
};

export type NewsletterEntityResponse = {
	__typename?: 'NewsletterEntityResponse';
	data?: Maybe<NewsletterEntity>;
};

export type NewsletterField = {
	__typename?: 'NewsletterField';
	field: Scalars['String'];
	value?: Maybe<Scalars['String']>;
};

export type NewsletterInput = {
	expireTime?: InputMaybe<Scalars['DateTime']>;
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	token?: InputMaybe<Scalars['String']>;
};

export type NewsletterInternal = {
	__typename?: 'NewsletterInternal';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<NewsletterInternalRelationResponseCollection>;
	newsletter: ComponentInternalNewsletter;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsletterInternalLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type NewsletterInternalEntity = {
	__typename?: 'NewsletterInternalEntity';
	attributes?: Maybe<NewsletterInternal>;
	id?: Maybe<Scalars['ID']>;
};

export type NewsletterInternalEntityResponse = {
	__typename?: 'NewsletterInternalEntityResponse';
	data?: Maybe<NewsletterInternalEntity>;
};

export type NewsletterInternalInput = {
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	newsletter?: InputMaybe<ComponentInternalNewsletterInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type NewsletterInternalRelationResponseCollection = {
	__typename?: 'NewsletterInternalRelationResponseCollection';
	data: Array<NewsletterInternalEntity>;
};

export type NewsletterPublic = {
	__typename?: 'NewsletterPublic';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<NewsletterPublicRelationResponseCollection>;
	newsletter: ComponentInternalNewsletter;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsletterPublicLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type NewsletterPublicEntity = {
	__typename?: 'NewsletterPublicEntity';
	attributes?: Maybe<NewsletterPublic>;
	id?: Maybe<Scalars['ID']>;
};

export type NewsletterPublicEntityResponse = {
	__typename?: 'NewsletterPublicEntityResponse';
	data?: Maybe<NewsletterPublicEntity>;
};

export type NewsletterPublicInput = {
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	newsletter?: InputMaybe<ComponentInternalNewsletterInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type NewsletterPublicRelationResponseCollection = {
	__typename?: 'NewsletterPublicRelationResponseCollection';
	data: Array<NewsletterPublicEntity>;
};

export type NewsletterSubscriptions = {
	__typename?: 'NewsletterSubscriptions';
	campaign: Scalars['String'];
	succeeded: Scalars['Boolean'];
};

export type NewslettersList = {
	__typename?: 'NewslettersList';
	description?: Maybe<Scalars['String']>;
	field: Scalars['String'];
	value?: Maybe<Scalars['String']>;
};

export type NewslettersListInput = {
	field: Scalars['String'];
	value?: InputMaybe<Scalars['String']>;
};

export type NotFoundPage = {
	__typename?: 'NotFoundPage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	cta: ComponentModulesCta;
	header: ComponentModulesIntroHeader;
	links: ComponentModulesLinkBox;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<NotFoundPageRelationResponseCollection>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotFoundPageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type NotFoundPageEntity = {
	__typename?: 'NotFoundPageEntity';
	attributes?: Maybe<NotFoundPage>;
	id?: Maybe<Scalars['ID']>;
};

export type NotFoundPageEntityResponse = {
	__typename?: 'NotFoundPageEntityResponse';
	data?: Maybe<NotFoundPageEntity>;
};

export type NotFoundPageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	cta?: InputMaybe<ComponentModulesCtaInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	links?: InputMaybe<ComponentModulesLinkBoxInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NotFoundPageRelationResponseCollection = {
	__typename?: 'NotFoundPageRelationResponseCollection';
	data: Array<NotFoundPageEntity>;
};

export type NoteNote = {
	__typename?: 'NoteNote';
	createdAt?: Maybe<Scalars['DateTime']>;
	note?: Maybe<Scalars['String']>;
	noteAuthor?: Maybe<Scalars['String']>;
	pageAuthor?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NoteNoteEntity = {
	__typename?: 'NoteNoteEntity';
	attributes?: Maybe<NoteNote>;
	id?: Maybe<Scalars['ID']>;
};

export type NoteNoteEntityResponse = {
	__typename?: 'NoteNoteEntityResponse';
	data?: Maybe<NoteNoteEntity>;
};

export type NoteNoteEntityResponseCollection = {
	__typename?: 'NoteNoteEntityResponseCollection';
	data: Array<NoteNoteEntity>;
	meta: ResponseCollectionMeta;
};

export type NoteNoteFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<NoteNoteFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<NoteNoteFiltersInput>;
	note?: InputMaybe<StringFilterInput>;
	noteAuthor?: InputMaybe<StringFilterInput>;
	or?: InputMaybe<Array<InputMaybe<NoteNoteFiltersInput>>>;
	pageAuthor?: InputMaybe<StringFilterInput>;
	password?: InputMaybe<StringFilterInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type NoteNoteInput = {
	note?: InputMaybe<Scalars['String']>;
	noteAuthor?: InputMaybe<Scalars['String']>;
	pageAuthor?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type NoteNoterelation = {
	__typename?: 'NoteNoterelation';
	contentTypeEntryTitle?: Maybe<Scalars['String']>;
	contentTypeId?: Maybe<Scalars['Int']>;
	contentTypeTitle?: Maybe<Scalars['String']>;
	contentTypeUid?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	noteId?: Maybe<Scalars['Int']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NoteNoterelationEntity = {
	__typename?: 'NoteNoterelationEntity';
	attributes?: Maybe<NoteNoterelation>;
	id?: Maybe<Scalars['ID']>;
};

export type NoteNoterelationEntityResponse = {
	__typename?: 'NoteNoterelationEntityResponse';
	data?: Maybe<NoteNoterelationEntity>;
};

export type NoteNoterelationEntityResponseCollection = {
	__typename?: 'NoteNoterelationEntityResponseCollection';
	data: Array<NoteNoterelationEntity>;
	meta: ResponseCollectionMeta;
};

export type NoteNoterelationFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<NoteNoterelationFiltersInput>>>;
	contentTypeEntryTitle?: InputMaybe<StringFilterInput>;
	contentTypeId?: InputMaybe<IntFilterInput>;
	contentTypeTitle?: InputMaybe<StringFilterInput>;
	contentTypeUid?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<NoteNoterelationFiltersInput>;
	noteId?: InputMaybe<IntFilterInput>;
	or?: InputMaybe<Array<InputMaybe<NoteNoterelationFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type NoteNoterelationInput = {
	contentTypeEntryTitle?: InputMaybe<Scalars['String']>;
	contentTypeId?: InputMaybe<Scalars['Int']>;
	contentTypeTitle?: InputMaybe<Scalars['String']>;
	contentTypeUid?: InputMaybe<Scalars['String']>;
	noteId?: InputMaybe<Scalars['Int']>;
	password?: InputMaybe<Scalars['String']>;
};

export type NoteSearchResult = {
	__typename?: 'NoteSearchResult';
	createdAt?: Maybe<Scalars['String']>;
	excerpt?: Maybe<Scalars['String']>;
	fullPath?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
};

export type NoteSearchResults = {
	__typename?: 'NoteSearchResults';
	entities?: Maybe<Array<Maybe<NoteSearchResult>>>;
};

export type OpOostHomepage = {
	__typename?: 'OpOostHomepage';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesHomepageHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostHomepageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<OpOostHomepageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostHomepageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpOostHomepageEntity = {
	__typename?: 'OpOostHomepageEntity';
	attributes?: Maybe<OpOostHomepage>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostHomepageEntityResponse = {
	__typename?: 'OpOostHomepageEntityResponse';
	data?: Maybe<OpOostHomepageEntity>;
};

export type OpOostHomepageInput = {
	header?: InputMaybe<ComponentModulesHomepageHeaderInput>;
	modules?: InputMaybe<Array<Scalars['OpOostHomepageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type OpOostHomepageModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsOpOost
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type OpOostHomepageRelationResponseCollection = {
	__typename?: 'OpOostHomepageRelationResponseCollection';
	data: Array<OpOostHomepageEntity>;
};

export type OpOostInConvoWithArticle = {
	__typename?: 'OpOostInConvoWithArticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostInConvoWithArticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<OpOostInConvoWithArticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostInConvoWithArticleLocalizationsArgs = {
	filters?: InputMaybe<OpOostInConvoWithArticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OpOostInConvoWithArticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OpOostInConvoWithArticleEntity = {
	__typename?: 'OpOostInConvoWithArticleEntity';
	attributes?: Maybe<OpOostInConvoWithArticle>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostInConvoWithArticleEntityResponse = {
	__typename?: 'OpOostInConvoWithArticleEntityResponse';
	data?: Maybe<OpOostInConvoWithArticleEntity>;
};

export type OpOostInConvoWithArticleEntityResponseCollection = {
	__typename?: 'OpOostInConvoWithArticleEntityResponseCollection';
	data: Array<OpOostInConvoWithArticleEntity>;
	meta: ResponseCollectionMeta;
};

export type OpOostInConvoWithArticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<OpOostInConvoWithArticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<OpOostInConvoWithArticleFiltersInput>;
	not?: InputMaybe<OpOostInConvoWithArticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<OpOostInConvoWithArticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type OpOostInConvoWithArticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['OpOostInConvoWithArticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type OpOostInConvoWithArticleModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type OpOostInConvoWithArticleRelationResponseCollection = {
	__typename?: 'OpOostInConvoWithArticleRelationResponseCollection';
	data: Array<OpOostInConvoWithArticleEntity>;
};

export type OpOostInConvoWithDetail = {
	__typename?: 'OpOostInConvoWithDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostInConvoWithDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<OpOostInConvoWithDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostInConvoWithDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpOostInConvoWithDetailEntity = {
	__typename?: 'OpOostInConvoWithDetailEntity';
	attributes?: Maybe<OpOostInConvoWithDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostInConvoWithDetailEntityResponse = {
	__typename?: 'OpOostInConvoWithDetailEntityResponse';
	data?: Maybe<OpOostInConvoWithDetailEntity>;
};

export type OpOostInConvoWithDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['OpOostInConvoWithDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OpOostInConvoWithDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type OpOostInConvoWithDetailRelationResponseCollection = {
	__typename?: 'OpOostInConvoWithDetailRelationResponseCollection';
	data: Array<OpOostInConvoWithDetailEntity>;
};

export type OpOostInConvoWithOverview = {
	__typename?: 'OpOostInConvoWithOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostInConvoWithOverviewRelationResponseCollection>;
	modules: Array<Maybe<OpOostInConvoWithOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostInConvoWithOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpOostInConvoWithOverviewEntity = {
	__typename?: 'OpOostInConvoWithOverviewEntity';
	attributes?: Maybe<OpOostInConvoWithOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostInConvoWithOverviewEntityResponse = {
	__typename?: 'OpOostInConvoWithOverviewEntityResponse';
	data?: Maybe<OpOostInConvoWithOverviewEntity>;
};

export type OpOostInConvoWithOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['OpOostInConvoWithOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type OpOostInConvoWithOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesInConvoWithOverview
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type OpOostInConvoWithOverviewRelationResponseCollection = {
	__typename?: 'OpOostInConvoWithOverviewRelationResponseCollection';
	data: Array<OpOostInConvoWithOverviewEntity>;
};

export type OpOostInformation = {
	__typename?: 'OpOostInformation';
	createdAt?: Maybe<Scalars['DateTime']>;
	footer?: Maybe<ComponentInternalFooter>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostInformationRelationResponseCollection>;
	navbar?: Maybe<ComponentInternalNavbar>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo?: Maybe<ComponentInternalSeo>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostInformationLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpOostInformationEntity = {
	__typename?: 'OpOostInformationEntity';
	attributes?: Maybe<OpOostInformation>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostInformationEntityResponse = {
	__typename?: 'OpOostInformationEntityResponse';
	data?: Maybe<OpOostInformationEntity>;
};

export type OpOostInformationInput = {
	footer?: InputMaybe<ComponentInternalFooterInput>;
	navbar?: InputMaybe<ComponentInternalNavbarInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentInternalSeoInput>;
};

export type OpOostInformationRelationResponseCollection = {
	__typename?: 'OpOostInformationRelationResponseCollection';
	data: Array<OpOostInformationEntity>;
};

export type OpOostNewsDetail = {
	__typename?: 'OpOostNewsDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostNewsDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<OpOostNewsDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostNewsDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpOostNewsDetailEntity = {
	__typename?: 'OpOostNewsDetailEntity';
	attributes?: Maybe<OpOostNewsDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostNewsDetailEntityResponse = {
	__typename?: 'OpOostNewsDetailEntityResponse';
	data?: Maybe<OpOostNewsDetailEntity>;
};

export type OpOostNewsDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['OpOostNewsDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OpOostNewsDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsOpOost
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type OpOostNewsDetailRelationResponseCollection = {
	__typename?: 'OpOostNewsDetailRelationResponseCollection';
	data: Array<OpOostNewsDetailEntity>;
};

export type OpOostNewsOverview = {
	__typename?: 'OpOostNewsOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostNewsOverviewRelationResponseCollection>;
	modules: Array<Maybe<OpOostNewsOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostNewsOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpOostNewsOverviewEntity = {
	__typename?: 'OpOostNewsOverviewEntity';
	attributes?: Maybe<OpOostNewsOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostNewsOverviewEntityResponse = {
	__typename?: 'OpOostNewsOverviewEntityResponse';
	data?: Maybe<OpOostNewsOverviewEntity>;
};

export type OpOostNewsOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['OpOostNewsOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type OpOostNewsOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesNewsOverview
	| ComponentModulesPhotoAlbum
	| Error;

export type OpOostNewsOverviewRelationResponseCollection = {
	__typename?: 'OpOostNewsOverviewRelationResponseCollection';
	data: Array<OpOostNewsOverviewEntity>;
};

export type OpOostNewsarticle = {
	__typename?: 'OpOostNewsarticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostNewsarticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<OpOostNewsarticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostNewsarticleLocalizationsArgs = {
	filters?: InputMaybe<OpOostNewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OpOostNewsarticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OpOostNewsarticleEntity = {
	__typename?: 'OpOostNewsarticleEntity';
	attributes?: Maybe<OpOostNewsarticle>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostNewsarticleEntityResponse = {
	__typename?: 'OpOostNewsarticleEntityResponse';
	data?: Maybe<OpOostNewsarticleEntity>;
};

export type OpOostNewsarticleEntityResponseCollection = {
	__typename?: 'OpOostNewsarticleEntityResponseCollection';
	data: Array<OpOostNewsarticleEntity>;
	meta: ResponseCollectionMeta;
};

export type OpOostNewsarticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<OpOostNewsarticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<OpOostNewsarticleFiltersInput>;
	not?: InputMaybe<OpOostNewsarticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<OpOostNewsarticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type OpOostNewsarticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['OpOostNewsarticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type OpOostNewsarticleModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsOpOost
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesShare
	| ComponentModulesTestimonial
	| Error;

export type OpOostNewsarticleRelationResponseCollection = {
	__typename?: 'OpOostNewsarticleRelationResponseCollection';
	data: Array<OpOostNewsarticleEntity>;
};

export type OpOostPage = {
	__typename?: 'OpOostPage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpOostPageRelationResponseCollection>;
	modules: Array<Maybe<OpOostPageModulesDynamicZone>>;
	parent?: Maybe<OpOostPageEntityResponse>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	shortDescription?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpOostPageLocalizationsArgs = {
	filters?: InputMaybe<OpOostPageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OpOostPageTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OpOostPageEntity = {
	__typename?: 'OpOostPageEntity';
	attributes?: Maybe<OpOostPage>;
	id?: Maybe<Scalars['ID']>;
};

export type OpOostPageEntityResponse = {
	__typename?: 'OpOostPageEntityResponse';
	data?: Maybe<OpOostPageEntity>;
};

export type OpOostPageEntityResponseCollection = {
	__typename?: 'OpOostPageEntityResponseCollection';
	data: Array<OpOostPageEntity>;
	meta: ResponseCollectionMeta;
};

export type OpOostPageFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<OpOostPageFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<OpOostPageFiltersInput>;
	not?: InputMaybe<OpOostPageFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<OpOostPageFiltersInput>>>;
	parent?: InputMaybe<OpOostPageFiltersInput>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	shortDescription?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type OpOostPageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['OpOostPageModulesDynamicZoneInput']>>;
	parent?: InputMaybe<Scalars['ID']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	shortDescription?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type OpOostPageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsOpOost
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesShare
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type OpOostPageRelationResponseCollection = {
	__typename?: 'OpOostPageRelationResponseCollection';
	data: Array<OpOostPageEntity>;
};

export type OpenGelderlandHomepage = {
	__typename?: 'OpenGelderlandHomepage';
	collections: ComponentModulesCollections;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpenGelderlandHomepageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<OpenGelderlandHomepageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpenGelderlandHomepageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpenGelderlandHomepageEntity = {
	__typename?: 'OpenGelderlandHomepageEntity';
	attributes?: Maybe<OpenGelderlandHomepage>;
	id?: Maybe<Scalars['ID']>;
};

export type OpenGelderlandHomepageEntityResponse = {
	__typename?: 'OpenGelderlandHomepageEntityResponse';
	data?: Maybe<OpenGelderlandHomepageEntity>;
};

export type OpenGelderlandHomepageInput = {
	collections?: InputMaybe<ComponentModulesCollectionsInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['OpenGelderlandHomepageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type OpenGelderlandHomepageModulesDynamicZone = ComponentModulesCta | Error;

export type OpenGelderlandHomepageRelationResponseCollection = {
	__typename?: 'OpenGelderlandHomepageRelationResponseCollection';
	data: Array<OpenGelderlandHomepageEntity>;
};

export type OpenGelderlandInformation = {
	__typename?: 'OpenGelderlandInformation';
	createdAt?: Maybe<Scalars['DateTime']>;
	footer?: Maybe<ComponentInternalFooter>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpenGelderlandInformationRelationResponseCollection>;
	navbar?: Maybe<ComponentInternalNavbar>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo?: Maybe<ComponentInternalSeo>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OpenGelderlandInformationLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpenGelderlandInformationEntity = {
	__typename?: 'OpenGelderlandInformationEntity';
	attributes?: Maybe<OpenGelderlandInformation>;
	id?: Maybe<Scalars['ID']>;
};

export type OpenGelderlandInformationEntityResponse = {
	__typename?: 'OpenGelderlandInformationEntityResponse';
	data?: Maybe<OpenGelderlandInformationEntity>;
};

export type OpenGelderlandInformationInput = {
	footer?: InputMaybe<ComponentInternalFooterInput>;
	navbar?: InputMaybe<ComponentInternalNavbarInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentInternalSeoInput>;
};

export type OpenGelderlandInformationRelationResponseCollection = {
	__typename?: 'OpenGelderlandInformationRelationResponseCollection';
	data: Array<OpenGelderlandInformationEntity>;
};

export type OpenGelderlandWooOverview = {
	__typename?: 'OpenGelderlandWooOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<OpenGelderlandWooOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<OpenGelderlandWooOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
	woo_overview: ComponentModulesOpenWooRequestOverview;
};

export type OpenGelderlandWooOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type OpenGelderlandWooOverviewEntity = {
	__typename?: 'OpenGelderlandWooOverviewEntity';
	attributes?: Maybe<OpenGelderlandWooOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type OpenGelderlandWooOverviewEntityResponse = {
	__typename?: 'OpenGelderlandWooOverviewEntityResponse';
	data?: Maybe<OpenGelderlandWooOverviewEntity>;
};

export type OpenGelderlandWooOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['OpenGelderlandWooOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	woo_overview?: InputMaybe<ComponentModulesOpenWooRequestOverviewInput>;
};

export type OpenGelderlandWooOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| Error;

export type OpenGelderlandWooOverviewRelationResponseCollection = {
	__typename?: 'OpenGelderlandWooOverviewRelationResponseCollection';
	data: Array<OpenGelderlandWooOverviewEntity>;
};

export type Page = {
	__typename?: 'Page';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PageRelationResponseCollection>;
	modules: Array<Maybe<PageModulesDynamicZone>>;
	parent?: Maybe<PageEntityResponse>;
	password?: Maybe<Scalars['String']>;
	project?: Maybe<ProjectEntityResponse>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	region?: Maybe<RegionEntityResponse>;
	seo: ComponentModulesSeo;
	shortDescription?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	theme?: Maybe<ThemeEntityResponse>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PageLocalizationsArgs = {
	filters?: InputMaybe<PageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PageTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PageEntity = {
	__typename?: 'PageEntity';
	attributes?: Maybe<Page>;
	id?: Maybe<Scalars['ID']>;
};

export type PageEntityResponse = {
	__typename?: 'PageEntityResponse';
	data?: Maybe<PageEntity>;
};

export type PageEntityResponseCollection = {
	__typename?: 'PageEntityResponseCollection';
	data: Array<PageEntity>;
	meta: ResponseCollectionMeta;
};

export type PageFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<PageFiltersInput>;
	not?: InputMaybe<PageFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
	parent?: InputMaybe<PageFiltersInput>;
	password?: InputMaybe<StringFilterInput>;
	project?: InputMaybe<ProjectFiltersInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	region?: InputMaybe<RegionFiltersInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	shortDescription?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	theme?: InputMaybe<ThemeFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PageInformation = {
	__typename?: 'PageInformation';
	createdAt?: Maybe<Scalars['DateTime']>;
	footer?: Maybe<ComponentInternalFooter>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PageInformationRelationResponseCollection>;
	navbar?: Maybe<ComponentInternalNavbar>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo?: Maybe<ComponentInternalSeo>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PageInformationLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type PageInformationEntity = {
	__typename?: 'PageInformationEntity';
	attributes?: Maybe<PageInformation>;
	id?: Maybe<Scalars['ID']>;
};

export type PageInformationEntityResponse = {
	__typename?: 'PageInformationEntityResponse';
	data?: Maybe<PageInformationEntity>;
};

export type PageInformationInput = {
	footer?: InputMaybe<ComponentInternalFooterInput>;
	navbar?: InputMaybe<ComponentInternalNavbarInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentInternalSeoInput>;
};

export type PageInformationRelationResponseCollection = {
	__typename?: 'PageInformationRelationResponseCollection';
	data: Array<PageInformationEntity>;
};

export type PageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['PageModulesDynamicZoneInput']>>;
	parent?: InputMaybe<Scalars['ID']>;
	password?: InputMaybe<Scalars['String']>;
	project?: InputMaybe<Scalars['ID']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	region?: InputMaybe<Scalars['ID']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	shortDescription?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	theme?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type PageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesScheduling
	| ComponentModulesShare
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| ComponentModulesThemes
	| Error;

export type PageRelationResponseCollection = {
	__typename?: 'PageRelationResponseCollection';
	data: Array<PageEntity>;
};

export type Pagination = {
	__typename?: 'Pagination';
	page: Scalars['Int'];
	pageCount: Scalars['Int'];
	pageSize: Scalars['Int'];
	total: Scalars['Int'];
};

export type PaginationArg = {
	limit?: InputMaybe<Scalars['Int']>;
	page?: InputMaybe<Scalars['Int']>;
	pageSize?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
};

export type PasswordPage = {
	__typename?: 'PasswordPage';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PasswordPageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<PasswordPageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PasswordPageEntity = {
	__typename?: 'PasswordPageEntity';
	attributes?: Maybe<PasswordPage>;
	id?: Maybe<Scalars['ID']>;
};

export type PasswordPageEntityResponse = {
	__typename?: 'PasswordPageEntityResponse';
	data?: Maybe<PasswordPageEntity>;
};

export type PasswordPageInput = {
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['PasswordPageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
};

export type PasswordPageModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesSubjects
	| ComponentModulesTestimonial
	| ComponentModulesThemes
	| Error;

export type PasswordPageRelationResponseCollection = {
	__typename?: 'PasswordPageRelationResponseCollection';
	data: Array<PasswordPageEntity>;
};

export type PathEntity = {
	__typename?: 'PathEntity';
	fullSlug?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
};

export type Permit = {
	__typename?: 'Permit';
	audience: Enum_Permit_Audience;
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	hideFromOverview: Scalars['Boolean'];
	keywords?: Maybe<Scalars['String']>;
	law?: Maybe<Enum_Permit_Law>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PermitRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<PermitModulesDynamicZone>>>;
	onlineRequest?: Maybe<Scalars['Boolean']>;
	password?: Maybe<Scalars['String']>;
	permitLaws?: Maybe<PermitLawRelationResponseCollection>;
	permitProductTypes?: Maybe<PermitProductTypeRelationResponseCollection>;
	productType?: Maybe<Enum_Permit_Producttype>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	themes?: Maybe<ThemeRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
	upls?: Maybe<UplRelationResponseCollection>;
};

export type PermitLocalizationsArgs = {
	filters?: InputMaybe<PermitFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PermitPermitLawsArgs = {
	filters?: InputMaybe<PermitLawFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PermitPermitProductTypesArgs = {
	filters?: InputMaybe<PermitProductTypeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PermitThemesArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PermitUplsArgs = {
	filters?: InputMaybe<UplFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PermitDetail = {
	__typename?: 'PermitDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PermitDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<PermitDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermitDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type PermitDetailEntity = {
	__typename?: 'PermitDetailEntity';
	attributes?: Maybe<PermitDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type PermitDetailEntityResponse = {
	__typename?: 'PermitDetailEntityResponse';
	data?: Maybe<PermitDetailEntity>;
};

export type PermitDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['PermitDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PermitDetailModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| Error;

export type PermitDetailRelationResponseCollection = {
	__typename?: 'PermitDetailRelationResponseCollection';
	data: Array<PermitDetailEntity>;
};

export type PermitEntity = {
	__typename?: 'PermitEntity';
	attributes?: Maybe<Permit>;
	id?: Maybe<Scalars['ID']>;
};

export type PermitEntityResponse = {
	__typename?: 'PermitEntityResponse';
	data?: Maybe<PermitEntity>;
};

export type PermitEntityResponseCollection = {
	__typename?: 'PermitEntityResponseCollection';
	data: Array<PermitEntity>;
	meta: ResponseCollectionMeta;
};

export type PermitFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<PermitFiltersInput>>>;
	audience?: InputMaybe<StringFilterInput>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	hideFromOverview?: InputMaybe<BooleanFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	law?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<PermitFiltersInput>;
	not?: InputMaybe<PermitFiltersInput>;
	onlineRequest?: InputMaybe<BooleanFilterInput>;
	or?: InputMaybe<Array<InputMaybe<PermitFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	permitLaws?: InputMaybe<PermitLawFiltersInput>;
	permitProductTypes?: InputMaybe<PermitProductTypeFiltersInput>;
	productType?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	themes?: InputMaybe<ThemeFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	upls?: InputMaybe<UplFiltersInput>;
};

export type PermitInput = {
	audience?: InputMaybe<Enum_Permit_Audience>;
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	hideFromOverview?: InputMaybe<Scalars['Boolean']>;
	keywords?: InputMaybe<Scalars['String']>;
	law?: InputMaybe<Enum_Permit_Law>;
	modules?: InputMaybe<Array<Scalars['PermitModulesDynamicZoneInput']>>;
	onlineRequest?: InputMaybe<Scalars['Boolean']>;
	password?: InputMaybe<Scalars['String']>;
	permitLaws?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	permitProductTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	productType?: InputMaybe<Enum_Permit_Producttype>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	themes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
	upls?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PermitLaw = {
	__typename?: 'PermitLaw';
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PermitLawRelationResponseCollection>;
	name: Scalars['String'];
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermitLawLocalizationsArgs = {
	filters?: InputMaybe<PermitLawFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PermitLawEntity = {
	__typename?: 'PermitLawEntity';
	attributes?: Maybe<PermitLaw>;
	id?: Maybe<Scalars['ID']>;
};

export type PermitLawEntityResponse = {
	__typename?: 'PermitLawEntityResponse';
	data?: Maybe<PermitLawEntity>;
};

export type PermitLawEntityResponseCollection = {
	__typename?: 'PermitLawEntityResponseCollection';
	data: Array<PermitLawEntity>;
	meta: ResponseCollectionMeta;
};

export type PermitLawFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<PermitLawFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<PermitLawFiltersInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<PermitLawFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<PermitLawFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PermitLawInput = {
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PermitLawRelationResponseCollection = {
	__typename?: 'PermitLawRelationResponseCollection';
	data: Array<PermitLawEntity>;
};

export type PermitModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type PermitProductType = {
	__typename?: 'PermitProductType';
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PermitProductTypeRelationResponseCollection>;
	name: Scalars['String'];
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermitProductTypeLocalizationsArgs = {
	filters?: InputMaybe<PermitProductTypeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PermitProductTypeEntity = {
	__typename?: 'PermitProductTypeEntity';
	attributes?: Maybe<PermitProductType>;
	id?: Maybe<Scalars['ID']>;
};

export type PermitProductTypeEntityResponse = {
	__typename?: 'PermitProductTypeEntityResponse';
	data?: Maybe<PermitProductTypeEntity>;
};

export type PermitProductTypeEntityResponseCollection = {
	__typename?: 'PermitProductTypeEntityResponseCollection';
	data: Array<PermitProductTypeEntity>;
	meta: ResponseCollectionMeta;
};

export type PermitProductTypeFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<PermitProductTypeFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<PermitProductTypeFiltersInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<PermitProductTypeFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<PermitProductTypeFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PermitProductTypeInput = {
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
};

export type PermitProductTypeRelationResponseCollection = {
	__typename?: 'PermitProductTypeRelationResponseCollection';
	data: Array<PermitProductTypeEntity>;
};

export type PermitRelationResponseCollection = {
	__typename?: 'PermitRelationResponseCollection';
	data: Array<PermitEntity>;
};

export type PermitsOverview = {
	__typename?: 'PermitsOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PermitsOverviewRelationResponseCollection>;
	modules: Array<Maybe<PermitsOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermitsOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type PermitsOverviewEntity = {
	__typename?: 'PermitsOverviewEntity';
	attributes?: Maybe<PermitsOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type PermitsOverviewEntityResponse = {
	__typename?: 'PermitsOverviewEntityResponse';
	data?: Maybe<PermitsOverviewEntity>;
};

export type PermitsOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['PermitsOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type PermitsOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesPermitsOverview
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| Error;

export type PermitsOverviewRelationResponseCollection = {
	__typename?: 'PermitsOverviewRelationResponseCollection';
	data: Array<PermitsOverviewEntity>;
};

export type PressRelease = {
	__typename?: 'PressRelease';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	contactPerson?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PressReleaseRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<PressReleaseModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	theme?: Maybe<ThemeRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PressReleaseLocalizationsArgs = {
	filters?: InputMaybe<PressReleaseFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PressReleaseTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PressReleaseThemeArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PressReleaseData = {
	__typename?: 'PressReleaseData';
	contactPerson: Scalars['String'];
};

export type PressReleaseDetail = {
	__typename?: 'PressReleaseDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PressReleaseDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<PressReleaseDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PressReleaseDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type PressReleaseDetailEntity = {
	__typename?: 'PressReleaseDetailEntity';
	attributes?: Maybe<PressReleaseDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type PressReleaseDetailEntityResponse = {
	__typename?: 'PressReleaseDetailEntityResponse';
	data?: Maybe<PressReleaseDetailEntity>;
};

export type PressReleaseDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['PressReleaseDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PressReleaseDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesSubjects
	| Error;

export type PressReleaseDetailRelationResponseCollection = {
	__typename?: 'PressReleaseDetailRelationResponseCollection';
	data: Array<PressReleaseDetailEntity>;
};

export type PressReleaseEntity = {
	__typename?: 'PressReleaseEntity';
	attributes?: Maybe<PressRelease>;
	id?: Maybe<Scalars['ID']>;
};

export type PressReleaseEntityResponse = {
	__typename?: 'PressReleaseEntityResponse';
	data?: Maybe<PressReleaseEntity>;
};

export type PressReleaseEntityResponseCollection = {
	__typename?: 'PressReleaseEntityResponseCollection';
	data: Array<PressReleaseEntity>;
	meta: ResponseCollectionMeta;
};

export type PressReleaseFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<PressReleaseFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	contactPerson?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<PressReleaseFiltersInput>;
	not?: InputMaybe<PressReleaseFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<PressReleaseFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	theme?: InputMaybe<ThemeFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PressReleaseInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	contactPerson?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['PressReleaseModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	theme?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type PressReleaseModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type PressReleaseOverview = {
	__typename?: 'PressReleaseOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PressReleaseOverviewRelationResponseCollection>;
	modules: Array<Maybe<PressReleaseOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PressReleaseOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type PressReleaseOverviewEntity = {
	__typename?: 'PressReleaseOverviewEntity';
	attributes?: Maybe<PressReleaseOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type PressReleaseOverviewEntityResponse = {
	__typename?: 'PressReleaseOverviewEntityResponse';
	data?: Maybe<PressReleaseOverviewEntity>;
};

export type PressReleaseOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['PressReleaseOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type PressReleaseOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesPressReleaseOverview
	| Error;

export type PressReleaseOverviewRelationResponseCollection = {
	__typename?: 'PressReleaseOverviewRelationResponseCollection';
	data: Array<PressReleaseOverviewEntity>;
};

export type PressReleaseRelationResponseCollection = {
	__typename?: 'PressReleaseRelationResponseCollection';
	data: Array<PressReleaseEntity>;
};

export type PrivacyPolicy = {
	__typename?: 'PrivacyPolicy';
	basePath?: Maybe<Scalars['String']>;
	bodytext: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<PrivacyPolicyRelationResponseCollection>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrivacyPolicyLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type PrivacyPolicyEntity = {
	__typename?: 'PrivacyPolicyEntity';
	attributes?: Maybe<PrivacyPolicy>;
	id?: Maybe<Scalars['ID']>;
};

export type PrivacyPolicyEntityResponse = {
	__typename?: 'PrivacyPolicyEntityResponse';
	data?: Maybe<PrivacyPolicyEntity>;
};

export type PrivacyPolicyInput = {
	basePath?: InputMaybe<Scalars['String']>;
	bodytext?: InputMaybe<ComponentModulesBodyTextInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type PrivacyPolicyRelationResponseCollection = {
	__typename?: 'PrivacyPolicyRelationResponseCollection';
	data: Array<PrivacyPolicyEntity>;
};

export type Project = {
	__typename?: 'Project';
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<ProjectRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<ProjectModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	shortDescription?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	theme?: Maybe<ThemeEntityResponse>;
	title?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectLocalizationsArgs = {
	filters?: InputMaybe<ProjectFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProjectDetail = {
	__typename?: 'ProjectDetail';
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<ProjectDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<ProjectDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type ProjectDetailEntity = {
	__typename?: 'ProjectDetailEntity';
	attributes?: Maybe<ProjectDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type ProjectDetailEntityResponse = {
	__typename?: 'ProjectDetailEntityResponse';
	data?: Maybe<ProjectDetailEntity>;
};

export type ProjectDetailInput = {
	modules?: InputMaybe<Array<Scalars['ProjectDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectDetailModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type ProjectDetailRelationResponseCollection = {
	__typename?: 'ProjectDetailRelationResponseCollection';
	data: Array<ProjectDetailEntity>;
};

export type ProjectEntity = {
	__typename?: 'ProjectEntity';
	attributes?: Maybe<Project>;
	id?: Maybe<Scalars['ID']>;
};

export type ProjectEntityResponse = {
	__typename?: 'ProjectEntityResponse';
	data?: Maybe<ProjectEntity>;
};

export type ProjectEntityResponseCollection = {
	__typename?: 'ProjectEntityResponseCollection';
	data: Array<ProjectEntity>;
	meta: ResponseCollectionMeta;
};

export type ProjectFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ProjectFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<ProjectFiltersInput>;
	not?: InputMaybe<ProjectFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ProjectFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	shortDescription?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	theme?: InputMaybe<ThemeFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProjectInput = {
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['ProjectModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	shortDescription?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	theme?: InputMaybe<Scalars['ID']>;
	title?: InputMaybe<Scalars['String']>;
};

export type ProjectModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type ProjectOverview = {
	__typename?: 'ProjectOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<ProjectOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<ProjectOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type ProjectOverviewEntity = {
	__typename?: 'ProjectOverviewEntity';
	attributes?: Maybe<ProjectOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type ProjectOverviewEntityResponse = {
	__typename?: 'ProjectOverviewEntityResponse';
	data?: Maybe<ProjectOverviewEntity>;
};

export type ProjectOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['ProjectOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type ProjectOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesProjectOverview
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type ProjectOverviewRelationResponseCollection = {
	__typename?: 'ProjectOverviewRelationResponseCollection';
	data: Array<ProjectOverviewEntity>;
};

export type ProjectRelationResponseCollection = {
	__typename?: 'ProjectRelationResponseCollection';
	data: Array<ProjectEntity>;
};

export type PublicNewsletterPage = {
	__typename?: 'PublicNewsletterPage';
	page?: Maybe<NewsletterPublic>;
};

export type PublicationState = 'LIVE' | 'PREVIEW';

export type Query = {
	__typename?: 'Query';
	agendaPage?: Maybe<AgendaPageEntityResponse>;
	auditLogAuditLog?: Maybe<AuditLogAuditLogEntityResponse>;
	auditLogAuditLogs?: Maybe<AuditLogAuditLogEntityResponseCollection>;
	condoleanceHomepage?: Maybe<CondoleanceHomepageEntityResponse>;
	condoleanceInformation?: Maybe<CondoleanceInformationEntityResponse>;
	condoleancePage?: Maybe<CondoleancePageEntityResponse>;
	condoleancePageBySlug?: Maybe<CondoleancePageEntityResponse>;
	condoleancePages?: Maybe<CondoleancePageEntityResponseCollection>;
	contact?: Maybe<ContactEntityResponse>;
	conversationsConversationitem?: Maybe<ConversationsConversationitemEntityResponse>;
	conversationsConversationitems?: Maybe<ConversationsConversationitemEntityResponseCollection>;
	conversationsConversations?: Maybe<ConversationsConversationsEntityResponseCollection>;
	cookiePage?: Maybe<CookiePageEntityResponse>;
	countDocumentsPerCategory?: Maybe<Array<Maybe<WooCategoryCount>>>;
	decisionsPage?: Maybe<DecisionsPageEntityResponse>;
	downloadsPage?: Maybe<DownloadsPageEntityResponse>;
	eerbeekloenenHomepage?: Maybe<EerbeekloenenHomepageEntityResponse>;
	eerbeekloenenInConvoArticle?: Maybe<EerbeekloenenInConvoArticleEntityResponse>;
	eerbeekloenenInConvoArticleBySlug?: Maybe<EerbeekloenenInConvoArticleEntityResponse>;
	eerbeekloenenInConvoArticles?: Maybe<EerbeekloenenInConvoArticleEntityResponseCollection>;
	eerbeekloenenInConvoOverview?: Maybe<EerbeekloenenInConvoOverviewEntityResponse>;
	eerbeekloenenInConvoWithDetail?: Maybe<EerbeekloenenInConvoWithDetailEntityResponse>;
	eerbeekloenenInformation?: Maybe<EerbeekloenenInformationEntityResponse>;
	eerbeekloenenNewsDetail?: Maybe<EerbeekloenenNewsDetailEntityResponse>;
	eerbeekloenenNewsOverview?: Maybe<EerbeekloenenNewsOverviewEntityResponse>;
	eerbeekloenenNewsarticle?: Maybe<EerbeekloenenNewsarticleEntityResponse>;
	eerbeekloenenNewsarticleBySlug?: Maybe<EerbeekloenenNewsarticleEntityResponse>;
	eerbeekloenenNewsarticles?: Maybe<EerbeekloenenNewsarticleEntityResponseCollection>;
	eerbeekloenenPage?: Maybe<EerbeekloenenPageEntityResponse>;
	eerbeekloenenPageBySlug?: Maybe<EerbeekloenenPageEntityResponse>;
	eerbeekloenenPages?: Maybe<EerbeekloenenPageEntityResponseCollection>;
	efroOostHomepage?: Maybe<EfroOostHomepageEntityResponse>;
	efroOostInConvoWithArticle?: Maybe<EfroOostInConvoWithArticleEntityResponse>;
	efroOostInConvoWithArticleBySlug?: Maybe<EfroOostInConvoWithArticleEntityResponse>;
	efroOostInConvoWithArticles?: Maybe<EfroOostInConvoWithArticleEntityResponseCollection>;
	efroOostInConvoWithDetail?: Maybe<EfroOostInConvoWithDetailEntityResponse>;
	efroOostInConvoWithOverview?: Maybe<EfroOostInConvoWithOverviewEntityResponse>;
	efroOostInformation?: Maybe<EfroOostInformationEntityResponse>;
	efroOostNewsDetail?: Maybe<EfroOostNewsDetailEntityResponse>;
	efroOostNewsOverview?: Maybe<EfroOostNewsOverviewEntityResponse>;
	efroOostNewsarticle?: Maybe<EfroOostNewsarticleEntityResponse>;
	efroOostNewsarticleBySlug?: Maybe<EfroOostNewsarticleEntityResponse>;
	efroOostNewsarticles?: Maybe<EfroOostNewsarticleEntityResponseCollection>;
	efroOostPage?: Maybe<EfroOostPageEntityResponse>;
	efroOostPageBySlug?: Maybe<EfroOostPageEntityResponse>;
	efroOostPages?: Maybe<EfroOostPageEntityResponseCollection>;
	event?: Maybe<EventEntityResponse>;
	eventOverview?: Maybe<EventOverviewEntityResponse>;
	events?: Maybe<EventEntityResponseCollection>;
	findAllPaths?: Maybe<Array<Maybe<PathEntity>>>;
	findSitemapList?: Maybe<Array<Maybe<SitemapPage>>>;
	geolinkHomepage?: Maybe<GeolinkHomepageEntityResponse>;
	geolinkInformation?: Maybe<GeolinkInformationEntityResponse>;
	geolinkPage?: Maybe<GeolinkPageEntityResponse>;
	geolinkPageBySlug?: Maybe<GeolinkPageEntityResponse>;
	geolinkPages?: Maybe<GeolinkPageEntityResponseCollection>;
	getCommentsEnabled?: Maybe<Scalars['Boolean']>;
	getManyNewsletters?: Maybe<Array<Maybe<NewsletterData>>>;
	getNewsLetterCampaigns?: Maybe<Array<Maybe<NewsletterCampaign>>>;
	getNewsarticleContactPersonById?: Maybe<NewsarticleData>;
	getPressReleaseContactPersonById?: Maybe<PressReleaseData>;
	homePage?: Maybe<HomePageEntityResponse>;
	i18NLocale?: Maybe<I18NLocaleEntityResponse>;
	i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
	inConvoWithArticle?: Maybe<InConvoWithArticleEntityResponse>;
	inConvoWithArticleBySlug?: Maybe<InConvoWithArticleEntityResponse>;
	inConvoWithArticles?: Maybe<InConvoWithArticleEntityResponseCollection>;
	inConvoWithDetail?: Maybe<InConvoWithDetailEntityResponse>;
	inConvoWithOverview?: Maybe<InConvoWithOverviewEntityResponse>;
	internalLinkInternalLink?: Maybe<InternalLinkInternalLinkEntityResponse>;
	internalLinkInternalLinkWysiwyg?: Maybe<InternalLinkInternalLinkWysiwygEntityResponse>;
	internalLinkInternalLinks?: Maybe<InternalLinkInternalLinkEntityResponseCollection>;
	internalLinkInternalLinksWysiwyg?: Maybe<InternalLinkInternalLinkWysiwygEntityResponseCollection>;
	internalNewsletterWithSubscriptions?: Maybe<Array<Maybe<InternalNewsletterWithSubscriptions>>>;
	login?: Maybe<LoginEntityResponse>;
	mapsFigure?: Maybe<MapsFigureEntityResponse>;
	mapsFigures?: Maybe<MapsFigureEntityResponseCollection>;
	mapsFiguresOverview?: Maybe<MapsFiguresOverviewEntityResponse>;
	me?: Maybe<UsersPermissionsMe>;
	newsDetail?: Maybe<NewsDetailEntityResponse>;
	newsOverview?: Maybe<NewsOverviewEntityResponse>;
	newsarticle?: Maybe<NewsarticleEntityResponse>;
	newsarticleBySlug?: Maybe<NewsarticleEntityResponse>;
	newsarticles?: Maybe<NewsarticleEntityResponseCollection>;
	newsletter?: Maybe<NewsletterEntityResponse>;
	newsletterArchiveOverview?: Maybe<NewsletterArchiveOverviewEntityResponse>;
	newsletterContact?: Maybe<NewsletterContact>;
	newsletterInternal?: Maybe<NewsletterInternalEntityResponse>;
	newsletterPublic?: Maybe<NewsletterPublicEntityResponse>;
	notFoundPage?: Maybe<NotFoundPageEntityResponse>;
	noteNote?: Maybe<NoteNoteEntityResponse>;
	noteNoteRelations?: Maybe<NoteNoterelationEntityResponseCollection>;
	noteNoterelation?: Maybe<NoteNoterelationEntityResponse>;
	noteNotes?: Maybe<NoteNoteEntityResponseCollection>;
	notesByRelation?: Maybe<NoteNoteEntityResponseCollection>;
	opOostHomepage?: Maybe<OpOostHomepageEntityResponse>;
	opOostInConvoWithArticle?: Maybe<OpOostInConvoWithArticleEntityResponse>;
	opOostInConvoWithArticleBySlug?: Maybe<OpOostInConvoWithArticleEntityResponse>;
	opOostInConvoWithArticles?: Maybe<OpOostInConvoWithArticleEntityResponseCollection>;
	opOostInConvoWithDetail?: Maybe<OpOostInConvoWithDetailEntityResponse>;
	opOostInConvoWithOverview?: Maybe<OpOostInConvoWithOverviewEntityResponse>;
	opOostInformation?: Maybe<OpOostInformationEntityResponse>;
	opOostNewsDetail?: Maybe<OpOostNewsDetailEntityResponse>;
	opOostNewsOverview?: Maybe<OpOostNewsOverviewEntityResponse>;
	opOostNewsarticle?: Maybe<OpOostNewsarticleEntityResponse>;
	opOostNewsarticleBySlug?: Maybe<OpOostNewsarticleEntityResponse>;
	opOostNewsarticles?: Maybe<OpOostNewsarticleEntityResponseCollection>;
	opOostPage?: Maybe<OpOostPageEntityResponse>;
	opOostPageBySlug?: Maybe<OpOostPageEntityResponse>;
	opOostPages?: Maybe<OpOostPageEntityResponseCollection>;
	openGelderlandHomepage?: Maybe<OpenGelderlandHomepageEntityResponse>;
	openGelderlandInformation?: Maybe<OpenGelderlandInformationEntityResponse>;
	openGelderlandWooOverview?: Maybe<OpenGelderlandWooOverviewEntityResponse>;
	page?: Maybe<PageEntityResponse>;
	pageBySlug?: Maybe<PageEntityResponse>;
	pageInformation?: Maybe<PageInformationEntityResponse>;
	pages?: Maybe<PageEntityResponseCollection>;
	passwordPage?: Maybe<PasswordPageEntityResponse>;
	permit?: Maybe<PermitEntityResponse>;
	permitBySlug?: Maybe<PermitEntityResponse>;
	permitDetail?: Maybe<PermitDetailEntityResponse>;
	permitLaw?: Maybe<PermitLawEntityResponse>;
	permitLaws?: Maybe<PermitLawEntityResponseCollection>;
	permitProductType?: Maybe<PermitProductTypeEntityResponse>;
	permitProductTypes?: Maybe<PermitProductTypeEntityResponseCollection>;
	permits?: Maybe<PermitEntityResponseCollection>;
	permitsOverview?: Maybe<PermitsOverviewEntityResponse>;
	pressRelease?: Maybe<PressReleaseEntityResponse>;
	pressReleaseBySlug?: Maybe<PressReleaseEntityResponse>;
	pressReleaseDetail?: Maybe<PressReleaseDetailEntityResponse>;
	pressReleaseOverview?: Maybe<PressReleaseOverviewEntityResponse>;
	pressReleases?: Maybe<PressReleaseEntityResponseCollection>;
	privacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
	project?: Maybe<ProjectEntityResponse>;
	projectBySlug?: Maybe<ProjectEntityResponse>;
	projectDetail?: Maybe<ProjectDetailEntityResponse>;
	projectOverview?: Maybe<ProjectOverviewEntityResponse>;
	projects?: Maybe<ProjectEntityResponseCollection>;
	publicNewsletterPage?: Maybe<Array<Maybe<PublicNewsletterPage>>>;
	recordLockingOpenEntities?: Maybe<RecordLockingOpenEntityEntityResponseCollection>;
	recordLockingOpenEntity?: Maybe<RecordLockingOpenEntityEntityResponse>;
	recycleBinRecycleBinItem?: Maybe<RecycleBinRecycleBinItemEntityResponse>;
	recycleBinRecycleBinItems?: Maybe<RecycleBinRecycleBinItemEntityResponseCollection>;
	regioExpresHomepage?: Maybe<RegioExpresHomepageEntityResponse>;
	regioExpresInConvoOverview?: Maybe<RegioExpresInConvoOverviewEntityResponse>;
	regioExpresInConvoWithArticle?: Maybe<RegioExpresInConvoWithArticleEntityResponse>;
	regioExpresInConvoWithArticleBySlug?: Maybe<RegioExpresInConvoWithArticleEntityResponse>;
	regioExpresInConvoWithArticles?: Maybe<RegioExpresInConvoWithArticleEntityResponseCollection>;
	regioExpresInConvoWithDetail?: Maybe<RegioExpresInConvoWithDetailEntityResponse>;
	regioExpresInformation?: Maybe<RegioExpresInformationEntityResponse>;
	regioExpresNewsDetail?: Maybe<RegioExpresNewsDetailEntityResponse>;
	regioExpresNewsOverview?: Maybe<RegioExpresNewsOverviewEntityResponse>;
	regioExpresNewsarticle?: Maybe<RegioExpresNewsarticleEntityResponse>;
	regioExpresNewsarticleBySlug?: Maybe<RegioExpresNewsarticleEntityResponse>;
	regioExpresNewsarticles?: Maybe<RegioExpresNewsarticleEntityResponseCollection>;
	regioExpresPage?: Maybe<RegioExpresPageEntityResponse>;
	regioExpresPageBySlug?: Maybe<RegioExpresPageEntityResponse>;
	regioExpresPages?: Maybe<RegioExpresPageEntityResponseCollection>;
	region?: Maybe<RegionEntityResponse>;
	regionBySlug?: Maybe<RegionEntityResponse>;
	regionDetail?: Maybe<RegionDetailEntityResponse>;
	regionOverview?: Maybe<RegionOverviewEntityResponse>;
	regions?: Maybe<RegionEntityResponseCollection>;
	roadMaintenance?: Maybe<RoadMaintenanceEntityResponse>;
	roadMaintenanceBySlug?: Maybe<RoadMaintenanceEntityResponse>;
	roadMaintenanceDetail?: Maybe<RoadMaintenanceDetailEntityResponse>;
	roadMaintenanceOverview?: Maybe<RoadMaintenanceOverviewEntityResponse>;
	roadMaintenances?: Maybe<RoadMaintenanceEntityResponseCollection>;
	schedulerScheduler?: Maybe<SchedulerSchedulerEntityResponseCollection>;
	sdgLocatie?: Maybe<SdgLocatieEntityResponse>;
	sdgLocaties?: Maybe<SdgLocatieEntityResponseCollection>;
	sdgSdg?: Maybe<SdgSdgEntityResponse>;
	sdgSdgs?: Maybe<SdgSdgEntityResponseCollection>;
	sdgUplLijst?: Maybe<SdgUplLijstEntityResponse>;
	sdgUplLijsts?: Maybe<SdgUplLijstEntityResponseCollection>;
	searchCollectionTypes: Array<Maybe<SearchQueryResponse>>;
	searchEerbeekloenenNewsarticle?: Maybe<EerbeekloenenNewsarticleEntityResponseCollection>;
	searchEerbeekloenenPage?: Maybe<EerbeekloenenPageEntityResponseCollection>;
	searchEfroOostNewsarticle?: Maybe<EfroOostNewsarticleEntityResponseCollection>;
	searchEfroOostPage?: Maybe<EfroOostPageEntityResponseCollection>;
	searchMapsFigure?: Maybe<MapsFigureEntityResponseCollection>;
	searchMediaLibrary?: Maybe<UploadFileEntityResponseCollection>;
	searchNewsarticle?: Maybe<NewsarticleEntityResponseCollection>;
	searchNotes?: Maybe<NoteSearchResults>;
	searchOpOostNewsarticle?: Maybe<OpOostNewsarticleEntityResponseCollection>;
	searchOpOostPage?: Maybe<OpOostPageEntityResponseCollection>;
	searchOverview?: Maybe<SearchOverviewEntityResponse>;
	searchPage?: Maybe<PageEntityResponseCollection>;
	searchPermit?: Maybe<PermitEntityResponseCollection>;
	searchPressRelease?: Maybe<PressReleaseEntityResponseCollection>;
	searchProject?: Maybe<ProjectEntityResponseCollection>;
	searchRegioExpresNewsarticle?: Maybe<RegioExpresNewsarticleEntityResponseCollection>;
	searchRegioExpresPage?: Maybe<RegioExpresPageEntityResponseCollection>;
	searchRoadMaintenance?: Maybe<RoadMaintenanceEntityResponseCollection>;
	searchSubsidy?: Maybe<SubsidyEntityResponseCollection>;
	searchTag?: Maybe<TagEntityResponseCollection>;
	searchTheme?: Maybe<ThemeEntityResponseCollection>;
	searchWobRequest?: Maybe<WobRequestEntityResponseCollection>;
	searchWobRequests?: Maybe<WobRequestEntityResponseCollection>;
	searchWooRequest?: Maybe<WooRequestEntityResponseCollection>;
	searchWooRequests?: Maybe<WooRequestEntityResponseCollection>;
	sitemap?: Maybe<SitemapEntityResponse>;
	subsidies?: Maybe<SubsidyEntityResponseCollection>;
	subsidy?: Maybe<SubsidyEntityResponse>;
	subsidyBySlug?: Maybe<SubsidyEntityResponse>;
	subsidyDetail?: Maybe<SubsidyDetailEntityResponse>;
	subsidyKind?: Maybe<SubsidyKindEntityResponse>;
	subsidyKinds?: Maybe<SubsidyKindEntityResponseCollection>;
	subsidyOverview?: Maybe<SubsidyOverviewEntityResponse>;
	subsidyScale?: Maybe<SubsidyScaleEntityResponse>;
	subsidyScales?: Maybe<SubsidyScaleEntityResponseCollection>;
	tag?: Maybe<TagEntityResponse>;
	tags?: Maybe<TagEntityResponseCollection>;
	targetGroup?: Maybe<TargetGroupEntityResponse>;
	targetGroups?: Maybe<TargetGroupEntityResponseCollection>;
	theme?: Maybe<ThemeEntityResponse>;
	themeBySlug?: Maybe<ThemeEntityResponse>;
	themeDetail?: Maybe<ThemeDetailEntityResponse>;
	themeOverview?: Maybe<ThemeOverviewEntityResponse>;
	themes?: Maybe<ThemeEntityResponseCollection>;
	upl?: Maybe<UplEntityResponse>;
	uploadFile?: Maybe<UploadFileEntityResponse>;
	uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
	uploadFolder?: Maybe<UploadFolderEntityResponse>;
	uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
	upls?: Maybe<UplEntityResponseCollection>;
	usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
	usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
	usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
	usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
	vacancyDetail?: Maybe<VacancyDetailEntityResponse>;
	vacancyInternalDetail?: Maybe<VacancyInternalDetailEntityResponse>;
	vacancyInternalOverview?: Maybe<VacancyInternalOverviewEntityResponse>;
	vacancyOverview?: Maybe<VacancyOverviewEntityResponse>;
	wobRequest?: Maybe<WobRequestEntityResponse>;
	wobRequestOverview?: Maybe<WobRequestOverviewEntityResponse>;
	wobRequests?: Maybe<WobRequestEntityResponseCollection>;
	wooCategories?: Maybe<WooCategoryEntityResponseCollection>;
	wooCategory?: Maybe<WooCategoryEntityResponse>;
	wooDocumentDetail?: Maybe<WooDocumentDetailEntityResponse>;
	wooRequest?: Maybe<WooRequestEntityResponse>;
	wooRequestBySlug?: Maybe<WooRequestEntityResponse>;
	wooRequestOverview?: Maybe<WooRequestOverviewEntityResponse>;
	wooRequests?: Maybe<WooRequestEntityResponseCollection>;
	workAt?: Maybe<WorkAtEntityResponse>;
};

export type QueryAgendaPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryAuditLogAuditLogArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryAuditLogAuditLogsArgs = {
	filters?: InputMaybe<AuditLogAuditLogFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryCondoleanceHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryCondoleanceInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryCondoleancePageArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryCondoleancePageBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryCondoleancePagesArgs = {
	filters?: InputMaybe<CondoleancePageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryContactArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryConversationsConversationitemArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryConversationsConversationitemsArgs = {
	filters?: InputMaybe<ConversationsConversationitemFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryConversationsConversationsArgs = {
	filters?: InputMaybe<ConversationsConversationsFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryCookiePageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryDecisionsPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryDownloadsPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenInConvoArticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryEerbeekloenenInConvoArticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenInConvoArticlesArgs = {
	filters?: InputMaybe<EerbeekloenenInConvoArticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEerbeekloenenInConvoOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenNewsarticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryEerbeekloenenNewsarticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenNewsarticlesArgs = {
	filters?: InputMaybe<EerbeekloenenNewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEerbeekloenenPageArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryEerbeekloenenPageBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEerbeekloenenPagesArgs = {
	filters?: InputMaybe<EerbeekloenenPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEfroOostHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostInConvoWithArticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryEfroOostInConvoWithArticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostInConvoWithArticlesArgs = {
	filters?: InputMaybe<EfroOostInConvoWithArticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEfroOostInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostInConvoWithOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostNewsarticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryEfroOostNewsarticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostNewsarticlesArgs = {
	filters?: InputMaybe<EfroOostNewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEfroOostPageArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryEfroOostPageBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEfroOostPagesArgs = {
	filters?: InputMaybe<EfroOostPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEventArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryEventOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryEventsArgs = {
	filters?: InputMaybe<EventFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryFindAllPathsArgs = {
	_entityId?: InputMaybe<Scalars['String']>;
};

export type QueryFindSitemapListArgs = {
	_domain?: InputMaybe<Scalars['String']>;
	_locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryGeolinkHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryGeolinkInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryGeolinkPageArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryGeolinkPageBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryGeolinkPagesArgs = {
	filters?: InputMaybe<GeolinkPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetCommentsEnabledArgs = {
	contentTypeId?: InputMaybe<Scalars['Int']>;
	contentTypeUid?: InputMaybe<Scalars['String']>;
};

export type QueryGetNewsarticleContactPersonByIdArgs = {
	id: Scalars['String'];
};

export type QueryGetPressReleaseContactPersonByIdArgs = {
	id: Scalars['String'];
};

export type QueryHomePageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryI18NLocaleArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryI18NLocalesArgs = {
	filters?: InputMaybe<I18NLocaleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryInConvoWithArticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryInConvoWithArticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryInConvoWithArticlesArgs = {
	filters?: InputMaybe<InConvoWithArticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryInConvoWithOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryInternalLinkInternalLinkArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryInternalLinkInternalLinkWysiwygArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryInternalLinkInternalLinksArgs = {
	filters?: InputMaybe<InternalLinkInternalLinkFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryInternalLinkInternalLinksWysiwygArgs = {
	filters?: InputMaybe<InternalLinkInternalLinkWysiwygFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryInternalNewsletterWithSubscriptionsArgs = {
	_locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	_publicationState?: InputMaybe<PublicationState>;
};

export type QueryLoginArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryMapsFigureArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryMapsFiguresArgs = {
	filters?: InputMaybe<MapsFigureFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryMapsFiguresOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNewsarticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryNewsarticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNewsarticlesArgs = {
	filters?: InputMaybe<NewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryNewsletterArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNewsletterArchiveOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNewsletterContactArgs = {
	email: Scalars['String'];
};

export type QueryNewsletterInternalArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNewsletterPublicArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNotFoundPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryNoteNoteArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryNoteNoteRelationsArgs = {
	filters?: InputMaybe<NoteNoterelationFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryNoteNoterelationArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryNoteNotesArgs = {
	filters?: InputMaybe<NoteNoteFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryNotesByRelationArgs = {
	contentTypeId: Scalars['Int'];
	contentTypeUid: Scalars['String'];
};

export type QueryOpOostHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostInConvoWithArticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryOpOostInConvoWithArticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostInConvoWithArticlesArgs = {
	filters?: InputMaybe<OpOostInConvoWithArticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryOpOostInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostInConvoWithOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostNewsarticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryOpOostNewsarticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostNewsarticlesArgs = {
	filters?: InputMaybe<OpOostNewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryOpOostPageArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryOpOostPageBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpOostPagesArgs = {
	filters?: InputMaybe<OpOostPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryOpenGelderlandHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpenGelderlandInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryOpenGelderlandWooOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPageArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryPageBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPageInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPagesArgs = {
	filters?: InputMaybe<PageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryPasswordPageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryPermitArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryPermitBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPermitDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPermitLawArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryPermitLawsArgs = {
	filters?: InputMaybe<PermitLawFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryPermitProductTypeArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryPermitProductTypesArgs = {
	filters?: InputMaybe<PermitProductTypeFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryPermitsArgs = {
	filters?: InputMaybe<PermitFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryPermitsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPressReleaseArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryPressReleaseBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPressReleaseDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPressReleaseOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryPressReleasesArgs = {
	filters?: InputMaybe<PressReleaseFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryPrivacyPolicyArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryProjectArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryProjectBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryProjectDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryProjectOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryProjectsArgs = {
	filters?: InputMaybe<ProjectFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryPublicNewsletterPageArgs = {
	_locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	_publicationState?: InputMaybe<PublicationState>;
};

export type QueryRecordLockingOpenEntitiesArgs = {
	filters?: InputMaybe<RecordLockingOpenEntityFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRecordLockingOpenEntityArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryRecycleBinRecycleBinItemArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryRecycleBinRecycleBinItemsArgs = {
	filters?: InputMaybe<RecycleBinRecycleBinItemFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRegioExpresHomepageArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresInConvoOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresInConvoWithArticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryRegioExpresInConvoWithArticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresInConvoWithArticlesArgs = {
	filters?: InputMaybe<RegioExpresInConvoWithArticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRegioExpresInConvoWithDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresInformationArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresNewsDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresNewsOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresNewsarticleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryRegioExpresNewsarticleBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresNewsarticlesArgs = {
	filters?: InputMaybe<RegioExpresNewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRegioExpresPageArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryRegioExpresPageBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegioExpresPagesArgs = {
	filters?: InputMaybe<RegioExpresPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRegionArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryRegionBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegionDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegionOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRegionsArgs = {
	filters?: InputMaybe<RegionFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRoadMaintenanceArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryRoadMaintenanceBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRoadMaintenanceDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRoadMaintenanceOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryRoadMaintenancesArgs = {
	filters?: InputMaybe<RoadMaintenanceFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySchedulerSchedulerArgs = {
	filters?: InputMaybe<SchedulerSchedulerFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySdgLocatieArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QuerySdgLocatiesArgs = {
	filters?: InputMaybe<SdgLocatieFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySdgSdgArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QuerySdgSdgsArgs = {
	filters?: InputMaybe<SdgSdgFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySdgUplLijstArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QuerySdgUplLijstsArgs = {
	filters?: InputMaybe<SdgUplLijstFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchCollectionTypesArgs = {
	createdAtAfter?: InputMaybe<Scalars['String']>;
	createdAtBefore?: InputMaybe<Scalars['String']>;
	domain: Scalars['String'];
	searchQuery: Scalars['String'];
	sort?: InputMaybe<Scalars['String']>;
};

export type QuerySearchEerbeekloenenNewsarticleArgs = {
	filters?: InputMaybe<EerbeekloenenNewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchEerbeekloenenPageArgs = {
	filters?: InputMaybe<EerbeekloenenPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchEfroOostNewsarticleArgs = {
	filters?: InputMaybe<EfroOostNewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchEfroOostPageArgs = {
	filters?: InputMaybe<EfroOostPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchMapsFigureArgs = {
	filters?: InputMaybe<MapsFigureFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchMediaLibraryArgs = {
	createdAtAfter?: InputMaybe<Scalars['String']>;
	createdAtBefore?: InputMaybe<Scalars['String']>;
	searchQuery: Scalars['String'];
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchNewsarticleArgs = {
	filters?: InputMaybe<NewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchNotesArgs = {
	createdAtAfter?: InputMaybe<Scalars['String']>;
	createdAtBefore?: InputMaybe<Scalars['String']>;
	searchQuery: Scalars['String'];
	sort?: InputMaybe<Scalars['String']>;
};

export type QuerySearchOpOostNewsarticleArgs = {
	filters?: InputMaybe<OpOostNewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchOpOostPageArgs = {
	filters?: InputMaybe<OpOostPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QuerySearchPageArgs = {
	filters?: InputMaybe<PageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchPermitArgs = {
	filters?: InputMaybe<PermitFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchPressReleaseArgs = {
	filters?: InputMaybe<PressReleaseFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchProjectArgs = {
	filters?: InputMaybe<ProjectFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchRegioExpresNewsarticleArgs = {
	filters?: InputMaybe<RegioExpresNewsarticleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchRegioExpresPageArgs = {
	filters?: InputMaybe<RegioExpresPageFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchRoadMaintenanceArgs = {
	filters?: InputMaybe<RoadMaintenanceFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchSubsidyArgs = {
	filters?: InputMaybe<SubsidyFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchTagArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchThemeArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchWobRequestArgs = {
	filters?: InputMaybe<WobRequestFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchWobRequestsArgs = {
	createdAtAfter?: InputMaybe<Scalars['String']>;
	createdAtBefore?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	searchQuery: Scalars['String'];
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchWooRequestArgs = {
	filters?: InputMaybe<WooRequestFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	searchQuery?: InputMaybe<Scalars['String']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchWooRequestsArgs = {
	createdAtAfter?: InputMaybe<Scalars['String']>;
	createdAtBefore?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	searchQuery: Scalars['String'];
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySitemapArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QuerySubsidiesArgs = {
	filters?: InputMaybe<SubsidyFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySubsidyArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QuerySubsidyBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QuerySubsidyDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QuerySubsidyKindArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QuerySubsidyKindsArgs = {
	filters?: InputMaybe<SubsidyKindFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySubsidyOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QuerySubsidyScaleArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QuerySubsidyScalesArgs = {
	filters?: InputMaybe<SubsidyScaleFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTagArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTargetGroupArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryTargetGroupsArgs = {
	filters?: InputMaybe<TargetGroupFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryThemeArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryThemeBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryThemeDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryThemeOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryThemesArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUplArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryUploadFileArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryUploadFilesArgs = {
	filters?: InputMaybe<UploadFileFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUploadFolderArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryUploadFoldersArgs = {
	filters?: InputMaybe<UploadFolderFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUplsArgs = {
	filters?: InputMaybe<UplFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryUsersPermissionsRolesArgs = {
	filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUsersPermissionsUserArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryUsersPermissionsUsersArgs = {
	filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryVacancyDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryVacancyInternalDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryVacancyInternalOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryVacancyOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryWobRequestArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryWobRequestOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryWobRequestsArgs = {
	filters?: InputMaybe<WobRequestFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryWooCategoriesArgs = {
	filters?: InputMaybe<WooCategoryFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryWooCategoryArgs = {
	id?: InputMaybe<Scalars['ID']>;
};

export type QueryWooDocumentDetailArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryWooRequestArgs = {
	id?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type QueryWooRequestBySlugArgs = {
	fullSlug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryWooRequestOverviewArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type QueryWooRequestsArgs = {
	filters?: InputMaybe<WooRequestFiltersInput>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryWorkAtArgs = {
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
};

export type RecordLockingOpenEntity = {
	__typename?: 'RecordLockingOpenEntity';
	connectionId?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	entityItentificator?: Maybe<Scalars['String']>;
	entityType?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	user?: Maybe<Scalars['String']>;
};

export type RecordLockingOpenEntityEntity = {
	__typename?: 'RecordLockingOpenEntityEntity';
	attributes?: Maybe<RecordLockingOpenEntity>;
	id?: Maybe<Scalars['ID']>;
};

export type RecordLockingOpenEntityEntityResponse = {
	__typename?: 'RecordLockingOpenEntityEntityResponse';
	data?: Maybe<RecordLockingOpenEntityEntity>;
};

export type RecordLockingOpenEntityEntityResponseCollection = {
	__typename?: 'RecordLockingOpenEntityEntityResponseCollection';
	data: Array<RecordLockingOpenEntityEntity>;
	meta: ResponseCollectionMeta;
};

export type RecordLockingOpenEntityFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<RecordLockingOpenEntityFiltersInput>>>;
	connectionId?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	entityItentificator?: InputMaybe<StringFilterInput>;
	entityType?: InputMaybe<StringFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<RecordLockingOpenEntityFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<RecordLockingOpenEntityFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	user?: InputMaybe<StringFilterInput>;
};

export type RecordLockingOpenEntityInput = {
	connectionId?: InputMaybe<Scalars['String']>;
	entityItentificator?: InputMaybe<Scalars['String']>;
	entityType?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	user?: InputMaybe<Scalars['String']>;
};

export type RecycleBinRecycleBinItem = {
	__typename?: 'RecycleBinRecycleBinItem';
	createdAt?: Maybe<Scalars['DateTime']>;
	data: Scalars['JSON'];
	model: Scalars['JSON'];
	password?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	uid: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecycleBinRecycleBinItemEntity = {
	__typename?: 'RecycleBinRecycleBinItemEntity';
	attributes?: Maybe<RecycleBinRecycleBinItem>;
	id?: Maybe<Scalars['ID']>;
};

export type RecycleBinRecycleBinItemEntityResponse = {
	__typename?: 'RecycleBinRecycleBinItemEntityResponse';
	data?: Maybe<RecycleBinRecycleBinItemEntity>;
};

export type RecycleBinRecycleBinItemEntityResponseCollection = {
	__typename?: 'RecycleBinRecycleBinItemEntityResponseCollection';
	data: Array<RecycleBinRecycleBinItemEntity>;
	meta: ResponseCollectionMeta;
};

export type RecycleBinRecycleBinItemFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<RecycleBinRecycleBinItemFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	data?: InputMaybe<JsonFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	model?: InputMaybe<JsonFilterInput>;
	not?: InputMaybe<RecycleBinRecycleBinItemFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<RecycleBinRecycleBinItemFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	title?: InputMaybe<StringFilterInput>;
	uid?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RecycleBinRecycleBinItemInput = {
	data?: InputMaybe<Scalars['JSON']>;
	model?: InputMaybe<Scalars['JSON']>;
	password?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
	uid?: InputMaybe<Scalars['String']>;
};

export type RegioExpresHomepage = {
	__typename?: 'RegioExpresHomepage';
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesHomepageHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresHomepageRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RegioExpresHomepageModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresHomepageLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RegioExpresHomepageEntity = {
	__typename?: 'RegioExpresHomepageEntity';
	attributes?: Maybe<RegioExpresHomepage>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresHomepageEntityResponse = {
	__typename?: 'RegioExpresHomepageEntityResponse';
	data?: Maybe<RegioExpresHomepageEntity>;
};

export type RegioExpresHomepageInput = {
	header?: InputMaybe<ComponentModulesHomepageHeaderInput>;
	modules?: InputMaybe<Array<Scalars['RegioExpresHomepageModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	title?: InputMaybe<Scalars['String']>;
};

export type RegioExpresHomepageModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsRegioExpres
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type RegioExpresHomepageRelationResponseCollection = {
	__typename?: 'RegioExpresHomepageRelationResponseCollection';
	data: Array<RegioExpresHomepageEntity>;
};

export type RegioExpresInConvoOverview = {
	__typename?: 'RegioExpresInConvoOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresInConvoOverviewRelationResponseCollection>;
	modules: Array<Maybe<RegioExpresInConvoOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresInConvoOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RegioExpresInConvoOverviewEntity = {
	__typename?: 'RegioExpresInConvoOverviewEntity';
	attributes?: Maybe<RegioExpresInConvoOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresInConvoOverviewEntityResponse = {
	__typename?: 'RegioExpresInConvoOverviewEntityResponse';
	data?: Maybe<RegioExpresInConvoOverviewEntity>;
};

export type RegioExpresInConvoOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['RegioExpresInConvoOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type RegioExpresInConvoOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesInConvoWithOverview
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type RegioExpresInConvoOverviewRelationResponseCollection = {
	__typename?: 'RegioExpresInConvoOverviewRelationResponseCollection';
	data: Array<RegioExpresInConvoOverviewEntity>;
};

export type RegioExpresInConvoWithArticle = {
	__typename?: 'RegioExpresInConvoWithArticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresInConvoWithArticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RegioExpresInConvoWithArticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresInConvoWithArticleLocalizationsArgs = {
	filters?: InputMaybe<RegioExpresInConvoWithArticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RegioExpresInConvoWithArticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RegioExpresInConvoWithArticleEntity = {
	__typename?: 'RegioExpresInConvoWithArticleEntity';
	attributes?: Maybe<RegioExpresInConvoWithArticle>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresInConvoWithArticleEntityResponse = {
	__typename?: 'RegioExpresInConvoWithArticleEntityResponse';
	data?: Maybe<RegioExpresInConvoWithArticleEntity>;
};

export type RegioExpresInConvoWithArticleEntityResponseCollection = {
	__typename?: 'RegioExpresInConvoWithArticleEntityResponseCollection';
	data: Array<RegioExpresInConvoWithArticleEntity>;
	meta: ResponseCollectionMeta;
};

export type RegioExpresInConvoWithArticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<RegioExpresInConvoWithArticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<RegioExpresInConvoWithArticleFiltersInput>;
	not?: InputMaybe<RegioExpresInConvoWithArticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<RegioExpresInConvoWithArticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RegioExpresInConvoWithArticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['RegioExpresInConvoWithArticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type RegioExpresInConvoWithArticleModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesTestimonial
	| Error;

export type RegioExpresInConvoWithArticleRelationResponseCollection = {
	__typename?: 'RegioExpresInConvoWithArticleRelationResponseCollection';
	data: Array<RegioExpresInConvoWithArticleEntity>;
};

export type RegioExpresInConvoWithDetail = {
	__typename?: 'RegioExpresInConvoWithDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresInConvoWithDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RegioExpresInConvoWithDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresInConvoWithDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RegioExpresInConvoWithDetailEntity = {
	__typename?: 'RegioExpresInConvoWithDetailEntity';
	attributes?: Maybe<RegioExpresInConvoWithDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresInConvoWithDetailEntityResponse = {
	__typename?: 'RegioExpresInConvoWithDetailEntityResponse';
	data?: Maybe<RegioExpresInConvoWithDetailEntity>;
};

export type RegioExpresInConvoWithDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['RegioExpresInConvoWithDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RegioExpresInConvoWithDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type RegioExpresInConvoWithDetailRelationResponseCollection = {
	__typename?: 'RegioExpresInConvoWithDetailRelationResponseCollection';
	data: Array<RegioExpresInConvoWithDetailEntity>;
};

export type RegioExpresInformation = {
	__typename?: 'RegioExpresInformation';
	createdAt?: Maybe<Scalars['DateTime']>;
	footer?: Maybe<ComponentInternalFooter>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresInformationRelationResponseCollection>;
	navbar?: Maybe<ComponentInternalNavbar>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo?: Maybe<ComponentInternalSeo>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresInformationLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RegioExpresInformationEntity = {
	__typename?: 'RegioExpresInformationEntity';
	attributes?: Maybe<RegioExpresInformation>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresInformationEntityResponse = {
	__typename?: 'RegioExpresInformationEntityResponse';
	data?: Maybe<RegioExpresInformationEntity>;
};

export type RegioExpresInformationInput = {
	footer?: InputMaybe<ComponentInternalFooterInput>;
	navbar?: InputMaybe<ComponentInternalNavbarInput>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentInternalSeoInput>;
};

export type RegioExpresInformationRelationResponseCollection = {
	__typename?: 'RegioExpresInformationRelationResponseCollection';
	data: Array<RegioExpresInformationEntity>;
};

export type RegioExpresNewsDetail = {
	__typename?: 'RegioExpresNewsDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresNewsDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RegioExpresNewsDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresNewsDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RegioExpresNewsDetailEntity = {
	__typename?: 'RegioExpresNewsDetailEntity';
	attributes?: Maybe<RegioExpresNewsDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresNewsDetailEntityResponse = {
	__typename?: 'RegioExpresNewsDetailEntityResponse';
	data?: Maybe<RegioExpresNewsDetailEntity>;
};

export type RegioExpresNewsDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['RegioExpresNewsDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RegioExpresNewsDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsRegioExpres
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| Error;

export type RegioExpresNewsDetailRelationResponseCollection = {
	__typename?: 'RegioExpresNewsDetailRelationResponseCollection';
	data: Array<RegioExpresNewsDetailEntity>;
};

export type RegioExpresNewsOverview = {
	__typename?: 'RegioExpresNewsOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresNewsOverviewRelationResponseCollection>;
	modules: Array<Maybe<RegioExpresNewsOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresNewsOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RegioExpresNewsOverviewEntity = {
	__typename?: 'RegioExpresNewsOverviewEntity';
	attributes?: Maybe<RegioExpresNewsOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresNewsOverviewEntityResponse = {
	__typename?: 'RegioExpresNewsOverviewEntityResponse';
	data?: Maybe<RegioExpresNewsOverviewEntity>;
};

export type RegioExpresNewsOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['RegioExpresNewsOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type RegioExpresNewsOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesNewsOverview
	| ComponentModulesPhotoAlbum
	| Error;

export type RegioExpresNewsOverviewRelationResponseCollection = {
	__typename?: 'RegioExpresNewsOverviewRelationResponseCollection';
	data: Array<RegioExpresNewsOverviewEntity>;
};

export type RegioExpresNewsarticle = {
	__typename?: 'RegioExpresNewsarticle';
	allowedOnHomePage?: Maybe<Scalars['Boolean']>;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresNewsarticleRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RegioExpresNewsarticleModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresNewsarticleLocalizationsArgs = {
	filters?: InputMaybe<RegioExpresNewsarticleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RegioExpresNewsarticleTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RegioExpresNewsarticleEntity = {
	__typename?: 'RegioExpresNewsarticleEntity';
	attributes?: Maybe<RegioExpresNewsarticle>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresNewsarticleEntityResponse = {
	__typename?: 'RegioExpresNewsarticleEntityResponse';
	data?: Maybe<RegioExpresNewsarticleEntity>;
};

export type RegioExpresNewsarticleEntityResponseCollection = {
	__typename?: 'RegioExpresNewsarticleEntityResponseCollection';
	data: Array<RegioExpresNewsarticleEntity>;
	meta: ResponseCollectionMeta;
};

export type RegioExpresNewsarticleFiltersInput = {
	allowedOnHomePage?: InputMaybe<BooleanFilterInput>;
	and?: InputMaybe<Array<InputMaybe<RegioExpresNewsarticleFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<RegioExpresNewsarticleFiltersInput>;
	not?: InputMaybe<RegioExpresNewsarticleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<RegioExpresNewsarticleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RegioExpresNewsarticleInput = {
	allowedOnHomePage?: InputMaybe<Scalars['Boolean']>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['RegioExpresNewsarticleModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type RegioExpresNewsarticleModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsRegioExpres
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesShare
	| ComponentModulesTestimonial
	| Error;

export type RegioExpresNewsarticleRelationResponseCollection = {
	__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
	data: Array<RegioExpresNewsarticleEntity>;
};

export type RegioExpresPage = {
	__typename?: 'RegioExpresPage';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeaderImage;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegioExpresPageRelationResponseCollection>;
	modules: Array<Maybe<RegioExpresPageModulesDynamicZone>>;
	parent?: Maybe<RegioExpresPageEntityResponse>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	shortDescription?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegioExpresPageLocalizationsArgs = {
	filters?: InputMaybe<RegioExpresPageFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RegioExpresPageTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RegioExpresPageEntity = {
	__typename?: 'RegioExpresPageEntity';
	attributes?: Maybe<RegioExpresPage>;
	id?: Maybe<Scalars['ID']>;
};

export type RegioExpresPageEntityResponse = {
	__typename?: 'RegioExpresPageEntityResponse';
	data?: Maybe<RegioExpresPageEntity>;
};

export type RegioExpresPageEntityResponseCollection = {
	__typename?: 'RegioExpresPageEntityResponseCollection';
	data: Array<RegioExpresPageEntity>;
	meta: ResponseCollectionMeta;
};

export type RegioExpresPageFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<RegioExpresPageFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<RegioExpresPageFiltersInput>;
	not?: InputMaybe<RegioExpresPageFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<RegioExpresPageFiltersInput>>>;
	parent?: InputMaybe<RegioExpresPageFiltersInput>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	shortDescription?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RegioExpresPageInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderImageInput>;
	modules?: InputMaybe<Array<Scalars['RegioExpresPageModulesDynamicZoneInput']>>;
	parent?: InputMaybe<Scalars['ID']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	shortDescription?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type RegioExpresPageModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNewsRegioExpres
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPartners
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesShare
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| ComponentModulesTestimonial
	| Error;

export type RegioExpresPageRelationResponseCollection = {
	__typename?: 'RegioExpresPageRelationResponseCollection';
	data: Array<RegioExpresPageEntity>;
};

export type Region = {
	__typename?: 'Region';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header?: Maybe<ComponentModulesExpandedHeader>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegionRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RegionModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegionLocalizationsArgs = {
	filters?: InputMaybe<RegionFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RegionTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RegionDetail = {
	__typename?: 'RegionDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegionDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RegionDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegionDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RegionDetailEntity = {
	__typename?: 'RegionDetailEntity';
	attributes?: Maybe<RegionDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type RegionDetailEntityResponse = {
	__typename?: 'RegionDetailEntityResponse';
	data?: Maybe<RegionDetailEntity>;
};

export type RegionDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['RegionDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RegionDetailModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesThemes
	| Error;

export type RegionDetailRelationResponseCollection = {
	__typename?: 'RegionDetailRelationResponseCollection';
	data: Array<RegionDetailEntity>;
};

export type RegionEntity = {
	__typename?: 'RegionEntity';
	attributes?: Maybe<Region>;
	id?: Maybe<Scalars['ID']>;
};

export type RegionEntityResponse = {
	__typename?: 'RegionEntityResponse';
	data?: Maybe<RegionEntity>;
};

export type RegionEntityResponseCollection = {
	__typename?: 'RegionEntityResponseCollection';
	data: Array<RegionEntity>;
	meta: ResponseCollectionMeta;
};

export type RegionFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<RegionFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesExpandedHeaderFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<RegionFiltersInput>;
	not?: InputMaybe<RegionFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<RegionFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RegionInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesExpandedHeaderInput>;
	modules?: InputMaybe<Array<Scalars['RegionModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type RegionModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesNewsOverview
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesThemes
	| Error;

export type RegionOverview = {
	__typename?: 'RegionOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RegionOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RegionOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegionOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RegionOverviewEntity = {
	__typename?: 'RegionOverviewEntity';
	attributes?: Maybe<RegionOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type RegionOverviewEntityResponse = {
	__typename?: 'RegionOverviewEntityResponse';
	data?: Maybe<RegionOverviewEntity>;
};

export type RegionOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['RegionOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type RegionOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesThemes
	| Error;

export type RegionOverviewRelationResponseCollection = {
	__typename?: 'RegionOverviewRelationResponseCollection';
	data: Array<RegionOverviewEntity>;
};

export type RegionRelationResponseCollection = {
	__typename?: 'RegionRelationResponseCollection';
	data: Array<RegionEntity>;
};

export type ResponseCollectionMeta = {
	__typename?: 'ResponseCollectionMeta';
	pagination: Pagination;
};

export type RoadMaintenance = {
	__typename?: 'RoadMaintenance';
	aboutMaintenance: ComponentInternalAboutMaintenance;
	basePath?: Maybe<Scalars['String']>;
	bodyText: ComponentModulesBodyText;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeader;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RoadMaintenanceRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RoadMaintenanceModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	status?: Maybe<Enum_Roadmaintenance_Status>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoadMaintenanceLocalizationsArgs = {
	filters?: InputMaybe<RoadMaintenanceFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RoadMaintenanceDetail = {
	__typename?: 'RoadMaintenanceDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RoadMaintenanceDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RoadMaintenanceDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoadMaintenanceDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RoadMaintenanceDetailEntity = {
	__typename?: 'RoadMaintenanceDetailEntity';
	attributes?: Maybe<RoadMaintenanceDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type RoadMaintenanceDetailEntityResponse = {
	__typename?: 'RoadMaintenanceDetailEntityResponse';
	data?: Maybe<RoadMaintenanceDetailEntity>;
};

export type RoadMaintenanceDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['RoadMaintenanceDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RoadMaintenanceDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| Error;

export type RoadMaintenanceDetailRelationResponseCollection = {
	__typename?: 'RoadMaintenanceDetailRelationResponseCollection';
	data: Array<RoadMaintenanceDetailEntity>;
};

export type RoadMaintenanceEntity = {
	__typename?: 'RoadMaintenanceEntity';
	attributes?: Maybe<RoadMaintenance>;
	id?: Maybe<Scalars['ID']>;
};

export type RoadMaintenanceEntityResponse = {
	__typename?: 'RoadMaintenanceEntityResponse';
	data?: Maybe<RoadMaintenanceEntity>;
};

export type RoadMaintenanceEntityResponseCollection = {
	__typename?: 'RoadMaintenanceEntityResponseCollection';
	data: Array<RoadMaintenanceEntity>;
	meta: ResponseCollectionMeta;
};

export type RoadMaintenanceFiltersInput = {
	aboutMaintenance?: InputMaybe<ComponentInternalAboutMaintenanceFiltersInput>;
	and?: InputMaybe<Array<InputMaybe<RoadMaintenanceFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	bodyText?: InputMaybe<ComponentModulesBodyTextFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<RoadMaintenanceFiltersInput>;
	not?: InputMaybe<RoadMaintenanceFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<RoadMaintenanceFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	status?: InputMaybe<StringFilterInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RoadMaintenanceInput = {
	aboutMaintenance?: InputMaybe<ComponentInternalAboutMaintenanceInput>;
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['RoadMaintenanceModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Enum_Roadmaintenance_Status>;
	title?: InputMaybe<Scalars['String']>;
};

export type RoadMaintenanceModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesScheduling
	| ComponentModulesShare
	| ComponentModulesStepByStep
	| ComponentModulesTable
	| Error;

export type RoadMaintenanceOverview = {
	__typename?: 'RoadMaintenanceOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	list: ComponentModulesRoadMaintenanceOverview;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<RoadMaintenanceOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<RoadMaintenanceOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoadMaintenanceOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type RoadMaintenanceOverviewEntity = {
	__typename?: 'RoadMaintenanceOverviewEntity';
	attributes?: Maybe<RoadMaintenanceOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type RoadMaintenanceOverviewEntityResponse = {
	__typename?: 'RoadMaintenanceOverviewEntityResponse';
	data?: Maybe<RoadMaintenanceOverviewEntity>;
};

export type RoadMaintenanceOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	list?: InputMaybe<ComponentModulesRoadMaintenanceOverviewInput>;
	modules?: InputMaybe<Array<Scalars['RoadMaintenanceOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type RoadMaintenanceOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| Error;

export type RoadMaintenanceOverviewRelationResponseCollection = {
	__typename?: 'RoadMaintenanceOverviewRelationResponseCollection';
	data: Array<RoadMaintenanceOverviewEntity>;
};

export type RoadMaintenanceRelationResponseCollection = {
	__typename?: 'RoadMaintenanceRelationResponseCollection';
	data: Array<RoadMaintenanceEntity>;
};

export type SchedulerScheduler = {
	__typename?: 'SchedulerScheduler';
	createdAt?: Maybe<Scalars['DateTime']>;
	datetime?: Maybe<Scalars['DateTime']>;
	entryId: Scalars['Long'];
	password?: Maybe<Scalars['String']>;
	type: Enum_Schedulerscheduler_Type;
	uid: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SchedulerSchedulerEntity = {
	__typename?: 'SchedulerSchedulerEntity';
	attributes?: Maybe<SchedulerScheduler>;
	id?: Maybe<Scalars['ID']>;
};

export type SchedulerSchedulerEntityResponse = {
	__typename?: 'SchedulerSchedulerEntityResponse';
	data?: Maybe<SchedulerSchedulerEntity>;
};

export type SchedulerSchedulerEntityResponseCollection = {
	__typename?: 'SchedulerSchedulerEntityResponseCollection';
	data: Array<SchedulerSchedulerEntity>;
	meta: ResponseCollectionMeta;
};

export type SchedulerSchedulerFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<SchedulerSchedulerFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	datetime?: InputMaybe<DateTimeFilterInput>;
	entryId?: InputMaybe<LongFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<SchedulerSchedulerFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<SchedulerSchedulerFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	type?: InputMaybe<StringFilterInput>;
	uid?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SchedulerSchedulerInput = {
	datetime?: InputMaybe<Scalars['DateTime']>;
	entryId?: InputMaybe<Scalars['Long']>;
	password?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<Enum_Schedulerscheduler_Type>;
	uid?: InputMaybe<Scalars['String']>;
};

export type SdgLocatie = {
	__typename?: 'SdgLocatie';
	createdAt?: Maybe<Scalars['DateTime']>;
	land: Scalars['String'];
	naam: Scalars['String'];
	nummer: Scalars['String'];
	openingstijdDinsdag?: Maybe<Scalars['String']>;
	openingstijdDonderdag?: Maybe<Scalars['String']>;
	openingstijdMaandag?: Maybe<Scalars['String']>;
	openingstijdVrijdag?: Maybe<Scalars['String']>;
	openingstijdWoensdag?: Maybe<Scalars['String']>;
	openingstijdZaterdag?: Maybe<Scalars['String']>;
	openingstijdZondag?: Maybe<Scalars['String']>;
	openingstijdenOpmerking?: Maybe<Scalars['String']>;
	organisatie: Scalars['JSON'];
	password?: Maybe<Scalars['String']>;
	plaats: Scalars['String'];
	postcode: Scalars['String'];
	straat: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
	url?: Maybe<Scalars['String']>;
};

export type SdgLocatieEntity = {
	__typename?: 'SdgLocatieEntity';
	attributes?: Maybe<SdgLocatie>;
	id?: Maybe<Scalars['ID']>;
};

export type SdgLocatieEntityResponse = {
	__typename?: 'SdgLocatieEntityResponse';
	data?: Maybe<SdgLocatieEntity>;
};

export type SdgLocatieEntityResponseCollection = {
	__typename?: 'SdgLocatieEntityResponseCollection';
	data: Array<SdgLocatieEntity>;
	meta: ResponseCollectionMeta;
};

export type SdgLocatieFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<SdgLocatieFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	land?: InputMaybe<StringFilterInput>;
	naam?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<SdgLocatieFiltersInput>;
	nummer?: InputMaybe<StringFilterInput>;
	openingstijdDinsdag?: InputMaybe<StringFilterInput>;
	openingstijdDonderdag?: InputMaybe<StringFilterInput>;
	openingstijdMaandag?: InputMaybe<StringFilterInput>;
	openingstijdVrijdag?: InputMaybe<StringFilterInput>;
	openingstijdWoensdag?: InputMaybe<StringFilterInput>;
	openingstijdZaterdag?: InputMaybe<StringFilterInput>;
	openingstijdZondag?: InputMaybe<StringFilterInput>;
	openingstijdenOpmerking?: InputMaybe<StringFilterInput>;
	or?: InputMaybe<Array<InputMaybe<SdgLocatieFiltersInput>>>;
	organisatie?: InputMaybe<JsonFilterInput>;
	password?: InputMaybe<StringFilterInput>;
	plaats?: InputMaybe<StringFilterInput>;
	postcode?: InputMaybe<StringFilterInput>;
	straat?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	url?: InputMaybe<StringFilterInput>;
};

export type SdgLocatieInput = {
	land?: InputMaybe<Scalars['String']>;
	naam?: InputMaybe<Scalars['String']>;
	nummer?: InputMaybe<Scalars['String']>;
	openingstijdDinsdag?: InputMaybe<Scalars['String']>;
	openingstijdDonderdag?: InputMaybe<Scalars['String']>;
	openingstijdMaandag?: InputMaybe<Scalars['String']>;
	openingstijdVrijdag?: InputMaybe<Scalars['String']>;
	openingstijdWoensdag?: InputMaybe<Scalars['String']>;
	openingstijdZaterdag?: InputMaybe<Scalars['String']>;
	openingstijdZondag?: InputMaybe<Scalars['String']>;
	openingstijdenOpmerking?: InputMaybe<Scalars['String']>;
	organisatie?: InputMaybe<Scalars['JSON']>;
	password?: InputMaybe<Scalars['String']>;
	plaats?: InputMaybe<Scalars['String']>;
	postcode?: InputMaybe<Scalars['String']>;
	straat?: InputMaybe<Scalars['String']>;
	url?: InputMaybe<Scalars['String']>;
};

export type SdgLocatieRelationResponseCollection = {
	__typename?: 'SdgLocatieRelationResponseCollection';
	data: Array<SdgLocatieEntity>;
};

export type SdgSdg = {
	__typename?: 'SdgSdg';
	bevoegdeOrganisatie?: Maybe<Scalars['JSON']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	doelgroepBedrijf: Scalars['Boolean'];
	doelgroepBurger: Scalars['Boolean'];
	locaties?: Maybe<SdgLocatieRelationResponseCollection>;
	password?: Maybe<Scalars['String']>;
	productAanwezig: Scalars['Boolean'];
	productValtOnder?: Maybe<SdgSdgEntityResponse>;
	publicatieDatum: Scalars['Date'];
	publishedAt?: Maybe<Scalars['DateTime']>;
	titel?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	upl: Scalars['String'];
	verantwoordelijkeOrganisatie: Scalars['JSON'];
	vertalingen: Array<Maybe<ComponentSdgSdgProductVertaling>>;
};

export type SdgSdgLocatiesArgs = {
	filters?: InputMaybe<SdgLocatieFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SdgSdgVertalingenArgs = {
	filters?: InputMaybe<ComponentSdgSdgProductVertalingFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SdgSdgEntity = {
	__typename?: 'SdgSdgEntity';
	attributes?: Maybe<SdgSdg>;
	id?: Maybe<Scalars['ID']>;
};

export type SdgSdgEntityResponse = {
	__typename?: 'SdgSdgEntityResponse';
	data?: Maybe<SdgSdgEntity>;
};

export type SdgSdgEntityResponseCollection = {
	__typename?: 'SdgSdgEntityResponseCollection';
	data: Array<SdgSdgEntity>;
	meta: ResponseCollectionMeta;
};

export type SdgSdgFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<SdgSdgFiltersInput>>>;
	bevoegdeOrganisatie?: InputMaybe<JsonFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	doelgroepBedrijf?: InputMaybe<BooleanFilterInput>;
	doelgroepBurger?: InputMaybe<BooleanFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	locaties?: InputMaybe<SdgLocatieFiltersInput>;
	not?: InputMaybe<SdgSdgFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<SdgSdgFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	productAanwezig?: InputMaybe<BooleanFilterInput>;
	productValtOnder?: InputMaybe<SdgSdgFiltersInput>;
	publicatieDatum?: InputMaybe<DateFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	titel?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	upl?: InputMaybe<StringFilterInput>;
	verantwoordelijkeOrganisatie?: InputMaybe<JsonFilterInput>;
	vertalingen?: InputMaybe<ComponentSdgSdgProductVertalingFiltersInput>;
};

export type SdgSdgInput = {
	bevoegdeOrganisatie?: InputMaybe<Scalars['JSON']>;
	doelgroepBedrijf?: InputMaybe<Scalars['Boolean']>;
	doelgroepBurger?: InputMaybe<Scalars['Boolean']>;
	locaties?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	password?: InputMaybe<Scalars['String']>;
	productAanwezig?: InputMaybe<Scalars['Boolean']>;
	productValtOnder?: InputMaybe<Scalars['ID']>;
	publicatieDatum?: InputMaybe<Scalars['Date']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	titel?: InputMaybe<Scalars['String']>;
	upl?: InputMaybe<Scalars['String']>;
	verantwoordelijkeOrganisatie?: InputMaybe<Scalars['JSON']>;
	vertalingen?: InputMaybe<Array<InputMaybe<ComponentSdgSdgProductVertalingInput>>>;
};

export type SdgUplLijst = {
	__typename?: 'SdgUplLijst';
	bedrijf?: Maybe<Scalars['Boolean']>;
	burger?: Maybe<Scalars['Boolean']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	password?: Maybe<Scalars['String']>;
	uniformeProductnaam?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	uri?: Maybe<Scalars['String']>;
};

export type SdgUplLijstEntity = {
	__typename?: 'SdgUplLijstEntity';
	attributes?: Maybe<SdgUplLijst>;
	id?: Maybe<Scalars['ID']>;
};

export type SdgUplLijstEntityResponse = {
	__typename?: 'SdgUplLijstEntityResponse';
	data?: Maybe<SdgUplLijstEntity>;
};

export type SdgUplLijstEntityResponseCollection = {
	__typename?: 'SdgUplLijstEntityResponseCollection';
	data: Array<SdgUplLijstEntity>;
	meta: ResponseCollectionMeta;
};

export type SdgUplLijstFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<SdgUplLijstFiltersInput>>>;
	bedrijf?: InputMaybe<BooleanFilterInput>;
	burger?: InputMaybe<BooleanFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<SdgUplLijstFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<SdgUplLijstFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	uniformeProductnaam?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	uri?: InputMaybe<StringFilterInput>;
};

export type SdgUplLijstInput = {
	bedrijf?: InputMaybe<Scalars['Boolean']>;
	burger?: InputMaybe<Scalars['Boolean']>;
	password?: InputMaybe<Scalars['String']>;
	uniformeProductnaam?: InputMaybe<Scalars['String']>;
	uri?: InputMaybe<Scalars['String']>;
};

export type SearchOverview = {
	__typename?: 'SearchOverview';
	Zoekresultaten: ComponentModulesSearchOverview;
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<SearchOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<SearchOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	searchHeader?: Maybe<ComponentModulesSearchHeader>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SearchOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type SearchOverviewEntity = {
	__typename?: 'SearchOverviewEntity';
	attributes?: Maybe<SearchOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type SearchOverviewEntityResponse = {
	__typename?: 'SearchOverviewEntityResponse';
	data?: Maybe<SearchOverviewEntity>;
};

export type SearchOverviewInput = {
	Zoekresultaten?: InputMaybe<ComponentModulesSearchOverviewInput>;
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['SearchOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	searchHeader?: InputMaybe<ComponentModulesSearchHeaderInput>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type SearchOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| Error;

export type SearchOverviewRelationResponseCollection = {
	__typename?: 'SearchOverviewRelationResponseCollection';
	data: Array<SearchOverviewEntity>;
};

export type SearchQueryResponse = {
	__typename?: 'SearchQueryResponse';
	createdAt?: Maybe<Scalars['DateTime']>;
	excerpt?: Maybe<Scalars['String']>;
	fullPath?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	searchSortKey?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type Sitemap = {
	__typename?: 'Sitemap';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<SitemapRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<SitemapModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SitemapEntity = {
	__typename?: 'SitemapEntity';
	attributes?: Maybe<Sitemap>;
	id?: Maybe<Scalars['ID']>;
};

export type SitemapEntityResponse = {
	__typename?: 'SitemapEntityResponse';
	data?: Maybe<SitemapEntity>;
};

export type SitemapInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['SitemapModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type SitemapModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| Error;

export type SitemapPage = {
	__typename?: 'SitemapPage';
	fullPath?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	updated_at?: Maybe<Scalars['String']>;
};

export type SitemapRelationResponseCollection = {
	__typename?: 'SitemapRelationResponseCollection';
	data: Array<SitemapEntity>;
};

export type StringFilterInput = {
	and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	contains?: InputMaybe<Scalars['String']>;
	containsi?: InputMaybe<Scalars['String']>;
	endsWith?: InputMaybe<Scalars['String']>;
	eq?: InputMaybe<Scalars['String']>;
	eqi?: InputMaybe<Scalars['String']>;
	gt?: InputMaybe<Scalars['String']>;
	gte?: InputMaybe<Scalars['String']>;
	in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	lt?: InputMaybe<Scalars['String']>;
	lte?: InputMaybe<Scalars['String']>;
	ne?: InputMaybe<Scalars['String']>;
	nei?: InputMaybe<Scalars['String']>;
	not?: InputMaybe<StringFilterInput>;
	notContains?: InputMaybe<Scalars['String']>;
	notContainsi?: InputMaybe<Scalars['String']>;
	notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	notNull?: InputMaybe<Scalars['Boolean']>;
	null?: InputMaybe<Scalars['Boolean']>;
	or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	startsWith?: InputMaybe<Scalars['String']>;
};

export type Subsidy = {
	__typename?: 'Subsidy';
	audience: Enum_Subsidy_Audience;
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	endDate?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header: ComponentModulesIntroHeader;
	hideFromOverview: Scalars['Boolean'];
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<SubsidyRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<SubsidyModulesDynamicZone>>>;
	onlineRequest?: Maybe<Scalars['Boolean']>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	status: Enum_Subsidy_Status;
	subsidyCeiling?: Maybe<Enum_Subsidy_Subsidyceiling>;
	subsidy_kinds?: Maybe<SubsidyKindRelationResponseCollection>;
	subsidy_scales?: Maybe<SubsidyScaleRelationResponseCollection>;
	tags?: Maybe<TagRelationResponseCollection>;
	targetGroupText?: Maybe<Scalars['String']>;
	target_groups?: Maybe<TargetGroupRelationResponseCollection>;
	themes?: Maybe<ThemeRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
	upls?: Maybe<UplRelationResponseCollection>;
};

export type SubsidyLocalizationsArgs = {
	filters?: InputMaybe<SubsidyFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidySubsidy_KindsArgs = {
	filters?: InputMaybe<SubsidyKindFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidySubsidy_ScalesArgs = {
	filters?: InputMaybe<SubsidyScaleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidyTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidyTarget_GroupsArgs = {
	filters?: InputMaybe<TargetGroupFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidyThemesArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidyUplsArgs = {
	filters?: InputMaybe<UplFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidyDetail = {
	__typename?: 'SubsidyDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<SubsidyDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<SubsidyDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	showInOverview?: Maybe<Scalars['Boolean']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubsidyDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type SubsidyDetailEntity = {
	__typename?: 'SubsidyDetailEntity';
	attributes?: Maybe<SubsidyDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type SubsidyDetailEntityResponse = {
	__typename?: 'SubsidyDetailEntityResponse';
	data?: Maybe<SubsidyDetailEntity>;
};

export type SubsidyDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['SubsidyDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	showInOverview?: InputMaybe<Scalars['Boolean']>;
};

export type SubsidyDetailModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesThemes
	| Error;

export type SubsidyDetailRelationResponseCollection = {
	__typename?: 'SubsidyDetailRelationResponseCollection';
	data: Array<SubsidyDetailEntity>;
};

export type SubsidyEntity = {
	__typename?: 'SubsidyEntity';
	attributes?: Maybe<Subsidy>;
	id?: Maybe<Scalars['ID']>;
};

export type SubsidyEntityResponse = {
	__typename?: 'SubsidyEntityResponse';
	data?: Maybe<SubsidyEntity>;
};

export type SubsidyEntityResponseCollection = {
	__typename?: 'SubsidyEntityResponseCollection';
	data: Array<SubsidyEntity>;
	meta: ResponseCollectionMeta;
};

export type SubsidyFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<SubsidyFiltersInput>>>;
	audience?: InputMaybe<StringFilterInput>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	endDate?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderFiltersInput>;
	hideFromOverview?: InputMaybe<BooleanFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<SubsidyFiltersInput>;
	not?: InputMaybe<SubsidyFiltersInput>;
	onlineRequest?: InputMaybe<BooleanFilterInput>;
	or?: InputMaybe<Array<InputMaybe<SubsidyFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	status?: InputMaybe<StringFilterInput>;
	subsidyCeiling?: InputMaybe<StringFilterInput>;
	subsidy_kinds?: InputMaybe<SubsidyKindFiltersInput>;
	subsidy_scales?: InputMaybe<SubsidyScaleFiltersInput>;
	tags?: InputMaybe<TagFiltersInput>;
	targetGroupText?: InputMaybe<StringFilterInput>;
	target_groups?: InputMaybe<TargetGroupFiltersInput>;
	themes?: InputMaybe<ThemeFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	upls?: InputMaybe<UplFiltersInput>;
};

export type SubsidyInput = {
	audience?: InputMaybe<Enum_Subsidy_Audience>;
	basePath?: InputMaybe<Scalars['String']>;
	endDate?: InputMaybe<Scalars['DateTime']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	hideFromOverview?: InputMaybe<Scalars['Boolean']>;
	keywords?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['SubsidyModulesDynamicZoneInput']>>;
	onlineRequest?: InputMaybe<Scalars['Boolean']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	status?: InputMaybe<Enum_Subsidy_Status>;
	subsidyCeiling?: InputMaybe<Enum_Subsidy_Subsidyceiling>;
	subsidy_kinds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	subsidy_scales?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	targetGroupText?: InputMaybe<Scalars['String']>;
	target_groups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	themes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
	upls?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type SubsidyKind = {
	__typename?: 'SubsidyKind';
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<SubsidyKindRelationResponseCollection>;
	name?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubsidyKindLocalizationsArgs = {
	filters?: InputMaybe<SubsidyKindFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidyKindEntity = {
	__typename?: 'SubsidyKindEntity';
	attributes?: Maybe<SubsidyKind>;
	id?: Maybe<Scalars['ID']>;
};

export type SubsidyKindEntityResponse = {
	__typename?: 'SubsidyKindEntityResponse';
	data?: Maybe<SubsidyKindEntity>;
};

export type SubsidyKindEntityResponseCollection = {
	__typename?: 'SubsidyKindEntityResponseCollection';
	data: Array<SubsidyKindEntity>;
	meta: ResponseCollectionMeta;
};

export type SubsidyKindFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<SubsidyKindFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<SubsidyKindFiltersInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<SubsidyKindFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<SubsidyKindFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SubsidyKindInput = {
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
};

export type SubsidyKindRelationResponseCollection = {
	__typename?: 'SubsidyKindRelationResponseCollection';
	data: Array<SubsidyKindEntity>;
};

export type SubsidyModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesThemes
	| Error;

export type SubsidyOverview = {
	__typename?: 'SubsidyOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<SubsidyOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<SubsidyOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubsidyOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type SubsidyOverviewEntity = {
	__typename?: 'SubsidyOverviewEntity';
	attributes?: Maybe<SubsidyOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type SubsidyOverviewEntityResponse = {
	__typename?: 'SubsidyOverviewEntityResponse';
	data?: Maybe<SubsidyOverviewEntity>;
};

export type SubsidyOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['SubsidyOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type SubsidyOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesSubjects
	| ComponentModulesSubsidyOverview
	| ComponentModulesThemes
	| Error;

export type SubsidyOverviewRelationResponseCollection = {
	__typename?: 'SubsidyOverviewRelationResponseCollection';
	data: Array<SubsidyOverviewEntity>;
};

export type SubsidyRelationResponseCollection = {
	__typename?: 'SubsidyRelationResponseCollection';
	data: Array<SubsidyEntity>;
};

export type SubsidyScale = {
	__typename?: 'SubsidyScale';
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<SubsidyScaleRelationResponseCollection>;
	name?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubsidyScaleLocalizationsArgs = {
	filters?: InputMaybe<SubsidyScaleFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubsidyScaleEntity = {
	__typename?: 'SubsidyScaleEntity';
	attributes?: Maybe<SubsidyScale>;
	id?: Maybe<Scalars['ID']>;
};

export type SubsidyScaleEntityResponse = {
	__typename?: 'SubsidyScaleEntityResponse';
	data?: Maybe<SubsidyScaleEntity>;
};

export type SubsidyScaleEntityResponseCollection = {
	__typename?: 'SubsidyScaleEntityResponseCollection';
	data: Array<SubsidyScaleEntity>;
	meta: ResponseCollectionMeta;
};

export type SubsidyScaleFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<SubsidyScaleFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<SubsidyScaleFiltersInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<SubsidyScaleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<SubsidyScaleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SubsidyScaleInput = {
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
};

export type SubsidyScaleRelationResponseCollection = {
	__typename?: 'SubsidyScaleRelationResponseCollection';
	data: Array<SubsidyScaleEntity>;
};

export type Tag = {
	__typename?: 'Tag';
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<TagRelationResponseCollection>;
	name: Scalars['String'];
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagLocalizationsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TagEntity = {
	__typename?: 'TagEntity';
	attributes?: Maybe<Tag>;
	id?: Maybe<Scalars['ID']>;
};

export type TagEntityResponse = {
	__typename?: 'TagEntityResponse';
	data?: Maybe<TagEntity>;
};

export type TagEntityResponseCollection = {
	__typename?: 'TagEntityResponseCollection';
	data: Array<TagEntity>;
	meta: ResponseCollectionMeta;
};

export type TagFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<TagFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<TagFiltersInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<TagFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<TagFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TagInput = {
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
};

export type TagRelationResponseCollection = {
	__typename?: 'TagRelationResponseCollection';
	data: Array<TagEntity>;
};

export type TargetGroup = {
	__typename?: 'TargetGroup';
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<TargetGroupRelationResponseCollection>;
	name?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TargetGroupLocalizationsArgs = {
	filters?: InputMaybe<TargetGroupFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TargetGroupEntity = {
	__typename?: 'TargetGroupEntity';
	attributes?: Maybe<TargetGroup>;
	id?: Maybe<Scalars['ID']>;
};

export type TargetGroupEntityResponse = {
	__typename?: 'TargetGroupEntityResponse';
	data?: Maybe<TargetGroupEntity>;
};

export type TargetGroupEntityResponseCollection = {
	__typename?: 'TargetGroupEntityResponseCollection';
	data: Array<TargetGroupEntity>;
	meta: ResponseCollectionMeta;
};

export type TargetGroupFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<TargetGroupFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<TargetGroupFiltersInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<TargetGroupFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<TargetGroupFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TargetGroupInput = {
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
};

export type TargetGroupRelationResponseCollection = {
	__typename?: 'TargetGroupRelationResponseCollection';
	data: Array<TargetGroupEntity>;
};

export type Theme = {
	__typename?: 'Theme';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	header?: Maybe<ComponentModulesExpandedHeader>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<ThemeRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<ThemeModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	slug: Scalars['String'];
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ThemeLocalizationsArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ThemeTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ThemeDetail = {
	__typename?: 'ThemeDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<ThemeDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<ThemeDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ThemeDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type ThemeDetailEntity = {
	__typename?: 'ThemeDetailEntity';
	attributes?: Maybe<ThemeDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type ThemeDetailEntityResponse = {
	__typename?: 'ThemeDetailEntityResponse';
	data?: Maybe<ThemeDetailEntity>;
};

export type ThemeDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['ThemeDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ThemeDetailModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesThemes
	| Error;

export type ThemeDetailRelationResponseCollection = {
	__typename?: 'ThemeDetailRelationResponseCollection';
	data: Array<ThemeDetailEntity>;
};

export type ThemeEntity = {
	__typename?: 'ThemeEntity';
	attributes?: Maybe<Theme>;
	id?: Maybe<Scalars['ID']>;
};

export type ThemeEntityResponse = {
	__typename?: 'ThemeEntityResponse';
	data?: Maybe<ThemeEntity>;
};

export type ThemeEntityResponseCollection = {
	__typename?: 'ThemeEntityResponseCollection';
	data: Array<ThemeEntity>;
	meta: ResponseCollectionMeta;
};

export type ThemeFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<ThemeFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	header?: InputMaybe<ComponentModulesExpandedHeaderFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<ThemeFiltersInput>;
	not?: InputMaybe<ThemeFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<ThemeFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	seo?: InputMaybe<ComponentModulesSeoFiltersInput>;
	slug?: InputMaybe<StringFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ThemeInput = {
	basePath?: InputMaybe<Scalars['String']>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesExpandedHeaderInput>;
	modules?: InputMaybe<Array<Scalars['ThemeModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
	slug?: InputMaybe<Scalars['String']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
};

export type ThemeModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesNewsOverview
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesThemes
	| Error;

export type ThemeOverview = {
	__typename?: 'ThemeOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<ThemeOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<ThemeOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ThemeOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type ThemeOverviewEntity = {
	__typename?: 'ThemeOverviewEntity';
	attributes?: Maybe<ThemeOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type ThemeOverviewEntityResponse = {
	__typename?: 'ThemeOverviewEntityResponse';
	data?: Maybe<ThemeOverviewEntity>;
};

export type ThemeOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['ThemeOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type ThemeOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesRegions
	| ComponentModulesScheduling
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| ComponentModulesTable
	| ComponentModulesThemes
	| Error;

export type ThemeOverviewRelationResponseCollection = {
	__typename?: 'ThemeOverviewRelationResponseCollection';
	data: Array<ThemeOverviewEntity>;
};

export type ThemeRelationResponseCollection = {
	__typename?: 'ThemeRelationResponseCollection';
	data: Array<ThemeEntity>;
};

export type Upl = {
	__typename?: 'Upl';
	URI?: Maybe<Scalars['String']>;
	UniformeProductnaam?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UplEntity = {
	__typename?: 'UplEntity';
	attributes?: Maybe<Upl>;
	id?: Maybe<Scalars['ID']>;
};

export type UplEntityResponse = {
	__typename?: 'UplEntityResponse';
	data?: Maybe<UplEntity>;
};

export type UplEntityResponseCollection = {
	__typename?: 'UplEntityResponseCollection';
	data: Array<UplEntity>;
	meta: ResponseCollectionMeta;
};

export type UplFiltersInput = {
	URI?: InputMaybe<StringFilterInput>;
	UniformeProductnaam?: InputMaybe<StringFilterInput>;
	and?: InputMaybe<Array<InputMaybe<UplFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<UplFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<UplFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UplInput = {
	URI?: InputMaybe<Scalars['String']>;
	UniformeProductnaam?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
};

export type UplRelationResponseCollection = {
	__typename?: 'UplRelationResponseCollection';
	data: Array<UplEntity>;
};

export type UploadFile = {
	__typename?: 'UploadFile';
	alternativeText?: Maybe<Scalars['String']>;
	blurhash?: Maybe<Scalars['String']>;
	caption?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	ext?: Maybe<Scalars['String']>;
	formats?: Maybe<Scalars['JSON']>;
	hash: Scalars['String'];
	height?: Maybe<Scalars['Int']>;
	mime: Scalars['String'];
	name: Scalars['String'];
	password?: Maybe<Scalars['String']>;
	previewUrl?: Maybe<Scalars['String']>;
	provider: Scalars['String'];
	provider_metadata?: Maybe<Scalars['JSON']>;
	related?: Maybe<Array<Maybe<GenericMorph>>>;
	size: Scalars['Float'];
	updatedAt?: Maybe<Scalars['DateTime']>;
	url: Scalars['String'];
	width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
	__typename?: 'UploadFileEntity';
	attributes?: Maybe<UploadFile>;
	id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
	__typename?: 'UploadFileEntityResponse';
	data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
	__typename?: 'UploadFileEntityResponseCollection';
	data: Array<UploadFileEntity>;
	meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
	alternativeText?: InputMaybe<StringFilterInput>;
	and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
	blurhash?: InputMaybe<StringFilterInput>;
	caption?: InputMaybe<StringFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	ext?: InputMaybe<StringFilterInput>;
	folder?: InputMaybe<UploadFolderFiltersInput>;
	folderPath?: InputMaybe<StringFilterInput>;
	formats?: InputMaybe<JsonFilterInput>;
	hash?: InputMaybe<StringFilterInput>;
	height?: InputMaybe<IntFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	mime?: InputMaybe<StringFilterInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<UploadFileFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	previewUrl?: InputMaybe<StringFilterInput>;
	provider?: InputMaybe<StringFilterInput>;
	provider_metadata?: InputMaybe<JsonFilterInput>;
	size?: InputMaybe<FloatFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	url?: InputMaybe<StringFilterInput>;
	width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
	alternativeText?: InputMaybe<Scalars['String']>;
	blurhash?: InputMaybe<Scalars['String']>;
	caption?: InputMaybe<Scalars['String']>;
	ext?: InputMaybe<Scalars['String']>;
	folder?: InputMaybe<Scalars['ID']>;
	folderPath?: InputMaybe<Scalars['String']>;
	formats?: InputMaybe<Scalars['JSON']>;
	hash?: InputMaybe<Scalars['String']>;
	height?: InputMaybe<Scalars['Int']>;
	mime?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	previewUrl?: InputMaybe<Scalars['String']>;
	provider?: InputMaybe<Scalars['String']>;
	provider_metadata?: InputMaybe<Scalars['JSON']>;
	size?: InputMaybe<Scalars['Float']>;
	url?: InputMaybe<Scalars['String']>;
	width?: InputMaybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
	__typename?: 'UploadFileRelationResponseCollection';
	data: Array<UploadFileEntity>;
};

export type UploadFolder = {
	__typename?: 'UploadFolder';
	children?: Maybe<UploadFolderRelationResponseCollection>;
	createdAt?: Maybe<Scalars['DateTime']>;
	files?: Maybe<UploadFileRelationResponseCollection>;
	name: Scalars['String'];
	parent?: Maybe<UploadFolderEntityResponse>;
	password?: Maybe<Scalars['String']>;
	path: Scalars['String'];
	pathId: Scalars['Int'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UploadFolderChildrenArgs = {
	filters?: InputMaybe<UploadFolderFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderFilesArgs = {
	filters?: InputMaybe<UploadFileFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
	__typename?: 'UploadFolderEntity';
	attributes?: Maybe<UploadFolder>;
	id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
	__typename?: 'UploadFolderEntityResponse';
	data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
	__typename?: 'UploadFolderEntityResponseCollection';
	data: Array<UploadFolderEntity>;
	meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
	children?: InputMaybe<UploadFolderFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	files?: InputMaybe<UploadFileFiltersInput>;
	id?: InputMaybe<IdFilterInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<UploadFolderFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
	parent?: InputMaybe<UploadFolderFiltersInput>;
	password?: InputMaybe<StringFilterInput>;
	path?: InputMaybe<StringFilterInput>;
	pathId?: InputMaybe<IntFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
	children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	name?: InputMaybe<Scalars['String']>;
	parent?: InputMaybe<Scalars['ID']>;
	password?: InputMaybe<Scalars['String']>;
	path?: InputMaybe<Scalars['String']>;
	pathId?: InputMaybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
	__typename?: 'UploadFolderRelationResponseCollection';
	data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
	__typename?: 'UsersPermissionsCreateRolePayload';
	ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
	__typename?: 'UsersPermissionsDeleteRolePayload';
	ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
	identifier: Scalars['String'];
	password: Scalars['String'];
	provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
	__typename?: 'UsersPermissionsLoginPayload';
	jwt?: Maybe<Scalars['String']>;
	user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
	__typename?: 'UsersPermissionsMe';
	blocked?: Maybe<Scalars['Boolean']>;
	confirmed?: Maybe<Scalars['Boolean']>;
	email?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	role?: Maybe<UsersPermissionsMeRole>;
	username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
	__typename?: 'UsersPermissionsMeRole';
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	name: Scalars['String'];
	type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
	__typename?: 'UsersPermissionsPasswordPayload';
	ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
	__typename?: 'UsersPermissionsPermission';
	action: Scalars['String'];
	createdAt?: Maybe<Scalars['DateTime']>;
	password?: Maybe<Scalars['String']>;
	role?: Maybe<UsersPermissionsRoleEntityResponse>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
	__typename?: 'UsersPermissionsPermissionEntity';
	attributes?: Maybe<UsersPermissionsPermission>;
	id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
	action?: InputMaybe<StringFilterInput>;
	and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
	__typename?: 'UsersPermissionsPermissionRelationResponseCollection';
	data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
	email: Scalars['String'];
	password: Scalars['String'];
	username: Scalars['String'];
};

export type UsersPermissionsRole = {
	__typename?: 'UsersPermissionsRole';
	createdAt?: Maybe<Scalars['DateTime']>;
	description?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	password?: Maybe<Scalars['String']>;
	permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
	type?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
	filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
	filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
	__typename?: 'UsersPermissionsRoleEntity';
	attributes?: Maybe<UsersPermissionsRole>;
	id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
	__typename?: 'UsersPermissionsRoleEntityResponse';
	data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
	__typename?: 'UsersPermissionsRoleEntityResponseCollection';
	data: Array<UsersPermissionsRoleEntity>;
	meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	description?: InputMaybe<StringFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	name?: InputMaybe<StringFilterInput>;
	not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
	type?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
	description?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	type?: InputMaybe<Scalars['String']>;
	users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
	__typename?: 'UsersPermissionsUpdateRolePayload';
	ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
	__typename?: 'UsersPermissionsUser';
	blocked?: Maybe<Scalars['Boolean']>;
	confirmed?: Maybe<Scalars['Boolean']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	email: Scalars['String'];
	provider?: Maybe<Scalars['String']>;
	role?: Maybe<UsersPermissionsRoleEntityResponse>;
	updatedAt?: Maybe<Scalars['DateTime']>;
	username: Scalars['String'];
};

export type UsersPermissionsUserEntity = {
	__typename?: 'UsersPermissionsUserEntity';
	attributes?: Maybe<UsersPermissionsUser>;
	id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
	__typename?: 'UsersPermissionsUserEntityResponse';
	data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
	__typename?: 'UsersPermissionsUserEntityResponseCollection';
	data: Array<UsersPermissionsUserEntity>;
	meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
	blocked?: InputMaybe<BooleanFilterInput>;
	confirmationToken?: InputMaybe<StringFilterInput>;
	confirmed?: InputMaybe<BooleanFilterInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	email?: InputMaybe<StringFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<UsersPermissionsUserFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	provider?: InputMaybe<StringFilterInput>;
	resetPasswordToken?: InputMaybe<StringFilterInput>;
	role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
	blocked?: InputMaybe<Scalars['Boolean']>;
	confirmationToken?: InputMaybe<Scalars['String']>;
	confirmed?: InputMaybe<Scalars['Boolean']>;
	email?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	provider?: InputMaybe<Scalars['String']>;
	resetPasswordToken?: InputMaybe<Scalars['String']>;
	role?: InputMaybe<Scalars['ID']>;
	username?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
	__typename?: 'UsersPermissionsUserRelationResponseCollection';
	data: Array<UsersPermissionsUserEntity>;
};

export type VacancyDetail = {
	__typename?: 'VacancyDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<VacancyDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<VacancyDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	share?: Maybe<ComponentModulesShare>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VacancyDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type VacancyDetailEntity = {
	__typename?: 'VacancyDetailEntity';
	attributes?: Maybe<VacancyDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type VacancyDetailEntityResponse = {
	__typename?: 'VacancyDetailEntityResponse';
	data?: Maybe<VacancyDetailEntity>;
};

export type VacancyDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['VacancyDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	share?: InputMaybe<ComponentModulesShareInput>;
};

export type VacancyDetailModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesSubjects
	| Error;

export type VacancyDetailRelationResponseCollection = {
	__typename?: 'VacancyDetailRelationResponseCollection';
	data: Array<VacancyDetailEntity>;
};

export type VacancyInternalDetail = {
	__typename?: 'VacancyInternalDetail';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<VacancyInternalDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<VacancyInternalDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VacancyInternalDetailEntity = {
	__typename?: 'VacancyInternalDetailEntity';
	attributes?: Maybe<VacancyInternalDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type VacancyInternalDetailEntityResponse = {
	__typename?: 'VacancyInternalDetailEntityResponse';
	data?: Maybe<VacancyInternalDetailEntity>;
};

export type VacancyInternalDetailInput = {
	basePath?: InputMaybe<Scalars['String']>;
	modules?: InputMaybe<Array<Scalars['VacancyInternalDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
};

export type VacancyInternalDetailModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesFeaturedNews
	| ComponentModulesFeaturedPages
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesSubjects
	| Error;

export type VacancyInternalDetailRelationResponseCollection = {
	__typename?: 'VacancyInternalDetailRelationResponseCollection';
	data: Array<VacancyInternalDetailEntity>;
};

export type VacancyInternalOverview = {
	__typename?: 'VacancyInternalOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<VacancyInternalOverviewRelationResponseCollection>;
	modules: Array<Maybe<VacancyInternalOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VacancyInternalOverviewEntity = {
	__typename?: 'VacancyInternalOverviewEntity';
	attributes?: Maybe<VacancyInternalOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type VacancyInternalOverviewEntityResponse = {
	__typename?: 'VacancyInternalOverviewEntityResponse';
	data?: Maybe<VacancyInternalOverviewEntity>;
};

export type VacancyInternalOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['VacancyInternalOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type VacancyInternalOverviewModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesVacanciesOverview
	| Error;

export type VacancyInternalOverviewRelationResponseCollection = {
	__typename?: 'VacancyInternalOverviewRelationResponseCollection';
	data: Array<VacancyInternalOverviewEntity>;
};

export type VacancyOverview = {
	__typename?: 'VacancyOverview';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<VacancyOverviewRelationResponseCollection>;
	modules: Array<Maybe<VacancyOverviewModulesDynamicZone>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VacancyOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type VacancyOverviewEntity = {
	__typename?: 'VacancyOverviewEntity';
	attributes?: Maybe<VacancyOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type VacancyOverviewEntityResponse = {
	__typename?: 'VacancyOverviewEntityResponse';
	data?: Maybe<VacancyOverviewEntity>;
};

export type VacancyOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['VacancyOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type VacancyOverviewModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesVacanciesOverview
	| Error;

export type VacancyOverviewRelationResponseCollection = {
	__typename?: 'VacancyOverviewRelationResponseCollection';
	data: Array<VacancyOverviewEntity>;
};

export type WobRequest = {
	__typename?: 'WobRequest';
	createdAt?: Maybe<Scalars['DateTime']>;
	date?: Maybe<Scalars['Date']>;
	files?: Maybe<UploadFileRelationResponseCollection>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<WobRequestRelationResponseCollection>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	tags?: Maybe<TagRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
	zip?: Maybe<Scalars['JSON']>;
};

export type WobRequestFilesArgs = {
	filters?: InputMaybe<UploadFileFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WobRequestLocalizationsArgs = {
	filters?: InputMaybe<WobRequestFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WobRequestTagsArgs = {
	filters?: InputMaybe<TagFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WobRequestEntity = {
	__typename?: 'WobRequestEntity';
	attributes?: Maybe<WobRequest>;
	id?: Maybe<Scalars['ID']>;
};

export type WobRequestEntityResponse = {
	__typename?: 'WobRequestEntityResponse';
	data?: Maybe<WobRequestEntity>;
};

export type WobRequestEntityResponseCollection = {
	__typename?: 'WobRequestEntityResponseCollection';
	data: Array<WobRequestEntity>;
	meta: ResponseCollectionMeta;
};

export type WobRequestFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<WobRequestFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	date?: InputMaybe<DateFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<WobRequestFiltersInput>;
	not?: InputMaybe<WobRequestFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<WobRequestFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	tags?: InputMaybe<TagFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	zip?: InputMaybe<JsonFilterInput>;
};

export type WobRequestInput = {
	date?: InputMaybe<Scalars['Date']>;
	files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
	zip?: InputMaybe<Scalars['JSON']>;
};

export type WobRequestOverview = {
	__typename?: 'WobRequestOverview';
	basePath?: Maybe<Scalars['String']>;
	bodyText?: Maybe<ComponentModulesBodyText>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	list: ComponentModulesWobRequestOverview;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<WobRequestOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<WobRequestOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WobRequestOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type WobRequestOverviewEntity = {
	__typename?: 'WobRequestOverviewEntity';
	attributes?: Maybe<WobRequestOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type WobRequestOverviewEntityResponse = {
	__typename?: 'WobRequestOverviewEntityResponse';
	data?: Maybe<WobRequestOverviewEntity>;
};

export type WobRequestOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	list?: InputMaybe<ComponentModulesWobRequestOverviewInput>;
	modules?: InputMaybe<Array<Scalars['WobRequestOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type WobRequestOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| Error;

export type WobRequestOverviewRelationResponseCollection = {
	__typename?: 'WobRequestOverviewRelationResponseCollection';
	data: Array<WobRequestOverviewEntity>;
};

export type WobRequestRelationResponseCollection = {
	__typename?: 'WobRequestRelationResponseCollection';
	data: Array<WobRequestEntity>;
};

export type WooCategory = {
	__typename?: 'WooCategory';
	createdAt?: Maybe<Scalars['DateTime']>;
	password?: Maybe<Scalars['String']>;
	resource: Scalars['String'];
	sitemap: Scalars['String'];
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WooCategoryCount = {
	__typename?: 'WooCategoryCount';
	count?: Maybe<Scalars['Int']>;
	sitemap?: Maybe<Scalars['String']>;
};

export type WooCategoryEntity = {
	__typename?: 'WooCategoryEntity';
	attributes?: Maybe<WooCategory>;
	id?: Maybe<Scalars['ID']>;
};

export type WooCategoryEntityResponse = {
	__typename?: 'WooCategoryEntityResponse';
	data?: Maybe<WooCategoryEntity>;
};

export type WooCategoryEntityResponseCollection = {
	__typename?: 'WooCategoryEntityResponseCollection';
	data: Array<WooCategoryEntity>;
	meta: ResponseCollectionMeta;
};

export type WooCategoryFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<WooCategoryFiltersInput>>>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	not?: InputMaybe<WooCategoryFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<WooCategoryFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	resource?: InputMaybe<StringFilterInput>;
	sitemap?: InputMaybe<StringFilterInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type WooCategoryInput = {
	password?: InputMaybe<Scalars['String']>;
	resource?: InputMaybe<Scalars['String']>;
	sitemap?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type WooDocumentDetail = {
	__typename?: 'WooDocumentDetail';
	createdAt?: Maybe<Scalars['DateTime']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<WooDocumentDetailRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<WooDocumentDetailModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WooDocumentDetailLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type WooDocumentDetailEntity = {
	__typename?: 'WooDocumentDetailEntity';
	attributes?: Maybe<WooDocumentDetail>;
	id?: Maybe<Scalars['ID']>;
};

export type WooDocumentDetailEntityResponse = {
	__typename?: 'WooDocumentDetailEntityResponse';
	data?: Maybe<WooDocumentDetailEntity>;
};

export type WooDocumentDetailInput = {
	modules?: InputMaybe<Array<Scalars['WooDocumentDetailModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type WooDocumentDetailModulesDynamicZone =
	| ComponentModulesBbvmsMediaplayer
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesLinkBox
	| ComponentModulesShare
	| Error;

export type WooDocumentDetailRelationResponseCollection = {
	__typename?: 'WooDocumentDetailRelationResponseCollection';
	data: Array<WooDocumentDetailEntity>;
};

export type WooRequest = {
	__typename?: 'WooRequest';
	basePath?: Maybe<Scalars['String']>;
	caseNumber?: Maybe<Scalars['String']>;
	category?: Maybe<WooCategoryEntityResponse>;
	createdAt?: Maybe<Scalars['DateTime']>;
	description?: Maybe<Scalars['String']>;
	endDate?: Maybe<Scalars['Date']>;
	files: UploadFileRelationResponseCollection;
	fullPath?: Maybe<Scalars['String']>;
	fullSlug?: Maybe<Scalars['String']>;
	keywords?: Maybe<Scalars['String']>;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<WooRequestRelationResponseCollection>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	publisher?: Maybe<Enum_Woorequest_Publisher>;
	shortTitle?: Maybe<Scalars['String']>;
	slug: Scalars['String'];
	startDate: Scalars['Date'];
	themes?: Maybe<ThemeRelationResponseCollection>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['DateTime']>;
	zip?: Maybe<Scalars['JSON']>;
};

export type WooRequestFilesArgs = {
	filters?: InputMaybe<UploadFileFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WooRequestLocalizationsArgs = {
	filters?: InputMaybe<WooRequestFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WooRequestThemesArgs = {
	filters?: InputMaybe<ThemeFiltersInput>;
	pagination?: InputMaybe<PaginationArg>;
	publicationState?: InputMaybe<PublicationState>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WooRequestEntity = {
	__typename?: 'WooRequestEntity';
	attributes?: Maybe<WooRequest>;
	id?: Maybe<Scalars['ID']>;
};

export type WooRequestEntityResponse = {
	__typename?: 'WooRequestEntityResponse';
	data?: Maybe<WooRequestEntity>;
};

export type WooRequestEntityResponseCollection = {
	__typename?: 'WooRequestEntityResponseCollection';
	data: Array<WooRequestEntity>;
	meta: ResponseCollectionMeta;
};

export type WooRequestFiltersInput = {
	and?: InputMaybe<Array<InputMaybe<WooRequestFiltersInput>>>;
	basePath?: InputMaybe<StringFilterInput>;
	caseNumber?: InputMaybe<StringFilterInput>;
	category?: InputMaybe<WooCategoryFiltersInput>;
	createdAt?: InputMaybe<DateTimeFilterInput>;
	description?: InputMaybe<StringFilterInput>;
	endDate?: InputMaybe<DateFilterInput>;
	endDateUnix?: InputMaybe<IntFilterInput>;
	fullPath?: InputMaybe<StringFilterInput>;
	fullSlug?: InputMaybe<StringFilterInput>;
	id?: InputMaybe<IdFilterInput>;
	keywords?: InputMaybe<StringFilterInput>;
	locale?: InputMaybe<StringFilterInput>;
	localizations?: InputMaybe<WooRequestFiltersInput>;
	not?: InputMaybe<WooRequestFiltersInput>;
	or?: InputMaybe<Array<InputMaybe<WooRequestFiltersInput>>>;
	password?: InputMaybe<StringFilterInput>;
	publishedAt?: InputMaybe<DateTimeFilterInput>;
	publisher?: InputMaybe<StringFilterInput>;
	shortTitle?: InputMaybe<StringFilterInput>;
	slug?: InputMaybe<StringFilterInput>;
	startDate?: InputMaybe<DateFilterInput>;
	startDateUnix?: InputMaybe<IntFilterInput>;
	themes?: InputMaybe<ThemeFiltersInput>;
	title?: InputMaybe<StringFilterInput>;
	updatedAt?: InputMaybe<DateTimeFilterInput>;
	zip?: InputMaybe<JsonFilterInput>;
};

export type WooRequestInput = {
	basePath?: InputMaybe<Scalars['String']>;
	caseNumber?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['ID']>;
	description?: InputMaybe<Scalars['String']>;
	endDate?: InputMaybe<Scalars['Date']>;
	endDateUnix?: InputMaybe<Scalars['Int']>;
	files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	fullPath?: InputMaybe<Scalars['String']>;
	fullSlug?: InputMaybe<Scalars['String']>;
	keywords?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	publisher?: InputMaybe<Enum_Woorequest_Publisher>;
	shortTitle?: InputMaybe<Scalars['String']>;
	slug?: InputMaybe<Scalars['String']>;
	startDate?: InputMaybe<Scalars['Date']>;
	startDateUnix?: InputMaybe<Scalars['Int']>;
	themes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
	title?: InputMaybe<Scalars['String']>;
	zip?: InputMaybe<Scalars['JSON']>;
};

export type WooRequestOverview = {
	__typename?: 'WooRequestOverview';
	basePath?: Maybe<Scalars['String']>;
	bodyText?: Maybe<ComponentModulesBodyText>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	list: ComponentModulesWooRequestOverview;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<WooRequestOverviewRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<WooRequestOverviewModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WooRequestOverviewLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type WooRequestOverviewEntity = {
	__typename?: 'WooRequestOverviewEntity';
	attributes?: Maybe<WooRequestOverview>;
	id?: Maybe<Scalars['ID']>;
};

export type WooRequestOverviewEntityResponse = {
	__typename?: 'WooRequestOverviewEntityResponse';
	data?: Maybe<WooRequestOverviewEntity>;
};

export type WooRequestOverviewInput = {
	basePath?: InputMaybe<Scalars['String']>;
	bodyText?: InputMaybe<ComponentModulesBodyTextInput>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	list?: InputMaybe<ComponentModulesWooRequestOverviewInput>;
	modules?: InputMaybe<Array<Scalars['WooRequestOverviewModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type WooRequestOverviewModulesDynamicZone =
	| ComponentModulesAccordeon
	| ComponentModulesBodyText
	| ComponentModulesBodyTextTwoColumn
	| ComponentModulesCta
	| ComponentModulesDownloads
	| ComponentModulesHighlight
	| ComponentModulesLinkBox
	| ComponentModulesMedia
	| ComponentModulesPhotoAlbum
	| ComponentModulesStepByStep
	| ComponentModulesSubjects
	| Error;

export type WooRequestOverviewRelationResponseCollection = {
	__typename?: 'WooRequestOverviewRelationResponseCollection';
	data: Array<WooRequestOverviewEntity>;
};

export type WooRequestRelationResponseCollection = {
	__typename?: 'WooRequestRelationResponseCollection';
	data: Array<WooRequestEntity>;
};

export type WorkAt = {
	__typename?: 'WorkAt';
	basePath?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['DateTime']>;
	header: ComponentModulesIntroHeader;
	locale?: Maybe<Scalars['String']>;
	localizations?: Maybe<WorkAtRelationResponseCollection>;
	modules?: Maybe<Array<Maybe<WorkAtModulesDynamicZone>>>;
	password?: Maybe<Scalars['String']>;
	publishedAt?: Maybe<Scalars['DateTime']>;
	seo: ComponentModulesSeo;
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkAtLocalizationsArgs = {
	publicationState?: InputMaybe<PublicationState>;
};

export type WorkAtEntity = {
	__typename?: 'WorkAtEntity';
	attributes?: Maybe<WorkAt>;
	id?: Maybe<Scalars['ID']>;
};

export type WorkAtEntityResponse = {
	__typename?: 'WorkAtEntityResponse';
	data?: Maybe<WorkAtEntity>;
};

export type WorkAtInput = {
	basePath?: InputMaybe<Scalars['String']>;
	header?: InputMaybe<ComponentModulesIntroHeaderInput>;
	modules?: InputMaybe<Array<Scalars['WorkAtModulesDynamicZoneInput']>>;
	password?: InputMaybe<Scalars['String']>;
	publishedAt?: InputMaybe<Scalars['DateTime']>;
	seo?: InputMaybe<ComponentModulesSeoInput>;
};

export type WorkAtModulesDynamicZone =
	| ComponentModulesBodyText
	| ComponentModulesCta
	| ComponentModulesLinkBox
	| ComponentModulesPartners
	| Error;

export type WorkAtRelationResponseCollection = {
	__typename?: 'WorkAtRelationResponseCollection';
	data: Array<WorkAtEntity>;
};

export type AddUserToNewslettersMutationVariables = Exact<{
	campaignId: Scalars['String'];
	email: Scalars['String'];
	newsletters: Array<InputMaybe<NewslettersListInput>> | InputMaybe<NewslettersListInput>;
}>;

export type AddUserToNewslettersMutation = {
	__typename?: 'Mutation';
	addUserToNewsletters?: Array<{ __typename?: 'NewsletterSubscriptions'; campaign: string; succeeded: boolean }>;
};

export type UpdateUserNewsletterSubscriptionMutationVariables = Exact<{
	campaignId: Scalars['String'];
	userId: Scalars['String'];
	email: Scalars['String'];
	newsletters: Array<InputMaybe<NewslettersListInput>> | InputMaybe<NewslettersListInput>;
}>;

export type UpdateUserNewsletterSubscriptionMutation = {
	__typename?: 'Mutation';
	updateUserNewsletterSubscription?: Array<{
		__typename?: 'NewsletterSubscriptions';
		campaign: string;
		succeeded: boolean;
	}>;
};

export type GetCommentsQueryVariables = Exact<{
	id?: InputMaybe<Scalars['Int']>;
	uid?: InputMaybe<Scalars['String']>;
	page?: InputMaybe<Scalars['Int']>;
	pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetCommentsQuery = {
	__typename?: 'Query';
	getCommentsEnabled?: boolean;
	conversationsConversationitems?: {
		__typename?: 'ConversationsConversationitemEntityResponseCollection';
		data: Array<{
			__typename?: 'ConversationsConversationitemEntity';
			id?: string;
			attributes?: {
				__typename: 'ConversationsConversationitem';
				contentTypeId?: number;
				contentTypeUid?: string;
				message?: string;
				author?: string;
				publishedAt?: any;
			};
		}>;
		meta: {
			__typename?: 'ResponseCollectionMeta';
			pagination: { __typename?: 'Pagination'; total: number; page: number; pageSize: number; pageCount: number };
		};
	};
};

export type AddCommentMutationVariables = Exact<{
	contentTypeUid?: InputMaybe<Scalars['String']>;
	contentTypeId?: InputMaybe<Scalars['Int']>;
	author?: InputMaybe<Scalars['String']>;
	message?: InputMaybe<Scalars['String']>;
}>;

export type AddCommentMutation = {
	__typename?: 'Mutation';
	addComment?: {
		__typename?: 'ConversationsConversationitemEntityResponse';
		data?: {
			__typename?: 'ConversationsConversationitemEntity';
			attributes?: {
				__typename: 'ConversationsConversationitem';
				contentTypeId?: number;
				contentTypeUid?: string;
				message?: string;
				author?: string;
				publishedAt?: any;
			};
		};
	};
};

export type GetCondoleancePageSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCondoleancePageSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; slug?: string; locale?: string }>;
};

export type GetEfroOostInConvoWithArticleSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEfroOostInConvoWithArticleSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetEfroOostInConvoWithArticlesQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	showOnHomePage?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetEfroOostInConvoWithArticlesQuery = {
	__typename?: 'Query';
	efroOostInConvoWithArticles?: {
		__typename?: 'EfroOostInConvoWithArticleEntityResponseCollection';
		data: Array<{
			__typename?: 'EfroOostInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetEfroOostNewsArticleSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEfroOostNewsArticleSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetEfroOostNewsArticlesQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	showOnHomePage?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetEfroOostNewsArticlesQuery = {
	__typename?: 'Query';
	efroOostNewsarticles?: {
		__typename?: 'EfroOostNewsarticleEntityResponseCollection';
		data: Array<{
			__typename?: 'EfroOostNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostNewsarticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsEfroOost';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'EfroOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'EfroOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'EfroOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetEfroOostPageSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEfroOostPageSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GetEventsQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	themeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetEventsQuery = {
	__typename?: 'Query';
	events?: {
		__typename?: 'EventEntityResponseCollection';
		data: Array<{
			__typename?: 'EventEntity';
			id?: string;
			attributes?: {
				__typename: 'Event';
				title: string;
				url: string;
				location: string;
				from: any;
				until: any;
				basePath: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				theme?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				share?: {
					__typename?: 'ComponentModulesShare';
					title?: any;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type FindNotesByRelationQueryVariables = Exact<{
	contentTypeUid: Scalars['String'];
	contentTypeId: Scalars['Int'];
}>;

export type FindNotesByRelationQuery = {
	__typename?: 'Query';
	notesByRelation?: {
		__typename?: 'NoteNoteEntityResponseCollection';
		data: Array<{
			__typename?: 'NoteNoteEntity';
			id?: string;
			attributes?: { __typename?: 'NoteNote'; title: string; note?: string; noteAuthor?: string; pageAuthor?: string };
		}>;
	};
};

export type GetGeolinkPageSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGeolinkPageSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GlobalSearchQueryVariables = Exact<{
	sort?: InputMaybe<Scalars['String']>;
	searchValue: Scalars['String'];
	createdAtBefore?: InputMaybe<Scalars['String']>;
	createdAtAfter?: InputMaybe<Scalars['String']>;
	domain: Scalars['String'];
}>;

export type GlobalSearchQuery = {
	__typename?: 'Query';
	searchCollectionTypes: Array<{
		__typename?: 'SearchQueryResponse';
		id?: string;
		title?: string;
		excerpt?: string;
		createdAt?: any;
		fullPath?: string;
	}>;
};

export type GlobalSearchDocumentsQueryVariables = Exact<{
	searchValue: Scalars['String'];
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	createdAtBefore?: InputMaybe<Scalars['String']>;
	createdAtAfter?: InputMaybe<Scalars['String']>;
}>;

export type GlobalSearchDocumentsQuery = {
	__typename?: 'Query';
	searchMediaLibrary?: {
		__typename?: 'UploadFileEntityResponseCollection';
		data: Array<{
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				name: string;
				url: string;
				createdAt?: any;
				ext?: string;
				size: number;
			};
		}>;
	};
};

export type GlobalSearchWooRequestsQueryVariables = Exact<{
	searchValue: Scalars['String'];
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	createdAtBefore?: InputMaybe<Scalars['String']>;
	createdAtAfter?: InputMaybe<Scalars['String']>;
}>;

export type GlobalSearchWooRequestsQuery = {
	__typename?: 'Query';
	searchWooRequests?: {
		__typename?: 'WooRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: { __typename?: 'WooRequest'; title: string; zip?: any; createdAt?: any; updatedAt?: any };
		}>;
	};
};

export type GlobalSearchWobRequestsQueryVariables = Exact<{
	searchValue: Scalars['String'];
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	createdAtBefore?: InputMaybe<Scalars['String']>;
	createdAtAfter?: InputMaybe<Scalars['String']>;
}>;

export type GlobalSearchWobRequestsQuery = {
	__typename?: 'Query';
	searchWobRequests?: {
		__typename?: 'WobRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WobRequestEntity';
			id?: string;
			attributes?: { __typename?: 'WobRequest'; title: string; zip?: any; createdAt?: any; updatedAt?: any };
		}>;
	};
};

export type GlobalSearchNotesQueryVariables = Exact<{
	searchValue: Scalars['String'];
}>;

export type GlobalSearchNotesQuery = {
	__typename?: 'Query';
	searchNotes?: {
		__typename?: 'NoteSearchResults';
		entities?: Array<{
			__typename?: 'NoteSearchResult';
			id?: number;
			title?: string;
			excerpt?: string;
			createdAt?: string;
			fullPath?: string;
		}>;
	};
};

export type GetHighlightQueryVariables = Exact<{ [key: string]: never }>;

export type GetHighlightQuery = {
	__typename?: 'Query';
	newsarticles?: {
		__typename?: 'NewsarticleEntityResponseCollection';
		data: Array<{
			__typename?: 'NewsarticleEntity';
			id?: string;
			attributes?: {
				__typename: 'Newsarticle';
				publishedAt?: any;
				title: string;
				basePath?: string;
				slug?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
	};
	subsidies?: {
		__typename?: 'SubsidyEntityResponseCollection';
		data: Array<{
			__typename?: 'SubsidyEntity';
			id?: string;
			attributes?: {
				__typename: 'Subsidy';
				basePath?: string;
				slug?: string;
				header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
			};
		}>;
	};
	permits?: {
		__typename?: 'PermitEntityResponseCollection';
		data: Array<{
			__typename?: 'PermitEntity';
			id?: string;
			attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
		}>;
	};
	mapsFigures?: {
		__typename?: 'MapsFigureEntityResponseCollection';
		data: Array<{
			__typename?: 'MapsFigureEntity';
			id?: string;
			attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
		}>;
	};
	wooRequests?: {
		__typename?: 'WooRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
		}>;
	};
	events?: {
		__typename?: 'EventEntityResponseCollection';
		data: Array<{
			__typename?: 'EventEntity';
			id?: string;
			attributes?: {
				__typename: 'Event';
				title: string;
				url: string;
				location: string;
				from: any;
				until: any;
				basePath: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				theme?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				share?: {
					__typename?: 'ComponentModulesShare';
					title?: any;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
	};
};

export type GetHighlightWithThemeQueryVariables = Exact<{
	themeId?: InputMaybe<Scalars['ID']>;
}>;

export type GetHighlightWithThemeQuery = {
	__typename?: 'Query';
	newsarticles?: {
		__typename?: 'NewsarticleEntityResponseCollection';
		data: Array<{
			__typename?: 'NewsarticleEntity';
			id?: string;
			attributes?: {
				__typename: 'Newsarticle';
				publishedAt?: any;
				title: string;
				basePath?: string;
				slug?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
	};
	subsidies?: {
		__typename?: 'SubsidyEntityResponseCollection';
		data: Array<{
			__typename?: 'SubsidyEntity';
			id?: string;
			attributes?: {
				__typename: 'Subsidy';
				basePath?: string;
				slug?: string;
				header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
			};
		}>;
	};
	permits?: {
		__typename?: 'PermitEntityResponseCollection';
		data: Array<{
			__typename?: 'PermitEntity';
			id?: string;
			attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
		}>;
	};
	mapsFigures?: {
		__typename?: 'MapsFigureEntityResponseCollection';
		data: Array<{
			__typename?: 'MapsFigureEntity';
			id?: string;
			attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
		}>;
	};
	wooRequests?: {
		__typename?: 'WooRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
		}>;
	};
	events?: {
		__typename?: 'EventEntityResponseCollection';
		data: Array<{
			__typename?: 'EventEntity';
			id?: string;
			attributes?: {
				__typename: 'Event';
				title: string;
				url: string;
				location: string;
				from: any;
				until: any;
				basePath: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				theme?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				share?: {
					__typename?: 'ComponentModulesShare';
					title?: any;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
	};
};

export type GetInConvoWithArticleSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetInConvoWithArticleSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetInConvoWithArticlesQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	showOnHomePage?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetInConvoWithArticlesQuery = {
	__typename?: 'Query';
	inConvoWithArticles?: {
		__typename?: 'InConvoWithArticleEntityResponseCollection';
		data: Array<{
			__typename?: 'InConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'InConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesShare' }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetLoginQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetLoginQuery = {
	__typename?: 'Query';
	login?: {
		__typename?: 'LoginEntityResponse';
		data?: {
			__typename?: 'LoginEntity';
			id?: string;
			attributes?: {
				__typename?: 'Login';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'ComponentModulesVacanciesOverview' }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type MapsFiguresSearchQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	searchValue?: InputMaybe<Scalars['String']>;
	themes?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	type?: InputMaybe<Scalars['String']>;
}>;

export type MapsFiguresSearchQuery = {
	__typename?: 'Query';
	searchMapsFigure?: {
		__typename?: 'MapsFigureEntityResponseCollection';
		data: Array<{
			__typename?: 'MapsFigureEntity';
			attributes?: {
				__typename?: 'MapsFigure';
				name: string;
				link: any;
				type: Enum_Mapsfigure_Type;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; ext?: string; size: number };
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetNewsArticleSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetNewsArticleSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetNewsArticlesQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	themeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	projectId?: InputMaybe<Scalars['ID']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	showOnHomePage?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetNewsArticlesQuery = {
	__typename?: 'Query';
	newsarticles?: {
		__typename?: 'NewsarticleEntityResponseCollection';
		data: Array<{
			__typename?: 'NewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'Newsarticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetNewsArticleContactPersonQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type GetNewsArticleContactPersonQuery = {
	__typename?: 'Query';
	getNewsarticleContactPersonById?: { __typename?: 'NewsarticleData'; contactPerson: string };
};

export type GetManyNewslettersQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyNewslettersQuery = {
	__typename?: 'Query';
	getManyNewsletters?: Array<{
		__typename?: 'NewsletterData';
		campaign?: { __typename?: 'NewsletterCampaign'; id: string; name?: string };
		newsletters?: Array<{ __typename?: 'NewslettersList'; field: string; description?: string }>;
	}>;
};

export type GetNewsletterContactQueryVariables = Exact<{
	email: Scalars['String'];
}>;

export type GetNewsletterContactQuery = {
	__typename?: 'Query';
	newsletterContact?: {
		__typename?: 'NewsletterContact';
		active?: boolean;
		campaigns?: Array<{
			__typename?: 'CampaignNewsletterContact';
			id: string;
			contact?: {
				__typename?: 'NewsletterContactData';
				id: string;
				email: string;
				create_date: string;
				custom?: Array<{ __typename?: 'NewsletterField'; field: string; value?: string }>;
			};
		}>;
		newsletters?: Array<{
			__typename?: 'CampaignNewsletterField';
			campaignId?: string;
			newsletter?: string;
			value?: string;
		}>;
	};
};

export type NewsletterFieldFieldsFragment = { __typename?: 'NewsletterField'; field: string; value?: string };

export type NewsletterContactDataFieldsFragment = {
	__typename?: 'NewsletterContactData';
	id: string;
	email: string;
	create_date: string;
	custom?: Array<{ __typename?: 'NewsletterField'; field: string; value?: string }>;
};

export type CampaignNewsletterContactFieldsFragment = {
	__typename?: 'CampaignNewsletterContact';
	id: string;
	contact?: {
		__typename?: 'NewsletterContactData';
		id: string;
		email: string;
		create_date: string;
		custom?: Array<{ __typename?: 'NewsletterField'; field: string; value?: string }>;
	};
};

export type CampaignNewsletterFieldFieldsFragment = {
	__typename?: 'CampaignNewsletterField';
	campaignId?: string;
	newsletter?: string;
	value?: string;
};

export type GetNotificationBarQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetNotificationBarQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			attributes?: {
				__typename?: 'PageInformation';
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationLink?: any;
					notificationMessage?: string;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string }>;
				};
			};
		};
	};
};

export type GetOpOostInConvoWithArticleSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOpOostInConvoWithArticleSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetOpOostInConvoWithArticlesQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	showOnHomePage?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOpOostInConvoWithArticlesQuery = {
	__typename?: 'Query';
	opOostInConvoWithArticles?: {
		__typename?: 'OpOostInConvoWithArticleEntityResponseCollection';
		data: Array<{
			__typename?: 'OpOostInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetOpOostNewsArticleSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOpOostNewsArticleSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetOpOostNewsArticlesQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	showOnHomePage?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOpOostNewsArticlesQuery = {
	__typename?: 'Query';
	opOostNewsarticles?: {
		__typename?: 'OpOostNewsarticleEntityResponseCollection';
		data: Array<{
			__typename?: 'OpOostNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostNewsarticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsOpOost';
							featuredNewsTitle?: any;
							news?: {
								__typename?: 'OpOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'OpOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'OpOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetOpOostPageSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOpOostPageSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GetPageSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPageSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; slug?: string; fullSlug?: string; locale?: string }>;
};

export type GetPermitSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermitSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GetPermitsQueryVariables = Exact<{
	searchValue?: InputMaybe<Scalars['String']>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	lawIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	themeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	productTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetPermitsQuery = {
	__typename?: 'Query';
	searchPermit?: {
		__typename?: 'PermitEntityResponseCollection';
		data: Array<{
			__typename?: 'PermitEntity';
			attributes?: {
				__typename?: 'Permit';
				publishedAt?: any;
				updatedAt?: any;
				title: string;
				law?: Enum_Permit_Law;
				productType?: Enum_Permit_Producttype;
				basePath?: string;
				fullPath?: string;
				fullSlug?: string;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				permitLaws?: {
					__typename?: 'PermitLawRelationResponseCollection';
					data: Array<{
						__typename?: 'PermitLawEntity';
						id?: string;
						attributes?: { __typename?: 'PermitLaw'; name: string };
					}>;
				};
				permitProductTypes?: {
					__typename?: 'PermitProductTypeRelationResponseCollection';
					data: Array<{
						__typename?: 'PermitProductTypeEntity';
						id?: string;
						attributes?: { __typename?: 'PermitProductType'; name: string };
					}>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetPressReleaseSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPressReleaseSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; slug?: string; fullSlug?: string; locale?: string }>;
};

export type GetPressReleasesQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPressReleasesQuery = {
	__typename?: 'Query';
	pressReleases?: {
		__typename?: 'PressReleaseEntityResponseCollection';
		data: Array<{
			__typename?: 'PressReleaseEntity';
			id?: string;
			attributes?: {
				__typename?: 'PressRelease';
				title: string;
				locale?: string;
				publishedAt?: any;
				basePath?: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				allowedOnHomePage?: boolean;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				theme?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesFeaturedNews' }
					| { __typename?: 'ComponentModulesFeaturedPages' }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetPressReleaseContactPersonQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type GetPressReleaseContactPersonQuery = {
	__typename?: 'Query';
	getPressReleaseContactPersonById?: { __typename?: 'PressReleaseData'; contactPerson: string };
};

export type GetProjectSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GetProjectsQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	themeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetProjectsQuery = {
	__typename?: 'Query';
	projects?: {
		__typename?: 'ProjectEntityResponseCollection';
		data: Array<{
			__typename?: 'ProjectEntity';
			id?: string;
			attributes?: {
				__typename?: 'Project';
				title?: string;
				publishedAt?: any;
				updatedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetRegioExpresInConvoWithArticleSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegioExpresInConvoWithArticleSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetRegioExpresInConvoWithArticlesQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	showOnHomePage?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetRegioExpresInConvoWithArticlesQuery = {
	__typename?: 'Query';
	regioExpresInConvoWithArticles?: {
		__typename?: 'RegioExpresInConvoWithArticleEntityResponseCollection';
		data: Array<{
			__typename?: 'RegioExpresInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetRegioExpresNewsArticleSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegioExpresNewsArticleSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetRegioExpresNewsArticlesQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	showOnHomePage?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetRegioExpresNewsArticlesQuery = {
	__typename?: 'Query';
	regioExpresNewsarticles?: {
		__typename?: 'RegioExpresNewsarticleEntityResponseCollection';
		data: Array<{
			__typename?: 'RegioExpresNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresNewsarticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsRegioExpres';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'RegioExpresNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'RegioExpresNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetRegioexpresPageSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegioexpresPageSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GetRegionSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegionSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GetRegionsQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetRegionsQuery = {
	__typename?: 'Query';
	regions?: {
		__typename?: 'RegionEntityResponseCollection';
		data: Array<{
			__typename?: 'RegionEntity';
			id?: string;
			attributes?: {
				__typename: 'Region';
				title?: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
	};
};

export type GetRoadMaintenancesSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoadMaintenancesSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; locale?: string; slug?: string; fullSlug?: string }>;
};

export type GetRoadMaintenancesQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRoadMaintenancesQuery = {
	__typename?: 'Query';
	roadMaintenances?: {
		__typename?: 'RoadMaintenanceEntityResponseCollection';
		data: Array<{
			__typename?: 'RoadMaintenanceEntity';
			id?: string;
			attributes?: {
				__typename: 'RoadMaintenance';
				title: string;
				slug: string;
				basePath?: string;
				fullPath?: string;
				locale?: string;
				status?: Enum_Roadmaintenance_Status;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				aboutMaintenance: {
					__typename: 'ComponentInternalAboutMaintenance';
					startDate?: any;
					endDate?: any;
					contractor?: string;
					contractorWebsite?: string;
					detour?: string;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetFullSitemapQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	domain?: InputMaybe<Scalars['String']>;
}>;

export type GetFullSitemapQuery = {
	__typename?: 'Query';
	findSitemapList?: Array<{ __typename?: 'SitemapPage'; fullPath?: string; title?: string; updated_at?: string }>;
};

export type GetSubsidiesQueryVariables = Exact<{
	searchValue: Scalars['String'];
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	themeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	kindIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	scaleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	groupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	status?: InputMaybe<Scalars['String']>;
}>;

export type GetSubsidiesQuery = {
	__typename?: 'Query';
	searchSubsidy?: {
		__typename?: 'SubsidyEntityResponseCollection';
		data: Array<{
			__typename?: 'SubsidyEntity';
			id?: string;
			attributes?: {
				__typename?: 'Subsidy';
				title: string;
				slug: string;
				basePath?: string;
				fullPath?: string;
				subsidyCeiling?: Enum_Subsidy_Subsidyceiling;
				updatedAt?: any;
				status: Enum_Subsidy_Status;
				endDate?: any;
				targetGroupText?: string;
				hideFromOverview: boolean;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				subsidy_scales?: {
					__typename?: 'SubsidyScaleRelationResponseCollection';
					data: Array<{
						__typename?: 'SubsidyScaleEntity';
						id?: string;
						attributes?: { __typename?: 'SubsidyScale'; name?: string };
					}>;
				};
				subsidy_kinds?: {
					__typename?: 'SubsidyKindRelationResponseCollection';
					data: Array<{
						__typename?: 'SubsidyKindEntity';
						id?: string;
						attributes?: { __typename?: 'SubsidyKind'; name?: string };
					}>;
				};
				target_groups?: {
					__typename?: 'TargetGroupRelationResponseCollection';
					data: Array<{
						__typename?: 'TargetGroupEntity';
						id?: string;
						attributes?: { __typename?: 'TargetGroup'; name?: string };
					}>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetSubsidyFiltersQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetSubsidyFiltersQuery = {
	__typename?: 'Query';
	subsidyKinds?: {
		__typename?: 'SubsidyKindEntityResponseCollection';
		data: Array<{
			__typename?: 'SubsidyKindEntity';
			id?: string;
			attributes?: { __typename?: 'SubsidyKind'; name?: string; locale?: string };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
	subsidyScales?: {
		__typename?: 'SubsidyScaleEntityResponseCollection';
		data: Array<{
			__typename?: 'SubsidyScaleEntity';
			id?: string;
			attributes?: { __typename?: 'SubsidyScale'; name?: string; locale?: string };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
	subsidyGroups?: {
		__typename?: 'TargetGroupEntityResponseCollection';
		data: Array<{
			__typename?: 'TargetGroupEntity';
			id?: string;
			attributes?: { __typename?: 'TargetGroup'; name?: string; locale?: string };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetSubsidySlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubsidySlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; slug?: string; fullSlug?: string; locale?: string }>;
};

export type GetTagsQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetTagsQuery = {
	__typename?: 'Query';
	tags?: {
		__typename?: 'TagEntityResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
};

export type GetThemeSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetThemeSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GetThemesQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetThemesQuery = {
	__typename?: 'Query';
	themes?: {
		__typename?: 'ThemeEntityResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
	};
};

export type GetWobRequestsQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetWobRequestsQuery = {
	__typename?: 'Query';
	wobRequests?: {
		__typename?: 'WobRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WobRequestEntity';
			id?: string;
			attributes?: { __typename?: 'WobRequest'; title: string; createdAt?: any; date?: any; zip?: any };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetWooCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWooCategoriesQuery = {
	__typename?: 'Query';
	wooCategories?: {
		__typename?: 'WooCategoryEntityResponseCollection';
		data: Array<{
			__typename?: 'WooCategoryEntity';
			id?: string;
			attributes?: { __typename?: 'WooCategory'; title: string };
		}>;
	};
};

export type GetWooRequestSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWooRequestSlugsQuery = {
	__typename?: 'Query';
	findAllPaths?: Array<{ __typename?: 'PathEntity'; fullSlug?: string; locale?: string }>;
};

export type GetWooRequestsQueryVariables = Exact<{
	publicationState?: InputMaybe<PublicationState>;
	limit?: InputMaybe<Scalars['Int']>;
	start?: InputMaybe<Scalars['Int']>;
	sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
	themeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	categoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	searchValue?: InputMaybe<Scalars['String']>;
}>;

export type GetWooRequestsQuery = {
	__typename?: 'Query';
	wooRequests?: {
		__typename?: 'WooRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooRequest';
				title: string;
				shortTitle?: string;
				description?: string;
				keywords?: string;
				publisher?: Enum_Woorequest_Publisher;
				caseNumber?: string;
				fullPath?: string;
				fullSlug?: string;
				locale?: string;
				zip?: any;
				publishedAt?: any;
				createdAt?: any;
				updatedAt?: any;
				startDate: any;
				endDate?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
				};
				category?: {
					__typename?: 'WooCategoryEntityResponse';
					data?: {
						__typename?: 'WooCategoryEntity';
						attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
					};
				};
				files: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
					}>;
				};
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetAgendaPageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetAgendaPageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	agendaPage?: {
		__typename?: 'AgendaPageEntityResponse';
		data?: {
			__typename?: 'AgendaPageEntity';
			id?: string;
			attributes?: {
				__typename: 'AgendaPage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetCondoleanceHomepageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetCondoleanceHomepageQuery = {
	__typename?: 'Query';
	condoleanceInformation?: {
		__typename?: 'CondoleanceInformationEntityResponse';
		data?: {
			__typename?: 'CondoleanceInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'CondoleanceInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	condoleanceHomepage?: {
		__typename?: 'CondoleanceHomepageEntityResponse';
		data?: {
			__typename?: 'CondoleanceHomepageEntity';
			id?: string;
			attributes?: {
				__typename?: 'CondoleanceHomepage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesHomepageHeader';
					id: string;
					description?: string;
					popularHeader?: string;
					homepageTitle?: string;
					backgroundImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetCondoleancePageQueryVariables = Exact<{
	slug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetCondoleancePageQuery = {
	__typename?: 'Query';
	condoleanceInformation?: {
		__typename?: 'CondoleanceInformationEntityResponse';
		data?: {
			__typename?: 'CondoleanceInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'CondoleanceInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	condoleancePageBySlug?: {
		__typename?: 'CondoleancePageEntityResponse';
		data?: {
			__typename?: 'CondoleancePageEntity';
			id?: string;
			attributes?: {
				__typename?: 'CondoleancePage';
				title: string;
				fullSlug?: string;
				basePath?: string;
				fullPath?: string;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetContactQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetContactQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	contact?: {
		__typename?: 'ContactEntityResponse';
		data?: {
			__typename?: 'ContactEntity';
			id?: string;
			attributes?: {
				__typename: 'Contact';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'ComponentModulesScheduling' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetCookiePageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetCookiePageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	cookiePage?: {
		__typename?: 'CookiePageEntityResponse';
		data?: {
			__typename?: 'CookiePageEntity';
			id?: string;
			attributes?: {
				__typename: 'CookiePage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetDecisionsPageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetDecisionsPageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	decisionsPage?: {
		__typename?: 'DecisionsPageEntityResponse';
		data?: {
			__typename?: 'DecisionsPageEntity';
			id?: string;
			attributes?: {
				__typename: 'DecisionsPage';
				locale?: string;
				bodyText?: string;
				sidebarCurrentYearTitle: string;
				sidebarArchiveTitle: string;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				archiveLink: { __typename?: 'ComponentComponentsLink'; title: string; description?: string; href: any };
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetDownloadsPageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetDownloadsPageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	downloadsPage?: {
		__typename?: 'DownloadsPageEntityResponse';
		data?: {
			__typename?: 'DownloadsPageEntity';
			id?: string;
			attributes?: {
				__typename: 'DownloadsPage';
				locale?: string;
				introHeader: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				downloads: Array<{
					__typename?: 'ComponentModulesDownloads';
					downloadsTitle?: any;
					downloads?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								name: string;
								size: number;
								ext?: string;
								caption?: string;
							};
						}>;
					};
					downloadLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
						extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
						extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
					};
				}>;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesScheduling' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetEfroOostHomepageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetEfroOostHomepageQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	efroOostHomepage?: {
		__typename?: 'EfroOostHomepageEntityResponse';
		data?: {
			__typename?: 'EfroOostHomepageEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostHomepage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesHomepageHeader';
					id: string;
					description?: string;
					popularHeader?: string;
					homepageTitle?: string;
					backgroundImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNewsEfroOost';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'EfroOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'EfroOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'EfroOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetEfroOostInConvoWithDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetEfroOostInConvoWithDetailQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	efroOostInConvoWithDetail?: {
		__typename?: 'EfroOostInConvoWithDetailEntityResponse';
		data?: {
			__typename?: 'EfroOostInConvoWithDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInConvoWithDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	efroOostInConvoWithArticleBySlug?: {
		__typename?: 'EfroOostInConvoWithArticleEntityResponse';
		data?: {
			__typename?: 'EfroOostInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetEfroOostInConvoWithOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetEfroOostInConvoWithOverviewQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	efroOostInConvoWithOverview?: {
		__typename?: 'EfroOostInConvoWithOverviewEntityResponse';
		data?: {
			__typename?: 'EfroOostInConvoWithOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInConvoWithOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesInConvoWithOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	efroOostInConvoWithArticles?: {
		__typename?: 'EfroOostInConvoWithArticleEntityResponseCollection';
		data: Array<{
			__typename?: 'EfroOostInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetEfroOostNewsDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetEfroOostNewsDetailQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	efroOostNewsDetail?: {
		__typename?: 'EfroOostNewsDetailEntityResponse';
		data?: {
			__typename?: 'EfroOostNewsDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostNewsDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNewsEfroOost';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'EfroOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'EfroOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'EfroOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	efroOostNewsarticleBySlug?: {
		__typename?: 'EfroOostNewsarticleEntityResponse';
		data?: {
			__typename?: 'EfroOostNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostNewsarticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsEfroOost';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'EfroOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'EfroOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'EfroOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetEfroOostNewsOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetEfroOostNewsOverviewQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	efroOostNewsOverview?: {
		__typename?: 'EfroOostNewsOverviewEntityResponse';
		data?: {
			__typename?: 'EfroOostNewsOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostNewsOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesNewsOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetEfroOostPageQueryVariables = Exact<{
	slug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetEfroOostPageQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	efroOostPageBySlug?: {
		__typename?: 'EfroOostPageEntityResponse';
		data?: {
			__typename?: 'EfroOostPageEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostPage';
				title: string;
				fullSlug?: string;
				basePath?: string;
				fullPath?: string;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsEfroOost';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'EfroOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'EfroOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'EfroOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetEventOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetEventOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	eventOverview?: {
		__typename?: 'EventOverviewEntityResponse';
		data?: {
			__typename?: 'EventOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'EventOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesEventOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	themes?: {
		__typename?: 'ThemeEntityResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetGeoLinkPageQueryVariables = Exact<{
	slug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetGeoLinkPageQuery = {
	__typename?: 'Query';
	geolinkInformation?: {
		__typename?: 'GeolinkInformationEntityResponse';
		data?: {
			__typename?: 'GeolinkInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'GeolinkInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	geolinkPageBySlug?: {
		__typename?: 'GeolinkPageEntityResponse';
		data?: {
			__typename?: 'GeolinkPageEntity';
			id?: string;
			attributes?: {
				__typename?: 'GeolinkPage';
				title: string;
				fullSlug?: string;
				basePath?: string;
				fullPath?: string;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesCta' }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetGeolinkHomepageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetGeolinkHomepageQuery = {
	__typename?: 'Query';
	geolinkInformation?: {
		__typename?: 'GeolinkInformationEntityResponse';
		data?: {
			__typename?: 'GeolinkInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'GeolinkInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	geolinkHomepage?: {
		__typename?: 'GeolinkHomepageEntityResponse';
		data?: {
			__typename?: 'GeolinkHomepageEntity';
			id?: string;
			attributes?: {
				__typename?: 'GeolinkHomepage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesHomepageHeader';
					id: string;
					description?: string;
					popularHeader?: string;
					homepageTitle?: string;
					backgroundImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetHomePageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetHomePageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	homePage?: {
		__typename?: 'HomePageEntityResponse';
		data?: {
			__typename?: 'HomePageEntity';
			id?: string;
			attributes?: {
				__typename?: 'HomePage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesHomepageHeader';
					id: string;
					description?: string;
					popularHeader?: string;
					homepageTitle?: string;
					backgroundImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesRegions';
							title?: any;
							regions?: {
								__typename?: 'RegionRelationResponseCollection';
								data: Array<{
									__typename?: 'RegionEntity';
									id?: string;
									attributes?: {
										__typename: 'Region';
										title?: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesThemes';
							title?: any;
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: {
										__typename: 'Theme';
										title: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetInConvoWithDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetInConvoWithDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	inConvoWithDetail?: {
		__typename?: 'InConvoWithDetailEntityResponse';
		data?: {
			__typename?: 'InConvoWithDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'InConvoWithDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	inConvoWithArticleBySlug?: {
		__typename?: 'InConvoWithArticleEntityResponse';
		data?: {
			__typename?: 'InConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'InConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesShare' }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetInConvoWithOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetInConvoWithOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	inConvoWithOverview?: {
		__typename?: 'InConvoWithOverviewEntityResponse';
		data?: {
			__typename?: 'InConvoWithOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'InConvoWithOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesInConvoWithOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	inConvoWithArticles?: {
		__typename?: 'InConvoWithArticleEntityResponseCollection';
		data: Array<{
			__typename?: 'InConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'InConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesShare' }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetMapsFiguresOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetMapsFiguresOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	mapsFiguresOverview?: {
		__typename?: 'MapsFiguresOverviewEntityResponse';
		data?: {
			__typename?: 'MapsFiguresOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'MapsFiguresOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				mapsFiguresOverview: {
					__typename?: 'ComponentModulesMapsFiguresOverview';
					id: string;
					showResultsText: string;
					headerTitle: string;
					searchPlaceholderText: string;
					themeFilterText: string;
					kindFilterText: string;
					showAllThemesText: string;
					overviewTitleText: string;
					filterHeaderText: string;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'ComponentModulesRegions' }
					| { __typename: 'ComponentModulesScheduling' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'ComponentModulesThemes' }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	themes?: {
		__typename?: 'ThemeEntityResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetNewsDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetNewsDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	newsDetail?: {
		__typename?: 'NewsDetailEntityResponse';
		data?: {
			__typename?: 'NewsDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'NewsDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	newsarticleBySlug?: {
		__typename?: 'NewsarticleEntityResponse';
		data?: {
			__typename?: 'NewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'Newsarticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetNewsOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetNewsOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	newsOverview?: {
		__typename?: 'NewsOverviewEntityResponse';
		data?: {
			__typename?: 'NewsOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'NewsOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesNewsOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	themes?: {
		__typename?: 'ThemeEntityResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetArchiveNewsletterOverviewPageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetArchiveNewsletterOverviewPageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	newsletterArchiveOverview?: {
		__typename?: 'NewsletterArchiveOverviewEntityResponse';
		data?: {
			__typename: 'NewsletterArchiveOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'NewsletterArchiveOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPermitsOverview';
							id: string;
							headerTitle: string;
							searchPlaceholderText: string;
							themeFilterText: string;
							lawFilterText: string;
							productTypeFilterText: string;
							showAllThemesText: string;
							showAllLawsText: string;
							showAllProductTypesText: string;
							overviewTitleText: string;
							showResultsText: string;
							filterHeaderText: string;
					  }
					| { __typename: 'ComponentModulesPhotoAlbum' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetInternalNewsletterPageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetInternalNewsletterPageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	internalNewsletterWithSubscriptions?: Array<{
		__typename?: 'InternalNewsletterWithSubscriptions';
		page?: {
			__typename?: 'NewsletterInternal';
			header: {
				__typename?: 'ComponentModulesIntroHeader';
				title: string;
				excerpt: string;
				backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
			};
			newsletter: {
				__typename?: 'ComponentInternalNewsletter';
				title?: string;
				fieldLabel?: string;
				fieldPlaceholder?: string;
				submitButton?: {
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
			};
			seo: {
				__typename?: 'ComponentModulesSeo';
				title?: string;
				description?: string;
				noIndex?: boolean;
				noFollow?: boolean;
				ogTitle?: string;
				ogDescription?: string;
				ogType?: string;
				ogUrl?: string;
				ogImage?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
		};
	}>;
};

export type GetPublicNewsletterPageCondoleanceQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPublicNewsletterPageCondoleanceQuery = {
	__typename?: 'Query';
	condoleanceInformation?: {
		__typename?: 'CondoleanceInformationEntityResponse';
		data?: {
			__typename?: 'CondoleanceInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'CondoleanceInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	publicNewsletterPage?: Array<{
		__typename?: 'PublicNewsletterPage';
		page?: {
			__typename?: 'NewsletterPublic';
			header: {
				__typename?: 'ComponentModulesIntroHeader';
				title: string;
				excerpt: string;
				backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
			};
			newsletter: {
				__typename?: 'ComponentInternalNewsletter';
				title?: string;
				fieldLabel?: string;
				fieldPlaceholder?: string;
				submitButton?: {
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
			};
			seo: {
				__typename?: 'ComponentModulesSeo';
				title?: string;
				description?: string;
				noIndex?: boolean;
				noFollow?: boolean;
				ogTitle?: string;
				ogDescription?: string;
				ogType?: string;
				ogUrl?: string;
				ogImage?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
		};
	}>;
};

export type GetPublicNewsletterPageOfroOostQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPublicNewsletterPageOfroOostQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	publicNewsletterPage?: Array<{
		__typename?: 'PublicNewsletterPage';
		page?: {
			__typename?: 'NewsletterPublic';
			header: {
				__typename?: 'ComponentModulesIntroHeader';
				title: string;
				excerpt: string;
				backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
			};
			newsletter: {
				__typename?: 'ComponentInternalNewsletter';
				title?: string;
				fieldLabel?: string;
				fieldPlaceholder?: string;
				submitButton?: {
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
			};
			seo: {
				__typename?: 'ComponentModulesSeo';
				title?: string;
				description?: string;
				noIndex?: boolean;
				noFollow?: boolean;
				ogTitle?: string;
				ogDescription?: string;
				ogType?: string;
				ogUrl?: string;
				ogImage?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
		};
	}>;
};

export type GetPublicNewsletterPageGeolinkQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPublicNewsletterPageGeolinkQuery = {
	__typename?: 'Query';
	geolinkInformation?: {
		__typename?: 'GeolinkInformationEntityResponse';
		data?: {
			__typename?: 'GeolinkInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'GeolinkInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	publicNewsletterPage?: Array<{
		__typename?: 'PublicNewsletterPage';
		page?: {
			__typename?: 'NewsletterPublic';
			header: {
				__typename?: 'ComponentModulesIntroHeader';
				title: string;
				excerpt: string;
				backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
			};
			newsletter: {
				__typename?: 'ComponentInternalNewsletter';
				title?: string;
				fieldLabel?: string;
				fieldPlaceholder?: string;
				submitButton?: {
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
			};
			seo: {
				__typename?: 'ComponentModulesSeo';
				title?: string;
				description?: string;
				noIndex?: boolean;
				noFollow?: boolean;
				ogTitle?: string;
				ogDescription?: string;
				ogType?: string;
				ogUrl?: string;
				ogImage?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
		};
	}>;
};

export type GetPublicNewsletterPageOpOostQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPublicNewsletterPageOpOostQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	publicNewsletterPage?: Array<{
		__typename?: 'PublicNewsletterPage';
		page?: {
			__typename?: 'NewsletterPublic';
			header: {
				__typename?: 'ComponentModulesIntroHeader';
				title: string;
				excerpt: string;
				backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
			};
			newsletter: {
				__typename?: 'ComponentInternalNewsletter';
				title?: string;
				fieldLabel?: string;
				fieldPlaceholder?: string;
				submitButton?: {
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
			};
			seo: {
				__typename?: 'ComponentModulesSeo';
				title?: string;
				description?: string;
				noIndex?: boolean;
				noFollow?: boolean;
				ogTitle?: string;
				ogDescription?: string;
				ogType?: string;
				ogUrl?: string;
				ogImage?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
		};
	}>;
};

export type GetPublicNewsletterPageRegioExpresQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPublicNewsletterPageRegioExpresQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	publicNewsletterPage?: Array<{
		__typename?: 'PublicNewsletterPage';
		page?: {
			__typename?: 'NewsletterPublic';
			header: {
				__typename?: 'ComponentModulesIntroHeader';
				title: string;
				excerpt: string;
				backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
			};
			newsletter: {
				__typename?: 'ComponentInternalNewsletter';
				title?: string;
				fieldLabel?: string;
				fieldPlaceholder?: string;
				submitButton?: {
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
			};
			seo: {
				__typename?: 'ComponentModulesSeo';
				title?: string;
				description?: string;
				noIndex?: boolean;
				noFollow?: boolean;
				ogTitle?: string;
				ogDescription?: string;
				ogType?: string;
				ogUrl?: string;
				ogImage?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
		};
	}>;
};

export type GetPublicNewsletterPageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPublicNewsletterPageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	publicNewsletterPage?: Array<{
		__typename?: 'PublicNewsletterPage';
		page?: {
			__typename?: 'NewsletterPublic';
			header: {
				__typename?: 'ComponentModulesIntroHeader';
				title: string;
				excerpt: string;
				backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
			};
			newsletter: {
				__typename?: 'ComponentInternalNewsletter';
				title?: string;
				fieldLabel?: string;
				fieldPlaceholder?: string;
				submitButton?: {
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
			};
			seo: {
				__typename?: 'ComponentModulesSeo';
				title?: string;
				description?: string;
				noIndex?: boolean;
				noFollow?: boolean;
				ogTitle?: string;
				ogDescription?: string;
				ogType?: string;
				ogUrl?: string;
				ogImage?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
		};
	}>;
};

export type GetNotFoundQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetNotFoundQuery = {
	__typename?: 'Query';
	notFoundPage?: {
		__typename?: 'NotFoundPageEntityResponse';
		data?: {
			__typename?: 'NotFoundPageEntity';
			id?: string;
			attributes?: {
				__typename: 'NotFoundPage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				links: {
					__typename?: 'ComponentModulesLinkBox';
					title?: any;
					description?: string;
					linkBoxLinks?: Array<{
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
						};
					}>;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
						extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
						extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
					};
				};
				cta: {
					__typename?: 'ComponentModulesCta';
					description?: string;
					imagePosition?: Enum_Componentmodulescta_Imageposition;
					videoLink?: string;
					ctaTitle?: any;
					buttons?: Array<{
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					}>;
					imageOrVideoFile?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								caption?: string;
								url: string;
								mime: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
						extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
						extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
					};
				};
			};
		};
	};
};

export type GetOpOostHomepageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetOpOostHomepageQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	opOostHomepage?: {
		__typename?: 'OpOostHomepageEntityResponse';
		data?: {
			__typename?: 'OpOostHomepageEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostHomepage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesHomepageHeader';
					id: string;
					description?: string;
					popularHeader?: string;
					homepageTitle?: string;
					backgroundImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNewsOpOost';
							featuredNewsTitle?: any;
							news?: {
								__typename?: 'OpOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'OpOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'OpOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetOpOostInConvoWithDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetOpOostInConvoWithDetailQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	opOostInConvoWithDetail?: {
		__typename?: 'OpOostInConvoWithDetailEntityResponse';
		data?: {
			__typename?: 'OpOostInConvoWithDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInConvoWithDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	opOostInConvoWithArticleBySlug?: {
		__typename?: 'OpOostInConvoWithArticleEntityResponse';
		data?: {
			__typename?: 'OpOostInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetOpOostInConvoWithOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetOpOostInConvoWithOverviewQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	opOostInConvoWithOverview?: {
		__typename?: 'OpOostInConvoWithOverviewEntityResponse';
		data?: {
			__typename?: 'OpOostInConvoWithOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInConvoWithOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesInConvoWithOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	opOostInConvoWithArticles?: {
		__typename?: 'OpOostInConvoWithArticleEntityResponseCollection';
		data: Array<{
			__typename?: 'OpOostInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetOpOostNewsDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetOpOostNewsDetailQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	opOostNewsDetail?: {
		__typename?: 'OpOostNewsDetailEntityResponse';
		data?: {
			__typename?: 'OpOostNewsDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostNewsDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNewsOpOost';
							featuredNewsTitle?: any;
							news?: {
								__typename?: 'OpOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'OpOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'OpOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	opOostNewsarticleBySlug?: {
		__typename?: 'OpOostNewsarticleEntityResponse';
		data?: {
			__typename?: 'OpOostNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostNewsarticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsOpOost';
							featuredNewsTitle?: any;
							news?: {
								__typename?: 'OpOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'OpOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'OpOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetOpOostNewsOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetOpOostNewsOverviewQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	opOostNewsOverview?: {
		__typename?: 'OpOostNewsOverviewEntityResponse';
		data?: {
			__typename?: 'OpOostNewsOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostNewsOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesNewsOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetOpOostPageQueryVariables = Exact<{
	slug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetOpOostPageQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	opOostPageBySlug?: {
		__typename?: 'OpOostPageEntityResponse';
		data?: {
			__typename?: 'OpOostPageEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostPage';
				title: string;
				fullSlug?: string;
				basePath?: string;
				fullPath?: string;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsOpOost';
							featuredNewsTitle?: any;
							news?: {
								__typename?: 'OpOostNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'OpOostNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'OpOostNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetOpenGelderlandHomepageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetOpenGelderlandHomepageQuery = {
	__typename?: 'Query';
	openGelderlandInformation?: {
		__typename?: 'OpenGelderlandInformationEntityResponse';
		data?: {
			__typename?: 'OpenGelderlandInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpenGelderlandInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	openGelderlandHomepage?: {
		__typename?: 'OpenGelderlandHomepageEntityResponse';
		data?: {
			__typename?: 'OpenGelderlandHomepageEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpenGelderlandHomepage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				collections: {
					__typename?: 'ComponentModulesCollections';
					id: string;
					description?: string;
					overviewTitle?: string;
					links: Array<{
						__typename?: 'ComponentComponentsLink';
						id: string;
						title: string;
						href: any;
						description?: string;
					}>;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetPageInformationCondoleanceQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPageInformationCondoleanceQuery = {
	__typename?: 'Query';
	condoleanceInformation?: {
		__typename?: 'CondoleanceInformationEntityResponse';
		data?: {
			__typename?: 'CondoleanceInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'CondoleanceInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetPageInformationEfroOostQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPageInformationEfroOostQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetPageInformationGelderlandQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPageInformationGelderlandQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetPageInformationGeolinkQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPageInformationGeolinkQuery = {
	__typename?: 'Query';
	geolinkInformation?: {
		__typename?: 'GeolinkInformationEntityResponse';
		data?: {
			__typename?: 'GeolinkInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'GeolinkInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetPageInformationOpOostQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPageInformationOpOostQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetPageInformationRegioExpresQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPageInformationRegioExpresQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
};

export type GetPageQueryVariables = Exact<{
	slug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	pageBySlug?: {
		__typename?: 'PageEntityResponse';
		data?: {
			__typename?: 'PageEntity';
			id?: string;
			attributes?: {
				__typename?: 'Page';
				title: string;
				fullSlug?: string;
				basePath?: string;
				fullPath?: string;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesRegions';
							title?: any;
							regions?: {
								__typename?: 'RegionRelationResponseCollection';
								data: Array<{
									__typename?: 'RegionEntity';
									id?: string;
									attributes?: {
										__typename: 'Region';
										title?: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesThemes';
							title?: any;
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: {
										__typename: 'Theme';
										title: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetPasswordPageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetPasswordPageQuery = {
	__typename?: 'Query';
	passwordPage?: {
		__typename?: 'PasswordPageEntityResponse';
		data?: {
			__typename?: 'PasswordPageEntity';
			id?: string;
			attributes?: {
				__typename: 'PasswordPage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesRegions';
							title?: any;
							regions?: {
								__typename?: 'RegionRelationResponseCollection';
								data: Array<{
									__typename?: 'RegionEntity';
									id?: string;
									attributes?: {
										__typename: 'Region';
										title?: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesThemes';
							title?: any;
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: {
										__typename: 'Theme';
										title: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetPermitDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPermitDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	permitDetail?: {
		__typename?: 'PermitDetailEntityResponse';
		data?: {
			__typename?: 'PermitDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'PermitDetail';
				locale?: string;
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	permitBySlug?: {
		__typename?: 'PermitEntityResponse';
		data?: {
			__typename?: 'PermitEntity';
			id?: string;
			attributes?: {
				__typename?: 'Permit';
				publishedAt?: any;
				updatedAt?: any;
				title: string;
				law?: Enum_Permit_Law;
				productType?: Enum_Permit_Producttype;
				basePath?: string;
				fullPath?: string;
				fullSlug?: string;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				permitLaws?: {
					__typename?: 'PermitLawRelationResponseCollection';
					data: Array<{
						__typename?: 'PermitLawEntity';
						id?: string;
						attributes?: { __typename?: 'PermitLaw'; name: string };
					}>;
				};
				permitProductTypes?: {
					__typename?: 'PermitProductTypeRelationResponseCollection';
					data: Array<{
						__typename?: 'PermitProductTypeEntity';
						id?: string;
						attributes?: { __typename?: 'PermitProductType'; name: string };
					}>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetPermitsOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPermitsOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	permitsOverview?: {
		__typename?: 'PermitsOverviewEntityResponse';
		data?: {
			__typename?: 'PermitsOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'PermitsOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPermitsOverview';
							id: string;
							headerTitle: string;
							searchPlaceholderText: string;
							themeFilterText: string;
							lawFilterText: string;
							productTypeFilterText: string;
							showAllThemesText: string;
							showAllLawsText: string;
							showAllProductTypesText: string;
							overviewTitleText: string;
							showResultsText: string;
							filterHeaderText: string;
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	permitLaws?: {
		__typename?: 'PermitLawEntityResponseCollection';
		data: Array<{
			__typename?: 'PermitLawEntity';
			id?: string;
			attributes?: { __typename?: 'PermitLaw'; name: string };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
	permitProductTypes?: {
		__typename?: 'PermitProductTypeEntityResponseCollection';
		data: Array<{
			__typename?: 'PermitProductTypeEntity';
			id?: string;
			attributes?: { __typename?: 'PermitProductType'; name: string };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
	themes?: {
		__typename?: 'ThemeEntityResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetPressReleaseDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
	slug?: InputMaybe<Scalars['String']>;
}>;

export type GetPressReleaseDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	pressReleaseDetail?: {
		__typename?: 'PressReleaseDetailEntityResponse';
		data?: {
			__typename?: 'PressReleaseDetailEntity';
			id?: string;
			attributes?: {
				__typename: 'PressReleaseDetail';
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
	pressReleaseBySlug?: {
		__typename?: 'PressReleaseEntityResponse';
		data?: {
			__typename?: 'PressReleaseEntity';
			id?: string;
			attributes?: {
				__typename?: 'PressRelease';
				title: string;
				locale?: string;
				publishedAt?: any;
				basePath?: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				allowedOnHomePage?: boolean;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				theme?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesFeaturedNews' }
					| { __typename?: 'ComponentModulesFeaturedPages' }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetPressReleaseOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPressReleaseOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	pressReleaseOverview?: {
		__typename?: 'PressReleaseOverviewEntityResponse';
		data?: {
			__typename?: 'PressReleaseOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'PressReleaseOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'ComponentModulesLinkBox' }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPressReleaseOverview';
							id: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	pressReleases?: {
		__typename?: 'PressReleaseEntityResponseCollection';
		data: Array<{
			__typename?: 'PressReleaseEntity';
			id?: string;
			attributes?: {
				__typename?: 'PressRelease';
				title: string;
				locale?: string;
				publishedAt?: any;
				basePath?: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				allowedOnHomePage?: boolean;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				theme?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesFeaturedNews' }
					| { __typename?: 'ComponentModulesFeaturedPages' }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetPrivacyPolicyQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetPrivacyPolicyQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	privacyPolicy?: {
		__typename?: 'PrivacyPolicyEntityResponse';
		data?: {
			__typename?: 'PrivacyPolicyEntity';
			id?: string;
			attributes?: {
				__typename: 'PrivacyPolicy';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				bodytext: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		};
	};
};

export type GetProjectDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetProjectDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	projectDetail?: {
		__typename?: 'ProjectDetailEntityResponse';
		data?: {
			__typename?: 'ProjectDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'ProjectDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	projectBySlug?: {
		__typename?: 'ProjectEntityResponse';
		data?: {
			__typename?: 'ProjectEntity';
			id?: string;
			attributes?: {
				__typename?: 'Project';
				title?: string;
				publishedAt?: any;
				updatedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetProjectOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetProjectOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	projectOverview?: {
		__typename?: 'ProjectOverviewEntityResponse';
		data?: {
			__typename?: 'ProjectOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'ProjectOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| { __typename: 'ComponentModulesAccordeon' }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'ComponentModulesPartners' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesProjectOverview';
							id: string;
							filterLabel?: string;
							extendedDefaultModuleOptions?: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'ComponentModulesScheduling' }
					| { __typename: 'ComponentModulesStepByStep' }
					| { __typename: 'ComponentModulesSubjects' }
					| { __typename: 'ComponentModulesTable' }
					| { __typename: 'ComponentModulesTestimonial' }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	themes?: {
		__typename?: 'ThemeEntityResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetRegioExpresHomepageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRegioExpresHomepageQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regioExpresHomepage?: {
		__typename?: 'RegioExpresHomepageEntityResponse';
		data?: {
			__typename?: 'RegioExpresHomepageEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresHomepage';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesHomepageHeader';
					id: string;
					description?: string;
					popularHeader?: string;
					homepageTitle?: string;
					backgroundImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNewsRegioExpres';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'RegioExpresNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'RegioExpresNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetRegioExpresInConvoWithDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRegioExpresInConvoWithDetailQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regioExpresInConvoWithDetail?: {
		__typename?: 'RegioExpresInConvoWithDetailEntityResponse';
		data?: {
			__typename?: 'RegioExpresInConvoWithDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInConvoWithDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	regioExpresInConvoWithArticleBySlug?: {
		__typename?: 'RegioExpresInConvoWithArticleEntityResponse';
		data?: {
			__typename?: 'RegioExpresInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetRegioExpresInConvoWithOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRegioExpresInConvoWithOverviewQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regioExpresInConvoOverview?: {
		__typename?: 'RegioExpresInConvoOverviewEntityResponse';
		data?: {
			__typename?: 'RegioExpresInConvoOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInConvoOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesInConvoWithOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	regioExpresInConvoWithArticles?: {
		__typename?: 'RegioExpresInConvoWithArticleEntityResponseCollection';
		data: Array<{
			__typename?: 'RegioExpresInConvoWithArticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInConvoWithArticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetRegioExpresNewsDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRegioExpresNewsDetailQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regioExpresNewsDetail?: {
		__typename?: 'RegioExpresNewsDetailEntityResponse';
		data?: {
			__typename?: 'RegioExpresNewsDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresNewsDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNewsRegioExpres';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'RegioExpresNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'RegioExpresNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	regioExpresNewsarticleBySlug?: {
		__typename?: 'RegioExpresNewsarticleEntityResponse';
		data?: {
			__typename?: 'RegioExpresNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresNewsarticle';
				title: string;
				publishedAt?: any;
				slug: string;
				basePath?: string;
				fullPath?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				tags?: {
					__typename?: 'TagRelationResponseCollection';
					data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsRegioExpres';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'RegioExpresNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'RegioExpresNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetRegioExpresNewsOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRegioExpresNewsOverviewQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regioExpresNewsOverview?: {
		__typename?: 'RegioExpresNewsOverviewEntityResponse';
		data?: {
			__typename?: 'RegioExpresNewsOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresNewsOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesNewsOverview';
							id: string;
							filterLabel?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetRegioExpresPageQueryVariables = Exact<{
	slug?: InputMaybe<Scalars['String']>;
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRegioExpresPageQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regioExpresPageBySlug?: {
		__typename?: 'RegioExpresPageEntityResponse';
		data?: {
			__typename?: 'RegioExpresPageEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresPage';
				title: string;
				fullSlug?: string;
				basePath?: string;
				fullPath?: string;
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNewsRegioExpres';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'RegioExpresNewsarticleEntity';
									id?: string;
									attributes?: {
										__typename?: 'RegioExpresNewsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTestimonial';
							id: string;
							quote?: string;
							name: string;
							organization?: string;
							quoteTitle: any;
							photo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							logo?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetRegionDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
	slug?: InputMaybe<Scalars['String']>;
}>;

export type GetRegionDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regionDetail?: {
		__typename?: 'RegionDetailEntityResponse';
		data?: {
			__typename?: 'RegionDetailEntity';
			id?: string;
			attributes?: {
				__typename: 'RegionDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
	regionBySlug?: {
		__typename?: 'RegionEntityResponse';
		data?: {
			__typename?: 'RegionEntity';
			id?: string;
			attributes?: {
				__typename: 'Region';
				title?: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type RegionDetailFieldsFragment = {
	__typename: 'RegionDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesRegions' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesThemes' }
		| { __typename?: 'Error' }
	>;
};

export type GetRegionOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRegionOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regionOverview?: {
		__typename?: 'RegionOverviewEntityResponse';
		data?: {
			__typename?: 'RegionOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegionOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesRegions';
							title?: any;
							regions?: {
								__typename?: 'RegionRelationResponseCollection';
								data: Array<{
									__typename?: 'RegionEntity';
									id?: string;
									attributes?: {
										__typename: 'Region';
										title?: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesScheduling' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesTable' }
					| {
							__typename?: 'ComponentModulesThemes';
							title?: any;
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: {
										__typename: 'Theme';
										title: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type RegionOverviewSingleFieldsFragment = {
	__typename?: 'RegionOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesRegions';
				title?: any;
				regions?: {
					__typename?: 'RegionRelationResponseCollection';
					data: Array<{
						__typename?: 'RegionEntity';
						id?: string;
						attributes?: {
							__typename: 'Region';
							title?: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesScheduling' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesTable' }
		| {
				__typename?: 'ComponentModulesThemes';
				title?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: {
							__typename: 'Theme';
							title: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type GetRoadMaintenanceDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRoadMaintenanceDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	roadMaintenanceDetail?: {
		__typename?: 'RoadMaintenanceDetailEntityResponse';
		data?: {
			__typename?: 'RoadMaintenanceDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'RoadMaintenanceDetail';
				locale?: string;
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename: 'ComponentModulesScheduling' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	roadMaintenanceBySlug?: {
		__typename?: 'RoadMaintenanceEntityResponse';
		data?: {
			__typename?: 'RoadMaintenanceEntity';
			id?: string;
			attributes?: {
				__typename: 'RoadMaintenance';
				title: string;
				slug: string;
				basePath?: string;
				fullPath?: string;
				locale?: string;
				status?: Enum_Roadmaintenance_Status;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				aboutMaintenance: {
					__typename: 'ComponentInternalAboutMaintenance';
					startDate?: any;
					endDate?: any;
					contractor?: string;
					contractorWebsite?: string;
					detour?: string;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetRoadMaintenanceOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetRoadMaintenanceOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	roadMaintenanceOverview?: {
		__typename?: 'RoadMaintenanceOverviewEntityResponse';
		data?: {
			__typename?: 'RoadMaintenanceOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'RoadMaintenanceOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				list: { __typename: 'ComponentModulesRoadMaintenanceOverview'; id: string; title: string };
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'ComponentModulesPhotoAlbum' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	themes?: {
		__typename?: 'ThemeEntityResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetSearchOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetSearchOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	searchOverview?: {
		__typename?: 'SearchOverviewEntityResponse';
		data?: {
			__typename?: 'SearchOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'SearchOverview';
				searchHeader?: {
					__typename?: 'ComponentModulesSearchHeader';
					id: string;
					title?: string;
					searchPlaceholder?: string;
				};
				Zoekresultaten: { __typename?: 'ComponentModulesSearchOverview'; id: string; resultLabel?: string };
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetLocalSitemapCondoleanceQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetLocalSitemapCondoleanceQuery = {
	__typename?: 'Query';
	condoleanceInformation?: {
		__typename?: 'CondoleanceInformationEntityResponse';
		data?: {
			__typename?: 'CondoleanceInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'CondoleanceInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	sitemap?: {
		__typename?: 'SitemapEntityResponse';
		data?: {
			__typename?: 'SitemapEntity';
			id?: string;
			attributes?: {
				__typename?: 'Sitemap';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetLocalSitemapEfroOostQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetLocalSitemapEfroOostQuery = {
	__typename?: 'Query';
	efroOostInformation?: {
		__typename?: 'EfroOostInformationEntityResponse';
		data?: {
			__typename?: 'EfroOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	sitemap?: {
		__typename?: 'SitemapEntityResponse';
		data?: {
			__typename?: 'SitemapEntity';
			id?: string;
			attributes?: {
				__typename?: 'Sitemap';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetLocalSitemapGeolinkQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetLocalSitemapGeolinkQuery = {
	__typename?: 'Query';
	geolinkInformation?: {
		__typename?: 'GeolinkInformationEntityResponse';
		data?: {
			__typename?: 'GeolinkInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'GeolinkInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	sitemap?: {
		__typename?: 'SitemapEntityResponse';
		data?: {
			__typename?: 'SitemapEntity';
			id?: string;
			attributes?: {
				__typename?: 'Sitemap';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetLocalSitemapOpOostQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetLocalSitemapOpOostQuery = {
	__typename?: 'Query';
	opOostInformation?: {
		__typename?: 'OpOostInformationEntityResponse';
		data?: {
			__typename?: 'OpOostInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	sitemap?: {
		__typename?: 'SitemapEntityResponse';
		data?: {
			__typename?: 'SitemapEntity';
			id?: string;
			attributes?: {
				__typename?: 'Sitemap';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetLocalSitemapRegioExpresQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetLocalSitemapRegioExpresQuery = {
	__typename?: 'Query';
	regioExpresInformation?: {
		__typename?: 'RegioExpresInformationEntityResponse';
		data?: {
			__typename?: 'RegioExpresInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	sitemap?: {
		__typename?: 'SitemapEntityResponse';
		data?: {
			__typename?: 'SitemapEntity';
			id?: string;
			attributes?: {
				__typename?: 'Sitemap';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetLocalSitemapQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;

export type GetLocalSitemapQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	sitemap?: {
		__typename?: 'SitemapEntityResponse';
		data?: {
			__typename?: 'SitemapEntity';
			id?: string;
			attributes?: {
				__typename?: 'Sitemap';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetSubsidyDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetSubsidyDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	subsidyDetail?: {
		__typename?: 'SubsidyDetailEntityResponse';
		data?: {
			__typename?: 'SubsidyDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'SubsidyDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesPhotoAlbum' }
					| { __typename?: 'ComponentModulesRegions' }
					| { __typename?: 'ComponentModulesScheduling' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
	subsidyBySlug?: {
		__typename?: 'SubsidyEntityResponse';
		data?: {
			__typename?: 'SubsidyEntity';
			id?: string;
			attributes?: {
				__typename?: 'Subsidy';
				title: string;
				slug: string;
				basePath?: string;
				fullPath?: string;
				subsidyCeiling?: Enum_Subsidy_Subsidyceiling;
				updatedAt?: any;
				status: Enum_Subsidy_Status;
				endDate?: any;
				targetGroupText?: string;
				hideFromOverview: boolean;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string };
					}>;
				};
				subsidy_scales?: {
					__typename?: 'SubsidyScaleRelationResponseCollection';
					data: Array<{
						__typename?: 'SubsidyScaleEntity';
						id?: string;
						attributes?: { __typename?: 'SubsidyScale'; name?: string };
					}>;
				};
				subsidy_kinds?: {
					__typename?: 'SubsidyKindRelationResponseCollection';
					data: Array<{
						__typename?: 'SubsidyKindEntity';
						id?: string;
						attributes?: { __typename?: 'SubsidyKind'; name?: string };
					}>;
				};
				target_groups?: {
					__typename?: 'TargetGroupRelationResponseCollection';
					data: Array<{
						__typename?: 'TargetGroupEntity';
						id?: string;
						attributes?: { __typename?: 'TargetGroup'; name?: string };
					}>;
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesScheduling';
							title?: any;
							firstColumnTitle: string;
							secondColumnTitle?: string;
							firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
							secondColumnRows?: Array<{
								__typename: 'ComponentInternalSecondColumnItems';
								id: string;
								bodyText?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesTable';
							table?: string;
							tableTitle?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type GetSubsidyOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetSubsidyOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	subsidyOverview?: {
		__typename?: 'SubsidyOverviewEntityResponse';
		data?: {
			__typename?: 'SubsidyOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'SubsidyOverview';
				locale?: string;
				publishedAt?: any;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| { __typename?: 'ComponentModulesAccordeon' }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesDownloads' }
					| { __typename?: 'ComponentModulesFeaturedNews' }
					| { __typename?: 'ComponentModulesFeaturedPages' }
					| { __typename?: 'ComponentModulesHighlight' }
					| { __typename?: 'ComponentModulesLinkBox' }
					| { __typename?: 'ComponentModulesMedia' }
					| { __typename?: 'ComponentModulesPhotoAlbum' }
					| { __typename?: 'ComponentModulesRegions' }
					| { __typename?: 'ComponentModulesSubjects' }
					| {
							__typename?: 'ComponentModulesSubsidyOverview';
							id: string;
							showResultsText: string;
							headerTitle: string;
							searchPlaceholderText: string;
							themeFilterText: string;
							showMoreThemesText: string;
							showLessThemesText: string;
							targetGroupFilterText: string;
							kindFilterText: string;
							scaleFilterText: string;
							overviewTitleText: string;
							filterHeaderText: string;
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
	kinds?: {
		__typename?: 'SubsidyKindEntityResponseCollection';
		data: Array<{
			__typename?: 'SubsidyKindEntity';
			id?: string;
			attributes?: { __typename?: 'SubsidyKind'; name?: string };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
	scales?: {
		__typename?: 'SubsidyScaleEntityResponseCollection';
		data: Array<{
			__typename?: 'SubsidyScaleEntity';
			id?: string;
			attributes?: { __typename?: 'SubsidyScale'; name?: string };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
	groups?: {
		__typename?: 'TargetGroupEntityResponseCollection';
		data: Array<{
			__typename?: 'TargetGroupEntity';
			id?: string;
			attributes?: { __typename?: 'TargetGroup'; name?: string };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
	themes?: {
		__typename?: 'ThemeEntityResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetThemeDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
	slug?: InputMaybe<Scalars['String']>;
}>;

export type GetThemeDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	themeDetail?: {
		__typename?: 'ThemeDetailEntityResponse';
		data?: {
			__typename?: 'ThemeDetailEntity';
			id?: string;
			attributes?: {
				__typename: 'ThemeDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
	themeBySlug?: {
		__typename?: 'ThemeEntityResponse';
		data?: {
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type ThemeDetailFieldsFragment = {
	__typename: 'ThemeDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesRegions' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesThemes' }
		| { __typename?: 'Error' }
	>;
};

export type GetThemeOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetThemeOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	themeOverview?: {
		__typename?: 'ThemeOverviewEntityResponse';
		data?: {
			__typename?: 'ThemeOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'ThemeOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesRegions';
							title?: any;
							regions?: {
								__typename?: 'RegionRelationResponseCollection';
								data: Array<{
									__typename?: 'RegionEntity';
									id?: string;
									attributes?: {
										__typename: 'Region';
										title?: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesScheduling' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesTable' }
					| {
							__typename?: 'ComponentModulesThemes';
							title?: any;
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: {
										__typename: 'Theme';
										title: string;
										slug: string;
										fullSlug?: string;
										fullPath?: string;
										basePath?: string;
										locale?: string;
										header?: {
											__typename?: 'ComponentModulesExpandedHeader';
											headerTitle?: string;
											headerDescription?: string;
											title?: string;
											description?: string;
											headerButton?: {
												__typename?: 'ComponentComponentsButton';
												title?: string;
												variant: Enum_Componentcomponentsbutton_Variant;
												href: any;
												iconLeft?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
												iconRight?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: { __typename?: 'UploadFile'; url: string; name: string };
													};
												};
											};
											headerImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														name: string;
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										seo: {
											__typename?: 'ComponentModulesSeo';
											title?: string;
											description?: string;
											noIndex?: boolean;
											noFollow?: boolean;
											ogTitle?: string;
											ogDescription?: string;
											ogType?: string;
											ogUrl?: string;
											ogImage?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										};
										modules?: Array<
											| {
													__typename: 'ComponentModulesAccordeon';
													accordeonTitle?: any;
													sections: Array<{
														__typename: 'ComponentInternalAccordeonSection';
														accordeonSectionName?: string;
														items: Array<{
															__typename: 'ComponentInternalAccordeonItem';
															itemTitle: string;
															description?: string;
														}>;
													}>;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesBbvmsMediaplayer';
													videoId: string;
													playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
													url?: string;
													title?: any;
													description?: string;
													transcript?: string;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyText';
													bodyText?: string;
													sidebar?: {
														__typename?: 'ComponentInternalSidebar';
														title?: any;
														links?: Array<{
															__typename?: 'ComponentComponentsLink';
															title: string;
															href: any;
															description?: string;
														}>;
														files?: {
															__typename?: 'UploadFileRelationResponseCollection';
															data: Array<{
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	ext?: string;
																	mime: string;
																	size: number;
																	url: string;
																	alternativeText?: string;
																	hash: string;
																	name: string;
																	caption?: string;
																};
															}>;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesBodyTextTwoColumn';
													leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesCta';
													description?: string;
													imagePosition?: Enum_Componentmodulescta_Imageposition;
													videoLink?: string;
													ctaTitle?: any;
													buttons?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: { __typename?: 'UploadFile'; url: string; name: string };
															};
														};
													}>;
													imageOrVideoFile?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																name: string;
																alternativeText?: string;
																caption?: string;
																url: string;
																mime: string;
																formats?: any;
																blurhash?: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesDownloads';
													downloadsTitle?: any;
													downloads?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																url: string;
																name: string;
																size: number;
																ext?: string;
																caption?: string;
															};
														}>;
													};
													downloadLinks?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedNews';
													featuredNewsTitle?: any;
													items?: {
														__typename?: 'NewsarticleRelationResponseCollection';
														data: Array<{
															__typename?: 'NewsarticleEntity';
															id?: string;
															attributes?: {
																__typename: 'Newsarticle';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
																bodyText: {
																	__typename?: 'ComponentModulesBodyText';
																	bodyText?: string;
																	sidebar?: {
																		__typename?: 'ComponentInternalSidebar';
																		title?: any;
																		links?: Array<{
																			__typename?: 'ComponentComponentsLink';
																			title: string;
																			href: any;
																			description?: string;
																		}>;
																		files?: {
																			__typename?: 'UploadFileRelationResponseCollection';
																			data: Array<{
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					ext?: string;
																					mime: string;
																					size: number;
																					url: string;
																					alternativeText?: string;
																					hash: string;
																					name: string;
																					caption?: string;
																				};
																			}>;
																		};
																	};
																	defaultModuleOptions: {
																		__typename?: 'ComponentInternalDefaultModuleOptions';
																		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesFeaturedPages';
													featuredPagesTitle?: any;
													items?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename: 'Page';
																publishedAt?: any;
																title: string;
																basePath?: string;
																slug?: string;
																header: {
																	__typename?: 'ComponentModulesIntroHeaderImage';
																	title: string;
																	excerpt?: string;
																	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																	cover?: {
																		__typename?: 'UploadFileEntityResponse';
																		data?: {
																			__typename?: 'UploadFileEntity';
																			id?: string;
																			attributes?: {
																				__typename?: 'UploadFile';
																				alternativeText?: string;
																				url: string;
																				formats?: any;
																				blurhash?: string;
																			};
																		};
																	};
																};
															};
														}>;
													};
													cta?: {
														__typename?: 'ComponentComponentsButton';
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														ctaTitle?: string;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename: 'ComponentModulesHighlight';
													id: string;
													title?: any;
													theme?: {
														__typename?: 'ThemeEntityResponse';
														data?: {
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string };
														};
													};
													newsTab?: {
														__typename?: 'ComponentInternalHighlightNews';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													eventsTab?: {
														__typename?: 'ComponentInternalHighlightEvents';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													permitsTab?: {
														__typename?: 'ComponentInternalHighlightPermits';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'PermitRelationResponseCollection';
															data: Array<{
																__typename?: 'PermitEntity';
																attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													wooTab?: {
														__typename?: 'ComponentInternalHighlightWoo';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'WooRequestRelationResponseCollection';
															data: Array<{
																__typename?: 'WooRequestEntity';
																attributes?: {
																	__typename: 'WooRequest';
																	title: string;
																	startDate: any;
																	zip?: any;
																	createdAt?: any;
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													mapsAndStatsTab?: {
														__typename?: 'ComponentInternalHighlightMapsAndStats';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'MapsFigureRelationResponseCollection';
															data: Array<{
																__typename?: 'MapsFigureEntity';
																attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													topicsTab?: {
														__typename?: 'ComponentInternalHighlightTopics';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'NewsarticleRelationResponseCollection';
															data: Array<{
																__typename?: 'NewsarticleEntity';
																attributes?: {
																	__typename: 'Newsarticle';
																	publishedAt?: any;
																	title: string;
																	basePath?: string;
																	slug?: string;
																	header: {
																		__typename?: 'ComponentModulesIntroHeaderImage';
																		title: string;
																		excerpt?: string;
																		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
																		cover?: {
																			__typename?: 'UploadFileEntityResponse';
																			data?: {
																				__typename?: 'UploadFileEntity';
																				id?: string;
																				attributes?: {
																					__typename?: 'UploadFile';
																					alternativeText?: string;
																					url: string;
																					formats?: any;
																					blurhash?: string;
																				};
																			};
																		};
																	};
																	bodyText: {
																		__typename?: 'ComponentModulesBodyText';
																		bodyText?: string;
																		sidebar?: {
																			__typename?: 'ComponentInternalSidebar';
																			title?: any;
																			links?: Array<{
																				__typename?: 'ComponentComponentsLink';
																				title: string;
																				href: any;
																				description?: string;
																			}>;
																			files?: {
																				__typename?: 'UploadFileRelationResponseCollection';
																				data: Array<{
																					__typename?: 'UploadFileEntity';
																					id?: string;
																					attributes?: {
																						__typename?: 'UploadFile';
																						ext?: string;
																						mime: string;
																						size: number;
																						url: string;
																						alternativeText?: string;
																						hash: string;
																						name: string;
																						caption?: string;
																					};
																				}>;
																			};
																		};
																		defaultModuleOptions: {
																			__typename?: 'ComponentInternalDefaultModuleOptions';
																			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
																		};
																	};
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													subsidiesTab?: {
														__typename?: 'ComponentInternalHighlightSubsidies';
														id: string;
														title?: string;
														buttonTitle?: string;
														buttonHref?: any;
														items?: {
															__typename?: 'SubsidyRelationResponseCollection';
															data: Array<{
																__typename?: 'SubsidyEntity';
																attributes?: {
																	__typename: 'Subsidy';
																	basePath?: string;
																	slug?: string;
																	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
																};
															}>;
														};
														empty?: {
															__typename?: 'ComponentInternalEmptyState';
															message?: string;
															title?: string;
															href?: string;
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesLinkBox';
													title?: any;
													description?: string;
													linkBoxLinks?: Array<{
														__typename?: 'ComponentComponentsButton';
														title?: string;
														variant: Enum_Componentcomponentsbutton_Variant;
														href: any;
														iconLeft?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
														iconRight?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																attributes?: { __typename?: 'UploadFile'; url: string };
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
														extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
														extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesMedia';
													transcript?: string;
													mediaLink?: string;
													mediaTitle?: any;
													mediaDescription?: string;
													media?: {
														__typename?: 'UploadFileEntityResponse';
														data?: {
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																size: number;
																ext?: string;
																url: string;
																alternativeText?: string;
																formats?: any;
																blurhash?: string;
																mime: string;
															};
														};
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesNewsOverview' }
											| {
													__typename: 'ComponentModulesPhotoAlbum';
													id: string;
													description?: string;
													horizontal?: boolean;
													jsonTitle: any;
													photos: Array<{
														__typename: 'ComponentInternalPhoto';
														id: string;
														title: string;
														description?: string;
														photo: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	url: string;
																	alternativeText?: string;
																	width?: number;
																	height?: number;
																	caption?: string;
																	blurhash?: string;
																	formats?: any;
																	size: number;
																	ext?: string;
																	mime: string;
																};
															};
														};
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesRegions' }
											| {
													__typename: 'ComponentModulesStepByStep';
													title?: any;
													stepByStepCard: Array<{
														__typename: 'ComponentInternalStepByStepCard';
														cardTitle: string;
														cardDescription?: string;
													}>;
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| {
													__typename?: 'ComponentModulesSubjects';
													id: string;
													description?: string;
													overviewTitle?: string;
													subjects?: {
														__typename?: 'PageRelationResponseCollection';
														data: Array<{
															__typename?: 'PageEntity';
															id?: string;
															attributes?: {
																__typename?: 'Page';
																title: string;
																slug: string;
																fullSlug?: string;
																shortDescription?: string;
															};
														}>;
													};
													projects?: {
														__typename?: 'ProjectRelationResponseCollection';
														data: Array<{
															__typename?: 'ProjectEntity';
															id?: string;
															attributes?: {
																__typename?: 'Project';
																title?: string;
																slug: string;
																fullPath?: string;
																shortDescription?: string;
															};
														}>;
													};
													themes?: {
														__typename?: 'ThemeRelationResponseCollection';
														data: Array<{
															__typename?: 'ThemeEntity';
															id?: string;
															attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
														}>;
													};
													defaultModuleOptions: {
														__typename?: 'ComponentInternalDefaultModuleOptions';
														backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
														padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
													};
											  }
											| { __typename?: 'ComponentModulesThemes' }
											| { __typename?: 'Error' }
										>;
									};
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type ThemeOverviewSingleFieldsFragment = {
	__typename?: 'ThemeOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesRegions';
				title?: any;
				regions?: {
					__typename?: 'RegionRelationResponseCollection';
					data: Array<{
						__typename?: 'RegionEntity';
						id?: string;
						attributes?: {
							__typename: 'Region';
							title?: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesScheduling' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesTable' }
		| {
				__typename?: 'ComponentModulesThemes';
				title?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: {
							__typename: 'Theme';
							title: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type GetVacancyDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetVacancyDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	vacancyDetail?: {
		__typename?: 'VacancyDetailEntityResponse';
		data?: {
			__typename?: 'VacancyDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'VacancyDetail';
				basePath?: string;
				share?: {
					__typename?: 'ComponentModulesShare';
					title?: any;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetVacancyInternalDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetVacancyInternalDetailQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	vacancyInternalDetail?: {
		__typename?: 'VacancyInternalDetailEntityResponse';
		data?: {
			__typename?: 'VacancyInternalDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'VacancyInternalDetail';
				basePath?: string;
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetVacancyInternalOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetVacancyInternalOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	vacancyInternalOverview?: {
		__typename?: 'VacancyInternalOverviewEntityResponse';
		data?: {
			__typename?: 'VacancyInternalOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'VacancyInternalOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesVacanciesOverview';
							id: string;
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetVacancyOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetVacancyOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	vacancyOverview?: {
		__typename?: 'VacancyOverviewEntityResponse';
		data?: {
			__typename?: 'VacancyOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'VacancyOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules: Array<
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesVacanciesOverview';
							id: string;
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
};

export type GetWobRequestOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	limit?: InputMaybe<Scalars['Int']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetWobRequestOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	wobRequestOverview?: {
		__typename?: 'WobRequestOverviewEntityResponse';
		data?: {
			__typename?: 'WobRequestOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'WobRequestOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				bodyText?: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				list: {
					__typename: 'ComponentModulesWobRequestOverview';
					id: string;
					sectionTitle: string;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	wobRequests?: {
		__typename?: 'WobRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WobRequestEntity';
			id?: string;
			attributes?: { __typename?: 'WobRequest'; title: string; createdAt?: any; date?: any; zip?: any };
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetWooDocumentCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetWooDocumentCountQuery = {
	__typename?: 'Query';
	countDocumentsPerCategory?: Array<{ __typename?: 'WooCategoryCount'; sitemap?: string; count?: number }>;
};

export type GetOpenWooRequestDetailQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
	slug?: InputMaybe<Scalars['String']>;
}>;

export type GetOpenWooRequestDetailQuery = {
	__typename?: 'Query';
	openGelderlandInformation?: {
		__typename?: 'OpenGelderlandInformationEntityResponse';
		data?: {
			__typename?: 'OpenGelderlandInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpenGelderlandInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	wooDocumentDetail?: {
		__typename?: 'WooDocumentDetailEntityResponse';
		data?: {
			__typename?: 'WooDocumentDetailEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooDocumentDetail';
				modules?: Array<
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesShare';
							title?: any;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	wooRequestBySlug?: {
		__typename?: 'WooRequestEntityResponse';
		data?: {
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooRequest';
				title: string;
				shortTitle?: string;
				description?: string;
				keywords?: string;
				publisher?: Enum_Woorequest_Publisher;
				caseNumber?: string;
				fullPath?: string;
				fullSlug?: string;
				locale?: string;
				zip?: any;
				publishedAt?: any;
				createdAt?: any;
				updatedAt?: any;
				startDate: any;
				endDate?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
				};
				category?: {
					__typename?: 'WooCategoryEntityResponse';
					data?: {
						__typename?: 'WooCategoryEntity';
						attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
					};
				};
				files: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
					}>;
				};
			};
		};
	};
};

export type GetOpenWooRequestOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	limit?: InputMaybe<Scalars['Int']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetOpenWooRequestOverviewQuery = {
	__typename?: 'Query';
	openGelderlandInformation?: {
		__typename?: 'OpenGelderlandInformationEntityResponse';
		data?: {
			__typename?: 'OpenGelderlandInformationEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpenGelderlandInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	openGelderlandWooOverview?: {
		__typename?: 'OpenGelderlandWooOverviewEntityResponse';
		data?: {
			__typename?: 'OpenGelderlandWooOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpenGelderlandWooOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				woo_overview: {
					__typename?: 'ComponentModulesOpenWooRequestOverview';
					id: string;
					filterHeaderText: string;
					headerTitle: string;
					filterLink?: {
						__typename?: 'ComponentComponentsLink';
						id: string;
						title: string;
						href: any;
						description?: string;
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	wooCategories?: {
		__typename?: 'WooCategoryEntityResponseCollection';
		data: Array<{
			__typename?: 'WooCategoryEntity';
			id?: string;
			attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
		}>;
	};
	wooRequests?: {
		__typename?: 'WooRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooRequest';
				title: string;
				shortTitle?: string;
				description?: string;
				keywords?: string;
				publisher?: Enum_Woorequest_Publisher;
				caseNumber?: string;
				fullPath?: string;
				fullSlug?: string;
				locale?: string;
				zip?: any;
				publishedAt?: any;
				createdAt?: any;
				updatedAt?: any;
				startDate: any;
				endDate?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
				};
				category?: {
					__typename?: 'WooCategoryEntityResponse';
					data?: {
						__typename?: 'WooCategoryEntity';
						attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
					};
				};
				files: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
					}>;
				};
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
	countDocumentsPerCategory?: Array<{ __typename?: 'WooCategoryCount'; sitemap?: string; count?: number }>;
};

export type GetWooRequestDetailpageQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetWooRequestDetailpageQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	wooRequestBySlug?: {
		__typename?: 'WooRequestEntityResponse';
		data?: {
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooRequest';
				title: string;
				shortTitle?: string;
				description?: string;
				keywords?: string;
				publisher?: Enum_Woorequest_Publisher;
				caseNumber?: string;
				fullPath?: string;
				fullSlug?: string;
				locale?: string;
				zip?: any;
				publishedAt?: any;
				createdAt?: any;
				updatedAt?: any;
				startDate: any;
				endDate?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
				};
				category?: {
					__typename?: 'WooCategoryEntityResponse';
					data?: {
						__typename?: 'WooCategoryEntity';
						attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
					};
				};
				files: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
					}>;
				};
			};
		};
	};
};

export type GetWooRequestDetailXmlQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	slug?: InputMaybe<Scalars['String']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetWooRequestDetailXmlQuery = {
	__typename?: 'Query';
	wooRequestBySlug?: {
		__typename?: 'WooRequestEntityResponse';
		data?: {
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooRequest';
				title: string;
				shortTitle?: string;
				description?: string;
				keywords?: string;
				publisher?: Enum_Woorequest_Publisher;
				caseNumber?: string;
				fullPath?: string;
				fullSlug?: string;
				locale?: string;
				zip?: any;
				publishedAt?: any;
				createdAt?: any;
				updatedAt?: any;
				startDate: any;
				endDate?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
				};
				category?: {
					__typename?: 'WooCategoryEntityResponse';
					data?: {
						__typename?: 'WooCategoryEntity';
						attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
					};
				};
				files: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
					}>;
				};
			};
		};
	};
};

export type WooRequestBySlugFieldsFragment = {
	__typename?: 'WooRequestEntityResponse';
	data?: {
		__typename?: 'WooRequestEntity';
		id?: string;
		attributes?: {
			__typename?: 'WooRequest';
			title: string;
			shortTitle?: string;
			description?: string;
			keywords?: string;
			publisher?: Enum_Woorequest_Publisher;
			caseNumber?: string;
			fullPath?: string;
			fullSlug?: string;
			locale?: string;
			zip?: any;
			publishedAt?: any;
			createdAt?: any;
			updatedAt?: any;
			startDate: any;
			endDate?: any;
			themes?: {
				__typename?: 'ThemeRelationResponseCollection';
				data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
			};
			category?: {
				__typename?: 'WooCategoryEntityResponse';
				data?: {
					__typename?: 'WooCategoryEntity';
					attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
				};
			};
			files: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
				}>;
			};
		};
	};
};

export type GetWooRequestOverviewQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	limit?: InputMaybe<Scalars['Int']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetWooRequestOverviewQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	wooRequestOverview?: {
		__typename?: 'WooRequestOverviewEntityResponse';
		data?: {
			__typename?: 'WooRequestOverviewEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooRequestOverview';
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				bodyText?: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				list: {
					__typename: 'ComponentModulesWooRequestOverview';
					id: string;
					filterLabel?: string;
					sectionTitle: string;
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename: 'Error' }
				>;
			};
		};
	};
	wooRequests?: {
		__typename?: 'WooRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooRequest';
				title: string;
				shortTitle?: string;
				description?: string;
				keywords?: string;
				publisher?: Enum_Woorequest_Publisher;
				caseNumber?: string;
				fullPath?: string;
				fullSlug?: string;
				locale?: string;
				zip?: any;
				publishedAt?: any;
				createdAt?: any;
				updatedAt?: any;
				startDate: any;
				endDate?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
				};
				category?: {
					__typename?: 'WooCategoryEntityResponse';
					data?: {
						__typename?: 'WooCategoryEntity';
						attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
					};
				};
				files: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
					}>;
				};
			};
		}>;
		meta: { __typename?: 'ResponseCollectionMeta'; pagination: { __typename?: 'Pagination'; total: number } };
	};
};

export type GetWooRequestOverviewXmlQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
	sitemap?: InputMaybe<Scalars['String']>;
}>;

export type GetWooRequestOverviewXmlQuery = {
	__typename?: 'Query';
	wooDocuments?: {
		__typename?: 'WooRequestEntityResponseCollection';
		data: Array<{
			__typename?: 'WooRequestEntity';
			id?: string;
			attributes?: {
				__typename?: 'WooRequest';
				title: string;
				shortTitle?: string;
				description?: string;
				keywords?: string;
				publisher?: Enum_Woorequest_Publisher;
				caseNumber?: string;
				fullPath?: string;
				fullSlug?: string;
				locale?: string;
				zip?: any;
				publishedAt?: any;
				createdAt?: any;
				updatedAt?: any;
				startDate: any;
				endDate?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
				};
				category?: {
					__typename?: 'WooCategoryEntityResponse';
					data?: {
						__typename?: 'WooCategoryEntity';
						attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
					};
				};
				files: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
					}>;
				};
			};
		}>;
	};
};

export type GetWorkAtQueryVariables = Exact<{
	locale?: InputMaybe<Scalars['I18NLocaleCode']>;
	publicationState?: InputMaybe<PublicationState>;
}>;

export type GetWorkAtQuery = {
	__typename?: 'Query';
	pageInformation?: {
		__typename?: 'PageInformationEntityResponse';
		data?: {
			__typename?: 'PageInformationEntity';
			id?: string;
			attributes?: {
				__typename: 'PageInformation';
				locale?: string;
				navbar?: {
					__typename?: 'ComponentInternalNavbar';
					notificationMessage?: string;
					mostPopularTitle?: string;
					ThemeGroupTitle?: string;
					searchPlaceholderText?: string;
					notificationLink?: any;
					notificationIsExternal?: boolean;
					notificationPublishDate?: any;
					notificationUnpublishDate?: any;
					rightNavLink?: any;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								alternativeText?: string;
								size: number;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					mostPopularGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
					themeGroupLinks?: Array<{
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						href?: any;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
					}>;
				};
				footer?: {
					__typename?: 'ComponentInternalFooter';
					accessibilityLink?: string;
					logo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
								width?: number;
								height?: number;
							};
						};
					};
					themeLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					contactLinkGroup?: {
						__typename?: 'ComponentComponentsLinkGroup';
						title: string;
						links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					};
					socialLinkGroup?: {
						__typename?: 'ComponentComponentsIconLinkGroup';
						title: string;
						links: Array<{
							__typename?: 'ComponentComponentsIconLink';
							title: string;
							href: any;
							icon: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
						}>;
					};
					bottomLinks?: Array<{
						__typename?: 'ComponentComponentsLink';
						title: string;
						href: any;
						description?: string;
					}>;
					accessibilityLogo?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo?: {
					__typename?: 'ComponentInternalSeo';
					id: string;
					title: string;
					description: string;
					noIndex: boolean;
					noFollow: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
			};
		};
	};
	workAt?: {
		__typename?: 'WorkAtEntityResponse';
		data?: {
			__typename?: 'WorkAtEntity';
			id?: string;
			attributes?: {
				__typename: 'WorkAt';
				locale?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeader';
					title: string;
					excerpt: string;
					backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesPartners';
							id: string;
							heading: any;
							logos: Array<{
								__typename: 'ComponentComponentsIconLink';
								id: string;
								title: string;
								href: any;
								icon: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| { __typename?: 'Error' }
				>;
			};
		};
	};
};

export type CommentFieldsFragment = {
	__typename: 'ConversationsConversationitem';
	contentTypeId?: number;
	contentTypeUid?: string;
	message?: string;
	author?: string;
	publishedAt?: any;
};

export type CondoleancePageSlugFieldsFragment = {
	__typename?: 'CondoleancePage';
	title: string;
	fullSlug?: string;
	basePath?: string;
	fullPath?: string;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type EfroOostInConvoWithArticleFieldsFragment = {
	__typename?: 'EfroOostInConvoWithArticle';
	title: string;
	publishedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	modules?: Array<
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type EfroOostNewsArticleFieldsFragment = {
	__typename?: 'EfroOostNewsarticle';
	title: string;
	publishedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNewsEfroOost';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'EfroOostNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'EfroOostNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'EfroOostNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type EfroOostPageSlugFieldsFragment = {
	__typename?: 'EfroOostPage';
	title: string;
	fullSlug?: string;
	basePath?: string;
	fullPath?: string;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNewsEfroOost';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'EfroOostNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'EfroOostNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'EfroOostNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type EventFieldsFragment = {
	__typename: 'Event';
	title: string;
	url: string;
	location: string;
	from: any;
	until: any;
	basePath: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	theme?: {
		__typename?: 'ThemeRelationResponseCollection';
		data: Array<{ __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } }>;
	};
	share?: {
		__typename?: 'ComponentModulesShare';
		title?: any;
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
};

export type GeolinkPageSlugFieldsFragment = {
	__typename?: 'GeolinkPage';
	title: string;
	fullSlug?: string;
	basePath?: string;
	fullPath?: string;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesCta' }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type HighlightMapsFiguresFieldsFragment = {
	__typename: 'MapsFigure';
	basePath?: string;
	slug: any;
	title: string;
};

export type HighlightNewsArticleEfroOostFieldsFragment = {
	__typename?: 'EfroOostNewsarticle';
	publishedAt?: any;
	title: string;
	basePath?: string;
	slug?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
};

export type HighlightNewsArticleOpOostFieldsFragment = {
	__typename?: 'OpOostNewsarticle';
	publishedAt?: any;
	title: string;
	basePath?: string;
	slug?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
};

export type HighlightNewsArticleRegioExpresFieldsFragment = {
	__typename?: 'RegioExpresNewsarticle';
	publishedAt?: any;
	title: string;
	basePath?: string;
	slug?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
};

export type HighlightNewsArticleFieldsFragment = {
	__typename: 'Newsarticle';
	publishedAt?: any;
	title: string;
	basePath?: string;
	slug?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
};

export type HighlightPermitsFieldsFragment = { __typename: 'Permit'; basePath?: string; title: string; slug?: string };

export type HighlightSubsidyFieldsFragment = {
	__typename: 'Subsidy';
	basePath?: string;
	slug?: string;
	header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
};

export type HighlightedPagesFieldsFragment = {
	__typename: 'Page';
	publishedAt?: any;
	title: string;
	basePath?: string;
	slug?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
};

export type HighlightWooFragment = {
	__typename: 'WooRequest';
	title: string;
	startDate: any;
	zip?: any;
	createdAt?: any;
};

export type InConvoWithArticleFieldsFragment = {
	__typename?: 'InConvoWithArticle';
	title: string;
	publishedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	modules?: Array<
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesShare' }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type MapsFiguresFieldsFragment = {
	__typename?: 'MapsFigure';
	name: string;
	link: any;
	type: Enum_Mapsfigure_Type;
	media?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: { __typename?: 'UploadFile'; url: string; ext?: string; size: number };
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	themes?: {
		__typename?: 'ThemeRelationResponseCollection';
		data: Array<{ __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } }>;
	};
};

export type NewsArticleFieldsFragment = {
	__typename?: 'Newsarticle';
	title: string;
	publishedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type NoteFieldsFragment = {
	__typename?: 'NoteNote';
	title: string;
	note?: string;
	noteAuthor?: string;
	pageAuthor?: string;
};

export type OpOostInConvoWithArticleFieldsFragment = {
	__typename?: 'OpOostInConvoWithArticle';
	title: string;
	publishedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	modules?: Array<
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type OpOostNewsArticleFieldsFragment = {
	__typename?: 'OpOostNewsarticle';
	title: string;
	publishedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNewsOpOost';
				featuredNewsTitle?: any;
				news?: {
					__typename?: 'OpOostNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'OpOostNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'OpOostNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type OpOostPageSlugFieldsFragment = {
	__typename?: 'OpOostPage';
	title: string;
	fullSlug?: string;
	basePath?: string;
	fullPath?: string;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNewsOpOost';
				featuredNewsTitle?: any;
				news?: {
					__typename?: 'OpOostNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'OpOostNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'OpOostNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type PageSlugFieldsFragment = {
	__typename?: 'Page';
	title: string;
	fullSlug?: string;
	basePath?: string;
	fullPath?: string;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesRegions';
				title?: any;
				regions?: {
					__typename?: 'RegionRelationResponseCollection';
					data: Array<{
						__typename?: 'RegionEntity';
						id?: string;
						attributes?: {
							__typename: 'Region';
							title?: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesThemes';
				title?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: {
							__typename: 'Theme';
							title: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type PermitFieldsFragment = {
	__typename?: 'Permit';
	publishedAt?: any;
	updatedAt?: any;
	title: string;
	law?: Enum_Permit_Law;
	productType?: Enum_Permit_Producttype;
	basePath?: string;
	fullPath?: string;
	fullSlug?: string;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	themes?: {
		__typename?: 'ThemeRelationResponseCollection';
		data: Array<{ __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } }>;
	};
	permitLaws?: {
		__typename?: 'PermitLawRelationResponseCollection';
		data: Array<{
			__typename?: 'PermitLawEntity';
			id?: string;
			attributes?: { __typename?: 'PermitLaw'; name: string };
		}>;
	};
	permitProductTypes?: {
		__typename?: 'PermitProductTypeRelationResponseCollection';
		data: Array<{
			__typename?: 'PermitProductTypeEntity';
			id?: string;
			attributes?: { __typename?: 'PermitProductType'; name: string };
		}>;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type PressReleaseFieldsFragment = {
	__typename?: 'PressRelease';
	title: string;
	locale?: string;
	publishedAt?: any;
	basePath?: string;
	slug: string;
	fullSlug?: string;
	fullPath?: string;
	allowedOnHomePage?: boolean;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	theme?: {
		__typename?: 'ThemeRelationResponseCollection';
		data: Array<{ __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } }>;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesFeaturedNews' }
		| { __typename?: 'ComponentModulesFeaturedPages' }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type ProjectFieldsFragment = {
	__typename?: 'Project';
	title?: string;
	publishedAt?: any;
	updatedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type RegioExpresInConvoWithArticleFieldsFragment = {
	__typename?: 'RegioExpresInConvoWithArticle';
	title: string;
	publishedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	modules?: Array<
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type RegioExpresNewsArticleFieldsFragment = {
	__typename?: 'RegioExpresNewsarticle';
	title: string;
	publishedAt?: any;
	slug: string;
	basePath?: string;
	fullPath?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	tags?: {
		__typename?: 'TagRelationResponseCollection';
		data: Array<{ __typename?: 'TagEntity'; id?: string; attributes?: { __typename?: 'Tag'; name: string } }>;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNewsRegioExpres';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'RegioExpresNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'RegioExpresNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type RegioExpresPageSlugFieldsFragment = {
	__typename?: 'RegioExpresPage';
	title: string;
	fullSlug?: string;
	basePath?: string;
	fullPath?: string;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNewsRegioExpres';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'RegioExpresNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'RegioExpresNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type RegionFieldsFragment = {
	__typename: 'Region';
	title?: string;
	slug: string;
	fullSlug?: string;
	fullPath?: string;
	basePath?: string;
	locale?: string;
	header?: {
		__typename?: 'ComponentModulesExpandedHeader';
		headerTitle?: string;
		headerDescription?: string;
		title?: string;
		description?: string;
		headerButton?: {
			__typename?: 'ComponentComponentsButton';
			title?: string;
			variant: Enum_Componentcomponentsbutton_Variant;
			href: any;
			iconLeft?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
			iconRight?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
		};
		headerImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesNewsOverview' }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesRegions' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesThemes' }
		| { __typename?: 'Error' }
	>;
};

export type RoadMaintenanceFieldsFragment = {
	__typename: 'RoadMaintenance';
	title: string;
	slug: string;
	basePath?: string;
	fullPath?: string;
	locale?: string;
	status?: Enum_Roadmaintenance_Status;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	aboutMaintenance: {
		__typename: 'ComponentInternalAboutMaintenance';
		startDate?: any;
		endDate?: any;
		contractor?: string;
		contractorWebsite?: string;
		detour?: string;
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type SubsidyFieldsFragment = {
	__typename?: 'Subsidy';
	title: string;
	slug: string;
	basePath?: string;
	fullPath?: string;
	subsidyCeiling?: Enum_Subsidy_Subsidyceiling;
	updatedAt?: any;
	status: Enum_Subsidy_Status;
	endDate?: any;
	targetGroupText?: string;
	hideFromOverview: boolean;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	themes?: {
		__typename?: 'ThemeRelationResponseCollection';
		data: Array<{ __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } }>;
	};
	subsidy_scales?: {
		__typename?: 'SubsidyScaleRelationResponseCollection';
		data: Array<{
			__typename?: 'SubsidyScaleEntity';
			id?: string;
			attributes?: { __typename?: 'SubsidyScale'; name?: string };
		}>;
	};
	subsidy_kinds?: {
		__typename?: 'SubsidyKindRelationResponseCollection';
		data: Array<{
			__typename?: 'SubsidyKindEntity';
			id?: string;
			attributes?: { __typename?: 'SubsidyKind'; name?: string };
		}>;
	};
	target_groups?: {
		__typename?: 'TargetGroupRelationResponseCollection';
		data: Array<{
			__typename?: 'TargetGroupEntity';
			id?: string;
			attributes?: { __typename?: 'TargetGroup'; name?: string };
		}>;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesRegions' }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesThemes' }
		| { __typename?: 'Error' }
	>;
};

export type TagFieldsFragment = { __typename?: 'Tag'; name: string };

export type ThemeFieldsFragment = {
	__typename: 'Theme';
	title: string;
	slug: string;
	fullSlug?: string;
	fullPath?: string;
	basePath?: string;
	locale?: string;
	header?: {
		__typename?: 'ComponentModulesExpandedHeader';
		headerTitle?: string;
		headerDescription?: string;
		title?: string;
		description?: string;
		headerButton?: {
			__typename?: 'ComponentComponentsButton';
			title?: string;
			variant: Enum_Componentcomponentsbutton_Variant;
			href: any;
			iconLeft?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
			iconRight?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
		};
		headerImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesNewsOverview' }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesRegions' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesThemes' }
		| { __typename?: 'Error' }
	>;
};

export type WobRequestFieldsFragment = {
	__typename?: 'WobRequest';
	title: string;
	createdAt?: any;
	date?: any;
	zip?: any;
};

export type WooCategoryFieldsFragment = {
	__typename?: 'WooCategory';
	title: string;
	sitemap: string;
	resource: string;
};

export type WooRequestFieldsFragment = {
	__typename?: 'WooRequest';
	title: string;
	shortTitle?: string;
	description?: string;
	keywords?: string;
	publisher?: Enum_Woorequest_Publisher;
	caseNumber?: string;
	fullPath?: string;
	fullSlug?: string;
	locale?: string;
	zip?: any;
	publishedAt?: any;
	createdAt?: any;
	updatedAt?: any;
	startDate: any;
	endDate?: any;
	themes?: {
		__typename?: 'ThemeRelationResponseCollection';
		data: Array<{ __typename?: 'ThemeEntity'; attributes?: { __typename?: 'Theme'; title: string } }>;
	};
	category?: {
		__typename?: 'WooCategoryEntityResponse';
		data?: {
			__typename?: 'WooCategoryEntity';
			attributes?: { __typename?: 'WooCategory'; title: string; sitemap: string; resource: string };
		};
	};
	files: {
		__typename?: 'UploadFileRelationResponseCollection';
		data: Array<{
			__typename?: 'UploadFileEntity';
			attributes?: { __typename?: 'UploadFile'; name: string; url: string; ext?: string; size: number };
		}>;
	};
};

export type ButtonFieldsFragment = {
	__typename?: 'ComponentComponentsButton';
	title?: string;
	variant: Enum_Componentcomponentsbutton_Variant;
	href: any;
	iconLeft?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: { __typename?: 'UploadFile'; url: string; name: string };
		};
	};
	iconRight?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: { __typename?: 'UploadFile'; url: string; name: string };
		};
	};
};

export type DefaultModuleOptionsExtendedFieldsFragment = {
	__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
	extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
	extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
};

export type DefaultModuleOptionsFieldsFragment = {
	__typename?: 'ComponentInternalDefaultModuleOptions';
	backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
	padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
};

export type EmptyStateFieldsFragment = {
	__typename?: 'ComponentInternalEmptyState';
	message?: string;
	title?: string;
	href?: string;
};

export type IconLinkFieldsFragment = {
	__typename: 'ComponentComponentsIconLink';
	id: string;
	title: string;
	href: any;
	icon: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				url: string;
				alternativeText?: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
};

export type LinkFieldsFragment = {
	__typename?: 'ComponentComponentsLink';
	id: string;
	title: string;
	href: any;
	description?: string;
};

export type SidebarFieldsFragment = {
	__typename?: 'ComponentInternalSidebar';
	title?: any;
	links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
	files?: {
		__typename?: 'UploadFileRelationResponseCollection';
		data: Array<{
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				ext?: string;
				mime: string;
				size: number;
				url: string;
				alternativeText?: string;
				hash: string;
				name: string;
				caption?: string;
			};
		}>;
	};
};

export type AccordeonFieldsFragment = {
	__typename: 'ComponentModulesAccordeon';
	accordeonTitle?: any;
	sections: Array<{
		__typename: 'ComponentInternalAccordeonSection';
		accordeonSectionName?: string;
		items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
	}>;
	sidebar?: {
		__typename?: 'ComponentInternalSidebar';
		title?: any;
		links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		files?: {
			__typename?: 'UploadFileRelationResponseCollection';
			data: Array<{
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					ext?: string;
					mime: string;
					size: number;
					url: string;
					alternativeText?: string;
					hash: string;
					name: string;
					caption?: string;
				};
			}>;
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type BbvmsMediaplayerFieldsFragment = {
	__typename: 'ComponentModulesBbvmsMediaplayer';
	videoId: string;
	playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
	url?: string;
	title?: any;
	description?: string;
	transcript?: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type BodyTextTwoColumnsFieldsFragment = {
	__typename?: 'ComponentModulesBodyTextTwoColumn';
	leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
	rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type BodyTextFieldsFragment = {
	__typename?: 'ComponentModulesBodyText';
	bodyText?: string;
	sidebar?: {
		__typename?: 'ComponentInternalSidebar';
		title?: any;
		links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		files?: {
			__typename?: 'UploadFileRelationResponseCollection';
			data: Array<{
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					ext?: string;
					mime: string;
					size: number;
					url: string;
					alternativeText?: string;
					hash: string;
					name: string;
					caption?: string;
				};
			}>;
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type CollectionFieldsFragment = {
	__typename?: 'ComponentModulesCollections';
	id: string;
	description?: string;
	overviewTitle?: string;
	links: Array<{ __typename?: 'ComponentComponentsLink'; id: string; title: string; href: any; description?: string }>;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type ContactModuleFieldsFragment = {
	__typename: 'Contact';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'ComponentModulesScheduling' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type CtaFieldsFragment = {
	__typename?: 'ComponentModulesCta';
	description?: string;
	imagePosition?: Enum_Componentmodulescta_Imageposition;
	videoLink?: string;
	ctaTitle?: any;
	buttons?: Array<{
		__typename?: 'ComponentComponentsButton';
		title?: string;
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	}>;
	imageOrVideoFile?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				name: string;
				alternativeText?: string;
				caption?: string;
				url: string;
				mime: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
		extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
		extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
	};
};

export type DownloadFieldsFragment = {
	__typename?: 'ComponentModulesDownloads';
	downloadsTitle?: any;
	downloads?: {
		__typename?: 'UploadFileRelationResponseCollection';
		data: Array<{
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				url: string;
				name: string;
				size: number;
				ext?: string;
				caption?: string;
			};
		}>;
	};
	downloadLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
		extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
		extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
	};
};

export type EfroOostInConvoWithOverviewFieldsFragment = {
	__typename?: 'ComponentModulesInConvoWithOverview';
	id: string;
	filterLabel?: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type EventOverviewFieldsFragment = {
	__typename?: 'ComponentModulesEventOverview';
	id: string;
	filterLabel?: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type ExpandedHeaderFieldsFragment = {
	__typename?: 'ComponentModulesExpandedHeader';
	headerTitle?: string;
	headerDescription?: string;
	title?: string;
	description?: string;
	headerButton?: {
		__typename?: 'ComponentComponentsButton';
		title?: string;
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	headerImage?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				name: string;
				url: string;
				alternativeText?: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
};

export type FeaturedNewsEfroOostFieldsFragment = {
	__typename?: 'ComponentModulesFeaturedNewsEfroOost';
	featuredNewsTitle?: any;
	items?: {
		__typename?: 'EfroOostNewsarticleRelationResponseCollection';
		data: Array<{
			__typename?: 'EfroOostNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'EfroOostNewsarticle';
				publishedAt?: any;
				title: string;
				basePath?: string;
				slug?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
	};
	cta?: {
		__typename?: 'ComponentComponentsButton';
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		ctaTitle?: string;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type FeaturedNewsOpOostFieldsFragment = {
	__typename?: 'ComponentModulesFeaturedNewsOpOost';
	featuredNewsTitle?: any;
	news?: {
		__typename?: 'OpOostNewsarticleRelationResponseCollection';
		data: Array<{
			__typename?: 'OpOostNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'OpOostNewsarticle';
				publishedAt?: any;
				title: string;
				basePath?: string;
				slug?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
	};
	cta?: {
		__typename?: 'ComponentComponentsButton';
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		ctaTitle?: string;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
			};
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type FeaturedNewsRegioExpresFieldsFragment = {
	__typename?: 'ComponentModulesFeaturedNewsRegioExpres';
	featuredNewsTitle?: any;
	items?: {
		__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
		data: Array<{
			__typename?: 'RegioExpresNewsarticleEntity';
			id?: string;
			attributes?: {
				__typename?: 'RegioExpresNewsarticle';
				publishedAt?: any;
				title: string;
				basePath?: string;
				slug?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
	};
	cta?: {
		__typename?: 'ComponentComponentsButton';
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		ctaTitle?: string;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type FeaturedNewsFieldsFragment = {
	__typename?: 'ComponentModulesFeaturedNews';
	featuredNewsTitle?: any;
	items?: {
		__typename?: 'NewsarticleRelationResponseCollection';
		data: Array<{
			__typename?: 'NewsarticleEntity';
			id?: string;
			attributes?: {
				__typename: 'Newsarticle';
				publishedAt?: any;
				title: string;
				basePath?: string;
				slug?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				bodyText: {
					__typename?: 'ComponentModulesBodyText';
					bodyText?: string;
					sidebar?: {
						__typename?: 'ComponentInternalSidebar';
						title?: any;
						links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
						files?: {
							__typename?: 'UploadFileRelationResponseCollection';
							data: Array<{
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									ext?: string;
									mime: string;
									size: number;
									url: string;
									alternativeText?: string;
									hash: string;
									name: string;
									caption?: string;
								};
							}>;
						};
					};
					defaultModuleOptions: {
						__typename?: 'ComponentInternalDefaultModuleOptions';
						backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
						padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
					};
				};
			};
		}>;
	};
	cta?: {
		__typename?: 'ComponentComponentsButton';
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		ctaTitle?: string;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type FeaturedPagesFieldsFragment = {
	__typename?: 'ComponentModulesFeaturedPages';
	featuredPagesTitle?: any;
	items?: {
		__typename?: 'PageRelationResponseCollection';
		data: Array<{
			__typename?: 'PageEntity';
			id?: string;
			attributes?: {
				__typename: 'Page';
				publishedAt?: any;
				title: string;
				basePath?: string;
				slug?: string;
				header: {
					__typename?: 'ComponentModulesIntroHeaderImage';
					title: string;
					excerpt?: string;
					backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
					cover?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								alternativeText?: string;
								url: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
			};
		}>;
	};
	cta?: {
		__typename?: 'ComponentComponentsButton';
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		ctaTitle?: string;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type FooterFieldsFragment = {
	__typename?: 'ComponentInternalFooter';
	accessibilityLink?: string;
	logo?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				url: string;
				alternativeText?: string;
				formats?: any;
				blurhash?: string;
				width?: number;
				height?: number;
			};
		};
	};
	themeLinkGroup?: {
		__typename?: 'ComponentComponentsLinkGroup';
		title: string;
		links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
	};
	contactLinkGroup?: {
		__typename?: 'ComponentComponentsLinkGroup';
		title: string;
		links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
	};
	socialLinkGroup?: {
		__typename?: 'ComponentComponentsIconLinkGroup';
		title: string;
		links: Array<{
			__typename?: 'ComponentComponentsIconLink';
			title: string;
			href: any;
			icon: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					attributes?: {
						__typename?: 'UploadFile';
						url: string;
						alternativeText?: string;
						formats?: any;
						blurhash?: string;
					};
				};
			};
		}>;
	};
	bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
	accessibilityLogo?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				url: string;
				alternativeText?: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
};

export type HighlightFieldsFragment = {
	__typename: 'ComponentModulesHighlight';
	id: string;
	title?: any;
	theme?: {
		__typename?: 'ThemeEntityResponse';
		data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
	};
	newsTab?: {
		__typename?: 'ComponentInternalHighlightNews';
		id: string;
		title?: string;
		buttonTitle?: string;
		buttonHref?: any;
		items?: {
			__typename?: 'NewsarticleRelationResponseCollection';
			data: Array<{
				__typename?: 'NewsarticleEntity';
				attributes?: {
					__typename: 'Newsarticle';
					publishedAt?: any;
					title: string;
					basePath?: string;
					slug?: string;
					header: {
						__typename?: 'ComponentModulesIntroHeaderImage';
						title: string;
						excerpt?: string;
						backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
						cover?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									alternativeText?: string;
									url: string;
									formats?: any;
									blurhash?: string;
								};
							};
						};
					};
					bodyText: {
						__typename?: 'ComponentModulesBodyText';
						bodyText?: string;
						sidebar?: {
							__typename?: 'ComponentInternalSidebar';
							title?: any;
							links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
							files?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										ext?: string;
										mime: string;
										size: number;
										url: string;
										alternativeText?: string;
										hash: string;
										name: string;
										caption?: string;
									};
								}>;
							};
						};
						defaultModuleOptions: {
							__typename?: 'ComponentInternalDefaultModuleOptions';
							backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
							padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
						};
					};
				};
			}>;
		};
		empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
	};
	eventsTab?: {
		__typename?: 'ComponentInternalHighlightEvents';
		id: string;
		title?: string;
		buttonTitle?: string;
		buttonHref?: any;
		items?: {
			__typename?: 'NewsarticleRelationResponseCollection';
			data: Array<{
				__typename?: 'NewsarticleEntity';
				attributes?: {
					__typename: 'Newsarticle';
					publishedAt?: any;
					title: string;
					basePath?: string;
					slug?: string;
					header: {
						__typename?: 'ComponentModulesIntroHeaderImage';
						title: string;
						excerpt?: string;
						backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
						cover?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									alternativeText?: string;
									url: string;
									formats?: any;
									blurhash?: string;
								};
							};
						};
					};
					bodyText: {
						__typename?: 'ComponentModulesBodyText';
						bodyText?: string;
						sidebar?: {
							__typename?: 'ComponentInternalSidebar';
							title?: any;
							links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
							files?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										ext?: string;
										mime: string;
										size: number;
										url: string;
										alternativeText?: string;
										hash: string;
										name: string;
										caption?: string;
									};
								}>;
							};
						};
						defaultModuleOptions: {
							__typename?: 'ComponentInternalDefaultModuleOptions';
							backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
							padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
						};
					};
				};
			}>;
		};
		empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
	};
	permitsTab?: {
		__typename?: 'ComponentInternalHighlightPermits';
		id: string;
		title?: string;
		buttonTitle?: string;
		buttonHref?: any;
		items?: {
			__typename?: 'PermitRelationResponseCollection';
			data: Array<{
				__typename?: 'PermitEntity';
				attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
			}>;
		};
		empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
	};
	wooTab?: {
		__typename?: 'ComponentInternalHighlightWoo';
		id: string;
		title?: string;
		buttonTitle?: string;
		buttonHref?: any;
		items?: {
			__typename?: 'WooRequestRelationResponseCollection';
			data: Array<{
				__typename?: 'WooRequestEntity';
				attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
			}>;
		};
		empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
	};
	mapsAndStatsTab?: {
		__typename?: 'ComponentInternalHighlightMapsAndStats';
		id: string;
		title?: string;
		buttonTitle?: string;
		buttonHref?: any;
		items?: {
			__typename?: 'MapsFigureRelationResponseCollection';
			data: Array<{
				__typename?: 'MapsFigureEntity';
				attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
			}>;
		};
		empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
	};
	topicsTab?: {
		__typename?: 'ComponentInternalHighlightTopics';
		id: string;
		title?: string;
		buttonTitle?: string;
		buttonHref?: any;
		items?: {
			__typename?: 'NewsarticleRelationResponseCollection';
			data: Array<{
				__typename?: 'NewsarticleEntity';
				attributes?: {
					__typename: 'Newsarticle';
					publishedAt?: any;
					title: string;
					basePath?: string;
					slug?: string;
					header: {
						__typename?: 'ComponentModulesIntroHeaderImage';
						title: string;
						excerpt?: string;
						backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
						cover?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: {
									__typename?: 'UploadFile';
									alternativeText?: string;
									url: string;
									formats?: any;
									blurhash?: string;
								};
							};
						};
					};
					bodyText: {
						__typename?: 'ComponentModulesBodyText';
						bodyText?: string;
						sidebar?: {
							__typename?: 'ComponentInternalSidebar';
							title?: any;
							links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
							files?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										ext?: string;
										mime: string;
										size: number;
										url: string;
										alternativeText?: string;
										hash: string;
										name: string;
										caption?: string;
									};
								}>;
							};
						};
						defaultModuleOptions: {
							__typename?: 'ComponentInternalDefaultModuleOptions';
							backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
							padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
						};
					};
				};
			}>;
		};
		empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
	};
	subsidiesTab?: {
		__typename?: 'ComponentInternalHighlightSubsidies';
		id: string;
		title?: string;
		buttonTitle?: string;
		buttonHref?: any;
		items?: {
			__typename?: 'SubsidyRelationResponseCollection';
			data: Array<{
				__typename?: 'SubsidyEntity';
				attributes?: {
					__typename: 'Subsidy';
					basePath?: string;
					slug?: string;
					header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
				};
			}>;
		};
		empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type HomepageHeaderFieldsFragment = {
	__typename?: 'ComponentModulesHomepageHeader';
	id: string;
	description?: string;
	popularHeader?: string;
	homepageTitle?: string;
	backgroundImage?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				url: string;
				alternativeText?: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
	links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
};

export type InConvoWithOverviewFieldsFragment = {
	__typename?: 'ComponentModulesInConvoWithOverview';
	id: string;
	filterLabel?: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type IntroHeaderImageFieldsFragment = {
	__typename?: 'ComponentModulesIntroHeaderImage';
	title: string;
	excerpt?: string;
	backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
	cover?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				alternativeText?: string;
				url: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
};

export type IntroHeaderFieldsFragment = {
	__typename?: 'ComponentModulesIntroHeader';
	title: string;
	excerpt: string;
	backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
};

export type LinkBoxFieldsFragment = {
	__typename?: 'ComponentModulesLinkBox';
	title?: any;
	description?: string;
	linkBoxLinks?: Array<{
		__typename?: 'ComponentComponentsButton';
		title?: string;
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
		};
	}>;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
		extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
		extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
	};
};

export type MapsFiguresOverviewFieldsFragment = {
	__typename?: 'ComponentModulesMapsFiguresOverview';
	id: string;
	showResultsText: string;
	headerTitle: string;
	searchPlaceholderText: string;
	themeFilterText: string;
	kindFilterText: string;
	showAllThemesText: string;
	overviewTitleText: string;
	filterHeaderText: string;
};

export type MediaFieldsFragment = {
	__typename?: 'ComponentModulesMedia';
	transcript?: string;
	mediaLink?: string;
	mediaTitle?: any;
	mediaDescription?: string;
	media?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				size: number;
				ext?: string;
				url: string;
				alternativeText?: string;
				formats?: any;
				blurhash?: string;
				mime: string;
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type NavbarFieldsFragment = {
	__typename?: 'ComponentInternalNavbar';
	notificationMessage?: string;
	mostPopularTitle?: string;
	ThemeGroupTitle?: string;
	searchPlaceholderText?: string;
	notificationLink?: any;
	notificationIsExternal?: boolean;
	notificationPublishDate?: any;
	notificationUnpublishDate?: any;
	rightNavLink?: any;
	logo?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				name: string;
				alternativeText?: string;
				size: number;
				url: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
	bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	mostPopularGroupLinks?: Array<{
		__typename?: 'ComponentComponentsLinkGroup';
		title: string;
		links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	}>;
	themeGroupLinks?: Array<{
		__typename?: 'ComponentComponentsLinkGroup';
		title: string;
		href?: any;
		links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	}>;
};

export type NewsOverviewFieldsFragment = {
	__typename?: 'ComponentModulesNewsOverview';
	id: string;
	filterLabel?: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type NewsletterFieldsFragment = {
	__typename?: 'ComponentInternalNewsletter';
	title?: string;
	fieldLabel?: string;
	fieldPlaceholder?: string;
	submitButton?: {
		__typename?: 'ComponentComponentsButton';
		title?: string;
		variant: Enum_Componentcomponentsbutton_Variant;
		href: any;
		iconLeft?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
		iconRight?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type NotificationBarFieldsFragment = {
	__typename?: 'ComponentInternalNavbar';
	notificationLink?: any;
	notificationMessage?: string;
	notificationIsExternal?: boolean;
	notificationPublishDate?: any;
	notificationUnpublishDate?: any;
	mostPopularTitle?: string;
	ThemeGroupTitle?: string;
	bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string }>;
};

export type OpOostInConvoWithOverviewFieldsFragment = {
	__typename?: 'ComponentModulesInConvoWithOverview';
	id: string;
	filterLabel?: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type OpenGelderlandWooDocumentOverviewFragment = {
	__typename?: 'ComponentModulesOpenWooRequestOverview';
	id: string;
	filterHeaderText: string;
	headerTitle: string;
	filterLink?: { __typename?: 'ComponentComponentsLink'; id: string; title: string; href: any; description?: string };
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type PartnersFieldsFragment = {
	__typename: 'ComponentModulesPartners';
	id: string;
	heading: any;
	logos: Array<{
		__typename: 'ComponentComponentsIconLink';
		id: string;
		title: string;
		href: any;
		icon: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	}>;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
		extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
		extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
	};
};

export type PermitsOverviewFieldsFragment = {
	__typename?: 'ComponentModulesPermitsOverview';
	id: string;
	headerTitle: string;
	searchPlaceholderText: string;
	themeFilterText: string;
	lawFilterText: string;
	productTypeFilterText: string;
	showAllThemesText: string;
	showAllLawsText: string;
	showAllProductTypesText: string;
	overviewTitleText: string;
	showResultsText: string;
	filterHeaderText: string;
};

export type PhotoAlbumFieldsFragment = {
	__typename: 'ComponentModulesPhotoAlbum';
	id: string;
	description?: string;
	horizontal?: boolean;
	jsonTitle: any;
	photos: Array<{
		__typename: 'ComponentInternalPhoto';
		id: string;
		title: string;
		description?: string;
		photo: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					width?: number;
					height?: number;
					caption?: string;
					blurhash?: string;
					formats?: any;
					size: number;
					ext?: string;
					mime: string;
				};
			};
		};
	}>;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type PhotoFieldsFragment = {
	__typename: 'ComponentInternalPhoto';
	id: string;
	title: string;
	description?: string;
	photo: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				url: string;
				alternativeText?: string;
				width?: number;
				height?: number;
				caption?: string;
				blurhash?: string;
				formats?: any;
				size: number;
				ext?: string;
				mime: string;
			};
		};
	};
};

export type FullImageFieldsFragment = {
	__typename?: 'UploadFileEntity';
	id?: string;
	attributes?: {
		__typename?: 'UploadFile';
		url: string;
		alternativeText?: string;
		width?: number;
		height?: number;
		caption?: string;
		blurhash?: string;
		formats?: any;
		size: number;
		ext?: string;
		mime: string;
	};
};

export type PressReleaseOverviewFieldsFragment = {
	__typename?: 'ComponentModulesPressReleaseOverview';
	id: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type ProjectOverviewFieldsFragment = {
	__typename?: 'ComponentModulesProjectOverview';
	id: string;
	filterLabel?: string;
	extendedDefaultModuleOptions?: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type RegioExpresInConvoWithOverviewFieldsFragment = {
	__typename?: 'ComponentModulesInConvoWithOverview';
	id: string;
	filterLabel?: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type RegionsFieldsFragment = {
	__typename?: 'ComponentModulesRegions';
	title?: any;
	regions?: {
		__typename?: 'RegionRelationResponseCollection';
		data: Array<{
			__typename?: 'RegionEntity';
			id?: string;
			attributes?: {
				__typename: 'Region';
				title?: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type RoadMaintenanceOverviewFieldsFragment = {
	__typename: 'ComponentModulesRoadMaintenanceOverview';
	id: string;
	title: string;
};

export type SchedulingFieldsFragment = {
	__typename: 'ComponentModulesScheduling';
	title?: any;
	firstColumnTitle: string;
	secondColumnTitle?: string;
	firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
	secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type SeoFieldsFragment = {
	__typename?: 'ComponentModulesSeo';
	title?: string;
	description?: string;
	noIndex?: boolean;
	noFollow?: boolean;
	ogTitle?: string;
	ogDescription?: string;
	ogType?: string;
	ogUrl?: string;
	ogImage?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: { __typename?: 'UploadFile'; url: string; name: string };
		};
	};
};

export type SeoSingleFieldsFragment = {
	__typename?: 'ComponentInternalSeo';
	id: string;
	title: string;
	description: string;
	noIndex: boolean;
	noFollow: boolean;
	ogTitle?: string;
	ogDescription?: string;
	ogType?: string;
	ogUrl?: string;
	ogImage?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: { __typename?: 'UploadFile'; url: string; name: string };
		};
	};
};

export type SharesFieldsFragment = {
	__typename?: 'ComponentModulesShare';
	title?: any;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type StepByStepFieldsFragment = {
	__typename: 'ComponentModulesStepByStep';
	title?: any;
	stepByStepCard: Array<{ __typename: 'ComponentInternalStepByStepCard'; cardTitle: string; cardDescription?: string }>;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type SubjectsFieldsFragment = {
	__typename?: 'ComponentModulesSubjects';
	id: string;
	description?: string;
	overviewTitle?: string;
	subjects?: {
		__typename?: 'PageRelationResponseCollection';
		data: Array<{
			__typename?: 'PageEntity';
			id?: string;
			attributes?: { __typename?: 'Page'; title: string; slug: string; fullSlug?: string; shortDescription?: string };
		}>;
	};
	projects?: {
		__typename?: 'ProjectRelationResponseCollection';
		data: Array<{
			__typename?: 'ProjectEntity';
			id?: string;
			attributes?: {
				__typename?: 'Project';
				title?: string;
				slug: string;
				fullPath?: string;
				shortDescription?: string;
			};
		}>;
	};
	themes?: {
		__typename?: 'ThemeRelationResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
		}>;
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type SubsidyOverviewFieldsFragment = {
	__typename?: 'ComponentModulesSubsidyOverview';
	id: string;
	showResultsText: string;
	headerTitle: string;
	searchPlaceholderText: string;
	themeFilterText: string;
	showMoreThemesText: string;
	showLessThemesText: string;
	targetGroupFilterText: string;
	kindFilterText: string;
	scaleFilterText: string;
	overviewTitleText: string;
	filterHeaderText: string;
};

export type TableFieldsFragment = {
	__typename: 'ComponentModulesTable';
	table?: string;
	tableTitle?: any;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type TestimonialFieldsFragment = {
	__typename: 'ComponentModulesTestimonial';
	id: string;
	quote?: string;
	name: string;
	organization?: string;
	quoteTitle: any;
	photo?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				url: string;
				alternativeText?: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
	logo?: {
		__typename?: 'UploadFileEntityResponse';
		data?: {
			__typename?: 'UploadFileEntity';
			id?: string;
			attributes?: {
				__typename?: 'UploadFile';
				url: string;
				alternativeText?: string;
				formats?: any;
				blurhash?: string;
			};
		};
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
		extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
		extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
	};
};

export type ThemesFieldsFragment = {
	__typename?: 'ComponentModulesThemes';
	title?: any;
	themes?: {
		__typename?: 'ThemeRelationResponseCollection';
		data: Array<{
			__typename?: 'ThemeEntity';
			id?: string;
			attributes?: {
				__typename: 'Theme';
				title: string;
				slug: string;
				fullSlug?: string;
				fullPath?: string;
				basePath?: string;
				locale?: string;
				header?: {
					__typename?: 'ComponentModulesExpandedHeader';
					headerTitle?: string;
					headerDescription?: string;
					title?: string;
					description?: string;
					headerButton?: {
						__typename?: 'ComponentComponentsButton';
						title?: string;
						variant: Enum_Componentcomponentsbutton_Variant;
						href: any;
						iconLeft?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
						iconRight?: {
							__typename?: 'UploadFileEntityResponse';
							data?: {
								__typename?: 'UploadFileEntity';
								id?: string;
								attributes?: { __typename?: 'UploadFile'; url: string; name: string };
							};
						};
					};
					headerImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								name: string;
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				seo: {
					__typename?: 'ComponentModulesSeo';
					title?: string;
					description?: string;
					noIndex?: boolean;
					noFollow?: boolean;
					ogTitle?: string;
					ogDescription?: string;
					ogType?: string;
					ogUrl?: string;
					ogImage?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				};
				modules?: Array<
					| {
							__typename: 'ComponentModulesAccordeon';
							accordeonTitle?: any;
							sections: Array<{
								__typename: 'ComponentInternalAccordeonSection';
								accordeonSectionName?: string;
								items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
							}>;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesBbvmsMediaplayer';
							videoId: string;
							playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
							url?: string;
							title?: any;
							description?: string;
							transcript?: string;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyText';
							bodyText?: string;
							sidebar?: {
								__typename?: 'ComponentInternalSidebar';
								title?: any;
								links?: Array<{
									__typename?: 'ComponentComponentsLink';
									title: string;
									href: any;
									description?: string;
								}>;
								files?: {
									__typename?: 'UploadFileRelationResponseCollection';
									data: Array<{
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											ext?: string;
											mime: string;
											size: number;
											url: string;
											alternativeText?: string;
											hash: string;
											name: string;
											caption?: string;
										};
									}>;
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesBodyTextTwoColumn';
							leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesCta';
							description?: string;
							imagePosition?: Enum_Componentmodulescta_Imageposition;
							videoLink?: string;
							ctaTitle?: any;
							buttons?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							}>;
							imageOrVideoFile?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										name: string;
										alternativeText?: string;
										caption?: string;
										url: string;
										mime: string;
										formats?: any;
										blurhash?: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesDownloads';
							downloadsTitle?: any;
							downloads?: {
								__typename?: 'UploadFileRelationResponseCollection';
								data: Array<{
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										url: string;
										name: string;
										size: number;
										ext?: string;
										caption?: string;
									};
								}>;
							};
							downloadLinks?: Array<{
								__typename?: 'ComponentComponentsLink';
								title: string;
								href: any;
								description?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedNews';
							featuredNewsTitle?: any;
							items?: {
								__typename?: 'NewsarticleRelationResponseCollection';
								data: Array<{
									__typename?: 'NewsarticleEntity';
									id?: string;
									attributes?: {
										__typename: 'Newsarticle';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										bodyText: {
											__typename?: 'ComponentModulesBodyText';
											bodyText?: string;
											sidebar?: {
												__typename?: 'ComponentInternalSidebar';
												title?: any;
												links?: Array<{
													__typename?: 'ComponentComponentsLink';
													title: string;
													href: any;
													description?: string;
												}>;
												files?: {
													__typename?: 'UploadFileRelationResponseCollection';
													data: Array<{
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															ext?: string;
															mime: string;
															size: number;
															url: string;
															alternativeText?: string;
															hash: string;
															name: string;
															caption?: string;
														};
													}>;
												};
											};
											defaultModuleOptions: {
												__typename?: 'ComponentInternalDefaultModuleOptions';
												backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
												padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesFeaturedPages';
							featuredPagesTitle?: any;
							items?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename: 'Page';
										publishedAt?: any;
										title: string;
										basePath?: string;
										slug?: string;
										header: {
											__typename?: 'ComponentModulesIntroHeaderImage';
											title: string;
											excerpt?: string;
											backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
											cover?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														alternativeText?: string;
														url: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
									};
								}>;
							};
							cta?: {
								__typename?: 'ComponentComponentsButton';
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								ctaTitle?: string;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename: 'ComponentModulesHighlight';
							id: string;
							title?: any;
							theme?: {
								__typename?: 'ThemeEntityResponse';
								data?: {
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string };
								};
							};
							newsTab?: {
								__typename?: 'ComponentInternalHighlightNews';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							eventsTab?: {
								__typename?: 'ComponentInternalHighlightEvents';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							permitsTab?: {
								__typename?: 'ComponentInternalHighlightPermits';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'PermitRelationResponseCollection';
									data: Array<{
										__typename?: 'PermitEntity';
										attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							wooTab?: {
								__typename?: 'ComponentInternalHighlightWoo';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'WooRequestRelationResponseCollection';
									data: Array<{
										__typename?: 'WooRequestEntity';
										attributes?: {
											__typename: 'WooRequest';
											title: string;
											startDate: any;
											zip?: any;
											createdAt?: any;
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							mapsAndStatsTab?: {
								__typename?: 'ComponentInternalHighlightMapsAndStats';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'MapsFigureRelationResponseCollection';
									data: Array<{
										__typename?: 'MapsFigureEntity';
										attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							topicsTab?: {
								__typename?: 'ComponentInternalHighlightTopics';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'NewsarticleRelationResponseCollection';
									data: Array<{
										__typename?: 'NewsarticleEntity';
										attributes?: {
											__typename: 'Newsarticle';
											publishedAt?: any;
											title: string;
											basePath?: string;
											slug?: string;
											header: {
												__typename?: 'ComponentModulesIntroHeaderImage';
												title: string;
												excerpt?: string;
												backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
												cover?: {
													__typename?: 'UploadFileEntityResponse';
													data?: {
														__typename?: 'UploadFileEntity';
														id?: string;
														attributes?: {
															__typename?: 'UploadFile';
															alternativeText?: string;
															url: string;
															formats?: any;
															blurhash?: string;
														};
													};
												};
											};
											bodyText: {
												__typename?: 'ComponentModulesBodyText';
												bodyText?: string;
												sidebar?: {
													__typename?: 'ComponentInternalSidebar';
													title?: any;
													links?: Array<{
														__typename?: 'ComponentComponentsLink';
														title: string;
														href: any;
														description?: string;
													}>;
													files?: {
														__typename?: 'UploadFileRelationResponseCollection';
														data: Array<{
															__typename?: 'UploadFileEntity';
															id?: string;
															attributes?: {
																__typename?: 'UploadFile';
																ext?: string;
																mime: string;
																size: number;
																url: string;
																alternativeText?: string;
																hash: string;
																name: string;
																caption?: string;
															};
														}>;
													};
												};
												defaultModuleOptions: {
													__typename?: 'ComponentInternalDefaultModuleOptions';
													backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
													padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
												};
											};
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							subsidiesTab?: {
								__typename?: 'ComponentInternalHighlightSubsidies';
								id: string;
								title?: string;
								buttonTitle?: string;
								buttonHref?: any;
								items?: {
									__typename?: 'SubsidyRelationResponseCollection';
									data: Array<{
										__typename?: 'SubsidyEntity';
										attributes?: {
											__typename: 'Subsidy';
											basePath?: string;
											slug?: string;
											header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
										};
									}>;
								};
								empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesLinkBox';
							title?: any;
							description?: string;
							linkBoxLinks?: Array<{
								__typename?: 'ComponentComponentsButton';
								title?: string;
								variant: Enum_Componentcomponentsbutton_Variant;
								href: any;
								iconLeft?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
								iconRight?: {
									__typename?: 'UploadFileEntityResponse';
									data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
								extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
								extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesMedia';
							transcript?: string;
							mediaLink?: string;
							mediaTitle?: any;
							mediaDescription?: string;
							media?: {
								__typename?: 'UploadFileEntityResponse';
								data?: {
									__typename?: 'UploadFileEntity';
									id?: string;
									attributes?: {
										__typename?: 'UploadFile';
										size: number;
										ext?: string;
										url: string;
										alternativeText?: string;
										formats?: any;
										blurhash?: string;
										mime: string;
									};
								};
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesNewsOverview' }
					| {
							__typename: 'ComponentModulesPhotoAlbum';
							id: string;
							description?: string;
							horizontal?: boolean;
							jsonTitle: any;
							photos: Array<{
								__typename: 'ComponentInternalPhoto';
								id: string;
								title: string;
								description?: string;
								photo: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											url: string;
											alternativeText?: string;
											width?: number;
											height?: number;
											caption?: string;
											blurhash?: string;
											formats?: any;
											size: number;
											ext?: string;
											mime: string;
										};
									};
								};
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesRegions' }
					| {
							__typename: 'ComponentModulesStepByStep';
							title?: any;
							stepByStepCard: Array<{
								__typename: 'ComponentInternalStepByStepCard';
								cardTitle: string;
								cardDescription?: string;
							}>;
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| {
							__typename?: 'ComponentModulesSubjects';
							id: string;
							description?: string;
							overviewTitle?: string;
							subjects?: {
								__typename?: 'PageRelationResponseCollection';
								data: Array<{
									__typename?: 'PageEntity';
									id?: string;
									attributes?: {
										__typename?: 'Page';
										title: string;
										slug: string;
										fullSlug?: string;
										shortDescription?: string;
									};
								}>;
							};
							projects?: {
								__typename?: 'ProjectRelationResponseCollection';
								data: Array<{
									__typename?: 'ProjectEntity';
									id?: string;
									attributes?: {
										__typename?: 'Project';
										title?: string;
										slug: string;
										fullPath?: string;
										shortDescription?: string;
									};
								}>;
							};
							themes?: {
								__typename?: 'ThemeRelationResponseCollection';
								data: Array<{
									__typename?: 'ThemeEntity';
									id?: string;
									attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
								}>;
							};
							defaultModuleOptions: {
								__typename?: 'ComponentInternalDefaultModuleOptions';
								backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
								padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
							};
					  }
					| { __typename?: 'ComponentModulesThemes' }
					| { __typename?: 'Error' }
				>;
			};
		}>;
	};
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type VacancyOverviewFieldsFragment = {
	__typename: 'ComponentModulesVacanciesOverview';
	id: string;
	title?: any;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type WobRequestOverviewFieldsFragment = {
	__typename: 'ComponentModulesWobRequestOverview';
	id: string;
	sectionTitle: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type WooRequestOverviewFieldsFragment = {
	__typename: 'ComponentModulesWooRequestOverview';
	id: string;
	filterLabel?: string;
	sectionTitle: string;
	defaultModuleOptions: {
		__typename?: 'ComponentInternalDefaultModuleOptions';
		backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
		padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
	};
};

export type AgendaPageFieldsFragment = {
	__typename: 'AgendaPage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type CondoleanceInformationFieldsFragment = {
	__typename?: 'CondoleanceInformation';
	locale?: string;
	navbar?: {
		__typename?: 'ComponentInternalNavbar';
		notificationMessage?: string;
		mostPopularTitle?: string;
		ThemeGroupTitle?: string;
		searchPlaceholderText?: string;
		notificationLink?: any;
		notificationIsExternal?: boolean;
		notificationPublishDate?: any;
		notificationUnpublishDate?: any;
		rightNavLink?: any;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					alternativeText?: string;
					size: number;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		mostPopularGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
		themeGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			href?: any;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
	};
	footer?: {
		__typename?: 'ComponentInternalFooter';
		accessibilityLink?: string;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
					width?: number;
					height?: number;
				};
			};
		};
		themeLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		contactLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		socialLinkGroup?: {
			__typename?: 'ComponentComponentsIconLinkGroup';
			title: string;
			links: Array<{
				__typename?: 'ComponentComponentsIconLink';
				title: string;
				href: any;
				icon: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
			}>;
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		accessibilityLogo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo?: {
		__typename?: 'ComponentInternalSeo';
		id: string;
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type CondoleanceHomePageFieldsFragment = {
	__typename?: 'CondoleanceHomepage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesHomepageHeader';
		id: string;
		description?: string;
		popularHeader?: string;
		homepageTitle?: string;
		backgroundImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type ContactFieldsFragment = {
	__typename: 'Contact';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'ComponentModulesScheduling' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type CookiePageFieldsFragment = {
	__typename: 'CookiePage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	bodyText: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type DecisionsPageFieldsFragment = {
	__typename: 'DecisionsPage';
	locale?: string;
	bodyText?: string;
	sidebarCurrentYearTitle: string;
	sidebarArchiveTitle: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	archiveLink: { __typename?: 'ComponentComponentsLink'; title: string; description?: string; href: any };
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type DownloadsPageFieldsFragment = {
	__typename: 'DownloadsPage';
	locale?: string;
	introHeader: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	downloads: Array<{
		__typename?: 'ComponentModulesDownloads';
		downloadsTitle?: any;
		downloads?: {
			__typename?: 'UploadFileRelationResponseCollection';
			data: Array<{
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					name: string;
					size: number;
					ext?: string;
					caption?: string;
				};
			}>;
		};
		downloadLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
			extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
			extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
		};
	}>;
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesScheduling' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type EfroOostInConvoWithDetailSingleFieldsFragment = {
	__typename?: 'EfroOostInConvoWithDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type EfroOostInConvoWithOverviewSingleFieldsFragment = {
	__typename?: 'EfroOostInConvoWithOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesInConvoWithOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type EfroOostInformationFieldsFragment = {
	__typename?: 'EfroOostInformation';
	locale?: string;
	navbar?: {
		__typename?: 'ComponentInternalNavbar';
		notificationMessage?: string;
		mostPopularTitle?: string;
		ThemeGroupTitle?: string;
		searchPlaceholderText?: string;
		notificationLink?: any;
		notificationIsExternal?: boolean;
		notificationPublishDate?: any;
		notificationUnpublishDate?: any;
		rightNavLink?: any;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					alternativeText?: string;
					size: number;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		mostPopularGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
		themeGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			href?: any;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
	};
	footer?: {
		__typename?: 'ComponentInternalFooter';
		accessibilityLink?: string;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
					width?: number;
					height?: number;
				};
			};
		};
		themeLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		contactLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		socialLinkGroup?: {
			__typename?: 'ComponentComponentsIconLinkGroup';
			title: string;
			links: Array<{
				__typename?: 'ComponentComponentsIconLink';
				title: string;
				href: any;
				icon: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
			}>;
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		accessibilityLogo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo?: {
		__typename?: 'ComponentInternalSeo';
		id: string;
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type EfroOostNewsDetailSingleFieldsFragment = {
	__typename?: 'EfroOostNewsDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNewsEfroOost';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'EfroOostNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'EfroOostNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'EfroOostNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type EfroOostNewsOverviewSingleFieldsFragment = {
	__typename?: 'EfroOostNewsOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesNewsOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type EfroOostHomePageFieldsFragment = {
	__typename?: 'EfroOostHomepage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesHomepageHeader';
		id: string;
		description?: string;
		popularHeader?: string;
		homepageTitle?: string;
		backgroundImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNewsEfroOost';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'EfroOostNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'EfroOostNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'EfroOostNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type EventOverviewSingleFieldsFragment = {
	__typename?: 'EventOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesEventOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type GeolinkInformationFieldsFragment = {
	__typename?: 'GeolinkInformation';
	locale?: string;
	navbar?: {
		__typename?: 'ComponentInternalNavbar';
		notificationMessage?: string;
		mostPopularTitle?: string;
		ThemeGroupTitle?: string;
		searchPlaceholderText?: string;
		notificationLink?: any;
		notificationIsExternal?: boolean;
		notificationPublishDate?: any;
		notificationUnpublishDate?: any;
		rightNavLink?: any;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					alternativeText?: string;
					size: number;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		mostPopularGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
		themeGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			href?: any;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
	};
	footer?: {
		__typename?: 'ComponentInternalFooter';
		accessibilityLink?: string;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
					width?: number;
					height?: number;
				};
			};
		};
		themeLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		contactLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		socialLinkGroup?: {
			__typename?: 'ComponentComponentsIconLinkGroup';
			title: string;
			links: Array<{
				__typename?: 'ComponentComponentsIconLink';
				title: string;
				href: any;
				icon: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
			}>;
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		accessibilityLogo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo?: {
		__typename?: 'ComponentInternalSeo';
		id: string;
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type GeolinkHomePageFieldsFragment = {
	__typename?: 'GeolinkHomepage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesHomepageHeader';
		id: string;
		description?: string;
		popularHeader?: string;
		homepageTitle?: string;
		backgroundImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type HomePageFieldsFragment = {
	__typename?: 'HomePage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesHomepageHeader';
		id: string;
		description?: string;
		popularHeader?: string;
		homepageTitle?: string;
		backgroundImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesRegions';
				title?: any;
				regions?: {
					__typename?: 'RegionRelationResponseCollection';
					data: Array<{
						__typename?: 'RegionEntity';
						id?: string;
						attributes?: {
							__typename: 'Region';
							title?: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesThemes';
				title?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: {
							__typename: 'Theme';
							title: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type InConvoWithDetailSingleFieldsFragment = {
	__typename?: 'InConvoWithDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type InConvoWithOverviewSingleFieldsFragment = {
	__typename?: 'InConvoWithOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesInConvoWithOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type LoginSingleFieldsFragment = {
	__typename?: 'Login';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'ComponentModulesVacanciesOverview' }
		| { __typename: 'Error' }
	>;
};

export type MapsFiguresOverviewSingleFieldsFragment = {
	__typename?: 'MapsFiguresOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	mapsFiguresOverview: {
		__typename?: 'ComponentModulesMapsFiguresOverview';
		id: string;
		showResultsText: string;
		headerTitle: string;
		searchPlaceholderText: string;
		themeFilterText: string;
		kindFilterText: string;
		showAllThemesText: string;
		overviewTitleText: string;
		filterHeaderText: string;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'ComponentModulesRegions' }
		| { __typename: 'ComponentModulesScheduling' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'ComponentModulesThemes' }
		| { __typename: 'Error' }
	>;
};

export type NewsDetailSingleFieldsFragment = {
	__typename?: 'NewsDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type NewsOverviewSingleFieldsFragment = {
	__typename?: 'NewsOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesNewsOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type NewsletterArchiveOverviewSingleFieldsFragment = {
	__typename?: 'NewsletterArchiveOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPermitsOverview';
				id: string;
				headerTitle: string;
				searchPlaceholderText: string;
				themeFilterText: string;
				lawFilterText: string;
				productTypeFilterText: string;
				showAllThemesText: string;
				showAllLawsText: string;
				showAllProductTypesText: string;
				overviewTitleText: string;
				showResultsText: string;
				filterHeaderText: string;
		  }
		| { __typename: 'ComponentModulesPhotoAlbum' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type InternalNewsletterWithSubscriptionsFieldsFragment = {
	__typename?: 'InternalNewsletterWithSubscriptions';
	page?: {
		__typename?: 'NewsletterInternal';
		header: {
			__typename?: 'ComponentModulesIntroHeader';
			title: string;
			excerpt: string;
			backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
		};
		newsletter: {
			__typename?: 'ComponentInternalNewsletter';
			title?: string;
			fieldLabel?: string;
			fieldPlaceholder?: string;
			submitButton?: {
				__typename?: 'ComponentComponentsButton';
				title?: string;
				variant: Enum_Componentcomponentsbutton_Variant;
				href: any;
				iconLeft?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
				iconRight?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
			defaultModuleOptions: {
				__typename?: 'ComponentInternalDefaultModuleOptions';
				backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
				padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
			};
		};
		seo: {
			__typename?: 'ComponentModulesSeo';
			title?: string;
			description?: string;
			noIndex?: boolean;
			noFollow?: boolean;
			ogTitle?: string;
			ogDescription?: string;
			ogType?: string;
			ogUrl?: string;
			ogImage?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
		};
	};
};

export type NewsletterInternalSingleFieldsFragment = {
	__typename?: 'NewsletterInternal';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	newsletter: {
		__typename?: 'ComponentInternalNewsletter';
		title?: string;
		fieldLabel?: string;
		fieldPlaceholder?: string;
		submitButton?: {
			__typename?: 'ComponentComponentsButton';
			title?: string;
			variant: Enum_Componentcomponentsbutton_Variant;
			href: any;
			iconLeft?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
			iconRight?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type NewsletterPublicSingleFieldsFragment = {
	__typename?: 'NewsletterPublic';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	newsletter: {
		__typename?: 'ComponentInternalNewsletter';
		title?: string;
		fieldLabel?: string;
		fieldPlaceholder?: string;
		submitButton?: {
			__typename?: 'ComponentComponentsButton';
			title?: string;
			variant: Enum_Componentcomponentsbutton_Variant;
			href: any;
			iconLeft?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
			iconRight?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type PublicNewsletterFieldsFragment = {
	__typename?: 'PublicNewsletterPage';
	page?: {
		__typename?: 'NewsletterPublic';
		header: {
			__typename?: 'ComponentModulesIntroHeader';
			title: string;
			excerpt: string;
			backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
		};
		newsletter: {
			__typename?: 'ComponentInternalNewsletter';
			title?: string;
			fieldLabel?: string;
			fieldPlaceholder?: string;
			submitButton?: {
				__typename?: 'ComponentComponentsButton';
				title?: string;
				variant: Enum_Componentcomponentsbutton_Variant;
				href: any;
				iconLeft?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
				iconRight?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: { __typename?: 'UploadFile'; url: string; name: string };
					};
				};
			};
			defaultModuleOptions: {
				__typename?: 'ComponentInternalDefaultModuleOptions';
				backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
				padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
			};
		};
		seo: {
			__typename?: 'ComponentModulesSeo';
			title?: string;
			description?: string;
			noIndex?: boolean;
			noFollow?: boolean;
			ogTitle?: string;
			ogDescription?: string;
			ogType?: string;
			ogUrl?: string;
			ogImage?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
		};
	};
};

export type NotFoundPageFieldsFragment = {
	__typename: 'NotFoundPage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	links: {
		__typename?: 'ComponentModulesLinkBox';
		title?: any;
		description?: string;
		linkBoxLinks?: Array<{
			__typename?: 'ComponentComponentsButton';
			title?: string;
			variant: Enum_Componentcomponentsbutton_Variant;
			href: any;
			iconLeft?: {
				__typename?: 'UploadFileEntityResponse';
				data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
			};
			iconRight?: {
				__typename?: 'UploadFileEntityResponse';
				data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
			};
		}>;
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
			extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
			extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
		};
	};
	cta: {
		__typename?: 'ComponentModulesCta';
		description?: string;
		imagePosition?: Enum_Componentmodulescta_Imageposition;
		videoLink?: string;
		ctaTitle?: any;
		buttons?: Array<{
			__typename?: 'ComponentComponentsButton';
			title?: string;
			variant: Enum_Componentcomponentsbutton_Variant;
			href: any;
			iconLeft?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
			iconRight?: {
				__typename?: 'UploadFileEntityResponse';
				data?: {
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: { __typename?: 'UploadFile'; url: string; name: string };
				};
			};
		}>;
		imageOrVideoFile?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					alternativeText?: string;
					caption?: string;
					url: string;
					mime: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
			extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
			extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
		};
	};
};

export type OpOostInConvoWithDetailSingleFieldsFragment = {
	__typename?: 'OpOostInConvoWithDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type OpOostInConvoWithOverviewSingleFieldsFragment = {
	__typename?: 'OpOostInConvoWithOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesInConvoWithOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type OpOostInformationFieldsFragment = {
	__typename?: 'OpOostInformation';
	locale?: string;
	navbar?: {
		__typename?: 'ComponentInternalNavbar';
		notificationMessage?: string;
		mostPopularTitle?: string;
		ThemeGroupTitle?: string;
		searchPlaceholderText?: string;
		notificationLink?: any;
		notificationIsExternal?: boolean;
		notificationPublishDate?: any;
		notificationUnpublishDate?: any;
		rightNavLink?: any;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					alternativeText?: string;
					size: number;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		mostPopularGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
		themeGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			href?: any;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
	};
	footer?: {
		__typename?: 'ComponentInternalFooter';
		accessibilityLink?: string;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
					width?: number;
					height?: number;
				};
			};
		};
		themeLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		contactLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		socialLinkGroup?: {
			__typename?: 'ComponentComponentsIconLinkGroup';
			title: string;
			links: Array<{
				__typename?: 'ComponentComponentsIconLink';
				title: string;
				href: any;
				icon: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
			}>;
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		accessibilityLogo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo?: {
		__typename?: 'ComponentInternalSeo';
		id: string;
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type OpOostNewsDetailSingleFieldsFragment = {
	__typename?: 'OpOostNewsDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNewsOpOost';
				featuredNewsTitle?: any;
				news?: {
					__typename?: 'OpOostNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'OpOostNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'OpOostNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type OpOostNewsOverviewSingleFieldsFragment = {
	__typename?: 'OpOostNewsOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesNewsOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type OpOostHomePageFieldsFragment = {
	__typename?: 'OpOostHomepage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesHomepageHeader';
		id: string;
		description?: string;
		popularHeader?: string;
		homepageTitle?: string;
		backgroundImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNewsOpOost';
				featuredNewsTitle?: any;
				news?: {
					__typename?: 'OpOostNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'OpOostNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'OpOostNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: { __typename?: 'UploadFile'; url: string; alternativeText?: string; formats?: any };
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type OpenGelderlandInformationFieldsFragment = {
	__typename?: 'OpenGelderlandInformation';
	locale?: string;
	navbar?: {
		__typename?: 'ComponentInternalNavbar';
		notificationMessage?: string;
		mostPopularTitle?: string;
		ThemeGroupTitle?: string;
		searchPlaceholderText?: string;
		notificationLink?: any;
		notificationIsExternal?: boolean;
		notificationPublishDate?: any;
		notificationUnpublishDate?: any;
		rightNavLink?: any;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					alternativeText?: string;
					size: number;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		mostPopularGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
		themeGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			href?: any;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
	};
	footer?: {
		__typename?: 'ComponentInternalFooter';
		accessibilityLink?: string;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
					width?: number;
					height?: number;
				};
			};
		};
		themeLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		contactLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		socialLinkGroup?: {
			__typename?: 'ComponentComponentsIconLinkGroup';
			title: string;
			links: Array<{
				__typename?: 'ComponentComponentsIconLink';
				title: string;
				href: any;
				icon: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
			}>;
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		accessibilityLogo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo?: {
		__typename?: 'ComponentInternalSeo';
		id: string;
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type OpenGelderlandWooRequestOverviewSingleFieldsFragment = {
	__typename?: 'OpenGelderlandWooOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	woo_overview: {
		__typename?: 'ComponentModulesOpenWooRequestOverview';
		id: string;
		filterHeaderText: string;
		headerTitle: string;
		filterLink?: { __typename?: 'ComponentComponentsLink'; id: string; title: string; href: any; description?: string };
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type OpenGelderlandHomePageFieldsFragment = {
	__typename?: 'OpenGelderlandHomepage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeaderImage';
		title: string;
		excerpt?: string;
		backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
		cover?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					alternativeText?: string;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	collections: {
		__typename?: 'ComponentModulesCollections';
		id: string;
		description?: string;
		overviewTitle?: string;
		links: Array<{
			__typename?: 'ComponentComponentsLink';
			id: string;
			title: string;
			href: any;
			description?: string;
		}>;
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type PageInformationFieldsFragment = {
	__typename: 'PageInformation';
	locale?: string;
	navbar?: {
		__typename?: 'ComponentInternalNavbar';
		notificationMessage?: string;
		mostPopularTitle?: string;
		ThemeGroupTitle?: string;
		searchPlaceholderText?: string;
		notificationLink?: any;
		notificationIsExternal?: boolean;
		notificationPublishDate?: any;
		notificationUnpublishDate?: any;
		rightNavLink?: any;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					alternativeText?: string;
					size: number;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		mostPopularGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
		themeGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			href?: any;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
	};
	footer?: {
		__typename?: 'ComponentInternalFooter';
		accessibilityLink?: string;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
					width?: number;
					height?: number;
				};
			};
		};
		themeLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		contactLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		socialLinkGroup?: {
			__typename?: 'ComponentComponentsIconLinkGroup';
			title: string;
			links: Array<{
				__typename?: 'ComponentComponentsIconLink';
				title: string;
				href: any;
				icon: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
			}>;
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		accessibilityLogo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo?: {
		__typename?: 'ComponentInternalSeo';
		id: string;
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type PasswordPageFieldsFragment = {
	__typename: 'PasswordPage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	modules?: Array<
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesRegions';
				title?: any;
				regions?: {
					__typename?: 'RegionRelationResponseCollection';
					data: Array<{
						__typename?: 'RegionEntity';
						id?: string;
						attributes?: {
							__typename: 'Region';
							title?: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesThemes';
				title?: any;
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: {
							__typename: 'Theme';
							title: string;
							slug: string;
							fullSlug?: string;
							fullPath?: string;
							basePath?: string;
							locale?: string;
							header?: {
								__typename?: 'ComponentModulesExpandedHeader';
								headerTitle?: string;
								headerDescription?: string;
								title?: string;
								description?: string;
								headerButton?: {
									__typename?: 'ComponentComponentsButton';
									title?: string;
									variant: Enum_Componentcomponentsbutton_Variant;
									href: any;
									iconLeft?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
									iconRight?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: { __typename?: 'UploadFile'; url: string; name: string };
										};
									};
								};
								headerImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											name: string;
											url: string;
											alternativeText?: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							seo: {
								__typename?: 'ComponentModulesSeo';
								title?: string;
								description?: string;
								noIndex?: boolean;
								noFollow?: boolean;
								ogTitle?: string;
								ogDescription?: string;
								ogType?: string;
								ogUrl?: string;
								ogImage?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: { __typename?: 'UploadFile'; url: string; name: string };
									};
								};
							};
							modules?: Array<
								| {
										__typename: 'ComponentModulesAccordeon';
										accordeonTitle?: any;
										sections: Array<{
											__typename: 'ComponentInternalAccordeonSection';
											accordeonSectionName?: string;
											items: Array<{
												__typename: 'ComponentInternalAccordeonItem';
												itemTitle: string;
												description?: string;
											}>;
										}>;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesBbvmsMediaplayer';
										videoId: string;
										playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
										url?: string;
										title?: any;
										description?: string;
										transcript?: string;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyText';
										bodyText?: string;
										sidebar?: {
											__typename?: 'ComponentInternalSidebar';
											title?: any;
											links?: Array<{
												__typename?: 'ComponentComponentsLink';
												title: string;
												href: any;
												description?: string;
											}>;
											files?: {
												__typename?: 'UploadFileRelationResponseCollection';
												data: Array<{
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														ext?: string;
														mime: string;
														size: number;
														url: string;
														alternativeText?: string;
														hash: string;
														name: string;
														caption?: string;
													};
												}>;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesBodyTextTwoColumn';
										leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesCta';
										description?: string;
										imagePosition?: Enum_Componentmodulescta_Imageposition;
										videoLink?: string;
										ctaTitle?: any;
										buttons?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: { __typename?: 'UploadFile'; url: string; name: string };
												};
											};
										}>;
										imageOrVideoFile?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													name: string;
													alternativeText?: string;
													caption?: string;
													url: string;
													mime: string;
													formats?: any;
													blurhash?: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesDownloads';
										downloadsTitle?: any;
										downloads?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													url: string;
													name: string;
													size: number;
													ext?: string;
													caption?: string;
												};
											}>;
										};
										downloadLinks?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedNews';
										featuredNewsTitle?: any;
										items?: {
											__typename?: 'NewsarticleRelationResponseCollection';
											data: Array<{
												__typename?: 'NewsarticleEntity';
												id?: string;
												attributes?: {
													__typename: 'Newsarticle';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
													bodyText: {
														__typename?: 'ComponentModulesBodyText';
														bodyText?: string;
														sidebar?: {
															__typename?: 'ComponentInternalSidebar';
															title?: any;
															links?: Array<{
																__typename?: 'ComponentComponentsLink';
																title: string;
																href: any;
																description?: string;
															}>;
															files?: {
																__typename?: 'UploadFileRelationResponseCollection';
																data: Array<{
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		ext?: string;
																		mime: string;
																		size: number;
																		url: string;
																		alternativeText?: string;
																		hash: string;
																		name: string;
																		caption?: string;
																	};
																}>;
															};
														};
														defaultModuleOptions: {
															__typename?: 'ComponentInternalDefaultModuleOptions';
															backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
															padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesFeaturedPages';
										featuredPagesTitle?: any;
										items?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename: 'Page';
													publishedAt?: any;
													title: string;
													basePath?: string;
													slug?: string;
													header: {
														__typename?: 'ComponentModulesIntroHeaderImage';
														title: string;
														excerpt?: string;
														backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
														cover?: {
															__typename?: 'UploadFileEntityResponse';
															data?: {
																__typename?: 'UploadFileEntity';
																id?: string;
																attributes?: {
																	__typename?: 'UploadFile';
																	alternativeText?: string;
																	url: string;
																	formats?: any;
																	blurhash?: string;
																};
															};
														};
													};
												};
											}>;
										};
										cta?: {
											__typename?: 'ComponentComponentsButton';
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											ctaTitle?: string;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														formats?: any;
														blurhash?: string;
													};
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename: 'ComponentModulesHighlight';
										id: string;
										title?: any;
										theme?: {
											__typename?: 'ThemeEntityResponse';
											data?: {
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string };
											};
										};
										newsTab?: {
											__typename?: 'ComponentInternalHighlightNews';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										eventsTab?: {
											__typename?: 'ComponentInternalHighlightEvents';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										permitsTab?: {
											__typename?: 'ComponentInternalHighlightPermits';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'PermitRelationResponseCollection';
												data: Array<{
													__typename?: 'PermitEntity';
													attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										wooTab?: {
											__typename?: 'ComponentInternalHighlightWoo';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'WooRequestRelationResponseCollection';
												data: Array<{
													__typename?: 'WooRequestEntity';
													attributes?: {
														__typename: 'WooRequest';
														title: string;
														startDate: any;
														zip?: any;
														createdAt?: any;
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										mapsAndStatsTab?: {
											__typename?: 'ComponentInternalHighlightMapsAndStats';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'MapsFigureRelationResponseCollection';
												data: Array<{
													__typename?: 'MapsFigureEntity';
													attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										topicsTab?: {
											__typename?: 'ComponentInternalHighlightTopics';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'NewsarticleRelationResponseCollection';
												data: Array<{
													__typename?: 'NewsarticleEntity';
													attributes?: {
														__typename: 'Newsarticle';
														publishedAt?: any;
														title: string;
														basePath?: string;
														slug?: string;
														header: {
															__typename?: 'ComponentModulesIntroHeaderImage';
															title: string;
															excerpt?: string;
															backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
															cover?: {
																__typename?: 'UploadFileEntityResponse';
																data?: {
																	__typename?: 'UploadFileEntity';
																	id?: string;
																	attributes?: {
																		__typename?: 'UploadFile';
																		alternativeText?: string;
																		url: string;
																		formats?: any;
																		blurhash?: string;
																	};
																};
															};
														};
														bodyText: {
															__typename?: 'ComponentModulesBodyText';
															bodyText?: string;
															sidebar?: {
																__typename?: 'ComponentInternalSidebar';
																title?: any;
																links?: Array<{
																	__typename?: 'ComponentComponentsLink';
																	title: string;
																	href: any;
																	description?: string;
																}>;
																files?: {
																	__typename?: 'UploadFileRelationResponseCollection';
																	data: Array<{
																		__typename?: 'UploadFileEntity';
																		id?: string;
																		attributes?: {
																			__typename?: 'UploadFile';
																			ext?: string;
																			mime: string;
																			size: number;
																			url: string;
																			alternativeText?: string;
																			hash: string;
																			name: string;
																			caption?: string;
																		};
																	}>;
																};
															};
															defaultModuleOptions: {
																__typename?: 'ComponentInternalDefaultModuleOptions';
																backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
																padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
															};
														};
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										subsidiesTab?: {
											__typename?: 'ComponentInternalHighlightSubsidies';
											id: string;
											title?: string;
											buttonTitle?: string;
											buttonHref?: any;
											items?: {
												__typename?: 'SubsidyRelationResponseCollection';
												data: Array<{
													__typename?: 'SubsidyEntity';
													attributes?: {
														__typename: 'Subsidy';
														basePath?: string;
														slug?: string;
														header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
													};
												}>;
											};
											empty?: {
												__typename?: 'ComponentInternalEmptyState';
												message?: string;
												title?: string;
												href?: string;
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesLinkBox';
										title?: any;
										description?: string;
										linkBoxLinks?: Array<{
											__typename?: 'ComponentComponentsButton';
											title?: string;
											variant: Enum_Componentcomponentsbutton_Variant;
											href: any;
											iconLeft?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
											iconRight?: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													attributes?: { __typename?: 'UploadFile'; url: string };
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
											extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
											extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesMedia';
										transcript?: string;
										mediaLink?: string;
										mediaTitle?: any;
										mediaDescription?: string;
										media?: {
											__typename?: 'UploadFileEntityResponse';
											data?: {
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													size: number;
													ext?: string;
													url: string;
													alternativeText?: string;
													formats?: any;
													blurhash?: string;
													mime: string;
												};
											};
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesNewsOverview' }
								| {
										__typename: 'ComponentModulesPhotoAlbum';
										id: string;
										description?: string;
										horizontal?: boolean;
										jsonTitle: any;
										photos: Array<{
											__typename: 'ComponentInternalPhoto';
											id: string;
											title: string;
											description?: string;
											photo: {
												__typename?: 'UploadFileEntityResponse';
												data?: {
													__typename?: 'UploadFileEntity';
													id?: string;
													attributes?: {
														__typename?: 'UploadFile';
														url: string;
														alternativeText?: string;
														width?: number;
														height?: number;
														caption?: string;
														blurhash?: string;
														formats?: any;
														size: number;
														ext?: string;
														mime: string;
													};
												};
											};
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesRegions' }
								| {
										__typename: 'ComponentModulesStepByStep';
										title?: any;
										stepByStepCard: Array<{
											__typename: 'ComponentInternalStepByStepCard';
											cardTitle: string;
											cardDescription?: string;
										}>;
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| {
										__typename?: 'ComponentModulesSubjects';
										id: string;
										description?: string;
										overviewTitle?: string;
										subjects?: {
											__typename?: 'PageRelationResponseCollection';
											data: Array<{
												__typename?: 'PageEntity';
												id?: string;
												attributes?: {
													__typename?: 'Page';
													title: string;
													slug: string;
													fullSlug?: string;
													shortDescription?: string;
												};
											}>;
										};
										projects?: {
											__typename?: 'ProjectRelationResponseCollection';
											data: Array<{
												__typename?: 'ProjectEntity';
												id?: string;
												attributes?: {
													__typename?: 'Project';
													title?: string;
													slug: string;
													fullPath?: string;
													shortDescription?: string;
												};
											}>;
										};
										themes?: {
											__typename?: 'ThemeRelationResponseCollection';
											data: Array<{
												__typename?: 'ThemeEntity';
												id?: string;
												attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
											}>;
										};
										defaultModuleOptions: {
											__typename?: 'ComponentInternalDefaultModuleOptions';
											backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
											padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
										};
								  }
								| { __typename?: 'ComponentModulesThemes' }
								| { __typename?: 'Error' }
							>;
						};
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type PermitDetailSingleFieldsFragment = {
	__typename?: 'PermitDetail';
	locale?: string;
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type PermitsOverviewSingleFieldsFragment = {
	__typename?: 'PermitsOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPermitsOverview';
				id: string;
				headerTitle: string;
				searchPlaceholderText: string;
				themeFilterText: string;
				lawFilterText: string;
				productTypeFilterText: string;
				showAllThemesText: string;
				showAllLawsText: string;
				showAllProductTypesText: string;
				overviewTitleText: string;
				showResultsText: string;
				filterHeaderText: string;
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type PressReleaseOverviewSingleFieldsFragment = {
	__typename?: 'PressReleaseOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'ComponentModulesLinkBox' }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPressReleaseOverview';
				id: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type PressReleaseDetailFieldsFragment = {
	__typename: 'PressReleaseDetail';
	modules?: Array<
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export type PrivacyPolicyFieldsFragment = {
	__typename: 'PrivacyPolicy';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	bodytext: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
};

export type ProjectDetailSingleFieldsFragment = {
	__typename?: 'ProjectDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesScheduling';
				title?: any;
				firstColumnTitle: string;
				secondColumnTitle?: string;
				firstColumnRows: Array<{ __typename: 'ComponentInternalFirstRowItems'; id: string; bodyText?: string }>;
				secondColumnRows?: Array<{ __typename: 'ComponentInternalSecondColumnItems'; id: string; bodyText?: string }>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type ProjectOverviewSingleFieldsFragment = {
	__typename?: 'ProjectOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| { __typename: 'ComponentModulesAccordeon' }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'ComponentModulesPartners' }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesProjectOverview';
				id: string;
				filterLabel?: string;
				extendedDefaultModuleOptions?: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'ComponentModulesScheduling' }
		| { __typename: 'ComponentModulesStepByStep' }
		| { __typename: 'ComponentModulesSubjects' }
		| { __typename: 'ComponentModulesTable' }
		| { __typename: 'ComponentModulesTestimonial' }
		| { __typename: 'Error' }
	>;
};

export type RegioExpresInConvoWithDetailSingleFieldsFragment = {
	__typename?: 'RegioExpresInConvoWithDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type RegioExpresInConvoWithOverviewSingleFieldsFragment = {
	__typename?: 'RegioExpresInConvoOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesInConvoWithOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type RegioExpresInformationFieldsFragment = {
	__typename?: 'RegioExpresInformation';
	locale?: string;
	navbar?: {
		__typename?: 'ComponentInternalNavbar';
		notificationMessage?: string;
		mostPopularTitle?: string;
		ThemeGroupTitle?: string;
		searchPlaceholderText?: string;
		notificationLink?: any;
		notificationIsExternal?: boolean;
		notificationPublishDate?: any;
		notificationUnpublishDate?: any;
		rightNavLink?: any;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					name: string;
					alternativeText?: string;
					size: number;
					url: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		mostPopularGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
		themeGroupLinks?: Array<{
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			href?: any;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
		}>;
	};
	footer?: {
		__typename?: 'ComponentInternalFooter';
		accessibilityLink?: string;
		logo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
					width?: number;
					height?: number;
				};
			};
		};
		themeLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		contactLinkGroup?: {
			__typename?: 'ComponentComponentsLinkGroup';
			title: string;
			links: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		};
		socialLinkGroup?: {
			__typename?: 'ComponentComponentsIconLinkGroup';
			title: string;
			links: Array<{
				__typename?: 'ComponentComponentsIconLink';
				title: string;
				href: any;
				icon: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
			}>;
		};
		bottomLinks?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
		accessibilityLogo?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
	};
	seo?: {
		__typename?: 'ComponentInternalSeo';
		id: string;
		title: string;
		description: string;
		noIndex: boolean;
		noFollow: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
};

export type RegioExpresNewsDetailSingleFieldsFragment = {
	__typename?: 'RegioExpresNewsDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNewsRegioExpres';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'RegioExpresNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'RegioExpresNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type RegioExpresNewsOverviewSingleFieldsFragment = {
	__typename?: 'RegioExpresNewsOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesNewsOverview';
				id: string;
				filterLabel?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type RegioExpresHomePageFieldsFragment = {
	__typename?: 'RegioExpresHomepage';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesHomepageHeader';
		id: string;
		description?: string;
		popularHeader?: string;
		homepageTitle?: string;
		backgroundImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: {
					__typename?: 'UploadFile';
					url: string;
					alternativeText?: string;
					formats?: any;
					blurhash?: string;
				};
			};
		};
		links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNewsRegioExpres';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'RegioExpresNewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'RegioExpresNewsarticleEntity';
						id?: string;
						attributes?: {
							__typename?: 'RegioExpresNewsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTestimonial';
				id: string;
				quote?: string;
				name: string;
				organization?: string;
				quoteTitle: any;
				photo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				logo?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type RoadMaintenanceDetailSingleFieldsFragment = {
	__typename?: 'RoadMaintenanceDetail';
	locale?: string;
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename: 'ComponentModulesScheduling' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type RoadMaintenanceOverviewSingleFieldsFragment = {
	__typename?: 'RoadMaintenanceOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	list: { __typename: 'ComponentModulesRoadMaintenanceOverview'; id: string; title: string };
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'ComponentModulesPhotoAlbum' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type SearchOverviewSingleFieldsFragment = {
	__typename?: 'SearchOverview';
	searchHeader?: {
		__typename?: 'ComponentModulesSearchHeader';
		id: string;
		title?: string;
		searchPlaceholder?: string;
	};
	Zoekresultaten: { __typename?: 'ComponentModulesSearchOverview'; id: string; resultLabel?: string };
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type SitemapPageFieldsFragment = {
	__typename?: 'Sitemap';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type SubsidyDetailSingleFieldsFragment = {
	__typename?: 'SubsidyDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesPhotoAlbum' }
		| { __typename?: 'ComponentModulesRegions' }
		| { __typename?: 'ComponentModulesScheduling' }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesTable';
				table?: string;
				tableTitle?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesThemes' }
		| { __typename?: 'Error' }
	>;
};

export type SubsidyOverviewSingleFieldsFragment = {
	__typename?: 'SubsidyOverview';
	locale?: string;
	publishedAt?: any;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| { __typename?: 'ComponentModulesAccordeon' }
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'ComponentModulesDownloads' }
		| { __typename?: 'ComponentModulesFeaturedNews' }
		| { __typename?: 'ComponentModulesFeaturedPages' }
		| { __typename?: 'ComponentModulesHighlight' }
		| { __typename?: 'ComponentModulesLinkBox' }
		| { __typename?: 'ComponentModulesMedia' }
		| { __typename?: 'ComponentModulesPhotoAlbum' }
		| { __typename?: 'ComponentModulesRegions' }
		| { __typename?: 'ComponentModulesSubjects' }
		| {
				__typename?: 'ComponentModulesSubsidyOverview';
				id: string;
				showResultsText: string;
				headerTitle: string;
				searchPlaceholderText: string;
				themeFilterText: string;
				showMoreThemesText: string;
				showLessThemesText: string;
				targetGroupFilterText: string;
				kindFilterText: string;
				scaleFilterText: string;
				overviewTitleText: string;
				filterHeaderText: string;
		  }
		| { __typename?: 'ComponentModulesThemes' }
		| { __typename?: 'Error' }
	>;
};

export type VacancyDetailSingleFieldsFragment = {
	__typename?: 'VacancyDetail';
	basePath?: string;
	share?: {
		__typename?: 'ComponentModulesShare';
		title?: any;
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type VacancyInternalDetailSingleFieldsFragment = {
	__typename?: 'VacancyInternalDetail';
	basePath?: string;
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedNews';
				featuredNewsTitle?: any;
				items?: {
					__typename?: 'NewsarticleRelationResponseCollection';
					data: Array<{
						__typename?: 'NewsarticleEntity';
						id?: string;
						attributes?: {
							__typename: 'Newsarticle';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
							bodyText: {
								__typename?: 'ComponentModulesBodyText';
								bodyText?: string;
								sidebar?: {
									__typename?: 'ComponentInternalSidebar';
									title?: any;
									links?: Array<{
										__typename?: 'ComponentComponentsLink';
										title: string;
										href: any;
										description?: string;
									}>;
									files?: {
										__typename?: 'UploadFileRelationResponseCollection';
										data: Array<{
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												ext?: string;
												mime: string;
												size: number;
												url: string;
												alternativeText?: string;
												hash: string;
												name: string;
												caption?: string;
											};
										}>;
									};
								};
								defaultModuleOptions: {
									__typename?: 'ComponentInternalDefaultModuleOptions';
									backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
									padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesFeaturedPages';
				featuredPagesTitle?: any;
				items?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename: 'Page';
							publishedAt?: any;
							title: string;
							basePath?: string;
							slug?: string;
							header: {
								__typename?: 'ComponentModulesIntroHeaderImage';
								title: string;
								excerpt?: string;
								backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
								cover?: {
									__typename?: 'UploadFileEntityResponse';
									data?: {
										__typename?: 'UploadFileEntity';
										id?: string;
										attributes?: {
											__typename?: 'UploadFile';
											alternativeText?: string;
											url: string;
											formats?: any;
											blurhash?: string;
										};
									};
								};
							};
						};
					}>;
				};
				cta?: {
					__typename?: 'ComponentComponentsButton';
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					ctaTitle?: string;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type VacancyInternalOverviewSingleFieldsFragment = {
	__typename?: 'VacancyInternalOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesVacanciesOverview';
				id: string;
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type VacancyOverviewSingleFieldsFragment = {
	__typename?: 'VacancyOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules: Array<
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesVacanciesOverview';
				id: string;
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type WobRequestOverviewSingleFieldsFragment = {
	__typename?: 'WobRequestOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	bodyText?: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	list: {
		__typename: 'ComponentModulesWobRequestOverview';
		id: string;
		sectionTitle: string;
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type WooDocumentDetailSingleFieldsFragment = {
	__typename?: 'WooDocumentDetail';
	modules?: Array<
		| {
				__typename: 'ComponentModulesBbvmsMediaplayer';
				videoId: string;
				playout: Enum_Componentmodulesbbvmsmediaplayer_Playout;
				url?: string;
				title?: any;
				description?: string;
				transcript?: string;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesShare';
				title?: any;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type WooRequestOverviewSingleFieldsFragment = {
	__typename?: 'WooRequestOverview';
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	bodyText?: {
		__typename?: 'ComponentModulesBodyText';
		bodyText?: string;
		sidebar?: {
			__typename?: 'ComponentInternalSidebar';
			title?: any;
			links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
			files?: {
				__typename?: 'UploadFileRelationResponseCollection';
				data: Array<{
					__typename?: 'UploadFileEntity';
					id?: string;
					attributes?: {
						__typename?: 'UploadFile';
						ext?: string;
						mime: string;
						size: number;
						url: string;
						alternativeText?: string;
						hash: string;
						name: string;
						caption?: string;
					};
				}>;
			};
		};
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	list: {
		__typename: 'ComponentModulesWooRequestOverview';
		id: string;
		filterLabel?: string;
		sectionTitle: string;
		defaultModuleOptions: {
			__typename?: 'ComponentInternalDefaultModuleOptions';
			backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
			padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
		};
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename: 'ComponentModulesAccordeon';
				accordeonTitle?: any;
				sections: Array<{
					__typename: 'ComponentInternalAccordeonSection';
					accordeonSectionName?: string;
					items: Array<{ __typename: 'ComponentInternalAccordeonItem'; itemTitle: string; description?: string }>;
				}>;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesBodyTextTwoColumn';
				leftColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				rightColumn: { __typename?: 'ComponentInternalBodyTextColumn'; bodyText?: string };
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesDownloads';
				downloadsTitle?: any;
				downloads?: {
					__typename?: 'UploadFileRelationResponseCollection';
					data: Array<{
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							url: string;
							name: string;
							size: number;
							ext?: string;
							caption?: string;
						};
					}>;
				};
				downloadLinks?: Array<{
					__typename?: 'ComponentComponentsLink';
					title: string;
					href: any;
					description?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesHighlight';
				id: string;
				title?: any;
				theme?: {
					__typename?: 'ThemeEntityResponse';
					data?: { __typename?: 'ThemeEntity'; id?: string; attributes?: { __typename?: 'Theme'; title: string } };
				};
				newsTab?: {
					__typename?: 'ComponentInternalHighlightNews';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				eventsTab?: {
					__typename?: 'ComponentInternalHighlightEvents';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				permitsTab?: {
					__typename?: 'ComponentInternalHighlightPermits';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'PermitRelationResponseCollection';
						data: Array<{
							__typename?: 'PermitEntity';
							attributes?: { __typename: 'Permit'; basePath?: string; title: string; slug?: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				wooTab?: {
					__typename?: 'ComponentInternalHighlightWoo';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'WooRequestRelationResponseCollection';
						data: Array<{
							__typename?: 'WooRequestEntity';
							attributes?: { __typename: 'WooRequest'; title: string; startDate: any; zip?: any; createdAt?: any };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				mapsAndStatsTab?: {
					__typename?: 'ComponentInternalHighlightMapsAndStats';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'MapsFigureRelationResponseCollection';
						data: Array<{
							__typename?: 'MapsFigureEntity';
							attributes?: { __typename: 'MapsFigure'; basePath?: string; slug: any; title: string };
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				topicsTab?: {
					__typename?: 'ComponentInternalHighlightTopics';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'NewsarticleRelationResponseCollection';
						data: Array<{
							__typename?: 'NewsarticleEntity';
							attributes?: {
								__typename: 'Newsarticle';
								publishedAt?: any;
								title: string;
								basePath?: string;
								slug?: string;
								header: {
									__typename?: 'ComponentModulesIntroHeaderImage';
									title: string;
									excerpt?: string;
									backgroundColor?: Enum_Componentmodulesintroheaderimage_Backgroundcolor;
									cover?: {
										__typename?: 'UploadFileEntityResponse';
										data?: {
											__typename?: 'UploadFileEntity';
											id?: string;
											attributes?: {
												__typename?: 'UploadFile';
												alternativeText?: string;
												url: string;
												formats?: any;
												blurhash?: string;
											};
										};
									};
								};
								bodyText: {
									__typename?: 'ComponentModulesBodyText';
									bodyText?: string;
									sidebar?: {
										__typename?: 'ComponentInternalSidebar';
										title?: any;
										links?: Array<{
											__typename?: 'ComponentComponentsLink';
											title: string;
											href: any;
											description?: string;
										}>;
										files?: {
											__typename?: 'UploadFileRelationResponseCollection';
											data: Array<{
												__typename?: 'UploadFileEntity';
												id?: string;
												attributes?: {
													__typename?: 'UploadFile';
													ext?: string;
													mime: string;
													size: number;
													url: string;
													alternativeText?: string;
													hash: string;
													name: string;
													caption?: string;
												};
											}>;
										};
									};
									defaultModuleOptions: {
										__typename?: 'ComponentInternalDefaultModuleOptions';
										backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
										padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
									};
								};
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				subsidiesTab?: {
					__typename?: 'ComponentInternalHighlightSubsidies';
					id: string;
					title?: string;
					buttonTitle?: string;
					buttonHref?: any;
					items?: {
						__typename?: 'SubsidyRelationResponseCollection';
						data: Array<{
							__typename?: 'SubsidyEntity';
							attributes?: {
								__typename: 'Subsidy';
								basePath?: string;
								slug?: string;
								header: { __typename?: 'ComponentModulesIntroHeader'; title: string };
							};
						}>;
					};
					empty?: { __typename?: 'ComponentInternalEmptyState'; message?: string; title?: string; href?: string };
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesMedia';
				transcript?: string;
				mediaLink?: string;
				mediaTitle?: any;
				mediaDescription?: string;
				media?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							size: number;
							ext?: string;
							url: string;
							alternativeText?: string;
							formats?: any;
							blurhash?: string;
							mime: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPhotoAlbum';
				id: string;
				description?: string;
				horizontal?: boolean;
				jsonTitle: any;
				photos: Array<{
					__typename: 'ComponentInternalPhoto';
					id: string;
					title: string;
					description?: string;
					photo: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								width?: number;
								height?: number;
								caption?: string;
								blurhash?: string;
								formats?: any;
								size: number;
								ext?: string;
								mime: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesStepByStep';
				title?: any;
				stepByStepCard: Array<{
					__typename: 'ComponentInternalStepByStepCard';
					cardTitle: string;
					cardDescription?: string;
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesSubjects';
				id: string;
				description?: string;
				overviewTitle?: string;
				subjects?: {
					__typename?: 'PageRelationResponseCollection';
					data: Array<{
						__typename?: 'PageEntity';
						id?: string;
						attributes?: {
							__typename?: 'Page';
							title: string;
							slug: string;
							fullSlug?: string;
							shortDescription?: string;
						};
					}>;
				};
				projects?: {
					__typename?: 'ProjectRelationResponseCollection';
					data: Array<{
						__typename?: 'ProjectEntity';
						id?: string;
						attributes?: {
							__typename?: 'Project';
							title?: string;
							slug: string;
							fullPath?: string;
							shortDescription?: string;
						};
					}>;
				};
				themes?: {
					__typename?: 'ThemeRelationResponseCollection';
					data: Array<{
						__typename?: 'ThemeEntity';
						id?: string;
						attributes?: { __typename?: 'Theme'; title: string; slug: string; fullPath?: string };
					}>;
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| { __typename: 'Error' }
	>;
};

export type WorkAtFieldsFragment = {
	__typename: 'WorkAt';
	locale?: string;
	header: {
		__typename?: 'ComponentModulesIntroHeader';
		title: string;
		excerpt: string;
		backgroundColor?: Enum_Componentmodulesintroheader_Backgroundcolor;
	};
	seo: {
		__typename?: 'ComponentModulesSeo';
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		ogTitle?: string;
		ogDescription?: string;
		ogType?: string;
		ogUrl?: string;
		ogImage?: {
			__typename?: 'UploadFileEntityResponse';
			data?: {
				__typename?: 'UploadFileEntity';
				id?: string;
				attributes?: { __typename?: 'UploadFile'; url: string; name: string };
			};
		};
	};
	modules?: Array<
		| {
				__typename?: 'ComponentModulesBodyText';
				bodyText?: string;
				sidebar?: {
					__typename?: 'ComponentInternalSidebar';
					title?: any;
					links?: Array<{ __typename?: 'ComponentComponentsLink'; title: string; href: any; description?: string }>;
					files?: {
						__typename?: 'UploadFileRelationResponseCollection';
						data: Array<{
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								ext?: string;
								mime: string;
								size: number;
								url: string;
								alternativeText?: string;
								hash: string;
								name: string;
								caption?: string;
							};
						}>;
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptions';
					backgroundColor: Enum_Componentinternaldefaultmoduleoptions_Backgroundcolor;
					padding: Enum_Componentinternaldefaultmoduleoptions_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesCta';
				description?: string;
				imagePosition?: Enum_Componentmodulescta_Imageposition;
				videoLink?: string;
				ctaTitle?: any;
				buttons?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: { __typename?: 'UploadFile'; url: string; name: string };
						};
					};
				}>;
				imageOrVideoFile?: {
					__typename?: 'UploadFileEntityResponse';
					data?: {
						__typename?: 'UploadFileEntity';
						id?: string;
						attributes?: {
							__typename?: 'UploadFile';
							name: string;
							alternativeText?: string;
							caption?: string;
							url: string;
							mime: string;
							formats?: any;
							blurhash?: string;
						};
					};
				};
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename?: 'ComponentModulesLinkBox';
				title?: any;
				description?: string;
				linkBoxLinks?: Array<{
					__typename?: 'ComponentComponentsButton';
					title?: string;
					variant: Enum_Componentcomponentsbutton_Variant;
					href: any;
					iconLeft?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
					iconRight?: {
						__typename?: 'UploadFileEntityResponse';
						data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } };
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| {
				__typename: 'ComponentModulesPartners';
				id: string;
				heading: any;
				logos: Array<{
					__typename: 'ComponentComponentsIconLink';
					id: string;
					title: string;
					href: any;
					icon: {
						__typename?: 'UploadFileEntityResponse';
						data?: {
							__typename?: 'UploadFileEntity';
							id?: string;
							attributes?: {
								__typename?: 'UploadFile';
								url: string;
								alternativeText?: string;
								formats?: any;
								blurhash?: string;
							};
						};
					};
				}>;
				defaultModuleOptions: {
					__typename?: 'ComponentInternalDefaultModuleOptionsExtended';
					extendedBackgroundColor: Enum_Componentinternaldefaultmoduleoptionsextended_Backgroundcolor;
					extendedPadding: Enum_Componentinternaldefaultmoduleoptionsextended_Padding;
				};
		  }
		| { __typename?: 'Error' }
	>;
};

export const NewsletterFieldFieldsFragmentDoc = gql`
	fragment newsletterFieldFields on NewsletterField {
		field
		value
	}
`;
export const NewsletterContactDataFieldsFragmentDoc = gql`
	fragment newsletterContactDataFields on NewsletterContactData {
		id
		email
		create_date
		custom {
			...newsletterFieldFields
		}
	}
	${NewsletterFieldFieldsFragmentDoc}
`;
export const CampaignNewsletterContactFieldsFragmentDoc = gql`
	fragment campaignNewsletterContactFields on CampaignNewsletterContact {
		id
		contact {
			...newsletterContactDataFields
		}
	}
	${NewsletterContactDataFieldsFragmentDoc}
`;
export const CampaignNewsletterFieldFieldsFragmentDoc = gql`
	fragment campaignNewsletterFieldFields on CampaignNewsletterField {
		campaignId
		newsletter
		value
	}
`;
export const ButtonFieldsFragmentDoc = gql`
	fragment buttonFields on ComponentComponentsButton {
		title
		variant
		href
		iconLeft {
			data {
				id
				attributes {
					url
					name
				}
			}
		}
		iconRight {
			data {
				id
				attributes {
					url
					name
				}
			}
		}
	}
`;
export const DefaultModuleOptionsExtendedFieldsFragmentDoc = gql`
	fragment defaultModuleOptionsExtendedFields on ComponentInternalDefaultModuleOptionsExtended {
		extendedBackgroundColor: backgroundColor
		extendedPadding: padding
	}
`;
export const CtaFieldsFragmentDoc = gql`
	fragment ctaFields on ComponentModulesCta {
		ctaTitle: title
		description
		buttons {
			...buttonFields
		}
		imageOrVideoFile {
			data {
				id
				attributes {
					name
					alternativeText
					caption
					url
					mime
					formats
					blurhash
				}
			}
		}
		imagePosition
		videoLink
		defaultModuleOptions {
			...defaultModuleOptionsExtendedFields
		}
	}
	${ButtonFieldsFragmentDoc}
	${DefaultModuleOptionsExtendedFieldsFragmentDoc}
`;
export const IntroHeaderImageFieldsFragmentDoc = gql`
	fragment introHeaderImageFields on ComponentModulesIntroHeaderImage {
		title
		excerpt
		backgroundColor
		cover {
			data {
				id
				attributes {
					alternativeText
					url
					formats
					blurhash
				}
			}
		}
	}
`;
export const SidebarFieldsFragmentDoc = gql`
	fragment sidebarFields on ComponentInternalSidebar {
		title
		links {
			title
			href
			description
		}
		files {
			data {
				id
				attributes {
					ext
					mime
					size
					url
					alternativeText
					hash
					name
					caption
				}
			}
		}
	}
`;
export const DefaultModuleOptionsFieldsFragmentDoc = gql`
	fragment defaultModuleOptionsFields on ComponentInternalDefaultModuleOptions {
		backgroundColor
		padding
	}
`;
export const BodyTextFieldsFragmentDoc = gql`
	fragment bodyTextFields on ComponentModulesBodyText {
		bodyText
		sidebar {
			...sidebarFields
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${SidebarFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const HighlightNewsArticleFieldsFragmentDoc = gql`
	fragment highlightNewsArticleFields on Newsarticle {
		__typename
		publishedAt
		title
		basePath
		slug: fullSlug
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
`;
export const FeaturedNewsFieldsFragmentDoc = gql`
	fragment featuredNewsFields on ComponentModulesFeaturedNews {
		featuredNewsTitle: title
		items: newsArticles(pagination: { limit: 3 }, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...highlightNewsArticleFields
					publishedAt
				}
			}
		}
		cta {
			ctaTitle: title
			variant
			href
			iconLeft {
				data {
					attributes {
						url
						alternativeText
						formats
						blurhash
					}
				}
			}
			iconRight {
				data {
					attributes {
						url
						alternativeText
						formats
						blurhash
					}
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${HighlightNewsArticleFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const HighlightedPagesFieldsFragmentDoc = gql`
	fragment highlightedPagesFields on Page {
		__typename
		publishedAt
		title
		basePath
		slug: fullSlug
		header {
			...introHeaderImageFields
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
`;
export const FeaturedPagesFieldsFragmentDoc = gql`
	fragment featuredPagesFields on ComponentModulesFeaturedPages {
		featuredPagesTitle: title
		items: pages(pagination: { limit: 6 }) {
			data {
				id
				attributes {
					...highlightedPagesFields
					publishedAt
				}
			}
		}
		cta {
			ctaTitle: title
			variant
			href
			iconLeft {
				data {
					attributes {
						url
						alternativeText
						formats
						blurhash
					}
				}
			}
			iconRight {
				data {
					attributes {
						url
						alternativeText
						formats
						blurhash
					}
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${HighlightedPagesFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const BodyTextTwoColumnsFieldsFragmentDoc = gql`
	fragment bodyTextTwoColumnsFields on ComponentModulesBodyTextTwoColumn {
		leftColumn {
			bodyText
		}
		rightColumn {
			bodyText
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const DownloadFieldsFragmentDoc = gql`
	fragment downloadFields on ComponentModulesDownloads {
		downloadsTitle: title
		downloads(pagination: { pageSize: 200 }) {
			data {
				id
				attributes {
					url
					name
					size
					ext
					caption
				}
			}
		}
		downloadLinks {
			title
			href
			description
		}
		defaultModuleOptions {
			...defaultModuleOptionsExtendedFields
		}
	}
	${DefaultModuleOptionsExtendedFieldsFragmentDoc}
`;
export const LinkBoxFieldsFragmentDoc = gql`
	fragment linkBoxFields on ComponentModulesLinkBox {
		title
		description
		linkBoxLinks: links {
			title
			variant
			href
			iconLeft {
				data {
					attributes {
						url
					}
				}
			}
			iconRight {
				data {
					attributes {
						url
					}
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsExtendedFields
		}
	}
	${DefaultModuleOptionsExtendedFieldsFragmentDoc}
`;
export const SubjectsFieldsFragmentDoc = gql`
	fragment subjectsFields on ComponentModulesSubjects {
		id
		overviewTitle: title
		description
		subjects(sort: "publishedAt:ASC", pagination: { limit: 999 }) {
			data {
				id
				attributes {
					title
					slug
					fullSlug
					shortDescription
				}
			}
		}
		projects(sort: "publishedAt:ASC", pagination: { limit: 999 }) {
			data {
				id
				attributes {
					title
					slug
					fullPath
					shortDescription
				}
			}
		}
		themes(sort: "publishedAt:ASC", pagination: { limit: 999 }) {
			data {
				id
				attributes {
					title
					slug
					fullPath
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const MediaFieldsFragmentDoc = gql`
	fragment mediaFields on ComponentModulesMedia {
		mediaTitle: title
		mediaDescription: description
		transcript
		mediaLink
		media {
			data {
				id
				attributes {
					size
					ext
					url
					alternativeText
					formats
					blurhash
					mime
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const AccordeonFieldsFragmentDoc = gql`
	fragment accordeonFields on ComponentModulesAccordeon {
		__typename
		accordeonTitle
		sections {
			__typename
			accordeonSectionName
			items(pagination: { limit: 100 }) {
				__typename
				itemTitle
				description
			}
		}
		sidebar {
			...sidebarFields
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${SidebarFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const EmptyStateFieldsFragmentDoc = gql`
	fragment emptyStateFields on ComponentInternalEmptyState {
		message
		title: buttonTitle
		href: buttonHref
	}
`;
export const HighlightPermitsFieldsFragmentDoc = gql`
	fragment highlightPermitsFields on Permit {
		__typename
		basePath
		slug: fullPath
		title
	}
`;
export const HighlightWooFragmentDoc = gql`
	fragment highlightWoo on WooRequest {
		__typename
		title
		startDate
		zip
		createdAt
	}
`;
export const HighlightMapsFiguresFieldsFragmentDoc = gql`
	fragment highlightMapsFiguresFields on MapsFigure {
		__typename
		basePath
		slug: link
		title: name
	}
`;
export const HighlightSubsidyFieldsFragmentDoc = gql`
	fragment highlightSubsidyFields on Subsidy {
		__typename
		basePath
		slug: fullPath
		header {
			title
		}
	}
`;
export const HighlightFieldsFragmentDoc = gql`
	fragment highlightFields on ComponentModulesHighlight {
		__typename
		id
		title
		theme {
			data {
				id
				attributes {
					title
				}
			}
		}
		newsTab {
			id
			title
			buttonTitle
			buttonHref
			items: newsArticles {
				data {
					attributes {
						...highlightNewsArticleFields
					}
				}
			}
			empty {
				...emptyStateFields
			}
		}
		eventsTab {
			id
			title
			buttonTitle
			buttonHref
			items: events {
				data {
					attributes {
						...highlightNewsArticleFields
					}
				}
			}
			empty {
				...emptyStateFields
			}
		}
		permitsTab {
			id
			title
			buttonTitle
			buttonHref
			items: permits {
				data {
					attributes {
						...highlightPermitsFields
					}
				}
			}
			empty {
				...emptyStateFields
			}
		}
		wooTab {
			id
			title
			buttonTitle
			buttonHref
			items: requests {
				data {
					attributes {
						...highlightWoo
					}
				}
			}
			empty {
				...emptyStateFields
			}
		}
		mapsAndStatsTab {
			id
			title
			buttonTitle
			buttonHref
			items: mapsAndStats {
				data {
					attributes {
						...highlightMapsFiguresFields
					}
				}
			}
			empty {
				...emptyStateFields
			}
		}
		topicsTab {
			id
			title
			buttonTitle
			buttonHref
			items: topics {
				data {
					attributes {
						...highlightNewsArticleFields
					}
				}
			}
			empty {
				...emptyStateFields
			}
		}
		subsidiesTab {
			id
			title
			buttonTitle
			buttonHref
			items: subsidies {
				data {
					attributes {
						...highlightSubsidyFields
					}
				}
			}
			empty {
				...emptyStateFields
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${HighlightNewsArticleFieldsFragmentDoc}
	${EmptyStateFieldsFragmentDoc}
	${HighlightPermitsFieldsFragmentDoc}
	${HighlightWooFragmentDoc}
	${HighlightMapsFiguresFieldsFragmentDoc}
	${HighlightSubsidyFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const StepByStepFieldsFragmentDoc = gql`
	fragment stepByStepFields on ComponentModulesStepByStep {
		__typename
		title
		stepByStepCard(pagination: { limit: 50 }) {
			__typename
			cardTitle
			cardDescription
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const FullImageFieldsFragmentDoc = gql`
	fragment fullImageFields on UploadFileEntity {
		id
		attributes {
			url
			alternativeText
			width
			height
			caption
			blurhash
			formats
			size
			ext
			mime
		}
	}
`;
export const PhotoFieldsFragmentDoc = gql`
	fragment photoFields on ComponentInternalPhoto {
		__typename
		id
		title
		description
		photo {
			data {
				...fullImageFields
			}
		}
	}
	${FullImageFieldsFragmentDoc}
`;
export const PhotoAlbumFieldsFragmentDoc = gql`
	fragment photoAlbumFields on ComponentModulesPhotoAlbum {
		__typename
		id
		jsonTitle: title
		description
		horizontal
		photos {
			...photoFields
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${PhotoFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const RegionDetailFieldsFragmentDoc = gql`
	fragment regionDetailFields on RegionDetail {
		__typename
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const IntroHeaderFieldsFragmentDoc = gql`
	fragment introHeaderFields on ComponentModulesIntroHeader {
		title
		excerpt
		backgroundColor
	}
`;
export const SeoFieldsFragmentDoc = gql`
	fragment seoFields on ComponentModulesSeo {
		title
		description
		noIndex
		noFollow
		ogTitle
		ogDescription
		ogImage {
			data {
				id
				attributes {
					url
					name
				}
			}
		}
		ogType
		ogUrl
	}
`;
export const ExpandedHeaderFieldsFragmentDoc = gql`
	fragment expandedHeaderFields on ComponentModulesExpandedHeader {
		headerTitle
		headerDescription
		headerButton {
			...buttonFields
		}
		headerImage {
			data {
				id
				attributes {
					name
					url
					alternativeText
					formats
					blurhash
				}
			}
		}
		title
		description
	}
	${ButtonFieldsFragmentDoc}
`;
export const BbvmsMediaplayerFieldsFragmentDoc = gql`
	fragment bbvmsMediaplayerFields on ComponentModulesBbvmsMediaplayer {
		__typename
		videoId
		playout
		url
		title
		description
		transcript
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const ThemeFieldsFragmentDoc = gql`
	fragment themeFields on Theme {
		__typename
		title
		slug
		fullSlug
		fullPath
		basePath
		locale
		header {
			...expandedHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${ExpandedHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const ThemesFieldsFragmentDoc = gql`
	fragment themesFields on ComponentModulesThemes {
		title
		themes(pagination: { limit: 15 }) {
			data {
				id
				attributes {
					...themeFields
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${ThemeFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const RegionFieldsFragmentDoc = gql`
	fragment regionFields on Region {
		__typename
		title
		slug
		fullSlug
		fullPath
		basePath
		locale
		header {
			...expandedHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${ExpandedHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const RegionsFieldsFragmentDoc = gql`
	fragment regionsFields on ComponentModulesRegions {
		title
		regions {
			data {
				id
				attributes {
					...regionFields
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${RegionFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const RegionOverviewSingleFieldsFragmentDoc = gql`
	fragment regionOverviewSingleFields on RegionOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesThemes {
				...themesFields
			}
			... on ComponentModulesRegions {
				...regionsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${ThemesFieldsFragmentDoc}
	${RegionsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const ThemeDetailFieldsFragmentDoc = gql`
	fragment themeDetailFields on ThemeDetail {
		__typename
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const ThemeOverviewSingleFieldsFragmentDoc = gql`
	fragment ThemeOverviewSingleFields on ThemeOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesThemes {
				...themesFields
			}
			... on ComponentModulesRegions {
				...regionsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${ThemesFieldsFragmentDoc}
	${RegionsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const WooRequestFieldsFragmentDoc = gql`
	fragment wooRequestFields on WooRequest {
		title
		shortTitle
		description
		keywords
		publisher
		caseNumber
		fullPath
		fullSlug
		locale
		zip
		publishedAt
		createdAt
		updatedAt
		startDate
		endDate
		themes {
			data {
				attributes {
					title
				}
			}
		}
		category {
			data {
				attributes {
					title
					sitemap
					resource
				}
			}
		}
		files(pagination: { limit: 999 }) {
			data {
				attributes {
					name
					url
					ext
					size
				}
			}
		}
	}
`;
export const WooRequestBySlugFieldsFragmentDoc = gql`
	fragment wooRequestBySlugFields on WooRequestEntityResponse {
		data {
			id
			attributes {
				...wooRequestFields
			}
		}
	}
	${WooRequestFieldsFragmentDoc}
`;
export const CommentFieldsFragmentDoc = gql`
	fragment commentFields on ConversationsConversationitem {
		__typename
		contentTypeId
		contentTypeUid
		message
		author
		publishedAt: createdAt
	}
`;
export const SchedulingFieldsFragmentDoc = gql`
	fragment schedulingFields on ComponentModulesScheduling {
		__typename
		title
		firstColumnTitle
		firstColumnRows(pagination: { limit: 99 }) {
			__typename
			id
			bodyText
		}
		secondColumnTitle
		secondColumnRows(pagination: { limit: 99 }) {
			__typename
			id
			bodyText
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const TableFieldsFragmentDoc = gql`
	fragment tableFields on ComponentModulesTable {
		__typename
		tableTitle: title
		table
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const TestimonialFieldsFragmentDoc = gql`
	fragment testimonialFields on ComponentModulesTestimonial {
		__typename
		id
		quoteTitle: title
		quote
		name
		organization
		photo {
			data {
				id
				attributes {
					url
					alternativeText
					formats
					blurhash
				}
			}
		}
		logo {
			data {
				id
				attributes {
					url
					alternativeText
					formats
					blurhash
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsExtendedFields
		}
	}
	${DefaultModuleOptionsExtendedFieldsFragmentDoc}
`;
export const IconLinkFieldsFragmentDoc = gql`
	fragment iconLinkFields on ComponentComponentsIconLink {
		__typename
		id
		title
		href
		icon {
			data {
				id
				attributes {
					url
					alternativeText
					formats
					blurhash
				}
			}
		}
	}
`;
export const PartnersFieldsFragmentDoc = gql`
	fragment partnersFields on ComponentModulesPartners {
		__typename
		id
		heading: title
		logos {
			... on ComponentComponentsIconLink {
				...iconLinkFields
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsExtendedFields
		}
	}
	${IconLinkFieldsFragmentDoc}
	${DefaultModuleOptionsExtendedFieldsFragmentDoc}
`;
export const CondoleancePageSlugFieldsFragmentDoc = gql`
	fragment condoleancePageSlugFields on CondoleancePage {
		title
		fullSlug
		basePath
		fullPath
		seo {
			...seoFields
		}
		header {
			...introHeaderImageFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${SeoFieldsFragmentDoc}
	${IntroHeaderImageFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const EfroOostInConvoWithArticleFieldsFragmentDoc = gql`
	fragment efroOostInConvoWithArticleFields on EfroOostInConvoWithArticle {
		title
		publishedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const HighlightNewsArticleEfroOostFieldsFragmentDoc = gql`
	fragment highlightNewsArticleEfroOostFields on EfroOostNewsarticle {
		publishedAt
		title
		basePath
		slug: fullSlug
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
`;
export const FeaturedNewsEfroOostFieldsFragmentDoc = gql`
	fragment featuredNewsEfroOostFields on ComponentModulesFeaturedNewsEfroOost {
		featuredNewsTitle: title
		items: newsArticles(pagination: { limit: 3 }, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...highlightNewsArticleEfroOostFields
					publishedAt
				}
			}
		}
		cta {
			ctaTitle: title
			variant
			href
			iconLeft {
				data {
					attributes {
						url
						alternativeText
						formats
						blurhash
					}
				}
			}
			iconRight {
				data {
					attributes {
						url
						alternativeText
						formats
						blurhash
					}
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${HighlightNewsArticleEfroOostFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const SharesFieldsFragmentDoc = gql`
	fragment sharesFields on ComponentModulesShare {
		title
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const EfroOostNewsArticleFieldsFragmentDoc = gql`
	fragment efroOostNewsArticleFields on EfroOostNewsarticle {
		title
		publishedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesFeaturedNewsEfroOost {
				...featuredNewsEfroOostFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${FeaturedNewsEfroOostFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const EfroOostPageSlugFieldsFragmentDoc = gql`
	fragment efroOostPageSlugFields on EfroOostPage {
		title
		fullSlug
		basePath
		fullPath
		seo {
			...seoFields
		}
		header {
			...introHeaderImageFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsEfroOost {
				...featuredNewsEfroOostFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${SeoFieldsFragmentDoc}
	${IntroHeaderImageFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsEfroOostFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const EventFieldsFragmentDoc = gql`
	fragment eventFields on Event {
		__typename
		title
		url
		location
		from
		until
		basePath
		header {
			...introHeaderImageFields
		}
		theme {
			data {
				id
				attributes {
					title
				}
			}
		}
		share {
			...sharesFields
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
`;
export const GeolinkPageSlugFieldsFragmentDoc = gql`
	fragment geolinkPageSlugFields on GeolinkPage {
		title
		fullSlug
		basePath
		fullPath
		seo {
			...seoFields
		}
		header {
			...introHeaderImageFields
		}
		modules {
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${SeoFieldsFragmentDoc}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const InConvoWithArticleFieldsFragmentDoc = gql`
	fragment inConvoWithArticleFields on InConvoWithArticle {
		title
		publishedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const MapsFiguresFieldsFragmentDoc = gql`
	fragment mapsFiguresFields on MapsFigure {
		name
		link
		type
		media {
			data {
				id
				attributes {
					url
					ext
					size
				}
			}
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		themes {
			data {
				id
				attributes {
					title
				}
			}
		}
	}
`;
export const NewsArticleFieldsFragmentDoc = gql`
	fragment newsArticleFields on Newsarticle {
		title
		publishedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const NoteFieldsFragmentDoc = gql`
	fragment NoteFields on NoteNote {
		title
		note
		noteAuthor
		pageAuthor
	}
`;
export const OpOostInConvoWithArticleFieldsFragmentDoc = gql`
	fragment opOostInConvoWithArticleFields on OpOostInConvoWithArticle {
		title
		publishedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const HighlightNewsArticleOpOostFieldsFragmentDoc = gql`
	fragment highlightNewsArticleOpOostFields on OpOostNewsarticle {
		publishedAt
		title
		basePath
		slug: fullSlug
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
`;
export const FeaturedNewsOpOostFieldsFragmentDoc = gql`
	fragment featuredNewsOpOostFields on ComponentModulesFeaturedNewsOpOost {
		featuredNewsTitle: title
		news(pagination: { limit: 3 }, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...highlightNewsArticleOpOostFields
					publishedAt
				}
			}
		}
		cta {
			ctaTitle: title
			variant
			href
			iconLeft {
				data {
					attributes {
						url
						alternativeText
						formats
					}
				}
			}
			iconRight {
				data {
					attributes {
						url
						alternativeText
						formats
					}
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${HighlightNewsArticleOpOostFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const OpOostNewsArticleFieldsFragmentDoc = gql`
	fragment opOostNewsArticleFields on OpOostNewsarticle {
		title
		publishedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesFeaturedNewsOpOost {
				...featuredNewsOpOostFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${FeaturedNewsOpOostFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const OpOostPageSlugFieldsFragmentDoc = gql`
	fragment opOostPageSlugFields on OpOostPage {
		title
		fullSlug
		basePath
		fullPath
		seo {
			...seoFields
		}
		header {
			...introHeaderImageFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsOpOost {
				...featuredNewsOpOostFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${SeoFieldsFragmentDoc}
	${IntroHeaderImageFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsOpOostFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const PageSlugFieldsFragmentDoc = gql`
	fragment pageSlugFields on Page {
		title
		fullSlug
		basePath
		fullPath
		seo {
			...seoFields
		}
		header {
			...introHeaderImageFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesThemes {
				...themesFields
			}
			... on ComponentModulesRegions {
				...regionsFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${SeoFieldsFragmentDoc}
	${IntroHeaderImageFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${ThemesFieldsFragmentDoc}
	${RegionsFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const PermitFieldsFragmentDoc = gql`
	fragment permitFields on Permit {
		publishedAt
		updatedAt
		title
		law
		productType
		basePath
		fullPath
		fullSlug
		seo {
			...seoFields
		}
		header {
			...introHeaderImageFields
		}
		themes {
			data {
				id
				attributes {
					title
				}
			}
		}
		permitLaws {
			data {
				id
				attributes {
					name
				}
			}
		}
		permitProductTypes {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${SeoFieldsFragmentDoc}
	${IntroHeaderImageFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const PressReleaseFieldsFragmentDoc = gql`
	fragment pressReleaseFields on PressRelease {
		title
		locale
		publishedAt
		basePath
		slug
		fullSlug
		fullPath
		header {
			...introHeaderFields
		}
		bodyText {
			...bodyTextFields
		}
		allowedOnHomePage
		seo {
			...seoFields
		}
		theme {
			data {
				id
				attributes {
					title
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const ProjectFieldsFragmentDoc = gql`
	fragment projectFields on Project {
		title
		publishedAt
		updatedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const RegioExpresInConvoWithArticleFieldsFragmentDoc = gql`
	fragment regioExpresInConvoWithArticleFields on RegioExpresInConvoWithArticle {
		title
		publishedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const HighlightNewsArticleRegioExpresFieldsFragmentDoc = gql`
	fragment highlightNewsArticleRegioExpresFields on RegioExpresNewsarticle {
		publishedAt
		title
		basePath
		slug: fullSlug
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
`;
export const FeaturedNewsRegioExpresFieldsFragmentDoc = gql`
	fragment featuredNewsRegioExpresFields on ComponentModulesFeaturedNewsRegioExpres {
		featuredNewsTitle: title
		items: newsArticles(pagination: { limit: 3 }, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...highlightNewsArticleRegioExpresFields
					publishedAt
				}
			}
		}
		cta {
			ctaTitle: title
			variant
			href
			iconLeft {
				data {
					attributes {
						url
						alternativeText
						formats
						blurhash
					}
				}
			}
			iconRight {
				data {
					attributes {
						url
						alternativeText
						formats
						blurhash
					}
				}
			}
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${HighlightNewsArticleRegioExpresFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const RegioExpresNewsArticleFieldsFragmentDoc = gql`
	fragment regioExpresNewsArticleFields on RegioExpresNewsarticle {
		title
		publishedAt
		slug
		basePath
		fullPath
		header {
			...introHeaderImageFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		tags {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesFeaturedNewsRegioExpres {
				...featuredNewsRegioExpresFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${FeaturedNewsRegioExpresFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const RegioExpresPageSlugFieldsFragmentDoc = gql`
	fragment regioExpresPageSlugFields on RegioExpresPage {
		title
		fullSlug
		basePath
		fullPath
		seo {
			...seoFields
		}
		header {
			...introHeaderImageFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsRegioExpres {
				...featuredNewsRegioExpresFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${SeoFieldsFragmentDoc}
	${IntroHeaderImageFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsRegioExpresFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const RoadMaintenanceFieldsFragmentDoc = gql`
	fragment roadMaintenanceFields on RoadMaintenance {
		__typename
		title
		slug
		basePath
		fullPath
		locale
		status
		header {
			...introHeaderFields
		}
		aboutMaintenance {
			__typename
			startDate
			endDate
			contractor
			contractorWebsite
			detour
			defaultModuleOptions {
				...defaultModuleOptionsFields
			}
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const SubsidyFieldsFragmentDoc = gql`
	fragment subsidyFields on Subsidy {
		title
		slug
		basePath
		fullPath
		subsidyCeiling
		updatedAt
		status
		endDate
		targetGroupText
		hideFromOverview
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		themes {
			data {
				id
				attributes {
					title
				}
			}
		}
		subsidy_scales {
			data {
				id
				attributes {
					name
				}
			}
		}
		subsidy_kinds {
			data {
				id
				attributes {
					name
				}
			}
		}
		target_groups {
			data {
				id
				attributes {
					name
				}
			}
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const TagFieldsFragmentDoc = gql`
	fragment tagFields on Tag {
		name
	}
`;
export const WobRequestFieldsFragmentDoc = gql`
	fragment wobRequestFields on WobRequest {
		title
		createdAt
		date
		zip
	}
`;
export const WooCategoryFieldsFragmentDoc = gql`
	fragment wooCategoryFields on WooCategory {
		title
		sitemap
		resource
	}
`;
export const ContactModuleFieldsFragmentDoc = gql`
	fragment contactModuleFields on Contact {
		__typename
		locale
		header {
			...introHeaderFields
		}
		modules {
			__typename
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
		seo {
			...seoFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
`;
export const EfroOostInConvoWithOverviewFieldsFragmentDoc = gql`
	fragment efroOostInConvoWithOverviewFields on ComponentModulesInConvoWithOverview {
		id
		filterLabel
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const InConvoWithOverviewFieldsFragmentDoc = gql`
	fragment inConvoWithOverviewFields on ComponentModulesInConvoWithOverview {
		id
		filterLabel
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const NotificationBarFieldsFragmentDoc = gql`
	fragment notificationBarFields on ComponentInternalNavbar {
		notificationLink
		notificationMessage
		notificationIsExternal
		notificationPublishDate
		notificationUnpublishDate
		mostPopularTitle
		ThemeGroupTitle
		bottomLinks {
			title
		}
	}
`;
export const RegioExpresInConvoWithOverviewFieldsFragmentDoc = gql`
	fragment regioExpresInConvoWithOverviewFields on ComponentModulesInConvoWithOverview {
		id
		filterLabel
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const AgendaPageFieldsFragmentDoc = gql`
	fragment agendaPageFields on AgendaPage {
		__typename
		locale
		header {
			...introHeaderFields
		}
		modules {
			__typename
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
		seo {
			...seoFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
`;
export const NavbarFieldsFragmentDoc = gql`
	fragment navbarFields on ComponentInternalNavbar {
		notificationMessage
		logo {
			data {
				id
				attributes {
					name
					alternativeText
					size
					url
					formats
					blurhash
				}
			}
		}
		bottomLinks {
			title
			href
		}
		mostPopularTitle
		mostPopularGroupLinks {
			title
			links {
				title
				href
			}
		}
		ThemeGroupTitle
		themeGroupLinks {
			title
			href
			links {
				title
				href
			}
		}
		searchPlaceholderText
		notificationLink
		notificationIsExternal
		notificationPublishDate
		notificationUnpublishDate
		rightNavLink
	}
`;
export const FooterFieldsFragmentDoc = gql`
	fragment footerFields on ComponentInternalFooter {
		logo {
			data {
				id
				attributes {
					url
					alternativeText
					formats
					blurhash
					width
					height
				}
			}
		}
		themeLinkGroup {
			title
			links {
				title
				href
				description
			}
		}
		contactLinkGroup {
			title
			links {
				title
				href
				description
			}
		}
		socialLinkGroup {
			title
			links {
				title
				href
				icon {
					data {
						attributes {
							url
							alternativeText
							formats
							blurhash
						}
					}
				}
			}
		}
		bottomLinks {
			title
			href
			description
		}
		accessibilityLogo {
			data {
				id
				attributes {
					url
					alternativeText
					formats
					blurhash
				}
			}
		}
		accessibilityLink
	}
`;
export const SeoSingleFieldsFragmentDoc = gql`
	fragment seoSingleFields on ComponentInternalSeo {
		id
		title
		description
		noIndex
		noFollow
		ogTitle
		ogDescription
		ogImage {
			data {
				id
				attributes {
					url
					name
				}
			}
		}
		ogType
		ogUrl
	}
`;
export const CondoleanceInformationFieldsFragmentDoc = gql`
	fragment condoleanceInformationFields on CondoleanceInformation {
		locale
		navbar {
			...navbarFields
		}
		footer {
			...footerFields
		}
		seo {
			...seoSingleFields
		}
	}
	${NavbarFieldsFragmentDoc}
	${FooterFieldsFragmentDoc}
	${SeoSingleFieldsFragmentDoc}
`;
export const HomepageHeaderFieldsFragmentDoc = gql`
	fragment homepageHeaderFields on ComponentModulesHomepageHeader {
		id
		homepageTitle: title
		backgroundImage {
			data {
				id
				attributes {
					url
					alternativeText
					formats
					blurhash
				}
			}
		}
		description
		popularHeader
		links {
			title
			href
		}
	}
`;
export const CondoleanceHomePageFieldsFragmentDoc = gql`
	fragment condoleanceHomePageFields on CondoleanceHomepage {
		header {
			...homepageHeaderFields
		}
		seo {
			...seoFields
		}
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${HomepageHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const ContactFieldsFragmentDoc = gql`
	fragment contactFields on Contact {
		__typename
		locale
		header {
			...introHeaderFields
		}
		modules {
			__typename
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
		seo {
			...seoFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
`;
export const CookiePageFieldsFragmentDoc = gql`
	fragment cookiePageFields on CookiePage {
		__typename
		locale
		header {
			...introHeaderFields
		}
		bodyText {
			...bodyTextFields
		}
		seo {
			...seoFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
`;
export const DecisionsPageFieldsFragmentDoc = gql`
	fragment decisionsPageFields on DecisionsPage {
		__typename
		locale
		bodyText
		sidebarCurrentYearTitle
		sidebarArchiveTitle
		header {
			...introHeaderFields
		}
		archiveLink {
			title
			description
			href
		}
		modules {
			__typename
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
		seo {
			...seoFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
`;
export const DownloadsPageFieldsFragmentDoc = gql`
	fragment downloadsPageFields on DownloadsPage {
		__typename
		locale
		introHeader {
			...introHeaderImageFields
		}
		downloads {
			...downloadFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const EfroOostInConvoWithDetailSingleFieldsFragmentDoc = gql`
	fragment efroOostInConvoWithDetailSingleFields on EfroOostInConvoWithDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const OpOostInConvoWithOverviewFieldsFragmentDoc = gql`
	fragment opOostInConvoWithOverviewFields on ComponentModulesInConvoWithOverview {
		id
		filterLabel
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const EfroOostInConvoWithOverviewSingleFieldsFragmentDoc = gql`
	fragment efroOostInConvoWithOverviewSingleFields on EfroOostInConvoWithOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesInConvoWithOverview {
				...opOostInConvoWithOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${OpOostInConvoWithOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const EfroOostInformationFieldsFragmentDoc = gql`
	fragment efroOostInformationFields on EfroOostInformation {
		locale
		navbar {
			...navbarFields
		}
		footer {
			...footerFields
		}
		seo {
			...seoSingleFields
		}
	}
	${NavbarFieldsFragmentDoc}
	${FooterFieldsFragmentDoc}
	${SeoSingleFieldsFragmentDoc}
`;
export const EfroOostNewsDetailSingleFieldsFragmentDoc = gql`
	fragment efroOostNewsDetailSingleFields on EfroOostNewsDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsEfroOost {
				...featuredNewsEfroOostFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsEfroOostFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const NewsOverviewFieldsFragmentDoc = gql`
	fragment newsOverviewFields on ComponentModulesNewsOverview {
		id
		filterLabel
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const EfroOostNewsOverviewSingleFieldsFragmentDoc = gql`
	fragment efroOostNewsOverviewSingleFields on EfroOostNewsOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesNewsOverview {
				...newsOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${NewsOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const EfroOostHomePageFieldsFragmentDoc = gql`
	fragment efroOostHomePageFields on EfroOostHomepage {
		header {
			...homepageHeaderFields
		}
		seo {
			...seoFields
		}
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsEfroOost {
				...featuredNewsEfroOostFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${HomepageHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsEfroOostFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const EventOverviewFieldsFragmentDoc = gql`
	fragment eventOverviewFields on ComponentModulesEventOverview {
		id
		filterLabel
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const EventOverviewSingleFieldsFragmentDoc = gql`
	fragment eventOverviewSingleFields on EventOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesEventOverview {
				...eventOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${EventOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const GeolinkInformationFieldsFragmentDoc = gql`
	fragment geolinkInformationFields on GeolinkInformation {
		locale
		navbar {
			...navbarFields
		}
		footer {
			...footerFields
		}
		seo {
			...seoSingleFields
		}
	}
	${NavbarFieldsFragmentDoc}
	${FooterFieldsFragmentDoc}
	${SeoSingleFieldsFragmentDoc}
`;
export const GeolinkHomePageFieldsFragmentDoc = gql`
	fragment geolinkHomePageFields on GeolinkHomepage {
		header {
			...homepageHeaderFields
		}
		seo {
			...seoFields
		}
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${HomepageHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const HomePageFieldsFragmentDoc = gql`
	fragment homePageFields on HomePage {
		header {
			...homepageHeaderFields
		}
		seo {
			...seoFields
		}
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesThemes {
				...themesFields
			}
			... on ComponentModulesRegions {
				...regionsFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${HomepageHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${ThemesFieldsFragmentDoc}
	${RegionsFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const InConvoWithDetailSingleFieldsFragmentDoc = gql`
	fragment inConvoWithDetailSingleFields on InConvoWithDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const InConvoWithOverviewSingleFieldsFragmentDoc = gql`
	fragment inConvoWithOverviewSingleFields on InConvoWithOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesInConvoWithOverview {
				...opOostInConvoWithOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${OpOostInConvoWithOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const LoginSingleFieldsFragmentDoc = gql`
	fragment loginSingleFields on Login {
		header {
			...introHeaderFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const MapsFiguresOverviewFieldsFragmentDoc = gql`
	fragment mapsFiguresOverviewFields on ComponentModulesMapsFiguresOverview {
		id
		showResultsText
		headerTitle
		searchPlaceholderText
		themeFilterText
		kindFilterText
		showAllThemesText
		showResultsText
		overviewTitleText
		filterHeaderText
		overviewTitleText
		filterHeaderText
	}
`;
export const MapsFiguresOverviewSingleFieldsFragmentDoc = gql`
	fragment mapsFiguresOverviewSingleFields on MapsFiguresOverview {
		header {
			...introHeaderFields
		}
		mapsFiguresOverview {
			...mapsFiguresOverviewFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${MapsFiguresOverviewFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const NewsDetailSingleFieldsFragmentDoc = gql`
	fragment newsDetailSingleFields on NewsDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
`;
export const NewsOverviewSingleFieldsFragmentDoc = gql`
	fragment newsOverviewSingleFields on NewsOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesNewsOverview {
				...newsOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${NewsOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const PermitsOverviewFieldsFragmentDoc = gql`
	fragment permitsOverviewFields on ComponentModulesPermitsOverview {
		id
		headerTitle
		searchPlaceholderText
		themeFilterText
		lawFilterText
		productTypeFilterText
		showAllThemesText
		showAllLawsText
		showAllProductTypesText
		overviewTitleText
		showResultsText
		filterHeaderText
	}
`;
export const NewsletterArchiveOverviewSingleFieldsFragmentDoc = gql`
	fragment newsletterArchiveOverviewSingleFields on NewsletterArchiveOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesPermitsOverview {
				...permitsOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${PermitsOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
`;
export const NewsletterFieldsFragmentDoc = gql`
	fragment newsletterFields on ComponentInternalNewsletter {
		title
		fieldLabel
		fieldPlaceholder
		submitButton {
			...buttonFields
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${ButtonFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const NewsletterInternalSingleFieldsFragmentDoc = gql`
	fragment newsletterInternalSingleFields on NewsletterInternal {
		header {
			...introHeaderFields
		}
		newsletter {
			...newsletterFields
		}
		seo {
			...seoFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${NewsletterFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
`;
export const InternalNewsletterWithSubscriptionsFieldsFragmentDoc = gql`
	fragment internalNewsletterWithSubscriptionsFields on InternalNewsletterWithSubscriptions {
		page {
			...newsletterInternalSingleFields
		}
	}
	${NewsletterInternalSingleFieldsFragmentDoc}
`;
export const NewsletterPublicSingleFieldsFragmentDoc = gql`
	fragment newsletterPublicSingleFields on NewsletterPublic {
		header {
			...introHeaderFields
		}
		newsletter {
			...newsletterFields
		}
		seo {
			...seoFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${NewsletterFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
`;
export const PublicNewsletterFieldsFragmentDoc = gql`
	fragment publicNewsletterFields on PublicNewsletterPage {
		page {
			...newsletterPublicSingleFields
		}
	}
	${NewsletterPublicSingleFieldsFragmentDoc}
`;
export const NotFoundPageFieldsFragmentDoc = gql`
	fragment notFoundPageFields on NotFoundPage {
		__typename
		locale
		header {
			...introHeaderFields
		}
		links {
			...linkBoxFields
		}
		cta {
			...ctaFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
`;
export const OpOostInConvoWithDetailSingleFieldsFragmentDoc = gql`
	fragment opOostInConvoWithDetailSingleFields on OpOostInConvoWithDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const OpOostInConvoWithOverviewSingleFieldsFragmentDoc = gql`
	fragment opOostInConvoWithOverviewSingleFields on OpOostInConvoWithOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesInConvoWithOverview {
				...opOostInConvoWithOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${OpOostInConvoWithOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const OpOostInformationFieldsFragmentDoc = gql`
	fragment opOostInformationFields on OpOostInformation {
		locale
		navbar {
			...navbarFields
		}
		footer {
			...footerFields
		}
		seo {
			...seoSingleFields
		}
	}
	${NavbarFieldsFragmentDoc}
	${FooterFieldsFragmentDoc}
	${SeoSingleFieldsFragmentDoc}
`;
export const OpOostNewsDetailSingleFieldsFragmentDoc = gql`
	fragment opOostNewsDetailSingleFields on OpOostNewsDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsOpOost {
				...featuredNewsOpOostFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsOpOostFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const OpOostNewsOverviewSingleFieldsFragmentDoc = gql`
	fragment opOostNewsOverviewSingleFields on OpOostNewsOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesNewsOverview {
				...newsOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${NewsOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const OpOostHomePageFieldsFragmentDoc = gql`
	fragment opOostHomePageFields on OpOostHomepage {
		header {
			...homepageHeaderFields
		}
		seo {
			...seoFields
		}
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsOpOost {
				...featuredNewsOpOostFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${HomepageHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsOpOostFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const OpenGelderlandInformationFieldsFragmentDoc = gql`
	fragment openGelderlandInformationFields on OpenGelderlandInformation {
		locale
		navbar {
			...navbarFields
		}
		footer {
			...footerFields
		}
		seo {
			...seoSingleFields
		}
	}
	${NavbarFieldsFragmentDoc}
	${FooterFieldsFragmentDoc}
	${SeoSingleFieldsFragmentDoc}
`;
export const LinkFieldsFragmentDoc = gql`
	fragment linkFields on ComponentComponentsLink {
		id
		title
		href
		description
	}
`;
export const OpenGelderlandWooDocumentOverviewFragmentDoc = gql`
	fragment openGelderlandWooDocumentOverview on ComponentModulesOpenWooRequestOverview {
		id
		filterHeaderText
		headerTitle
		filterLink {
			...linkFields
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${LinkFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const OpenGelderlandWooRequestOverviewSingleFieldsFragmentDoc = gql`
	fragment openGelderlandWooRequestOverviewSingleFields on OpenGelderlandWooOverview {
		header {
			...introHeaderFields
		}
		woo_overview {
			...openGelderlandWooDocumentOverview
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${OpenGelderlandWooDocumentOverviewFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const CollectionFieldsFragmentDoc = gql`
	fragment collectionFields on ComponentModulesCollections {
		id
		overviewTitle: title
		description
		links {
			...linkFields
		}
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${LinkFieldsFragmentDoc}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const OpenGelderlandHomePageFieldsFragmentDoc = gql`
	fragment openGelderlandHomePageFields on OpenGelderlandHomepage {
		header {
			...introHeaderImageFields
		}
		collections {
			...collectionFields
		}
		seo {
			...seoFields
		}
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
		}
	}
	${IntroHeaderImageFieldsFragmentDoc}
	${CollectionFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
`;
export const PageInformationFieldsFragmentDoc = gql`
	fragment pageInformationFields on PageInformation {
		__typename
		locale
		navbar {
			...navbarFields
		}
		footer {
			...footerFields
		}
		seo {
			...seoSingleFields
		}
	}
	${NavbarFieldsFragmentDoc}
	${FooterFieldsFragmentDoc}
	${SeoSingleFieldsFragmentDoc}
`;
export const PasswordPageFieldsFragmentDoc = gql`
	fragment passwordPageFields on PasswordPage {
		__typename
		locale
		header {
			...introHeaderFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesThemes {
				...themesFields
			}
			... on ComponentModulesRegions {
				...regionsFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${ThemesFieldsFragmentDoc}
	${RegionsFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const PermitDetailSingleFieldsFragmentDoc = gql`
	fragment permitDetailSingleFields on PermitDetail {
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
		}
	}
	${CtaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
`;
export const PermitsOverviewSingleFieldsFragmentDoc = gql`
	fragment permitsOverviewSingleFields on PermitsOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesPermitsOverview {
				...permitsOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${PermitsOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
`;
export const PressReleaseOverviewFieldsFragmentDoc = gql`
	fragment pressReleaseOverviewFields on ComponentModulesPressReleaseOverview {
		id
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const PressReleaseOverviewSingleFieldsFragmentDoc = gql`
	fragment pressReleaseOverviewSingleFields on PressReleaseOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesPressReleaseOverview {
				...pressReleaseOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${PressReleaseOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const PressReleaseDetailFieldsFragmentDoc = gql`
	fragment pressReleaseDetailFields on PressReleaseDetail {
		__typename
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const PrivacyPolicyFieldsFragmentDoc = gql`
	fragment privacyPolicyFields on PrivacyPolicy {
		__typename
		locale
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		bodytext {
			...bodyTextFields
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
`;
export const ProjectDetailSingleFieldsFragmentDoc = gql`
	fragment projectDetailSingleFields on ProjectDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesScheduling {
				...schedulingFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${SchedulingFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const ProjectOverviewFieldsFragmentDoc = gql`
	fragment projectOverviewFields on ComponentModulesProjectOverview {
		id
		filterLabel
		extendedDefaultModuleOptions: defaultModuleOptions {
			backgroundColor
			padding
		}
	}
`;
export const ProjectOverviewSingleFieldsFragmentDoc = gql`
	fragment projectOverviewSingleFields on ProjectOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesProjectOverview {
				...projectOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${ProjectOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const RegioExpresInConvoWithDetailSingleFieldsFragmentDoc = gql`
	fragment regioExpresInConvoWithDetailSingleFields on RegioExpresInConvoWithDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const RegioExpresInConvoWithOverviewSingleFieldsFragmentDoc = gql`
	fragment regioExpresInConvoWithOverviewSingleFields on RegioExpresInConvoOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesInConvoWithOverview {
				...opOostInConvoWithOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${OpOostInConvoWithOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const RegioExpresInformationFieldsFragmentDoc = gql`
	fragment regioExpresInformationFields on RegioExpresInformation {
		locale
		navbar {
			...navbarFields
		}
		footer {
			...footerFields
		}
		seo {
			...seoSingleFields
		}
	}
	${NavbarFieldsFragmentDoc}
	${FooterFieldsFragmentDoc}
	${SeoSingleFieldsFragmentDoc}
`;
export const RegioExpresNewsDetailSingleFieldsFragmentDoc = gql`
	fragment regioExpresNewsDetailSingleFields on RegioExpresNewsDetail {
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsRegioExpres {
				...featuredNewsRegioExpresFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsRegioExpresFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const RegioExpresNewsOverviewSingleFieldsFragmentDoc = gql`
	fragment regioExpresNewsOverviewSingleFields on RegioExpresNewsOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesNewsOverview {
				...newsOverviewFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${NewsOverviewFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const RegioExpresHomePageFieldsFragmentDoc = gql`
	fragment regioExpresHomePageFields on RegioExpresHomepage {
		header {
			...homepageHeaderFields
		}
		seo {
			...seoFields
		}
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNewsRegioExpres {
				...featuredNewsRegioExpresFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesTestimonial {
				...testimonialFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${HomepageHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsRegioExpresFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${TestimonialFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const RoadMaintenanceDetailSingleFieldsFragmentDoc = gql`
	fragment roadMaintenanceDetailSingleFields on RoadMaintenanceDetail {
		locale
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
		}
	}
	${CtaFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
`;
export const RoadMaintenanceOverviewFieldsFragmentDoc = gql`
	fragment roadMaintenanceOverviewFields on ComponentModulesRoadMaintenanceOverview {
		__typename
		id
		title
	}
`;
export const RoadMaintenanceOverviewSingleFieldsFragmentDoc = gql`
	fragment roadMaintenanceOverviewSingleFields on RoadMaintenanceOverview {
		header {
			...introHeaderFields
		}
		list {
			...roadMaintenanceOverviewFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${RoadMaintenanceOverviewFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
`;
export const SearchOverviewSingleFieldsFragmentDoc = gql`
	fragment searchOverviewSingleFields on SearchOverview {
		searchHeader {
			id
			title
			searchPlaceholder
		}
		Zoekresultaten {
			id
			resultLabel
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const SitemapPageFieldsFragmentDoc = gql`
	fragment sitemapPageFields on Sitemap {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const SubsidyDetailSingleFieldsFragmentDoc = gql`
	fragment subsidyDetailSingleFields on SubsidyDetail {
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesTable {
				...tableFields
			}
		}
	}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${TableFieldsFragmentDoc}
`;
export const SubsidyOverviewFieldsFragmentDoc = gql`
	fragment subsidyOverviewFields on ComponentModulesSubsidyOverview {
		id
		showResultsText
		headerTitle
		searchPlaceholderText
		themeFilterText
		showMoreThemesText
		showLessThemesText
		targetGroupFilterText
		kindFilterText
		scaleFilterText
		overviewTitleText
		filterHeaderText
		overviewTitleText
		filterHeaderText
	}
`;
export const SubsidyOverviewSingleFieldsFragmentDoc = gql`
	fragment subsidyOverviewSingleFields on SubsidyOverview {
		locale
		publishedAt
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesSubsidyOverview {
				...subsidyOverviewFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${SubsidyOverviewFieldsFragmentDoc}
`;
export const VacancyDetailSingleFieldsFragmentDoc = gql`
	fragment vacancyDetailSingleFields on VacancyDetail {
		basePath
		share {
			...sharesFields
		}
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${SharesFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const VacancyInternalDetailSingleFieldsFragmentDoc = gql`
	fragment vacancyInternalDetailSingleFields on VacancyInternalDetail {
		basePath
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesFeaturedNews {
				...featuredNewsFields
			}
			... on ComponentModulesFeaturedPages {
				...featuredPagesFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${DownloadFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${FeaturedNewsFieldsFragmentDoc}
	${FeaturedPagesFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const VacancyOverviewFieldsFragmentDoc = gql`
	fragment vacancyOverviewFields on ComponentModulesVacanciesOverview {
		__typename
		id
		title
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const VacancyInternalOverviewSingleFieldsFragmentDoc = gql`
	fragment vacancyInternalOverviewSingleFields on VacancyInternalOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesVacanciesOverview {
				...vacancyOverviewFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${VacancyOverviewFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const VacancyOverviewSingleFieldsFragmentDoc = gql`
	fragment vacancyOverviewSingleFields on VacancyOverview {
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
			... on ComponentModulesVacanciesOverview {
				...vacancyOverviewFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
	${VacancyOverviewFieldsFragmentDoc}
`;
export const WobRequestOverviewFieldsFragmentDoc = gql`
	fragment wobRequestOverviewFields on ComponentModulesWobRequestOverview {
		__typename
		id
		sectionTitle: title
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const WobRequestOverviewSingleFieldsFragmentDoc = gql`
	fragment wobRequestOverviewSingleFields on WobRequestOverview {
		header {
			...introHeaderFields
		}
		bodyText {
			...bodyTextFields
		}
		list {
			...wobRequestOverviewFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${WobRequestOverviewFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const WooDocumentDetailSingleFieldsFragmentDoc = gql`
	fragment wooDocumentDetailSingleFields on WooDocumentDetail {
		modules {
			__typename
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesShare {
				...sharesFields
			}
			... on ComponentModulesBbvmsMediaplayer {
				...bbvmsMediaplayerFields
			}
		}
	}
	${CtaFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${SharesFieldsFragmentDoc}
	${BbvmsMediaplayerFieldsFragmentDoc}
`;
export const WooRequestOverviewFieldsFragmentDoc = gql`
	fragment wooRequestOverviewFields on ComponentModulesWooRequestOverview {
		__typename
		id
		sectionTitle: title
		filterLabel
		defaultModuleOptions {
			...defaultModuleOptionsFields
		}
	}
	${DefaultModuleOptionsFieldsFragmentDoc}
`;
export const WooRequestOverviewSingleFieldsFragmentDoc = gql`
	fragment wooRequestOverviewSingleFields on WooRequestOverview {
		header {
			...introHeaderFields
		}
		bodyText {
			...bodyTextFields
		}
		list {
			...wooRequestOverviewFields
		}
		seo {
			...seoFields
		}
		modules {
			__typename
			... on ComponentModulesDownloads {
				...downloadFields
			}
			... on ComponentModulesStepByStep {
				...stepByStepFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesHighlight {
				...highlightFields
			}
			... on ComponentModulesSubjects {
				...subjectsFields
			}
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesBodyTextTwoColumn {
				...bodyTextTwoColumnsFields
			}
			... on ComponentModulesAccordeon {
				...accordeonFields
			}
			... on ComponentModulesMedia {
				...mediaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesPhotoAlbum {
				...photoAlbumFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${WooRequestOverviewFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${DownloadFieldsFragmentDoc}
	${StepByStepFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${HighlightFieldsFragmentDoc}
	${SubjectsFieldsFragmentDoc}
	${BodyTextTwoColumnsFieldsFragmentDoc}
	${AccordeonFieldsFragmentDoc}
	${MediaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${PhotoAlbumFieldsFragmentDoc}
`;
export const WorkAtFieldsFragmentDoc = gql`
	fragment workAtFields on WorkAt {
		__typename
		locale
		header {
			...introHeaderFields
		}
		seo {
			...seoFields
		}
		modules {
			... on ComponentModulesBodyText {
				...bodyTextFields
			}
			... on ComponentModulesCta {
				...ctaFields
			}
			... on ComponentModulesLinkBox {
				...linkBoxFields
			}
			... on ComponentModulesPartners {
				...partnersFields
			}
		}
	}
	${IntroHeaderFieldsFragmentDoc}
	${SeoFieldsFragmentDoc}
	${BodyTextFieldsFragmentDoc}
	${CtaFieldsFragmentDoc}
	${LinkBoxFieldsFragmentDoc}
	${PartnersFieldsFragmentDoc}
`;
export const AddUserToNewslettersDocument = gql`
	mutation addUserToNewsletters($campaignId: String!, $email: String!, $newsletters: [NewslettersListInput]!) {
		addUserToNewsletters(campaignId: $campaignId, email: $email, newsletters: $newsletters) {
			campaign
			succeeded
		}
	}
`;
export type AddUserToNewslettersMutationFn = Apollo.MutationFunction<
	AddUserToNewslettersMutation,
	AddUserToNewslettersMutationVariables
>;
export type AddUserToNewslettersMutationResult = Apollo.MutationResult<AddUserToNewslettersMutation>;
export type AddUserToNewslettersMutationOptions = Apollo.BaseMutationOptions<
	AddUserToNewslettersMutation,
	AddUserToNewslettersMutationVariables
>;
export const UpdateUserNewsletterSubscriptionDocument = gql`
	mutation updateUserNewsletterSubscription(
		$campaignId: String!
		$userId: String!
		$email: String!
		$newsletters: [NewslettersListInput]!
	) {
		updateUserNewsletterSubscription(
			campaignId: $campaignId
			userId: $userId
			email: $email
			newsletters: $newsletters
		) {
			campaign
			succeeded
		}
	}
`;
export type UpdateUserNewsletterSubscriptionMutationFn = Apollo.MutationFunction<
	UpdateUserNewsletterSubscriptionMutation,
	UpdateUserNewsletterSubscriptionMutationVariables
>;
export type UpdateUserNewsletterSubscriptionMutationResult =
	Apollo.MutationResult<UpdateUserNewsletterSubscriptionMutation>;
export type UpdateUserNewsletterSubscriptionMutationOptions = Apollo.BaseMutationOptions<
	UpdateUserNewsletterSubscriptionMutation,
	UpdateUserNewsletterSubscriptionMutationVariables
>;
export const GetCommentsDocument = gql`
	query GetComments($id: Int, $uid: String, $page: Int, $pageSize: Int) {
		getCommentsEnabled(contentTypeUid: $uid, contentTypeId: $id)
		conversationsConversationitems(
			sort: "publishedAt:desc"
			pagination: { page: $page, pageSize: $pageSize }
			filters: { and: { contentTypeUid: { eq: $uid }, contentTypeId: { eq: $id } } }
		) {
			data {
				id
				attributes {
					...commentFields
				}
			}
			meta {
				pagination {
					total
					page
					pageSize
					pageCount
				}
			}
		}
	}
	${CommentFieldsFragmentDoc}
`;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const AddCommentDocument = gql`
	mutation addComment($contentTypeUid: String, $contentTypeId: Int, $author: String, $message: String) {
		addComment(contentTypeUid: $contentTypeUid, contentTypeId: $contentTypeId, author: $author, message: $message) {
			data {
				attributes {
					...commentFields
				}
			}
		}
	}
	${CommentFieldsFragmentDoc}
`;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const GetCondoleancePageSlugsDocument = gql`
	query getCondoleancePageSlugs {
		findAllPaths(_entityId: "api::condoleance-page.condoleance-page") {
			fullSlug
			slug
			locale
		}
	}
`;
export type GetCondoleancePageSlugsQueryResult = Apollo.QueryResult<
	GetCondoleancePageSlugsQuery,
	GetCondoleancePageSlugsQueryVariables
>;
export const GetEfroOostInConvoWithArticleSlugsDocument = gql`
	query GetEfroOostInConvoWithArticleSlugs {
		findAllPaths(_entityId: "api::efro-oost-in-convo-with-article.efro-oost-in-convo-with-article") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetEfroOostInConvoWithArticleSlugsQueryResult = Apollo.QueryResult<
	GetEfroOostInConvoWithArticleSlugsQuery,
	GetEfroOostInConvoWithArticleSlugsQueryVariables
>;
export const GetEfroOostInConvoWithArticlesDocument = gql`
	query GetEfroOostInConvoWithArticles(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$showOnHomePage: Boolean
	) {
		efroOostInConvoWithArticles(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: { allowedOnHomePage: { eq: $showOnHomePage } }
		) {
			data {
				id
				attributes {
					...efroOostInConvoWithArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${EfroOostInConvoWithArticleFieldsFragmentDoc}
`;
export type GetEfroOostInConvoWithArticlesQueryResult = Apollo.QueryResult<
	GetEfroOostInConvoWithArticlesQuery,
	GetEfroOostInConvoWithArticlesQueryVariables
>;
export const GetEfroOostNewsArticleSlugsDocument = gql`
	query GetEfroOostNewsArticleSlugs {
		findAllPaths(_entityId: "api::efro-oost-newsarticle.efro-oost-newsarticle") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetEfroOostNewsArticleSlugsQueryResult = Apollo.QueryResult<
	GetEfroOostNewsArticleSlugsQuery,
	GetEfroOostNewsArticleSlugsQueryVariables
>;
export const GetEfroOostNewsArticlesDocument = gql`
	query GetEfroOostNewsArticles(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$showOnHomePage: Boolean
	) {
		efroOostNewsarticles(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: { allowedOnHomePage: { eq: $showOnHomePage } }
		) {
			data {
				id
				attributes {
					...efroOostNewsArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${EfroOostNewsArticleFieldsFragmentDoc}
`;
export type GetEfroOostNewsArticlesQueryResult = Apollo.QueryResult<
	GetEfroOostNewsArticlesQuery,
	GetEfroOostNewsArticlesQueryVariables
>;
export const GetEfroOostPageSlugsDocument = gql`
	query getEfroOostPageSlugs {
		findAllPaths(_entityId: "api::efro-oost-page.efro-oost-page") {
			fullSlug
			locale
		}
	}
`;
export type GetEfroOostPageSlugsQueryResult = Apollo.QueryResult<
	GetEfroOostPageSlugsQuery,
	GetEfroOostPageSlugsQueryVariables
>;
export const GetEventsDocument = gql`
	query GetEvents(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$themeIds: [ID]
		$locale: I18NLocaleCode
	) {
		events(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			filters: { theme: { id: { in: $themeIds } } }
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...eventFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${EventFieldsFragmentDoc}
`;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const FindNotesByRelationDocument = gql`
	query findNotesByRelation($contentTypeUid: String!, $contentTypeId: Int!) {
		notesByRelation(contentTypeUid: $contentTypeUid, contentTypeId: $contentTypeId) {
			data {
				id
				attributes {
					...NoteFields
				}
			}
		}
	}
	${NoteFieldsFragmentDoc}
`;
export type FindNotesByRelationQueryResult = Apollo.QueryResult<
	FindNotesByRelationQuery,
	FindNotesByRelationQueryVariables
>;
export const GetGeolinkPageSlugsDocument = gql`
	query getGeolinkPageSlugs {
		findAllPaths(_entityId: "api::geolink-page.geolink-page") {
			fullSlug
			locale
		}
	}
`;
export type GetGeolinkPageSlugsQueryResult = Apollo.QueryResult<
	GetGeolinkPageSlugsQuery,
	GetGeolinkPageSlugsQueryVariables
>;
export const GlobalSearchDocument = gql`
	query globalSearch(
		$sort: String
		$searchValue: String!
		$createdAtBefore: String
		$createdAtAfter: String
		$domain: String!
	) {
		searchCollectionTypes(
			searchQuery: $searchValue
			sort: $sort
			createdAtBefore: $createdAtBefore
			createdAtAfter: $createdAtAfter
			domain: $domain
		) {
			id
			title
			excerpt
			createdAt
			fullPath
		}
	}
`;
export type GlobalSearchQueryResult = Apollo.QueryResult<GlobalSearchQuery, GlobalSearchQueryVariables>;
export const GlobalSearchDocumentsDocument = gql`
	query globalSearchDocuments(
		$searchValue: String!
		$sort: [String]
		$createdAtBefore: String
		$createdAtAfter: String
	) {
		searchMediaLibrary(
			searchQuery: $searchValue
			sort: $sort
			createdAtBefore: $createdAtBefore
			createdAtAfter: $createdAtAfter
		) {
			data {
				id
				attributes {
					name
					url
					createdAt
					ext
					size
				}
			}
		}
	}
`;
export type GlobalSearchDocumentsQueryResult = Apollo.QueryResult<
	GlobalSearchDocumentsQuery,
	GlobalSearchDocumentsQueryVariables
>;
export const GlobalSearchWooRequestsDocument = gql`
	query globalSearchWooRequests(
		$searchValue: String!
		$sort: [String]
		$createdAtBefore: String
		$createdAtAfter: String
	) {
		searchWooRequests(
			searchQuery: $searchValue
			sort: $sort
			createdAtBefore: $createdAtBefore
			createdAtAfter: $createdAtAfter
		) {
			data {
				id
				attributes {
					title
					zip
					createdAt
					updatedAt
				}
			}
		}
	}
`;
export type GlobalSearchWooRequestsQueryResult = Apollo.QueryResult<
	GlobalSearchWooRequestsQuery,
	GlobalSearchWooRequestsQueryVariables
>;
export const GlobalSearchWobRequestsDocument = gql`
	query globalSearchWobRequests(
		$searchValue: String!
		$sort: [String]
		$createdAtBefore: String
		$createdAtAfter: String
	) {
		searchWobRequests(
			searchQuery: $searchValue
			sort: $sort
			createdAtBefore: $createdAtBefore
			createdAtAfter: $createdAtAfter
		) {
			data {
				id
				attributes {
					title
					zip
					createdAt
					updatedAt
				}
			}
		}
	}
`;
export type GlobalSearchWobRequestsQueryResult = Apollo.QueryResult<
	GlobalSearchWobRequestsQuery,
	GlobalSearchWobRequestsQueryVariables
>;
export const GlobalSearchNotesDocument = gql`
	query globalSearchNotes($searchValue: String!) {
		searchNotes(searchQuery: $searchValue) {
			entities {
				id
				title
				excerpt
				createdAt
				fullPath
			}
		}
	}
`;
export type GlobalSearchNotesQueryResult = Apollo.QueryResult<GlobalSearchNotesQuery, GlobalSearchNotesQueryVariables>;
export const GetHighlightDocument = gql`
	query GetHighlight {
		newsarticles(pagination: { limit: 3 }, publicationState: LIVE, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...highlightNewsArticleFields
				}
			}
		}
		subsidies(pagination: { limit: 5 }, publicationState: LIVE, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...highlightSubsidyFields
				}
			}
		}
		permits(pagination: { limit: 5 }, publicationState: LIVE, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...highlightPermitsFields
				}
			}
		}
		mapsFigures(pagination: { limit: 5 }, publicationState: LIVE, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...highlightMapsFiguresFields
				}
			}
		}
		wooRequests(pagination: { limit: 5 }, publicationState: LIVE, sort: "date:DESC") {
			data {
				id
				attributes {
					...highlightWoo
				}
			}
		}
		events(pagination: { limit: 5 }, publicationState: LIVE, sort: "publishedAt:DESC") {
			data {
				id
				attributes {
					...eventFields
				}
			}
		}
	}
	${HighlightNewsArticleFieldsFragmentDoc}
	${HighlightSubsidyFieldsFragmentDoc}
	${HighlightPermitsFieldsFragmentDoc}
	${HighlightMapsFiguresFieldsFragmentDoc}
	${HighlightWooFragmentDoc}
	${EventFieldsFragmentDoc}
`;
export type GetHighlightQueryResult = Apollo.QueryResult<GetHighlightQuery, GetHighlightQueryVariables>;
export const GetHighlightWithThemeDocument = gql`
	query GetHighlightWithTheme($themeId: ID) {
		newsarticles(
			pagination: { limit: 3 }
			publicationState: LIVE
			sort: "publishedAt:DESC"
			filters: { theme: { id: { eq: $themeId } } }
		) {
			data {
				id
				attributes {
					...highlightNewsArticleFields
				}
			}
		}
		subsidies(
			pagination: { limit: 5 }
			publicationState: LIVE
			sort: "publishedAt:DESC"
			filters: { themes: { id: { in: [$themeId] } } }
		) {
			data {
				id
				attributes {
					...highlightSubsidyFields
				}
			}
		}
		permits(
			pagination: { limit: 5 }
			publicationState: LIVE
			sort: "publishedAt:DESC"
			filters: { themes: { id: { in: [$themeId] } } }
		) {
			data {
				id
				attributes {
					...highlightPermitsFields
				}
			}
		}
		mapsFigures(
			pagination: { limit: 5 }
			publicationState: LIVE
			sort: "publishedAt:DESC"
			filters: { themes: { id: { in: [$themeId] } } }
		) {
			data {
				id
				attributes {
					...highlightMapsFiguresFields
				}
			}
		}
		wooRequests(
			pagination: { limit: 5 }
			publicationState: LIVE
			sort: "date:DESC"
			filters: { themes: { id: { in: [$themeId] } } }
		) {
			data {
				id
				attributes {
					...highlightWoo
				}
			}
		}
		events(
			pagination: { limit: 5 }
			publicationState: LIVE
			sort: "publishedAt:DESC"
			filters: { theme: { id: { in: [$themeId] } } }
		) {
			data {
				id
				attributes {
					...eventFields
				}
			}
		}
	}
	${HighlightNewsArticleFieldsFragmentDoc}
	${HighlightSubsidyFieldsFragmentDoc}
	${HighlightPermitsFieldsFragmentDoc}
	${HighlightMapsFiguresFieldsFragmentDoc}
	${HighlightWooFragmentDoc}
	${EventFieldsFragmentDoc}
`;
export type GetHighlightWithThemeQueryResult = Apollo.QueryResult<
	GetHighlightWithThemeQuery,
	GetHighlightWithThemeQueryVariables
>;
export const GetInConvoWithArticleSlugsDocument = gql`
	query GetInConvoWithArticleSlugs {
		findAllPaths(_entityId: "api::in-convo-with-article.in-convo-with-article") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetInConvoWithArticleSlugsQueryResult = Apollo.QueryResult<
	GetInConvoWithArticleSlugsQuery,
	GetInConvoWithArticleSlugsQueryVariables
>;
export const GetInConvoWithArticlesDocument = gql`
	query GetInConvoWithArticles(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$showOnHomePage: Boolean
	) {
		inConvoWithArticles(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: { allowedOnHomePage: { eq: $showOnHomePage } }
		) {
			data {
				id
				attributes {
					...inConvoWithArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${InConvoWithArticleFieldsFragmentDoc}
`;
export type GetInConvoWithArticlesQueryResult = Apollo.QueryResult<
	GetInConvoWithArticlesQuery,
	GetInConvoWithArticlesQueryVariables
>;
export const GetLoginDocument = gql`
	query getLogin($locale: I18NLocaleCode) {
		login(locale: $locale) {
			data {
				id
				attributes {
					...loginSingleFields
				}
			}
		}
	}
	${LoginSingleFieldsFragmentDoc}
`;
export type GetLoginQueryResult = Apollo.QueryResult<GetLoginQuery, GetLoginQueryVariables>;
export const MapsFiguresSearchDocument = gql`
	query mapsFiguresSearch(
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$searchValue: String
		$themes: [ID]
		$tags: [ID]
		$type: String
	) {
		searchMapsFigure(
			searchQuery: $searchValue
			pagination: { start: $start, limit: $limit }
			sort: $sort
			locale: $locale
			filters: { themes: { id: { in: $themes } }, tags: { id: { in: $tags } }, type: { eq: $type } }
		) {
			data {
				attributes {
					...mapsFiguresFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${MapsFiguresFieldsFragmentDoc}
`;
export type MapsFiguresSearchQueryResult = Apollo.QueryResult<MapsFiguresSearchQuery, MapsFiguresSearchQueryVariables>;
export const GetNewsArticleSlugsDocument = gql`
	query GetNewsArticleSlugs {
		findAllPaths(_entityId: "api::newsarticle.newsarticle") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetNewsArticleSlugsQueryResult = Apollo.QueryResult<
	GetNewsArticleSlugsQuery,
	GetNewsArticleSlugsQueryVariables
>;
export const GetNewsArticlesDocument = gql`
	query GetNewsArticles(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$themeIds: [ID]
		$projectId: ID
		$locale: I18NLocaleCode
		$showOnHomePage: Boolean
	) {
		newsarticles(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: {
				theme: { id: { in: $themeIds } }
				project: { id: { eq: $projectId } }
				and: { allowedOnHomePage: { eq: $showOnHomePage } }
			}
		) {
			data {
				id
				attributes {
					...newsArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${NewsArticleFieldsFragmentDoc}
`;
export type GetNewsArticlesQueryResult = Apollo.QueryResult<GetNewsArticlesQuery, GetNewsArticlesQueryVariables>;
export const GetNewsArticleContactPersonDocument = gql`
	query GetNewsArticleContactPerson($id: String!) {
		getNewsarticleContactPersonById(id: $id) {
			contactPerson
		}
	}
`;
export type GetNewsArticleContactPersonQueryResult = Apollo.QueryResult<
	GetNewsArticleContactPersonQuery,
	GetNewsArticleContactPersonQueryVariables
>;
export const GetManyNewslettersDocument = gql`
	query getManyNewsletters {
		getManyNewsletters {
			campaign {
				id
				name
			}
			newsletters {
				field
				description
			}
		}
	}
`;
export type GetManyNewslettersQueryResult = Apollo.QueryResult<
	GetManyNewslettersQuery,
	GetManyNewslettersQueryVariables
>;
export const GetNewsletterContactDocument = gql`
	query getNewsletterContact($email: String!) {
		newsletterContact(email: $email) {
			active
			campaigns {
				...campaignNewsletterContactFields
			}
			newsletters {
				...campaignNewsletterFieldFields
			}
		}
	}
	${CampaignNewsletterContactFieldsFragmentDoc}
	${CampaignNewsletterFieldFieldsFragmentDoc}
`;
export type GetNewsletterContactQueryResult = Apollo.QueryResult<
	GetNewsletterContactQuery,
	GetNewsletterContactQueryVariables
>;
export const GetNotificationBarDocument = gql`
	query getNotificationBar($locale: I18NLocaleCode) {
		pageInformation(locale: $locale) {
			data {
				attributes {
					navbar {
						...notificationBarFields
					}
				}
			}
		}
	}
	${NotificationBarFieldsFragmentDoc}
`;
export type GetNotificationBarQueryResult = Apollo.QueryResult<
	GetNotificationBarQuery,
	GetNotificationBarQueryVariables
>;
export const GetOpOostInConvoWithArticleSlugsDocument = gql`
	query GetOpOostInConvoWithArticleSlugs {
		findAllPaths(_entityId: "api::op-oost-in-convo-with-article.op-oost-in-convo-with-article") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetOpOostInConvoWithArticleSlugsQueryResult = Apollo.QueryResult<
	GetOpOostInConvoWithArticleSlugsQuery,
	GetOpOostInConvoWithArticleSlugsQueryVariables
>;
export const GetOpOostInConvoWithArticlesDocument = gql`
	query GetOpOostInConvoWithArticles(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$showOnHomePage: Boolean
	) {
		opOostInConvoWithArticles(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: { allowedOnHomePage: { eq: $showOnHomePage } }
		) {
			data {
				id
				attributes {
					...opOostInConvoWithArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${OpOostInConvoWithArticleFieldsFragmentDoc}
`;
export type GetOpOostInConvoWithArticlesQueryResult = Apollo.QueryResult<
	GetOpOostInConvoWithArticlesQuery,
	GetOpOostInConvoWithArticlesQueryVariables
>;
export const GetOpOostNewsArticleSlugsDocument = gql`
	query GetOpOostNewsArticleSlugs {
		findAllPaths(_entityId: "api::op-oost-newsarticle.op-oost-newsarticle") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetOpOostNewsArticleSlugsQueryResult = Apollo.QueryResult<
	GetOpOostNewsArticleSlugsQuery,
	GetOpOostNewsArticleSlugsQueryVariables
>;
export const GetOpOostNewsArticlesDocument = gql`
	query GetOpOostNewsArticles(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$showOnHomePage: Boolean
	) {
		opOostNewsarticles(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: { allowedOnHomePage: { eq: $showOnHomePage } }
		) {
			data {
				id
				attributes {
					...opOostNewsArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${OpOostNewsArticleFieldsFragmentDoc}
`;
export type GetOpOostNewsArticlesQueryResult = Apollo.QueryResult<
	GetOpOostNewsArticlesQuery,
	GetOpOostNewsArticlesQueryVariables
>;
export const GetOpOostPageSlugsDocument = gql`
	query getOpOostPageSlugs {
		findAllPaths(_entityId: "api::op-oost-page.op-oost-page") {
			fullSlug
			locale
		}
	}
`;
export type GetOpOostPageSlugsQueryResult = Apollo.QueryResult<
	GetOpOostPageSlugsQuery,
	GetOpOostPageSlugsQueryVariables
>;
export const GetPageSlugsDocument = gql`
	query GetPageSlugs {
		findAllPaths(_entityId: "api::page.page") {
			slug
			fullSlug
			locale
		}
	}
`;
export type GetPageSlugsQueryResult = Apollo.QueryResult<GetPageSlugsQuery, GetPageSlugsQueryVariables>;
export const GetPermitSlugsDocument = gql`
	query getPermitSlugs {
		findAllPaths(_entityId: "api::permit.permit") {
			fullSlug
			locale
		}
	}
`;
export type GetPermitSlugsQueryResult = Apollo.QueryResult<GetPermitSlugsQuery, GetPermitSlugsQueryVariables>;
export const GetPermitsDocument = gql`
	query GetPermits(
		$searchValue: String
		$limit: Int
		$start: Int
		$sort: [String]
		$lawIds: [ID]
		$themeIds: [ID]
		$productTypeIds: [ID]
		$locale: I18NLocaleCode
	) {
		searchPermit(
			searchQuery: $searchValue
			pagination: { limit: $limit, start: $start }
			locale: $locale
			sort: $sort
			filters: {
				permitLaws: { id: { in: $lawIds } }
				permitProductTypes: { id: { in: $productTypeIds } }
				themes: { id: { in: $themeIds } }
			}
		) {
			data {
				attributes {
					...permitFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PermitFieldsFragmentDoc}
`;
export type GetPermitsQueryResult = Apollo.QueryResult<GetPermitsQuery, GetPermitsQueryVariables>;
export const GetPressReleaseSlugsDocument = gql`
	query getPressReleaseSlugs {
		findAllPaths(_entityId: "api::press-release.press-release") {
			slug
			fullSlug
			locale
		}
	}
`;
export type GetPressReleaseSlugsQueryResult = Apollo.QueryResult<
	GetPressReleaseSlugsQuery,
	GetPressReleaseSlugsQueryVariables
>;
export const GetPressReleasesDocument = gql`
	query GetPressReleases(
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$publicationState: PublicationState
	) {
		pressReleases(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...pressReleaseFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PressReleaseFieldsFragmentDoc}
`;
export type GetPressReleasesQueryResult = Apollo.QueryResult<GetPressReleasesQuery, GetPressReleasesQueryVariables>;
export const GetPressReleaseContactPersonDocument = gql`
	query GetPressReleaseContactPerson($id: String!) {
		getPressReleaseContactPersonById(id: $id) {
			contactPerson
		}
	}
`;
export type GetPressReleaseContactPersonQueryResult = Apollo.QueryResult<
	GetPressReleaseContactPersonQuery,
	GetPressReleaseContactPersonQueryVariables
>;
export const GetProjectSlugsDocument = gql`
	query getProjectSlugs {
		findAllPaths(_entityId: "api::project.project") {
			fullSlug
			locale
		}
	}
`;
export type GetProjectSlugsQueryResult = Apollo.QueryResult<GetProjectSlugsQuery, GetProjectSlugsQueryVariables>;
export const GetProjectsDocument = gql`
	query GetProjects(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$themeIds: [ID]
		$locale: I18NLocaleCode
	) {
		projects(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: { theme: { id: { in: $themeIds } } }
		) {
			data {
				id
				attributes {
					...projectFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${ProjectFieldsFragmentDoc}
`;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetRegioExpresInConvoWithArticleSlugsDocument = gql`
	query GetRegioExpresInConvoWithArticleSlugs {
		findAllPaths(_entityId: "api::regio-expres-in-convo-with-article.regio-expres-in-convo-with-article") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetRegioExpresInConvoWithArticleSlugsQueryResult = Apollo.QueryResult<
	GetRegioExpresInConvoWithArticleSlugsQuery,
	GetRegioExpresInConvoWithArticleSlugsQueryVariables
>;
export const GetRegioExpresInConvoWithArticlesDocument = gql`
	query GetRegioExpresInConvoWithArticles(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$showOnHomePage: Boolean
	) {
		regioExpresInConvoWithArticles(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: { allowedOnHomePage: { eq: $showOnHomePage } }
		) {
			data {
				id
				attributes {
					...regioExpresInConvoWithArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${RegioExpresInConvoWithArticleFieldsFragmentDoc}
`;
export type GetRegioExpresInConvoWithArticlesQueryResult = Apollo.QueryResult<
	GetRegioExpresInConvoWithArticlesQuery,
	GetRegioExpresInConvoWithArticlesQueryVariables
>;
export const GetRegioExpresNewsArticleSlugsDocument = gql`
	query GetRegioExpresNewsArticleSlugs {
		findAllPaths(_entityId: "api::regio-expres-newsarticle.regio-expres-newsarticle") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetRegioExpresNewsArticleSlugsQueryResult = Apollo.QueryResult<
	GetRegioExpresNewsArticleSlugsQuery,
	GetRegioExpresNewsArticleSlugsQueryVariables
>;
export const GetRegioExpresNewsArticlesDocument = gql`
	query GetRegioExpresNewsArticles(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$showOnHomePage: Boolean
	) {
		regioExpresNewsarticles(
			pagination: { limit: $limit, start: $start }
			sort: $sort
			locale: $locale
			publicationState: $publicationState
			filters: { allowedOnHomePage: { eq: $showOnHomePage } }
		) {
			data {
				id
				attributes {
					...regioExpresNewsArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${RegioExpresNewsArticleFieldsFragmentDoc}
`;
export type GetRegioExpresNewsArticlesQueryResult = Apollo.QueryResult<
	GetRegioExpresNewsArticlesQuery,
	GetRegioExpresNewsArticlesQueryVariables
>;
export const GetRegioexpresPageSlugsDocument = gql`
	query getRegioexpresPageSlugs {
		findAllPaths(_entityId: "api::regio-expres-page.regio-expres-page") {
			fullSlug
			locale
		}
	}
`;
export type GetRegioexpresPageSlugsQueryResult = Apollo.QueryResult<
	GetRegioexpresPageSlugsQuery,
	GetRegioexpresPageSlugsQueryVariables
>;
export const GetRegionSlugsDocument = gql`
	query getRegionSlugs {
		findAllPaths(_entityId: "api::region.region") {
			fullSlug
			locale
		}
	}
`;
export type GetRegionSlugsQueryResult = Apollo.QueryResult<GetRegionSlugsQuery, GetRegionSlugsQueryVariables>;
export const GetRegionsDocument = gql`
	query getRegions($locale: I18NLocaleCode) {
		regions(locale: $locale, pagination: { limit: -1 }) {
			data {
				id
				attributes {
					...regionFields
				}
			}
		}
	}
	${RegionFieldsFragmentDoc}
`;
export type GetRegionsQueryResult = Apollo.QueryResult<GetRegionsQuery, GetRegionsQueryVariables>;
export const GetRoadMaintenancesSlugsDocument = gql`
	query GetRoadMaintenancesSlugs {
		findAllPaths(_entityId: "api::road-maintenance.road-maintenance") {
			locale
			slug
			fullSlug
		}
	}
`;
export type GetRoadMaintenancesSlugsQueryResult = Apollo.QueryResult<
	GetRoadMaintenancesSlugsQuery,
	GetRoadMaintenancesSlugsQueryVariables
>;
export const GetRoadMaintenancesDocument = gql`
	query GetRoadMaintenances($locale: I18NLocaleCode, $publicationState: PublicationState) {
		roadMaintenances(
			pagination: { limit: 9999, start: 0 }
			sort: "title"
			locale: $locale
			publicationState: $publicationState
			filters: { status: { in: ["Planned", "Active"] } }
		) {
			data {
				id
				attributes {
					...roadMaintenanceFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${RoadMaintenanceFieldsFragmentDoc}
`;
export type GetRoadMaintenancesQueryResult = Apollo.QueryResult<
	GetRoadMaintenancesQuery,
	GetRoadMaintenancesQueryVariables
>;
export const GetFullSitemapDocument = gql`
	query GetFullSitemap($locale: I18NLocaleCode, $domain: String) {
		findSitemapList(_locale: $locale, _domain: $domain) {
			fullPath
			title
			updated_at
		}
	}
`;
export type GetFullSitemapQueryResult = Apollo.QueryResult<GetFullSitemapQuery, GetFullSitemapQueryVariables>;
export const GetSubsidiesDocument = gql`
	query GetSubsidies(
		$searchValue: String!
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
		$themeIds: [ID]
		$kindIds: [ID]
		$scaleIds: [ID]
		$groupIds: [ID]
		$status: String
	) {
		searchSubsidy(
			searchQuery: $searchValue
			locale: $locale
			pagination: { limit: $limit, start: $start }
			sort: $sort
			filters: {
				themes: { id: { in: $themeIds } }
				target_groups: { id: { in: $groupIds } }
				subsidy_kinds: { id: { in: $kindIds } }
				subsidy_scales: { id: { in: $scaleIds } }
				hideFromOverview: { eq: false }
				status: { eq: $status }
			}
		) {
			data {
				id
				attributes {
					...subsidyFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${SubsidyFieldsFragmentDoc}
`;
export type GetSubsidiesQueryResult = Apollo.QueryResult<GetSubsidiesQuery, GetSubsidiesQueryVariables>;
export const GetSubsidyFiltersDocument = gql`
	query getSubsidyFilters($locale: I18NLocaleCode) {
		subsidyKinds(locale: $locale) {
			data {
				id
				attributes {
					name
					locale
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
		subsidyScales(locale: $locale) {
			data {
				id
				attributes {
					name
					locale
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
		subsidyGroups: targetGroups(locale: $locale) {
			data {
				id
				attributes {
					name
					locale
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
`;
export type GetSubsidyFiltersQueryResult = Apollo.QueryResult<GetSubsidyFiltersQuery, GetSubsidyFiltersQueryVariables>;
export const GetSubsidySlugsDocument = gql`
	query GetSubsidySlugs {
		findAllPaths(_entityId: "api::subsidy.subsidy") {
			slug
			fullSlug
			locale
		}
	}
`;
export type GetSubsidySlugsQueryResult = Apollo.QueryResult<GetSubsidySlugsQuery, GetSubsidySlugsQueryVariables>;
export const GetTagsDocument = gql`
	query getTags($locale: I18NLocaleCode) {
		tags(locale: $locale) {
			data {
				id
				attributes {
					...tagFields
				}
			}
		}
	}
	${TagFieldsFragmentDoc}
`;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const GetThemeSlugsDocument = gql`
	query getThemeSlugs {
		findAllPaths(_entityId: "api::theme.theme") {
			fullSlug
			locale
		}
	}
`;
export type GetThemeSlugsQueryResult = Apollo.QueryResult<GetThemeSlugsQuery, GetThemeSlugsQueryVariables>;
export const GetThemesDocument = gql`
	query getThemes($locale: I18NLocaleCode) {
		themes(locale: $locale, pagination: { limit: -1 }) {
			data {
				id
				attributes {
					...themeFields
				}
			}
		}
	}
	${ThemeFieldsFragmentDoc}
`;
export type GetThemesQueryResult = Apollo.QueryResult<GetThemesQuery, GetThemesQueryVariables>;
export const GetWobRequestsDocument = gql`
	query GetWobRequests(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$locale: I18NLocaleCode
	) {
		wobRequests(
			pagination: { limit: $limit, start: $start }
			locale: $locale
			publicationState: $publicationState
			sort: $sort
			filters: { zip: { notNull: true } }
		) {
			data {
				id
				attributes {
					...wobRequestFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${WobRequestFieldsFragmentDoc}
`;
export type GetWobRequestsQueryResult = Apollo.QueryResult<GetWobRequestsQuery, GetWobRequestsQueryVariables>;
export const GetWooCategoriesDocument = gql`
	query GetWooCategories {
		wooCategories {
			data {
				id
				attributes {
					title
				}
			}
		}
	}
`;
export type GetWooCategoriesQueryResult = Apollo.QueryResult<GetWooCategoriesQuery, GetWooCategoriesQueryVariables>;
export const GetWooRequestSlugsDocument = gql`
	query getWooRequestSlugs {
		findAllPaths(_entityId: "api::woo-request.woo-request") {
			fullSlug
			locale
		}
	}
`;
export type GetWooRequestSlugsQueryResult = Apollo.QueryResult<
	GetWooRequestSlugsQuery,
	GetWooRequestSlugsQueryVariables
>;
export const GetWooRequestsDocument = gql`
	query GetWooRequests(
		$publicationState: PublicationState
		$limit: Int
		$start: Int
		$sort: [String]
		$themeIds: [ID]
		$categoryIds: [ID]
		$locale: I18NLocaleCode
		$searchValue: String
	) {
		wooRequests(
			pagination: { limit: $limit, start: $start }
			locale: $locale
			publicationState: $publicationState
			sort: $sort
			filters: {
				and: {
					zip: { notNull: true }
					themes: { id: { in: $themeIds } }
					category: { id: { in: $categoryIds } }
					title: { containsi: $searchValue }
				}
			}
		) {
			data {
				id
				attributes {
					...wooRequestFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${WooRequestFieldsFragmentDoc}
`;
export type GetWooRequestsQueryResult = Apollo.QueryResult<GetWooRequestsQuery, GetWooRequestsQueryVariables>;
export const GetAgendaPageDocument = gql`
	query GetAgendaPage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		agendaPage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...agendaPageFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${AgendaPageFieldsFragmentDoc}
`;
export type GetAgendaPageQueryResult = Apollo.QueryResult<GetAgendaPageQuery, GetAgendaPageQueryVariables>;
export const GetCondoleanceHomepageDocument = gql`
	query GetCondoleanceHomepage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		condoleanceInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...condoleanceInformationFields
				}
			}
		}
		condoleanceHomepage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...condoleanceHomePageFields
				}
			}
		}
	}
	${CondoleanceInformationFieldsFragmentDoc}
	${CondoleanceHomePageFieldsFragmentDoc}
`;
export type GetCondoleanceHomepageQueryResult = Apollo.QueryResult<
	GetCondoleanceHomepageQuery,
	GetCondoleanceHomepageQueryVariables
>;
export const GetCondoleancePageDocument = gql`
	query GetCondoleancePage($slug: String, $locale: I18NLocaleCode, $publicationState: PublicationState) {
		condoleanceInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...condoleanceInformationFields
				}
			}
		}
		condoleancePageBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...condoleancePageSlugFields
				}
			}
		}
	}
	${CondoleanceInformationFieldsFragmentDoc}
	${CondoleancePageSlugFieldsFragmentDoc}
`;
export type GetCondoleancePageQueryResult = Apollo.QueryResult<
	GetCondoleancePageQuery,
	GetCondoleancePageQueryVariables
>;
export const GetContactDocument = gql`
	query GetContact($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		contact(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...contactFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${ContactFieldsFragmentDoc}
`;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetCookiePageDocument = gql`
	query GetCookiePage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		cookiePage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...cookiePageFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${CookiePageFieldsFragmentDoc}
`;
export type GetCookiePageQueryResult = Apollo.QueryResult<GetCookiePageQuery, GetCookiePageQueryVariables>;
export const GetDecisionsPageDocument = gql`
	query GetDecisionsPage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		decisionsPage(locale: $locale) {
			data {
				id
				attributes {
					...decisionsPageFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${DecisionsPageFieldsFragmentDoc}
`;
export type GetDecisionsPageQueryResult = Apollo.QueryResult<GetDecisionsPageQuery, GetDecisionsPageQueryVariables>;
export const GetDownloadsPageDocument = gql`
	query GetDownloadsPage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		downloadsPage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...downloadsPageFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${DownloadsPageFieldsFragmentDoc}
`;
export type GetDownloadsPageQueryResult = Apollo.QueryResult<GetDownloadsPageQuery, GetDownloadsPageQueryVariables>;
export const GetEfroOostHomepageDocument = gql`
	query GetEfroOostHomepage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		efroOostHomepage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostHomePageFields
				}
			}
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
	${EfroOostHomePageFieldsFragmentDoc}
`;
export type GetEfroOostHomepageQueryResult = Apollo.QueryResult<
	GetEfroOostHomepageQuery,
	GetEfroOostHomepageQueryVariables
>;
export const GetEfroOostInConvoWithDetailDocument = gql`
	query GetEfroOostInConvoWithDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		efroOostInConvoWithDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInConvoWithDetailSingleFields
				}
			}
		}
		efroOostInConvoWithArticleBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInConvoWithArticleFields
				}
			}
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
	${EfroOostInConvoWithDetailSingleFieldsFragmentDoc}
	${EfroOostInConvoWithArticleFieldsFragmentDoc}
`;
export type GetEfroOostInConvoWithDetailQueryResult = Apollo.QueryResult<
	GetEfroOostInConvoWithDetailQuery,
	GetEfroOostInConvoWithDetailQueryVariables
>;
export const GetEfroOostInConvoWithOverviewDocument = gql`
	query GetEfroOostInConvoWithOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		efroOostInConvoWithOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInConvoWithOverviewSingleFields
				}
			}
		}
		efroOostInConvoWithArticles(
			pagination: { limit: 9, start: 0 }
			sort: "publishedAt:DESC"
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...efroOostInConvoWithArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
	${EfroOostInConvoWithOverviewSingleFieldsFragmentDoc}
	${EfroOostInConvoWithArticleFieldsFragmentDoc}
`;
export type GetEfroOostInConvoWithOverviewQueryResult = Apollo.QueryResult<
	GetEfroOostInConvoWithOverviewQuery,
	GetEfroOostInConvoWithOverviewQueryVariables
>;
export const GetEfroOostNewsDetailDocument = gql`
	query GetEfroOostNewsDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		efroOostNewsDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostNewsDetailSingleFields
				}
			}
		}
		efroOostNewsarticleBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostNewsArticleFields
				}
			}
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
	${EfroOostNewsDetailSingleFieldsFragmentDoc}
	${EfroOostNewsArticleFieldsFragmentDoc}
`;
export type GetEfroOostNewsDetailQueryResult = Apollo.QueryResult<
	GetEfroOostNewsDetailQuery,
	GetEfroOostNewsDetailQueryVariables
>;
export const GetEfroOostNewsOverviewDocument = gql`
	query GetEfroOostNewsOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		efroOostNewsOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostNewsOverviewSingleFields
				}
			}
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
	${EfroOostNewsOverviewSingleFieldsFragmentDoc}
`;
export type GetEfroOostNewsOverviewQueryResult = Apollo.QueryResult<
	GetEfroOostNewsOverviewQuery,
	GetEfroOostNewsOverviewQueryVariables
>;
export const GetEfroOostPageDocument = gql`
	query GetEfroOostPage($slug: String, $locale: I18NLocaleCode, $publicationState: PublicationState) {
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		efroOostPageBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostPageSlugFields
				}
			}
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
	${EfroOostPageSlugFieldsFragmentDoc}
`;
export type GetEfroOostPageQueryResult = Apollo.QueryResult<GetEfroOostPageQuery, GetEfroOostPageQueryVariables>;
export const GetEventOverviewDocument = gql`
	query GetEventOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		eventOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...eventOverviewSingleFields
				}
			}
		}
		themes(locale: $locale, publicationState: $publicationState, pagination: { limit: -1 }) {
			data {
				id
				attributes {
					...themeFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${EventOverviewSingleFieldsFragmentDoc}
	${ThemeFieldsFragmentDoc}
`;
export type GetEventOverviewQueryResult = Apollo.QueryResult<GetEventOverviewQuery, GetEventOverviewQueryVariables>;
export const GetGeoLinkPageDocument = gql`
	query GetGeoLinkPage($slug: String, $locale: I18NLocaleCode, $publicationState: PublicationState) {
		geolinkInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...geolinkInformationFields
				}
			}
		}
		geolinkPageBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...geolinkPageSlugFields
				}
			}
		}
	}
	${GeolinkInformationFieldsFragmentDoc}
	${GeolinkPageSlugFieldsFragmentDoc}
`;
export type GetGeoLinkPageQueryResult = Apollo.QueryResult<GetGeoLinkPageQuery, GetGeoLinkPageQueryVariables>;
export const GetGeolinkHomepageDocument = gql`
	query GetGeolinkHomepage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		geolinkInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...geolinkInformationFields
				}
			}
		}
		geolinkHomepage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...geolinkHomePageFields
				}
			}
		}
	}
	${GeolinkInformationFieldsFragmentDoc}
	${GeolinkHomePageFieldsFragmentDoc}
`;
export type GetGeolinkHomepageQueryResult = Apollo.QueryResult<
	GetGeolinkHomepageQuery,
	GetGeolinkHomepageQueryVariables
>;
export const GetHomePageDocument = gql`
	query GetHomePage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		homePage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...homePageFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${HomePageFieldsFragmentDoc}
`;
export type GetHomePageQueryResult = Apollo.QueryResult<GetHomePageQuery, GetHomePageQueryVariables>;
export const GetInConvoWithDetailDocument = gql`
	query GetInConvoWithDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		inConvoWithDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...inConvoWithDetailSingleFields
				}
			}
		}
		inConvoWithArticleBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...inConvoWithArticleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${InConvoWithDetailSingleFieldsFragmentDoc}
	${InConvoWithArticleFieldsFragmentDoc}
`;
export type GetInConvoWithDetailQueryResult = Apollo.QueryResult<
	GetInConvoWithDetailQuery,
	GetInConvoWithDetailQueryVariables
>;
export const GetInConvoWithOverviewDocument = gql`
	query GetInConvoWithOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		inConvoWithOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...inConvoWithOverviewSingleFields
				}
			}
		}
		inConvoWithArticles(
			pagination: { limit: 9, start: 0 }
			sort: "publishedAt:DESC"
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...inConvoWithArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${InConvoWithOverviewSingleFieldsFragmentDoc}
	${InConvoWithArticleFieldsFragmentDoc}
`;
export type GetInConvoWithOverviewQueryResult = Apollo.QueryResult<
	GetInConvoWithOverviewQuery,
	GetInConvoWithOverviewQueryVariables
>;
export const GetMapsFiguresOverviewDocument = gql`
	query GetMapsFiguresOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		mapsFiguresOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...mapsFiguresOverviewSingleFields
				}
			}
		}
		themes(
			pagination: { limit: 9, start: 0 }
			sort: "title:ASC"
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...themeFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${MapsFiguresOverviewSingleFieldsFragmentDoc}
	${ThemeFieldsFragmentDoc}
`;
export type GetMapsFiguresOverviewQueryResult = Apollo.QueryResult<
	GetMapsFiguresOverviewQuery,
	GetMapsFiguresOverviewQueryVariables
>;
export const GetNewsDetailDocument = gql`
	query GetNewsDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		newsDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...newsDetailSingleFields
				}
			}
		}
		newsarticleBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...newsArticleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${NewsDetailSingleFieldsFragmentDoc}
	${NewsArticleFieldsFragmentDoc}
`;
export type GetNewsDetailQueryResult = Apollo.QueryResult<GetNewsDetailQuery, GetNewsDetailQueryVariables>;
export const GetNewsOverviewDocument = gql`
	query GetNewsOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		newsOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...newsOverviewSingleFields
				}
			}
		}
		themes(locale: $locale, publicationState: $publicationState, pagination: { limit: -1 }) {
			data {
				id
				attributes {
					...themeFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${NewsOverviewSingleFieldsFragmentDoc}
	${ThemeFieldsFragmentDoc}
`;
export type GetNewsOverviewQueryResult = Apollo.QueryResult<GetNewsOverviewQuery, GetNewsOverviewQueryVariables>;
export const GetArchiveNewsletterOverviewPageDocument = gql`
	query GetArchiveNewsletterOverviewPage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				__typename
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		newsletterArchiveOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				__typename
				attributes {
					...newsletterArchiveOverviewSingleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${NewsletterArchiveOverviewSingleFieldsFragmentDoc}
`;
export type GetArchiveNewsletterOverviewPageQueryResult = Apollo.QueryResult<
	GetArchiveNewsletterOverviewPageQuery,
	GetArchiveNewsletterOverviewPageQueryVariables
>;
export const GetInternalNewsletterPageDocument = gql`
	query GetInternalNewsletterPage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				__typename
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		internalNewsletterWithSubscriptions(_locale: $locale, _publicationState: $publicationState) {
			...internalNewsletterWithSubscriptionsFields
		}
	}
	${PageInformationFieldsFragmentDoc}
	${InternalNewsletterWithSubscriptionsFieldsFragmentDoc}
`;
export type GetInternalNewsletterPageQueryResult = Apollo.QueryResult<
	GetInternalNewsletterPageQuery,
	GetInternalNewsletterPageQueryVariables
>;
export const GetPublicNewsletterPageCondoleanceDocument = gql`
	query GetPublicNewsletterPageCondoleance($locale: I18NLocaleCode, $publicationState: PublicationState) {
		condoleanceInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...condoleanceInformationFields
				}
			}
		}
		publicNewsletterPage(_locale: $locale, _publicationState: $publicationState) {
			...publicNewsletterFields
		}
	}
	${CondoleanceInformationFieldsFragmentDoc}
	${PublicNewsletterFieldsFragmentDoc}
`;
export type GetPublicNewsletterPageCondoleanceQueryResult = Apollo.QueryResult<
	GetPublicNewsletterPageCondoleanceQuery,
	GetPublicNewsletterPageCondoleanceQueryVariables
>;
export const GetPublicNewsletterPageOfroOostDocument = gql`
	query GetPublicNewsletterPageOfroOost($locale: I18NLocaleCode, $publicationState: PublicationState) {
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		publicNewsletterPage(_locale: $locale, _publicationState: $publicationState) {
			...publicNewsletterFields
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
	${PublicNewsletterFieldsFragmentDoc}
`;
export type GetPublicNewsletterPageOfroOostQueryResult = Apollo.QueryResult<
	GetPublicNewsletterPageOfroOostQuery,
	GetPublicNewsletterPageOfroOostQueryVariables
>;
export const GetPublicNewsletterPageGeolinkDocument = gql`
	query GetPublicNewsletterPageGeolink($locale: I18NLocaleCode, $publicationState: PublicationState) {
		geolinkInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...geolinkInformationFields
				}
			}
		}
		publicNewsletterPage(_locale: $locale, _publicationState: $publicationState) {
			...publicNewsletterFields
		}
	}
	${GeolinkInformationFieldsFragmentDoc}
	${PublicNewsletterFieldsFragmentDoc}
`;
export type GetPublicNewsletterPageGeolinkQueryResult = Apollo.QueryResult<
	GetPublicNewsletterPageGeolinkQuery,
	GetPublicNewsletterPageGeolinkQueryVariables
>;
export const GetPublicNewsletterPageOpOostDocument = gql`
	query GetPublicNewsletterPageOpOost($locale: I18NLocaleCode, $publicationState: PublicationState) {
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		publicNewsletterPage(_locale: $locale, _publicationState: $publicationState) {
			...publicNewsletterFields
		}
	}
	${OpOostInformationFieldsFragmentDoc}
	${PublicNewsletterFieldsFragmentDoc}
`;
export type GetPublicNewsletterPageOpOostQueryResult = Apollo.QueryResult<
	GetPublicNewsletterPageOpOostQuery,
	GetPublicNewsletterPageOpOostQueryVariables
>;
export const GetPublicNewsletterPageRegioExpresDocument = gql`
	query GetPublicNewsletterPageRegioExpres($locale: I18NLocaleCode, $publicationState: PublicationState) {
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		publicNewsletterPage(_locale: $locale, _publicationState: $publicationState) {
			...publicNewsletterFields
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
	${PublicNewsletterFieldsFragmentDoc}
`;
export type GetPublicNewsletterPageRegioExpresQueryResult = Apollo.QueryResult<
	GetPublicNewsletterPageRegioExpresQuery,
	GetPublicNewsletterPageRegioExpresQueryVariables
>;
export const GetPublicNewsletterPageDocument = gql`
	query GetPublicNewsletterPage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				__typename
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		publicNewsletterPage(_locale: $locale, _publicationState: $publicationState) {
			...publicNewsletterFields
		}
	}
	${PageInformationFieldsFragmentDoc}
	${PublicNewsletterFieldsFragmentDoc}
`;
export type GetPublicNewsletterPageQueryResult = Apollo.QueryResult<
	GetPublicNewsletterPageQuery,
	GetPublicNewsletterPageQueryVariables
>;
export const GetNotFoundDocument = gql`
	query GetNotFound($locale: I18NLocaleCode, $publicationState: PublicationState) {
		notFoundPage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...notFoundPageFields
				}
			}
		}
	}
	${NotFoundPageFieldsFragmentDoc}
`;
export type GetNotFoundQueryResult = Apollo.QueryResult<GetNotFoundQuery, GetNotFoundQueryVariables>;
export const GetOpOostHomepageDocument = gql`
	query GetOpOostHomepage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		opOostHomepage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostHomePageFields
				}
			}
		}
	}
	${OpOostInformationFieldsFragmentDoc}
	${OpOostHomePageFieldsFragmentDoc}
`;
export type GetOpOostHomepageQueryResult = Apollo.QueryResult<GetOpOostHomepageQuery, GetOpOostHomepageQueryVariables>;
export const GetOpOostInConvoWithDetailDocument = gql`
	query GetOpOostInConvoWithDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		opOostInConvoWithDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInConvoWithDetailSingleFields
				}
			}
		}
		opOostInConvoWithArticleBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInConvoWithArticleFields
				}
			}
		}
	}
	${OpOostInformationFieldsFragmentDoc}
	${OpOostInConvoWithDetailSingleFieldsFragmentDoc}
	${OpOostInConvoWithArticleFieldsFragmentDoc}
`;
export type GetOpOostInConvoWithDetailQueryResult = Apollo.QueryResult<
	GetOpOostInConvoWithDetailQuery,
	GetOpOostInConvoWithDetailQueryVariables
>;
export const GetOpOostInConvoWithOverviewDocument = gql`
	query GetOpOostInConvoWithOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		opOostInConvoWithOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInConvoWithOverviewSingleFields
				}
			}
		}
		opOostInConvoWithArticles(
			pagination: { limit: 9, start: 0 }
			sort: "publishedAt:DESC"
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...opOostInConvoWithArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${OpOostInformationFieldsFragmentDoc}
	${OpOostInConvoWithOverviewSingleFieldsFragmentDoc}
	${OpOostInConvoWithArticleFieldsFragmentDoc}
`;
export type GetOpOostInConvoWithOverviewQueryResult = Apollo.QueryResult<
	GetOpOostInConvoWithOverviewQuery,
	GetOpOostInConvoWithOverviewQueryVariables
>;
export const GetOpOostNewsDetailDocument = gql`
	query GetOpOostNewsDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		opOostNewsDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostNewsDetailSingleFields
				}
			}
		}
		opOostNewsarticleBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostNewsArticleFields
				}
			}
		}
	}
	${OpOostInformationFieldsFragmentDoc}
	${OpOostNewsDetailSingleFieldsFragmentDoc}
	${OpOostNewsArticleFieldsFragmentDoc}
`;
export type GetOpOostNewsDetailQueryResult = Apollo.QueryResult<
	GetOpOostNewsDetailQuery,
	GetOpOostNewsDetailQueryVariables
>;
export const GetOpOostNewsOverviewDocument = gql`
	query GetOpOostNewsOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		opOostNewsOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostNewsOverviewSingleFields
				}
			}
		}
	}
	${OpOostInformationFieldsFragmentDoc}
	${OpOostNewsOverviewSingleFieldsFragmentDoc}
`;
export type GetOpOostNewsOverviewQueryResult = Apollo.QueryResult<
	GetOpOostNewsOverviewQuery,
	GetOpOostNewsOverviewQueryVariables
>;
export const GetOpOostPageDocument = gql`
	query GetOpOostPage($slug: String, $locale: I18NLocaleCode, $publicationState: PublicationState) {
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		opOostPageBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostPageSlugFields
				}
			}
		}
	}
	${OpOostInformationFieldsFragmentDoc}
	${OpOostPageSlugFieldsFragmentDoc}
`;
export type GetOpOostPageQueryResult = Apollo.QueryResult<GetOpOostPageQuery, GetOpOostPageQueryVariables>;
export const GetOpenGelderlandHomepageDocument = gql`
	query GetOpenGelderlandHomepage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		openGelderlandInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...openGelderlandInformationFields
				}
			}
		}
		openGelderlandHomepage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...openGelderlandHomePageFields
				}
			}
		}
	}
	${OpenGelderlandInformationFieldsFragmentDoc}
	${OpenGelderlandHomePageFieldsFragmentDoc}
`;
export type GetOpenGelderlandHomepageQueryResult = Apollo.QueryResult<
	GetOpenGelderlandHomepageQuery,
	GetOpenGelderlandHomepageQueryVariables
>;
export const GetPageInformationCondoleanceDocument = gql`
	query GetPageInformationCondoleance($locale: I18NLocaleCode, $publicationState: PublicationState) {
		condoleanceInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...condoleanceInformationFields
				}
			}
		}
	}
	${CondoleanceInformationFieldsFragmentDoc}
`;
export type GetPageInformationCondoleanceQueryResult = Apollo.QueryResult<
	GetPageInformationCondoleanceQuery,
	GetPageInformationCondoleanceQueryVariables
>;
export const GetPageInformationEfroOostDocument = gql`
	query GetPageInformationEfroOost($locale: I18NLocaleCode, $publicationState: PublicationState) {
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
`;
export type GetPageInformationEfroOostQueryResult = Apollo.QueryResult<
	GetPageInformationEfroOostQuery,
	GetPageInformationEfroOostQueryVariables
>;
export const GetPageInformationGelderlandDocument = gql`
	query GetPageInformationGelderland($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
`;
export type GetPageInformationGelderlandQueryResult = Apollo.QueryResult<
	GetPageInformationGelderlandQuery,
	GetPageInformationGelderlandQueryVariables
>;
export const GetPageInformationGeolinkDocument = gql`
	query GetPageInformationGeolink($locale: I18NLocaleCode, $publicationState: PublicationState) {
		geolinkInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...geolinkInformationFields
				}
			}
		}
	}
	${GeolinkInformationFieldsFragmentDoc}
`;
export type GetPageInformationGeolinkQueryResult = Apollo.QueryResult<
	GetPageInformationGeolinkQuery,
	GetPageInformationGeolinkQueryVariables
>;
export const GetPageInformationOpOostDocument = gql`
	query GetPageInformationOpOost($locale: I18NLocaleCode, $publicationState: PublicationState) {
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
	}
	${OpOostInformationFieldsFragmentDoc}
`;
export type GetPageInformationOpOostQueryResult = Apollo.QueryResult<
	GetPageInformationOpOostQuery,
	GetPageInformationOpOostQueryVariables
>;
export const GetPageInformationRegioExpresDocument = gql`
	query GetPageInformationRegioExpres($locale: I18NLocaleCode, $publicationState: PublicationState) {
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
`;
export type GetPageInformationRegioExpresQueryResult = Apollo.QueryResult<
	GetPageInformationRegioExpresQuery,
	GetPageInformationRegioExpresQueryVariables
>;
export const GetPageDocument = gql`
	query GetPage($slug: String, $locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		pageBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageSlugFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${PageSlugFieldsFragmentDoc}
`;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const GetPasswordPageDocument = gql`
	query GetPasswordPage($locale: I18NLocaleCode) {
		passwordPage(locale: $locale) {
			data {
				id
				attributes {
					...passwordPageFields
				}
			}
		}
	}
	${PasswordPageFieldsFragmentDoc}
`;
export type GetPasswordPageQueryResult = Apollo.QueryResult<GetPasswordPageQuery, GetPasswordPageQueryVariables>;
export const GetPermitDetailDocument = gql`
	query GetPermitDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		permitDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...permitDetailSingleFields
				}
			}
		}
		permitBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...permitFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${PermitDetailSingleFieldsFragmentDoc}
	${PermitFieldsFragmentDoc}
`;
export type GetPermitDetailQueryResult = Apollo.QueryResult<GetPermitDetailQuery, GetPermitDetailQueryVariables>;
export const GetPermitsOverviewDocument = gql`
	query GetPermitsOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		permitsOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...permitsOverviewSingleFields
				}
			}
		}
		permitLaws(pagination: { limit: 99, start: 0 }, locale: $locale) {
			data {
				id
				attributes {
					name
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
		permitProductTypes(pagination: { limit: 9, start: 0 }, locale: $locale) {
			data {
				id
				attributes {
					name
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
		themes(
			pagination: { limit: 9, start: 0 }
			sort: "title:ASC"
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...themeFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${PermitsOverviewSingleFieldsFragmentDoc}
	${ThemeFieldsFragmentDoc}
`;
export type GetPermitsOverviewQueryResult = Apollo.QueryResult<
	GetPermitsOverviewQuery,
	GetPermitsOverviewQueryVariables
>;
export const GetPressReleaseDetailDocument = gql`
	query GetPressReleaseDetail($locale: I18NLocaleCode, $publicationState: PublicationState, $slug: String) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		pressReleaseDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pressReleaseDetailFields
				}
			}
		}
		pressReleaseBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pressReleaseFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${PressReleaseDetailFieldsFragmentDoc}
	${PressReleaseFieldsFragmentDoc}
`;
export type GetPressReleaseDetailQueryResult = Apollo.QueryResult<
	GetPressReleaseDetailQuery,
	GetPressReleaseDetailQueryVariables
>;
export const GetPressReleaseOverviewDocument = gql`
	query GetPressReleaseOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		pressReleaseOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pressReleaseOverviewSingleFields
				}
			}
		}
		pressReleases(
			pagination: { limit: 12, start: 0 }
			sort: "publishedAt:DESC"
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...pressReleaseFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${PressReleaseOverviewSingleFieldsFragmentDoc}
	${PressReleaseFieldsFragmentDoc}
`;
export type GetPressReleaseOverviewQueryResult = Apollo.QueryResult<
	GetPressReleaseOverviewQuery,
	GetPressReleaseOverviewQueryVariables
>;
export const GetPrivacyPolicyDocument = gql`
	query GetPrivacyPolicy($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		privacyPolicy(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...privacyPolicyFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${PrivacyPolicyFieldsFragmentDoc}
`;
export type GetPrivacyPolicyQueryResult = Apollo.QueryResult<GetPrivacyPolicyQuery, GetPrivacyPolicyQueryVariables>;
export const GetProjectDetailDocument = gql`
	query GetProjectDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		projectDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...projectDetailSingleFields
				}
			}
		}
		projectBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...projectFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${ProjectDetailSingleFieldsFragmentDoc}
	${ProjectFieldsFragmentDoc}
`;
export type GetProjectDetailQueryResult = Apollo.QueryResult<GetProjectDetailQuery, GetProjectDetailQueryVariables>;
export const GetProjectOverviewDocument = gql`
	query GetProjectOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		projectOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...projectOverviewSingleFields
				}
			}
		}
		themes(locale: $locale, publicationState: $publicationState, pagination: { limit: -1 }) {
			data {
				id
				attributes {
					...themeFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${ProjectOverviewSingleFieldsFragmentDoc}
	${ThemeFieldsFragmentDoc}
`;
export type GetProjectOverviewQueryResult = Apollo.QueryResult<
	GetProjectOverviewQuery,
	GetProjectOverviewQueryVariables
>;
export const GetRegioExpresHomepageDocument = gql`
	query GetRegioExpresHomepage($locale: I18NLocaleCode, $publicationState: PublicationState) {
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		regioExpresHomepage(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresHomePageFields
				}
			}
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
	${RegioExpresHomePageFieldsFragmentDoc}
`;
export type GetRegioExpresHomepageQueryResult = Apollo.QueryResult<
	GetRegioExpresHomepageQuery,
	GetRegioExpresHomepageQueryVariables
>;
export const GetRegioExpresInConvoWithDetailDocument = gql`
	query GetRegioExpresInConvoWithDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		regioExpresInConvoWithDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInConvoWithDetailSingleFields
				}
			}
		}
		regioExpresInConvoWithArticleBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInConvoWithArticleFields
				}
			}
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
	${RegioExpresInConvoWithDetailSingleFieldsFragmentDoc}
	${RegioExpresInConvoWithArticleFieldsFragmentDoc}
`;
export type GetRegioExpresInConvoWithDetailQueryResult = Apollo.QueryResult<
	GetRegioExpresInConvoWithDetailQuery,
	GetRegioExpresInConvoWithDetailQueryVariables
>;
export const GetRegioExpresInConvoWithOverviewDocument = gql`
	query GetRegioExpresInConvoWithOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		regioExpresInConvoOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInConvoWithOverviewSingleFields
				}
			}
		}
		regioExpresInConvoWithArticles(
			pagination: { limit: 9, start: 0 }
			sort: "publishedAt:DESC"
			locale: $locale
			publicationState: $publicationState
		) {
			data {
				id
				attributes {
					...regioExpresInConvoWithArticleFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
	${RegioExpresInConvoWithOverviewSingleFieldsFragmentDoc}
	${RegioExpresInConvoWithArticleFieldsFragmentDoc}
`;
export type GetRegioExpresInConvoWithOverviewQueryResult = Apollo.QueryResult<
	GetRegioExpresInConvoWithOverviewQuery,
	GetRegioExpresInConvoWithOverviewQueryVariables
>;
export const GetRegioExpresNewsDetailDocument = gql`
	query GetRegioExpresNewsDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		regioExpresNewsDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresNewsDetailSingleFields
				}
			}
		}
		regioExpresNewsarticleBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresNewsArticleFields
				}
			}
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
	${RegioExpresNewsDetailSingleFieldsFragmentDoc}
	${RegioExpresNewsArticleFieldsFragmentDoc}
`;
export type GetRegioExpresNewsDetailQueryResult = Apollo.QueryResult<
	GetRegioExpresNewsDetailQuery,
	GetRegioExpresNewsDetailQueryVariables
>;
export const GetRegioExpresNewsOverviewDocument = gql`
	query GetRegioExpresNewsOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		regioExpresNewsOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresNewsOverviewSingleFields
				}
			}
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
	${RegioExpresNewsOverviewSingleFieldsFragmentDoc}
`;
export type GetRegioExpresNewsOverviewQueryResult = Apollo.QueryResult<
	GetRegioExpresNewsOverviewQuery,
	GetRegioExpresNewsOverviewQueryVariables
>;
export const GetRegioExpresPageDocument = gql`
	query GetRegioExpresPage($slug: String, $locale: I18NLocaleCode, $publicationState: PublicationState) {
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		regioExpresPageBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresPageSlugFields
				}
			}
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
	${RegioExpresPageSlugFieldsFragmentDoc}
`;
export type GetRegioExpresPageQueryResult = Apollo.QueryResult<
	GetRegioExpresPageQuery,
	GetRegioExpresPageQueryVariables
>;
export const GetRegionDetailDocument = gql`
	query GetRegionDetail($locale: I18NLocaleCode, $publicationState: PublicationState, $slug: String) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		regionDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regionDetailFields
				}
			}
		}
		regionBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regionFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${RegionDetailFieldsFragmentDoc}
	${RegionFieldsFragmentDoc}
`;
export type GetRegionDetailQueryResult = Apollo.QueryResult<GetRegionDetailQuery, GetRegionDetailQueryVariables>;
export const GetRegionOverviewDocument = gql`
	query GetRegionOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		regionOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regionOverviewSingleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${RegionOverviewSingleFieldsFragmentDoc}
`;
export type GetRegionOverviewQueryResult = Apollo.QueryResult<GetRegionOverviewQuery, GetRegionOverviewQueryVariables>;
export const GetRoadMaintenanceDetailDocument = gql`
	query GetRoadMaintenanceDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		roadMaintenanceDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...roadMaintenanceDetailSingleFields
				}
			}
		}
		roadMaintenanceBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...roadMaintenanceFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${RoadMaintenanceDetailSingleFieldsFragmentDoc}
	${RoadMaintenanceFieldsFragmentDoc}
`;
export type GetRoadMaintenanceDetailQueryResult = Apollo.QueryResult<
	GetRoadMaintenanceDetailQuery,
	GetRoadMaintenanceDetailQueryVariables
>;
export const GetRoadMaintenanceOverviewDocument = gql`
	query GetRoadMaintenanceOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		roadMaintenanceOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...roadMaintenanceOverviewSingleFields
				}
			}
		}
		themes(locale: $locale, publicationState: $publicationState, pagination: { limit: -1 }) {
			data {
				id
				attributes {
					...themeFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${RoadMaintenanceOverviewSingleFieldsFragmentDoc}
	${ThemeFieldsFragmentDoc}
`;
export type GetRoadMaintenanceOverviewQueryResult = Apollo.QueryResult<
	GetRoadMaintenanceOverviewQuery,
	GetRoadMaintenanceOverviewQueryVariables
>;
export const GetSearchOverviewDocument = gql`
	query GetSearchOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		opOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		efroOostInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		regioExpresInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		searchOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...searchOverviewSingleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${OpOostInformationFieldsFragmentDoc}
	${EfroOostInformationFieldsFragmentDoc}
	${RegioExpresInformationFieldsFragmentDoc}
	${SearchOverviewSingleFieldsFragmentDoc}
`;
export type GetSearchOverviewQueryResult = Apollo.QueryResult<GetSearchOverviewQuery, GetSearchOverviewQueryVariables>;
export const GetLocalSitemapCondoleanceDocument = gql`
	query GetLocalSitemapCondoleance($locale: I18NLocaleCode) {
		condoleanceInformation(locale: $locale) {
			data {
				id
				attributes {
					...condoleanceInformationFields
				}
			}
		}
		sitemap {
			data {
				id
				attributes {
					...sitemapPageFields
				}
			}
		}
	}
	${CondoleanceInformationFieldsFragmentDoc}
	${SitemapPageFieldsFragmentDoc}
`;
export type GetLocalSitemapCondoleanceQueryResult = Apollo.QueryResult<
	GetLocalSitemapCondoleanceQuery,
	GetLocalSitemapCondoleanceQueryVariables
>;
export const GetLocalSitemapEfroOostDocument = gql`
	query GetLocalSitemapEfroOost($locale: I18NLocaleCode) {
		efroOostInformation(locale: $locale) {
			data {
				id
				attributes {
					...efroOostInformationFields
				}
			}
		}
		sitemap {
			data {
				id
				attributes {
					...sitemapPageFields
				}
			}
		}
	}
	${EfroOostInformationFieldsFragmentDoc}
	${SitemapPageFieldsFragmentDoc}
`;
export type GetLocalSitemapEfroOostQueryResult = Apollo.QueryResult<
	GetLocalSitemapEfroOostQuery,
	GetLocalSitemapEfroOostQueryVariables
>;
export const GetLocalSitemapGeolinkDocument = gql`
	query GetLocalSitemapGeolink($locale: I18NLocaleCode) {
		geolinkInformation(locale: $locale) {
			data {
				id
				attributes {
					...geolinkInformationFields
				}
			}
		}
		sitemap {
			data {
				id
				attributes {
					...sitemapPageFields
				}
			}
		}
	}
	${GeolinkInformationFieldsFragmentDoc}
	${SitemapPageFieldsFragmentDoc}
`;
export type GetLocalSitemapGeolinkQueryResult = Apollo.QueryResult<
	GetLocalSitemapGeolinkQuery,
	GetLocalSitemapGeolinkQueryVariables
>;
export const GetLocalSitemapOpOostDocument = gql`
	query GetLocalSitemapOpOost($locale: I18NLocaleCode) {
		opOostInformation(locale: $locale) {
			data {
				id
				attributes {
					...opOostInformationFields
				}
			}
		}
		sitemap {
			data {
				id
				attributes {
					...sitemapPageFields
				}
			}
		}
	}
	${OpOostInformationFieldsFragmentDoc}
	${SitemapPageFieldsFragmentDoc}
`;
export type GetLocalSitemapOpOostQueryResult = Apollo.QueryResult<
	GetLocalSitemapOpOostQuery,
	GetLocalSitemapOpOostQueryVariables
>;
export const GetLocalSitemapRegioExpresDocument = gql`
	query GetLocalSitemapRegioExpres($locale: I18NLocaleCode) {
		regioExpresInformation(locale: $locale) {
			data {
				id
				attributes {
					...regioExpresInformationFields
				}
			}
		}
		sitemap {
			data {
				id
				attributes {
					...sitemapPageFields
				}
			}
		}
	}
	${RegioExpresInformationFieldsFragmentDoc}
	${SitemapPageFieldsFragmentDoc}
`;
export type GetLocalSitemapRegioExpresQueryResult = Apollo.QueryResult<
	GetLocalSitemapRegioExpresQuery,
	GetLocalSitemapRegioExpresQueryVariables
>;
export const GetLocalSitemapDocument = gql`
	query GetLocalSitemap($locale: I18NLocaleCode) {
		pageInformation(locale: $locale) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		sitemap {
			data {
				id
				attributes {
					...sitemapPageFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${SitemapPageFieldsFragmentDoc}
`;
export type GetLocalSitemapQueryResult = Apollo.QueryResult<GetLocalSitemapQuery, GetLocalSitemapQueryVariables>;
export const GetSubsidyDetailDocument = gql`
	query GetSubsidyDetail($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		subsidyDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...subsidyDetailSingleFields
				}
			}
		}
		subsidyBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...subsidyFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${SubsidyDetailSingleFieldsFragmentDoc}
	${SubsidyFieldsFragmentDoc}
`;
export type GetSubsidyDetailQueryResult = Apollo.QueryResult<GetSubsidyDetailQuery, GetSubsidyDetailQueryVariables>;
export const GetSubsidyOverviewDocument = gql`
	query GetSubsidyOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		subsidyOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...subsidyOverviewSingleFields
				}
			}
		}
		kinds: subsidyKinds(locale: $locale) {
			data {
				id
				attributes {
					name
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
		scales: subsidyScales(locale: $locale) {
			data {
				id
				attributes {
					name
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
		groups: targetGroups(locale: $locale) {
			data {
				id
				attributes {
					name
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
		themes(locale: $locale, publicationState: $publicationState, pagination: { limit: -1 }) {
			data {
				id
				attributes {
					...themeFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${SubsidyOverviewSingleFieldsFragmentDoc}
	${ThemeFieldsFragmentDoc}
`;
export type GetSubsidyOverviewQueryResult = Apollo.QueryResult<
	GetSubsidyOverviewQuery,
	GetSubsidyOverviewQueryVariables
>;
export const GetThemeDetailDocument = gql`
	query GetThemeDetail($locale: I18NLocaleCode, $publicationState: PublicationState, $slug: String) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		themeDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...themeDetailFields
				}
			}
		}
		themeBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			data {
				id
				attributes {
					...themeFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${ThemeDetailFieldsFragmentDoc}
	${ThemeFieldsFragmentDoc}
`;
export type GetThemeDetailQueryResult = Apollo.QueryResult<GetThemeDetailQuery, GetThemeDetailQueryVariables>;
export const GetThemeOverviewDocument = gql`
	query GetThemeOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		themeOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...ThemeOverviewSingleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${ThemeOverviewSingleFieldsFragmentDoc}
`;
export type GetThemeOverviewQueryResult = Apollo.QueryResult<GetThemeOverviewQuery, GetThemeOverviewQueryVariables>;
export const GetVacancyDetailDocument = gql`
	query GetVacancyDetail($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		vacancyDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...vacancyDetailSingleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${VacancyDetailSingleFieldsFragmentDoc}
`;
export type GetVacancyDetailQueryResult = Apollo.QueryResult<GetVacancyDetailQuery, GetVacancyDetailQueryVariables>;
export const GetVacancyInternalDetailDocument = gql`
	query GetVacancyInternalDetail($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		vacancyInternalDetail(locale: $locale) {
			data {
				id
				attributes {
					...vacancyInternalDetailSingleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${VacancyInternalDetailSingleFieldsFragmentDoc}
`;
export type GetVacancyInternalDetailQueryResult = Apollo.QueryResult<
	GetVacancyInternalDetailQuery,
	GetVacancyInternalDetailQueryVariables
>;
export const GetVacancyInternalOverviewDocument = gql`
	query GetVacancyInternalOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		vacancyInternalOverview(locale: $locale) {
			data {
				id
				attributes {
					...vacancyInternalOverviewSingleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${VacancyInternalOverviewSingleFieldsFragmentDoc}
`;
export type GetVacancyInternalOverviewQueryResult = Apollo.QueryResult<
	GetVacancyInternalOverviewQuery,
	GetVacancyInternalOverviewQueryVariables
>;
export const GetVacancyOverviewDocument = gql`
	query GetVacancyOverview($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		vacancyOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...vacancyOverviewSingleFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${VacancyOverviewSingleFieldsFragmentDoc}
`;
export type GetVacancyOverviewQueryResult = Apollo.QueryResult<
	GetVacancyOverviewQuery,
	GetVacancyOverviewQueryVariables
>;
export const GetWobRequestOverviewDocument = gql`
	query GetWobRequestOverview($locale: I18NLocaleCode, $limit: Int, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		wobRequestOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...wobRequestOverviewSingleFields
				}
			}
		}
		wobRequests(
			pagination: { limit: $limit }
			locale: $locale
			publicationState: $publicationState
			sort: "date:DESC"
			filters: { zip: { notNull: true } }
		) {
			data {
				id
				attributes {
					...wobRequestFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${WobRequestOverviewSingleFieldsFragmentDoc}
	${WobRequestFieldsFragmentDoc}
`;
export type GetWobRequestOverviewQueryResult = Apollo.QueryResult<
	GetWobRequestOverviewQuery,
	GetWobRequestOverviewQueryVariables
>;
export const GetWooDocumentCountDocument = gql`
	query GetWooDocumentCount {
		countDocumentsPerCategory {
			sitemap
			count
		}
	}
`;
export type GetWooDocumentCountQueryResult = Apollo.QueryResult<
	GetWooDocumentCountQuery,
	GetWooDocumentCountQueryVariables
>;
export const GetOpenWooRequestDetailDocument = gql`
	query GetOpenWooRequestDetail($locale: I18NLocaleCode, $publicationState: PublicationState, $slug: String) {
		openGelderlandInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...openGelderlandInformationFields
				}
			}
		}
		wooDocumentDetail(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...wooDocumentDetailSingleFields
				}
			}
		}
		wooRequestBySlug(locale: $locale, publicationState: $publicationState, fullSlug: $slug) {
			data {
				id
				attributes {
					...wooRequestFields
				}
			}
		}
	}
	${OpenGelderlandInformationFieldsFragmentDoc}
	${WooDocumentDetailSingleFieldsFragmentDoc}
	${WooRequestFieldsFragmentDoc}
`;
export type GetOpenWooRequestDetailQueryResult = Apollo.QueryResult<
	GetOpenWooRequestDetailQuery,
	GetOpenWooRequestDetailQueryVariables
>;
export const GetOpenWooRequestOverviewDocument = gql`
	query GetOpenWooRequestOverview($locale: I18NLocaleCode, $limit: Int, $publicationState: PublicationState) {
		openGelderlandInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...openGelderlandInformationFields
				}
			}
		}
		openGelderlandWooOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...openGelderlandWooRequestOverviewSingleFields
				}
			}
		}
		wooCategories(pagination: { limit: 20 }) {
			data {
				id
				attributes {
					...wooCategoryFields
				}
			}
		}
		wooRequests(
			pagination: { limit: $limit }
			locale: $locale
			publicationState: $publicationState
			sort: ["startDate:DESC", "id:ASC"]
			filters: { zip: { notNull: true } }
		) {
			data {
				id
				attributes {
					...wooRequestFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
		countDocumentsPerCategory {
			sitemap
			count
		}
	}
	${OpenGelderlandInformationFieldsFragmentDoc}
	${OpenGelderlandWooRequestOverviewSingleFieldsFragmentDoc}
	${WooCategoryFieldsFragmentDoc}
	${WooRequestFieldsFragmentDoc}
`;
export type GetOpenWooRequestOverviewQueryResult = Apollo.QueryResult<
	GetOpenWooRequestOverviewQuery,
	GetOpenWooRequestOverviewQueryVariables
>;
export const GetWooRequestDetailpageDocument = gql`
	query GetWooRequestDetailpage($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		wooRequestBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			...wooRequestBySlugFields
		}
	}
	${PageInformationFieldsFragmentDoc}
	${WooRequestBySlugFieldsFragmentDoc}
`;
export type GetWooRequestDetailpageQueryResult = Apollo.QueryResult<
	GetWooRequestDetailpageQuery,
	GetWooRequestDetailpageQueryVariables
>;
export const GetWooRequestDetailXmlDocument = gql`
	query GetWooRequestDetailXML($locale: I18NLocaleCode, $slug: String, $publicationState: PublicationState) {
		wooRequestBySlug(locale: $locale, fullSlug: $slug, publicationState: $publicationState) {
			...wooRequestBySlugFields
		}
	}
	${WooRequestBySlugFieldsFragmentDoc}
`;
export type GetWooRequestDetailXmlQueryResult = Apollo.QueryResult<
	GetWooRequestDetailXmlQuery,
	GetWooRequestDetailXmlQueryVariables
>;
export const GetWooRequestOverviewDocument = gql`
	query GetWooRequestOverview($locale: I18NLocaleCode, $limit: Int, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		wooRequestOverview(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...wooRequestOverviewSingleFields
				}
			}
		}
		wooRequests(
			pagination: { limit: $limit }
			locale: $locale
			publicationState: $publicationState
			sort: ["date:DESC", "id:ASC"]
			filters: { zip: { notNull: true } }
		) {
			data {
				id
				attributes {
					...wooRequestFields
				}
			}
			meta {
				pagination {
					total
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${WooRequestOverviewSingleFieldsFragmentDoc}
	${WooRequestFieldsFragmentDoc}
`;
export type GetWooRequestOverviewQueryResult = Apollo.QueryResult<
	GetWooRequestOverviewQuery,
	GetWooRequestOverviewQueryVariables
>;
export const GetWooRequestOverviewXmlDocument = gql`
	query GetWooRequestOverviewXML($locale: I18NLocaleCode, $publicationState: PublicationState, $sitemap: String) {
		wooDocuments: wooRequests(
			pagination: { limit: 1000 }
			locale: $locale
			publicationState: $publicationState
			filters: { category: { sitemap: { eq: $sitemap } } }
		) {
			data {
				id
				attributes {
					...wooRequestFields
				}
			}
		}
	}
	${WooRequestFieldsFragmentDoc}
`;
export type GetWooRequestOverviewXmlQueryResult = Apollo.QueryResult<
	GetWooRequestOverviewXmlQuery,
	GetWooRequestOverviewXmlQueryVariables
>;
export const GetWorkAtDocument = gql`
	query GetWorkAt($locale: I18NLocaleCode, $publicationState: PublicationState) {
		pageInformation(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...pageInformationFields
				}
			}
		}
		workAt(locale: $locale, publicationState: $publicationState) {
			data {
				id
				attributes {
					...workAtFields
				}
			}
		}
	}
	${PageInformationFieldsFragmentDoc}
	${WorkAtFieldsFragmentDoc}
`;
export type GetWorkAtQueryResult = Apollo.QueryResult<GetWorkAtQuery, GetWorkAtQueryVariables>;

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		AgendaPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'Error'
		],
		CondoleanceHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		CondoleancePageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		ContactModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesLinkBox',
			'ComponentModulesPartners',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		DecisionsPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'Error'
		],
		DownloadsPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'Error'
		],
		EerbeekloenenHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		EerbeekloenenInConvoArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		EerbeekloenenInConvoOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EerbeekloenenInConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EerbeekloenenNewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EerbeekloenenNewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EerbeekloenenNewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		EerbeekloenenPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		EfroOostHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		EfroOostInConvoWithArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		EfroOostInConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EfroOostInConvoWithOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EfroOostNewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EfroOostNewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		EfroOostNewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		EfroOostPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		EventOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesEventOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		GenericMorph: [
			'AgendaPage',
			'AuditLogAuditLog',
			'ComponentComponentsButton',
			'ComponentComponentsIconLink',
			'ComponentComponentsIconLinkGroup',
			'ComponentComponentsLink',
			'ComponentComponentsLinkGroup',
			'ComponentComponentsSubject',
			'ComponentInternalAboutMaintenance',
			'ComponentInternalAccordeonItem',
			'ComponentInternalAccordeonSection',
			'ComponentInternalBodyTextColumn',
			'ComponentInternalDefaultModuleOptions',
			'ComponentInternalDefaultModuleOptionsExtended',
			'ComponentInternalEmptyState',
			'ComponentInternalFirstRowItems',
			'ComponentInternalFooter',
			'ComponentInternalHighlightEvents',
			'ComponentInternalHighlightMapsAndStats',
			'ComponentInternalHighlightNews',
			'ComponentInternalHighlightPermits',
			'ComponentInternalHighlightSubsidies',
			'ComponentInternalHighlightTopics',
			'ComponentInternalHighlightWoo',
			'ComponentInternalNavbar',
			'ComponentInternalNewsletter',
			'ComponentInternalPhoto',
			'ComponentInternalSecondColumnItems',
			'ComponentInternalSeo',
			'ComponentInternalSidebar',
			'ComponentInternalStepByStepCard',
			'ComponentInternalTest',
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCollections',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesEventOverview',
			'ComponentModulesExpandedHeader',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedNewsEerbeekloenen',
			'ComponentModulesFeaturedNewsEfroOost',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesHomepageHeader',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesIntroHeader',
			'ComponentModulesIntroHeaderImage',
			'ComponentModulesLinkBox',
			'ComponentModulesMapsFiguresOverview',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesOpenWooRequestOverview',
			'ComponentModulesPartners',
			'ComponentModulesPermitsOverview',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesPressReleaseOverview',
			'ComponentModulesProjectOverview',
			'ComponentModulesRegions',
			'ComponentModulesRoadMaintenanceOverview',
			'ComponentModulesScheduling',
			'ComponentModulesSearchHeader',
			'ComponentModulesSearchOverview',
			'ComponentModulesSeo',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesSubsidyOverview',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'ComponentModulesThemes',
			'ComponentModulesVacanciesOverview',
			'ComponentModulesWobRequestOverview',
			'ComponentModulesWooRequestOverview',
			'ComponentSdgSdgLink',
			'ComponentSdgSdgProductVertaling',
			'CondoleanceHomepage',
			'CondoleanceInformation',
			'CondoleancePage',
			'Contact',
			'ConversationsConversationitem',
			'ConversationsConversations',
			'CookiePage',
			'DecisionsPage',
			'DownloadsPage',
			'EerbeekloenenHomepage',
			'EerbeekloenenInConvoArticle',
			'EerbeekloenenInConvoOverview',
			'EerbeekloenenInConvoWithDetail',
			'EerbeekloenenInformation',
			'EerbeekloenenNewsDetail',
			'EerbeekloenenNewsOverview',
			'EerbeekloenenNewsarticle',
			'EerbeekloenenPage',
			'EfroOostHomepage',
			'EfroOostInConvoWithArticle',
			'EfroOostInConvoWithDetail',
			'EfroOostInConvoWithOverview',
			'EfroOostInformation',
			'EfroOostNewsDetail',
			'EfroOostNewsOverview',
			'EfroOostNewsarticle',
			'EfroOostPage',
			'Event',
			'EventOverview',
			'GeolinkHomepage',
			'GeolinkInformation',
			'GeolinkPage',
			'HomePage',
			'I18NLocale',
			'InConvoWithArticle',
			'InConvoWithDetail',
			'InConvoWithOverview',
			'InternalLinkInternalLink',
			'InternalLinkInternalLinkWysiwyg',
			'Login',
			'MapsFigure',
			'MapsFiguresOverview',
			'NewsDetail',
			'NewsOverview',
			'Newsarticle',
			'Newsletter',
			'NewsletterArchiveOverview',
			'NewsletterInternal',
			'NewsletterPublic',
			'NotFoundPage',
			'NoteNote',
			'NoteNoterelation',
			'OpOostHomepage',
			'OpOostInConvoWithArticle',
			'OpOostInConvoWithDetail',
			'OpOostInConvoWithOverview',
			'OpOostInformation',
			'OpOostNewsDetail',
			'OpOostNewsOverview',
			'OpOostNewsarticle',
			'OpOostPage',
			'OpenGelderlandHomepage',
			'OpenGelderlandInformation',
			'OpenGelderlandWooOverview',
			'Page',
			'PageInformation',
			'PasswordPage',
			'Permit',
			'PermitDetail',
			'PermitLaw',
			'PermitProductType',
			'PermitsOverview',
			'PressRelease',
			'PressReleaseDetail',
			'PressReleaseOverview',
			'PrivacyPolicy',
			'Project',
			'ProjectDetail',
			'ProjectOverview',
			'RecordLockingOpenEntity',
			'RecycleBinRecycleBinItem',
			'RegioExpresHomepage',
			'RegioExpresInConvoOverview',
			'RegioExpresInConvoWithArticle',
			'RegioExpresInConvoWithDetail',
			'RegioExpresInformation',
			'RegioExpresNewsDetail',
			'RegioExpresNewsOverview',
			'RegioExpresNewsarticle',
			'RegioExpresPage',
			'Region',
			'RegionDetail',
			'RegionOverview',
			'RoadMaintenance',
			'RoadMaintenanceDetail',
			'RoadMaintenanceOverview',
			'SchedulerScheduler',
			'SdgLocatie',
			'SdgSdg',
			'SdgUplLijst',
			'SearchOverview',
			'Sitemap',
			'Subsidy',
			'SubsidyDetail',
			'SubsidyKind',
			'SubsidyOverview',
			'SubsidyScale',
			'Tag',
			'TargetGroup',
			'Theme',
			'ThemeDetail',
			'ThemeOverview',
			'Upl',
			'UploadFile',
			'UploadFolder',
			'UsersPermissionsPermission',
			'UsersPermissionsRole',
			'UsersPermissionsUser',
			'VacancyDetail',
			'VacancyInternalDetail',
			'VacancyInternalOverview',
			'VacancyOverview',
			'WobRequest',
			'WobRequestOverview',
			'WooCategory',
			'WooDocumentDetail',
			'WooRequest',
			'WooRequestOverview',
			'WorkAt'
		],
		GeolinkHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		GeolinkPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		HomePageModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesSubjects',
			'ComponentModulesTestimonial',
			'ComponentModulesThemes',
			'Error'
		],
		InConvoWithArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		InConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		InConvoWithOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		LoginModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesVacanciesOverview',
			'Error'
		],
		MapsFiguresOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		NewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesSubjects',
			'Error'
		],
		NewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		NewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		NewsletterArchiveOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesPermitsOverview',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'Error'
		],
		OpOostHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		OpOostInConvoWithArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		OpOostInConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		OpOostInConvoWithOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		OpOostNewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		OpOostNewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		OpOostNewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		OpOostPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsOpOost',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		OpenGelderlandHomepageModulesDynamicZone: ['ComponentModulesCta', 'Error'],
		OpenGelderlandWooOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		PageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'ComponentModulesThemes',
			'Error'
		],
		PasswordPageModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesSubjects',
			'ComponentModulesTestimonial',
			'ComponentModulesThemes',
			'Error'
		],
		PermitDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'Error'
		],
		PermitModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		PermitsOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesPermitsOverview',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'Error'
		],
		PressReleaseDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesSubjects',
			'Error'
		],
		PressReleaseModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		PressReleaseOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesPressReleaseOverview',
			'Error'
		],
		ProjectDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		ProjectModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		ProjectOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesProjectOverview',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegioExpresHomepageModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegioExpresInConvoOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesInConvoWithOverview',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		RegioExpresInConvoWithArticleModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegioExpresInConvoWithDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		RegioExpresNewsDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		RegioExpresNewsOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'Error'
		],
		RegioExpresNewsarticleModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesShare',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegioExpresPageModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNewsRegioExpres',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPartners',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'ComponentModulesTestimonial',
			'Error'
		],
		RegionDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesThemes',
			'Error'
		],
		RegionModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesThemes',
			'Error'
		],
		RegionOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		RoadMaintenanceDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'Error'
		],
		RoadMaintenanceModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesScheduling',
			'ComponentModulesShare',
			'ComponentModulesStepByStep',
			'ComponentModulesTable',
			'Error'
		],
		RoadMaintenanceOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		SearchOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		SitemapModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'Error'
		],
		SubsidyDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		SubsidyModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		SubsidyOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesSubjects',
			'ComponentModulesSubsidyOverview',
			'ComponentModulesThemes',
			'Error'
		],
		ThemeDetailModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesThemes',
			'Error'
		],
		ThemeModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesNewsOverview',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesThemes',
			'Error'
		],
		ThemeOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesRegions',
			'ComponentModulesScheduling',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'ComponentModulesTable',
			'ComponentModulesThemes',
			'Error'
		],
		VacancyDetailModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesSubjects',
			'Error'
		],
		VacancyInternalDetailModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesFeaturedNews',
			'ComponentModulesFeaturedPages',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesSubjects',
			'Error'
		],
		VacancyInternalOverviewModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesVacanciesOverview',
			'Error'
		],
		VacancyOverviewModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesVacanciesOverview',
			'Error'
		],
		WobRequestOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		WooDocumentDetailModulesDynamicZone: [
			'ComponentModulesBbvmsMediaplayer',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesLinkBox',
			'ComponentModulesShare',
			'Error'
		],
		WooRequestOverviewModulesDynamicZone: [
			'ComponentModulesAccordeon',
			'ComponentModulesBodyText',
			'ComponentModulesBodyTextTwoColumn',
			'ComponentModulesCta',
			'ComponentModulesDownloads',
			'ComponentModulesHighlight',
			'ComponentModulesLinkBox',
			'ComponentModulesMedia',
			'ComponentModulesPhotoAlbum',
			'ComponentModulesStepByStep',
			'ComponentModulesSubjects',
			'Error'
		],
		WorkAtModulesDynamicZone: [
			'ComponentModulesBodyText',
			'ComponentModulesCta',
			'ComponentModulesLinkBox',
			'ComponentModulesPartners',
			'Error'
		]
	}
};
export default result;
